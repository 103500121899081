import Text from '@atoms/Text/Text';
import { useEffect, useMemo, useState } from 'react';
import { handleOrderData, handleReceiptData, handleVoucherData } from '@helpers/HandlePersonalElementsData';
import useTranslation from 'next-translate/useTranslation';
import Modal from '@organisms/Modal/Modal';
import { startChangingOrder } from '@api/interfaces/orderApi';
import webToAppApi from '@api/web-to-app';
import paths from '@constants/paths';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useUserAgent from '@hooks/useUserAgent';
import useCustomRouter from '@hooks/useCustomRouter';
import { setChangeOrderModalVisibility } from '@slices/modalSlice';
import { isAxiosError } from 'axios';
import logger from '@logger';
import {
  trackPersonalElementsOrderOnClick,
  trackPersonalElementsReceiptOnClick,
  trackPersonalElementsVouchersOnClick,
} from '@helpers/analyticsHelpers/trackPersonalElements';
import {
  StyledOrderHeading,
  StyledPersonalElementsCard,
  StyledPrice,
  StyledText,
  StyledTextAndIconWrapper,
  StyledTextWithMargin,
  StyledVoucherHeading,
} from './PersonalElementsCard.styles';
import { StyledGenericPersonalCardTextWrapper } from '@molecules/GenericPersonalCard/GenericPersonalCard.styles';
import Button from '@atoms/Button/Button';
import useCustomer from '@hooks/useCustomer';
import Icon from '@atoms/Icon/Icon';
import getDisplayVoucher from '@helpers/getDisplayVoucher';
import useVouchers from '@hooks/useVouchers';
import type { AxfoodExternalVoucher, AxfoodPersonalElementViewModel } from '@api/generated/storefront';
import SharedAccount from '@icons/shared-account.svg';
import useCart from '@hooks/useCart';

export enum discountTypes {
  ABSOLUTE = 'Absolut värde',
}

export const initialCardData = {
  title: '',
  text: '',
  button: '',
};
export type CardDataType = typeof initialCardData;

type CardVariantType = 'order' | 'receipt' | 'voucher';

export interface Props {
  variant: CardVariantType;
  personalElement: AxfoodPersonalElementViewModel;
}

const PersonalElementsCard = ({ personalElement, variant }: Props) => {
  const [cardData, setCardData] = useState<CardDataType>(initialCardData);
  const [showChangeOrderModal, setShowChangeOrderModal] = useState<boolean>(false);
  const [showVoucherModal, setShowVoucherModal] = useState<boolean>(false);
  const [displayVoucher, setDisplayVoucher] = useState<AxfoodExternalVoucher>({} as AxfoodExternalVoucher);
  const [voucherType, setVoucherType] = useState('');
  const { customer } = useCustomer();
  const { onlineVouchers, offlineVouchers } = useVouchers();
  const receiptBaseUrl = '/axfood/rest/order/orders';
  const { t } = useTranslation('personalElements');
  const { cart, refreshCart } = useCart();
  const dispatch = useAppDispatch();
  const { isNativeApp } = useUserAgent();
  const router = useCustomRouter();

  const isOnlineOnly = useMemo(() => {
    if (displayVoucher?.voucherChannelData) {
      const { online, omni, selfcheckout, selfscan, checkout } = displayVoucher.voucherChannelData;
      return online && !omni && !selfcheckout && !selfscan && !checkout;
    }
    return false;
  }, [displayVoucher]);

  const changeOrder = async () => {
    try {
      const openOrder = await startChangingOrder(personalElement?.order?.orderCode);
      setShowChangeOrderModal(false);
      await refreshCart(openOrder.data);

      if (isNativeApp) {
        webToAppApi.actionShowUpdatedCartAfterReopeningOrder(openOrder.data.totalUnitCount);
      } else {
        router.push({ pathname: paths.CART }, { pathname: paths.CART });
      }
    } catch (e) {
      if (isAxiosError(e)) {
        logger.error({ error: 'Failed to start changing order', message: e.message, responseData: e.response?.data });
      }
    }
  };

  const orderClickHandler = () => {
    let status = '';
    if (
      (personalElement?.order?.orderStatus === 'CHANGING' ||
        cart?.orderReference === personalElement?.order?.orderCode) &&
      customer?.idInSourceSystem === personalElement?.order?.placedByIdInSourceSystem
    ) {
      dispatch(setChangeOrderModalVisibility(true));
    } else if (
      personalElement?.order?.editable &&
      customer?.idInSourceSystem === personalElement.order.placedByIdInSourceSystem
    ) {
      status = 'change_ongoing';
      setShowChangeOrderModal(true);
    } else {
      status = 'show';
      if (isNativeApp) {
        webToAppApi.actionOpenOrderDetails(personalElement?.order?.orderCode, '');
      } else {
        router.replace(
          { pathname: paths.ACCOUNT_ORDER_DETAILS, query: { ...router.query } },
          { pathname: `${paths.ACCOUNT_ORDERS}/${personalElement?.order?.orderCode}` }
        );
      }
    }

    if (status) {
      trackPersonalElementsOrderOnClick(status);
    }
  };

  const voucherClickHandler = () => {
    trackPersonalElementsVouchersOnClick();
    setShowVoucherModal(true);
  };

  const receiptClickHandler = () => {
    const receiptData = personalElement.digitalReceipt!;
    let url = `${receiptBaseUrl}/${personalElement.digitalReceipt?.orderNumber}/receipt`;

    if (receiptData.bookingDate && receiptData.digitalReceiptReference && receiptData.digitalReceiptAvailable) {
      url = `${receiptBaseUrl}/digitalreceipt/${receiptData.digitalReceiptReference}?date=${
        new Date(receiptData.bookingDate).toISOString().split('T')[0]
      }&storeId=${receiptData.storeCustomerId}&source=${receiptData.receiptSource}&memberCardNumber=${
        receiptData.memberCardNumber
      }`;
    }

    trackPersonalElementsReceiptOnClick();

    window.open(url, '_blank');
  };

  const onClickHandler = () => {
    switch (variant) {
      case 'order':
        return orderClickHandler();
      case 'receipt':
        return receiptClickHandler();
      default:
        return voucherClickHandler();
    }
  };

  useEffect(() => {
    const voucherArray = (onlineVouchers?.available || []).concat(offlineVouchers?.available || []);
    if (voucherArray?.length > 0) {
      getDisplayVoucher(voucherArray, setVoucherType, setDisplayVoucher);
    }
  }, [onlineVouchers, offlineVouchers]);

  useEffect(() => {
    switch (variant) {
      case 'order':
        if (personalElement.order) {
          setCardData(handleOrderData(personalElement.order, t, customer, cart));
        }
        break;
      case 'receipt':
        if (personalElement.digitalReceipt) {
          setCardData(handleReceiptData(personalElement.digitalReceipt, t));
        }
        break;
      default:
        setCardData(handleVoucherData(displayVoucher, t));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, personalElement, displayVoucher]);

  return (
    <>
      {cardData && (
        <StyledPersonalElementsCard variant={variant}>
          <StyledGenericPersonalCardTextWrapper>
            <Text type="label">
              {variant === 'voucher' ? (
                <>
                  {cardData.title.split(':')[0]}
                  <StyledPrice>{cardData.title.split(':')[1]}</StyledPrice>
                </>
              ) : (
                <StyledTextAndIconWrapper>
                  {cardData.title}
                  {((variant === 'receipt' &&
                    customer?.idInSourceSystem !== personalElement.digitalReceipt?.memberCardNumber) ||
                    (variant === 'order' &&
                      customer?.idInSourceSystem !== personalElement?.order?.placedByIdInSourceSystem)) && (
                    <Icon svg={SharedAccount} size={20} />
                  )}
                </StyledTextAndIconWrapper>
              )}
            </Text>

            <StyledText type="body" size="small">
              {cardData.text}
            </StyledText>
          </StyledGenericPersonalCardTextWrapper>
          <Button type="button" theme="secondary-light-bg" size="small" onClick={onClickHandler}>
            <Text type="subhead" size="small">
              {cardData.button}
            </Text>
          </Button>
        </StyledPersonalElementsCard>
      )}

      {showChangeOrderModal && (
        <Modal
          size="small"
          onClose={() => setShowChangeOrderModal(false)}
          onConfirm={changeOrder}
          confirmButtonText={t('order:modal->buttons->yes')}
          cancelButtonText={t('order:modal->buttons->no')}
          closeOnEscape
          buttonWidth={100}
        >
          <StyledOrderHeading variant="h2" size="small">
            {t('order:modal->title->startChangingOrder')}
          </StyledOrderHeading>

          <Text type="body" size="responsive">
            {t('order:modal->text->startChangingOrder')}
          </Text>
        </Modal>
      )}

      {showVoucherModal && displayVoucher && (
        <Modal
          size="small"
          onClose={() => setShowVoucherModal(false)}
          onConfirm={() => setShowVoucherModal(false)}
          confirmButtonText={t('order:modal->buttons->ok')}
          closeOnEscape
          buttonWidth={100}
        >
          <StyledVoucherHeading variant="h2" size="small">
            {t('voucher->modal->title', {
              value: displayVoucher.discountValue,
              unit:
                displayVoucher.discountType === discountTypes.ABSOLUTE
                  ? t('voucher->modal->absoluteValue')
                  : t('voucher->modal->percentageValue'),
            })}
          </StyledVoucherHeading>

          <StyledTextWithMargin type="body" size="small">
            {t(`voucher->modal->${voucherType}->text`)}
          </StyledTextWithMargin>
          <Text type="body" size="small" data-testid="voucher-requirements-and-validIn">
            {!!displayVoucher.minPurchaseAmount &&
              t(`voucher->modal->${voucherType}->requirements`, {
                value: displayVoucher.minPurchaseAmount,
              })}
            {t(`voucher->modal->${voucherType}->validIn`)}
          </Text>
          <StyledTextWithMargin type="body" size="small">
            {t('voucher->modal->validUntil', {
              date: displayVoucher.expirationDate,
            })}
          </StyledTextWithMargin>
          {!isOnlineOnly && (
            <>
              <Text type="label">{t('voucher->modal->code')}</Text>
              <StyledTextWithMargin type="price" size="large" color="red">
                {displayVoucher.voucherNbr}
              </StyledTextWithMargin>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default PersonalElementsCard;
