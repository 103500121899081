import { useImpressionTracker } from 'impression-tracker-react-hook';
import type { AxfoodProductDetailsViewModel } from '@api/storeFrontApi';
import logger from '@logger';
import { ReactNode, isValidElement, cloneElement } from 'react';

export interface Props {
  index: number;
  product: AxfoodProductDetailsViewModel;
  children: ReactNode;
  callback: (product: AxfoodProductDetailsViewModel, index: number) => void;
}

const TrackedComponent = ({ index, children, product, callback }: Props) => {
  const logImpression = () => {
    callback(product, index);
  };

  const handleError = (error: Error) => {
    logger.error({ error: 'Product tracking impression failed', message: error.message });
  };

  const [ref] = useImpressionTracker({
    logImpression,
    handleError,
    contentId: product.code ? product.code : product.name,
  });

  // If children is a single child, add the ref using cloneElement
  if (isValidElement(children)) {
    return cloneElement(children, { ref });
  }

  return <>{children}</>;
};

export default TrackedComponent;
