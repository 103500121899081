import { trackProductImpressionsWithPosition } from '@helpers/analyticsHelpers/trackProductImpressions';
import debounce from '@helpers/debounce';
import Config from '@config';
import type { AxfoodProductDetailsViewModel } from '@api/storeFrontApi';

type AnalyticsBufferType = { position: number; product: AxfoodProductDetailsViewModel };
const MAX_PRODUCTS = 10;
const useProductImpressionTracking = (listName: string) => {
  const itemsInView: AnalyticsBufferType[] = [];

  const track = (productCategory: string) => {
    while (itemsInView.length > 0) {
      const bufferItems = itemsInView.splice(0, MAX_PRODUCTS);
      const itemsToTrack = [...new Map(bufferItems.map((item) => [item.position, item])).values()];
      if (itemsToTrack.length) {
        trackProductImpressionsWithPosition(itemsToTrack, productCategory);
      }
    }
  };
  const debouncedTrack = debounce(track, Config.TIMEOUT.productsWithTrackingTrackMs);
  const onProductShown = (
    product: AxfoodProductDetailsViewModel | ImageGridComponentType,
    position: number,
    category?: string
  ) => {
    if (!!itemsInView && !('typeCode' in product)) {
      itemsInView.push({ product, position });
      debouncedTrack(!!category ? category : listName);
    }
  };
  return { onProductShown };
};
export default useProductImpressionTracking;
