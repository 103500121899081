import {
  Api,
  CustomerData,
  RegisterB2BCustomerForm,
  RegisterCustomerForm,
  SearchCompanyForm,
  VerifyMembersEmailFlowForm,
  VerifyMembersPhoneFlowForm,
} from '@api/storeFrontApi';

// Override optional properties from API
export interface OverriddenCustomerData extends CustomerData {
  isB2BCustomer: boolean;
  uid: string;
  isAnonymous: boolean;
  sapId: string;
  socialSecurityNumer: string;
  suppressAllEmails: boolean;
}

const api = new Api({
  baseUrl: '',
});

export const getCustomer = async (): Promise<OverriddenCustomerData> => {
  const response = await api.axfood.getSessionInformationUsingGet();
  const customer = response.data;

  // Override and default properties from API
  return {
    ...customer,
    uid: customer.uid || 'anonymous',
    isB2BCustomer: customer.isB2BCustomer || false,
    isAnonymous: customer.uid === 'anonymous' || false,
    sapId: customer.uid !== 'anonymous' ? customer.sapId ?? '' : '',
    socialSecurityNumer: customer.uid !== 'anonymous' ? customer.socialSecurityNumer ?? '' : '',
    suppressAllEmails: customer?.suppressAllEmails ?? false,
  };
};

export const lookupB2bCustomer = (form: SearchCompanyForm) => api.axfood.lookupB2BCustomerUsingPost(form);

export const registerB2cCustomer = (form: RegisterCustomerForm, autoLogin?: boolean) =>
  api.customers.registerCustomerUsingPost(form, { autoLogin });

export const deleteSavedCard = (cardId: string) => api.axfood.deleteAgreementUsingDelete(cardId);

export const registerB2BCustomer = async (form: RegisterB2BCustomerForm) => {
  const response = await api.axfood.registerB2BCustomerUsingPost(form);
  return response.data;
};

export const verifyAccountWithEmail = (form: VerifyMembersEmailFlowForm) => api.axfood.verifyEmailUsingPost(form);

export const verifyAccountWithPhone = (form: VerifyMembersPhoneFlowForm) => api.axfood.verifyPhoneUsingPost(form);
