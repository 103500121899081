/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddRecipeReplacementProducts {
  recipeId: string;
  replacedProductCode: string;
  replacementProductCode: string;
}

export interface AddRecipeToCartRequestBody {
  recipeId: string;
}

export interface AddressData {
  appartment?: string;
  billingAddress?: boolean;
  cellphone?: string;
  companyName?: string;
  country?: CountryData;
  customerComment?: string;
  defaultAddress?: boolean;
  district?: string;
  doorCode?: string;
  email?: string;
  firstName?: string;
  formattedAddress?: string;
  id?: string;
  lastName?: string;
  /** @format double */
  latitude?: number;
  line1?: string;
  line2?: string;
  /** @format double */
  longitude?: number;
  phone?: string;
  postalCode?: string;
  region?: RegionData;
  shippingAddress?: boolean;
  title?: string;
  titleCode?: string;
  town?: string;
  visibleInAddressBook?: boolean;
}

export interface AddToCartForm {
  hideDiscountToolTip?: boolean;
  noReplacementFlag?: boolean;
  pickUnit: string;
  productCodePost: string;
  /**
   * @format double
   * @min 0
   * @exclusiveMin false
   */
  qty: number;
}

export interface AddToCartFormListWrapper {
  products?: AddToCartForm[];
  storeId?: string;
}

export interface AnimalData {
  additiveStatement?: string;
  compositionStatement?: string;
  constituentStatement?: string;
  feedingData?: FeedingData[];
  feedingFrequencies?: FeedingFrequencyData[];
  feedingInstructions?: string;
  targetedConsumptionBy?: string;
  typeCode?: string;
}

export interface ApiError {
  additionalInfo?: object;
  errorCode?: string;
  errorMessage?: string;
  errors?: string[];
  timestamp?: string;
  url?: string;
}

export interface AppVersionData {
  minSupportedFailureMessage?: string;
  minSupportedFailureTitle?: string;
  minSupportedVersion?: string;
  recommendedFailureMessage?: string;
  recommendedFailureTitle?: string;
  recommendedVersion?: string;
}

export interface ArticleContentPageData {
  articleType?: string;
  description?: string;
  image?: MediaData;
  link?: string;
  publicationDate?: string;
  title?: string;
}

export interface AssetUrls {
  descriptive?: string;
  standard?: string;
}

export interface AutocompleteResultData {
  products?: ProductData[];
  suggestions?: AutocompleteSuggestionData[];
}

export interface AutocompleteSuggestionData {
  term?: string;
}

export interface AxfoodAbstractOrderViewModelOfOrderData {
  ageRestricted15: boolean;
  ageRestricted18: boolean;
  allergyApproved: boolean;
  anonymousOrder: boolean;
  anonymousOrderDetailLink?: string;
  appliedVouchers: VoucherViewModel[];
  ascFish: boolean;
  b2bCustomer: boolean;
  b2bSlot?: boolean;
  bufferedAmount?: string;
  bufferedPercent?: string;
  companyName?: string;
  companyStoreEmail?: string;
  companyStoreName?: string;
  companyStorePhone?: string;
  coupons?: VoucherViewModel[];
  customerComment?: string;
  customerNewProgramEnabled: boolean;
  deliveryAddress?: AddressData;
  deliveryCost?: string;
  deliveryModeCode?: string;
  deliveryModeName?: string;
  deliveryOrPickUpOriginalFee?: PriceData;
  deliverySlot?: boolean;
  doorCode?: string;
  ecological: boolean;
  ekoReplacement: boolean;
  employeeDiscountTotal?: string;
  environmentalChoice: boolean;
  euEcological: boolean;
  euFlower: boolean;
  fairtrade: boolean;
  franchiseStore: boolean;
  freeShipping: boolean;
  freeShippingVoucher?: VoucherViewModel;
  frequentShopperPointsAmount?: string;
  frozen: boolean;
  ghsSignalWordDanger: boolean;
  ghsSignalWordNotApplicable: boolean;
  ghsSignalWordWarning: boolean;
  ghsSymbolCorrosion: boolean;
  ghsSymbolEnvironment: boolean;
  ghsSymbolExcalmationMark: boolean;
  ghsSymbolExplodingBomb: boolean;
  ghsSymbolFlame: boolean;
  ghsSymbolFlameOverCircle: boolean;
  ghsSymbolGasCylinder: boolean;
  ghsSymbolHealthHazard: boolean;
  ghsSymbolNoPictogram: boolean;
  ghsSymbolSkullAndCrossbones: boolean;
  glutenfree: boolean;
  hasNicotineMedical: boolean;
  isocode?: string;
  keyhole: boolean;
  klarnaPaymentMethod?: string;
  krav: boolean;
  laktosfree: boolean;
  localAgreementDiscountTotal?: string;
  localB2bCustomer: boolean;
  mscFish: boolean;
  notEditableAfterOrder: boolean;
  oldOrderPrice?: PriceData;
  orderDate?: string;
  orderReference?: string;
  paymentType?: string;
  pickAndPackCost?: string;
  pickQuantities?: Record<string, number>;
  pickUpCost?: string;
  pickUpExternalLocationCost?: string;
  priceDiff?: PriceData;
  reservedAmount?: string;
  serviceCost?: string;
  slot?: TimeSlotData;
  /** @format int32 */
  slotCloseDateTime?: number;
  /** @format int32 */
  slotCloseDay: number;
  slotCloseTime?: string;
  slotCloseTimeFormatted?: string;
  slotCode?: string;
  slotFormattedDate?: string;
  slotPickAndPackCost?: string;
  /** @format int32 */
  slotStartDateTime?: number;
  /** @format int32 */
  slotStartDay: number;
  storeChangeAllowed: boolean;
  subTotalPrice: string;
  subTotalPriceWithDiscountsAndVouchers: string;
  subTotalPriceWithoutDiscounts?: string;
  subTotalPriceWithoutDiscountsNumber?: number;
  subTotalWithDiscounts?: string;
  subtotalWithDiscountsAndPercentageVouchers?: PriceData;
  swan: boolean;
  swedishBird: boolean;
  swedishFlag: boolean;
  swedishMeat: boolean;
  taxes?: Record<string, string>;
  totalDepositSum: string;
  totalDiscount: string;
  totalDiscountValue: number;
  totalGeneralDiscount: string;
  totalGeneralDiscountValue: number;
  /** @format int32 */
  totalItems: number;
  totalLoyaltyDiscount: string;
  totalPrice: string;
  totalPromotionDiscount: string;
  totalPromotionDiscountValue: number;
  totalTax: string;
  /** @format int32 */
  totalUnitCount: number;
  usersOrderReference: string;
}

export interface AxfoodAddressValidationResultAddressViewModel {
  letter?: string;
  locality: string;
  postalcode: string;
  street: string;
  street_number: string;
}

export interface AxfoodAddressValidationResultResponseViewModel {
  interpretation?: AxfoodAddressValidationResultAddressViewModel;
  /** @example false */
  is_valid: boolean;
  match?: AxfoodAddressValidationResultAddressViewModel;
  query?: AxfoodAddressValidationResultAddressViewModel;
  /** @format int32 */
  status: number;
  status_text: string;
}

export interface AxfoodAddressValidationResultViewModel {
  charset: string;
  name: string;
  response: AxfoodAddressValidationResultResponseViewModel;
  type: string;
}

export interface AxfoodAuthSignResponse {
  autoStartToken?: string;
  orderRef?: string;
}

export interface AxfoodBasicCategoryViewModel {
  id?: string;
  searchUrl?: string;
  title?: string;
  url?: string;
}

export interface AxfoodBasicOrderViewModel {
  /** @format int32 */
  orderDate: number;
  orderNumber: string;
}

export interface AxfoodBasicProductViewModel {
  addToCartDisabled?: boolean;
  addToCartMessage?: string;
  /** @format double */
  averageWeight?: number;
  bargainProduct?: boolean;
  code: string;
  comparePrice?: string;
  comparePriceUnit?: string;
  depositPrice?: string;
  displayVolume?: string;
  energyDeclaration?: EnergyDeclaration;
  gdprTrackingIncompliant?: boolean;
  googleAnalyticsCategory?: string;
  hazards: string[];
  image?: ImageData;
  inactivePotentialPromotions: AxfoodPromotionViewModel[];
  /** @format double */
  incrementValue?: number;
  isDrugProduct?: boolean;
  labels?: string[];
  manufacturer?: string;
  name: string;
  newsSplashProduct?: boolean;
  nicotineMedicalProduct?: boolean;
  nonEkoProduct?: boolean;
  /** @example false */
  notAllowedAnonymous: boolean;
  /** @example false */
  notAllowedB2b: boolean;
  /** @example false */
  online: boolean;
  outOfStock?: boolean;
  pickupProductLine2?: string;
  potentialPromotions: AxfoodPromotionViewModel[];
  precautionaryStatements: string[];
  price?: string;
  priceNoUnit?: string;
  priceUnit?: string;
  priceValue?: number;
  productBasketType?: ProductBasketTypeEnum;
  productLine2?: string;
  /** @format double */
  ranking?: number;
  savingsAmount?: number;
  seoDescription?: string;
  seoTitle?: string;
  /** @example false */
  showGoodPriceIcon: boolean;
  /** @format double */
  solrSearchScore?: number;
  thumbnail?: ImageData;
  tobaccoFreeNicotineProduct?: boolean;
  /** @example false */
  tobaccoProduct: boolean;
}

export interface AxfoodBasicStatusResponseViewModel {
  status: boolean;
}

export interface AxfoodBasicWishListViewModel {
  description?: string;
  id: string;
  image?: MediaData;
  modifiedTime?: string;
  name: string;
  /** @format int32 */
  numberOfProducts?: number;
  sorting?: string;
}

export interface AxfoodBreadCrumbsViewModel {
  categoryCode?: string;
  linkClass?: string;
  name?: string;
  url?: string;
}

export interface AxfoodCartEntryStatusViewModel {
  /** @format int64 */
  availableQuantity?: number;
  /** @format int32 */
  entryNumber?: number;
  entryProduct?: AxfoodBasicProductViewModel;
  externalBaseStore?: boolean;
  notAllowedAnonymous?: boolean;
  notAllowedB2b?: boolean;
  notAllowedBulkyProduct?: boolean;
  notAllowedExternalPickupLocation?: boolean;
  outOfStock?: boolean;
  partialOutOfStock?: boolean;
  /** @format double */
  pickQuantity?: number;
  priceAbscent?: boolean;
  replacementProductsForProduct?: AxfoodReplacementProductViewModel[];
  salableOnline?: boolean;
  showOutOfStock?: boolean;
  showSalableOnline?: boolean;
  showUpdatedPrice?: boolean;
  updatedPrice?: boolean;
}

export interface AxfoodCartProductViewModel {
  addToCartDisabled?: boolean;
  addToCartMessage?: string;
  appliedPromotions?: AxfoodPromotionCartViewModel[];
  /** @format double */
  averageWeight?: number;
  bargainProduct?: boolean;
  /**
   * Product may not be replaced. For example tobacco, nicotine or lottery products.
   * @example true
   */
  canBeReplaced: boolean;
  categoryCode?: string;
  categoryName?: string;
  code: string;
  comparePrice?: string;
  comparePriceUnit?: string;
  confirmRemoveRecipeProduct: boolean;
  depositPrice?: string;
  displayVolume?: string;
  /**
   * The customer has chosen that this entry should not be replaced. Set true For a product that should not be replaced
   * @example true
   */
  doNotReplace: boolean;
  energyDeclaration?: EnergyDeclaration;
  gdprTrackingIncompliant?: boolean;
  googleAnalyticsCategory?: string;
  hazards: string[];
  image?: ImageData;
  inactivePotentialPromotions: AxfoodPromotionViewModel[];
  includedInRecipeList: string[];
  /** @format double */
  incrementValue?: number;
  isDrugProduct?: boolean;
  labels?: string[];
  manufacturer?: string;
  name: string;
  newsSplashProduct?: boolean;
  nicotineMedicalProduct?: boolean;
  nonEkoProduct?: boolean;
  /** @example false */
  notAllowedAnonymous: boolean;
  /** @example false */
  notAllowedB2b: boolean;
  /** @example false */
  online: boolean;
  originalPickQuantity: number;
  otherCountries?: string;
  otherVarieties?: string;
  outOfStock?: boolean;
  pickQuantity: number;
  pickUnit?: UnitData;
  pickupProductLine2?: string;
  potentialPromotions: AxfoodPromotionViewModel[];
  precautionaryStatements: string[];
  price?: string;
  priceNoUnit?: string;
  priceUnit?: string;
  priceValue?: number;
  productBasketType?: ProductBasketTypeEnum;
  productLine2?: string;
  promotionCartLabel?: string;
  /** @format int64 */
  quantity: number;
  quantityIncludedInRecipe?: string;
  /** @format int64 */
  quantityInRefOrder: number;
  /** @format double */
  ranking?: number;
  /**
   * (Deprecated) The customer has chosen that this entry should not be replaced. Set false for not to be replaced
   * @example true
   */
  replacement: boolean;
  savingsAmount?: number;
  seoDescription?: string;
  seoTitle?: string;
  /** @example false */
  showGoodPriceIcon: boolean;
  showRecipeIcon: boolean;
  /** @format double */
  solrSearchScore?: number;
  thumbnail?: ImageData;
  tobaccoFreeNicotineProduct?: boolean;
  /** @example false */
  tobaccoProduct: boolean;
  totalDeposit?: string;
  totalDiscount?: string;
  totalDiscountedPrice?: string;
  totalDiscountedPriceWithDeposit?: string;
  totalDiscountNoUnit?: string;
  totalDiscountValue?: number;
  totalPrice?: string;
  unit?: UnitData;
}

export interface AxfoodCartRestorationViewModel {
  cart?: AxfoodCartViewModel;
  openOrdersClosed: boolean;
}

export interface AxfoodCartStatusViewModel {
  cartStatus?: AxfoodCartEntryStatusViewModel[];
  emptyCart?: boolean;
  reason?: string;
}

export interface AxfoodCartViewModel {
  ageRestricted15: boolean;
  ageRestricted18: boolean;
  allergyApproved: boolean;
  anonymousOrder: boolean;
  anonymousOrderDetailLink?: string;
  appliedVouchers: VoucherViewModel[];
  ascFish: boolean;
  b2bCustomer: boolean;
  b2bSlot?: boolean;
  bufferedAmount?: string;
  bufferedPercent?: string;
  cartStatus?: AxfoodCartEntryStatusViewModel[];
  companyName?: string;
  companyStoreEmail?: string;
  companyStoreName?: string;
  companyStorePhone?: string;
  coupons?: VoucherViewModel[];
  customerComment?: string;
  customerNewProgramEnabled: boolean;
  deliveryAddress?: AddressData;
  deliveryCost?: string;
  deliveryModeCode?: string;
  deliveryModeName?: string;
  deliveryOrPickUpOriginalFee?: PriceData;
  deliverySlot?: boolean;
  deliveryTimeSlot?: TimeSlotData;
  /** @format double */
  discountToolTip?: number;
  doorCode?: string;
  ecological: boolean;
  ekoReplacement: boolean;
  employeeDiscountTotal?: string;
  environmentalChoice: boolean;
  euEcological: boolean;
  euFlower: boolean;
  fairtrade: boolean;
  franchiseStore: boolean;
  freeDeliveryOrPickupThresholdStatus: AxfoodFreeDeliveryOrPickupStatus;
  freeShipping: boolean;
  freeShippingVoucher?: VoucherViewModel;
  frequentShopperPointsAmount?: string;
  frozen: boolean;
  ghsSignalWordDanger: boolean;
  ghsSignalWordNotApplicable: boolean;
  ghsSignalWordWarning: boolean;
  ghsSymbolCorrosion: boolean;
  ghsSymbolEnvironment: boolean;
  ghsSymbolExcalmationMark: boolean;
  ghsSymbolExplodingBomb: boolean;
  ghsSymbolFlame: boolean;
  ghsSymbolFlameOverCircle: boolean;
  ghsSymbolGasCylinder: boolean;
  ghsSymbolHealthHazard: boolean;
  ghsSymbolNoPictogram: boolean;
  ghsSymbolSkullAndCrossbones: boolean;
  glutenfree: boolean;
  hasNicotineMedical: boolean;
  isocode?: string;
  keyhole: boolean;
  klarnaPaymentMethod?: string;
  krav: boolean;
  laktosfree: boolean;
  localAgreementDiscountTotal?: string;
  localB2bCustomer: boolean;
  /** @format int64 */
  loyaltyProductCount?: number;
  loyaltySaveValue?: string;
  mscFish: boolean;
  notEditableAfterOrder: boolean;
  oldOrderPrice?: PriceData;
  oldVouchersNotApplied?: boolean;
  orderDate?: string;
  orderReference?: string;
  /** @format int32 */
  orderReferenceSlotCloseTime?: number;
  orderReferenceSlotCode?: string;
  /** @format int64 */
  orderReferenceTimeUntilSlotClose?: number;
  paymentType?: string;
  pickAndPackCost?: string;
  pickQuantities?: Record<string, number>;
  pickUpCost?: string;
  pickUpExternalLocationCost?: string;
  postalCode?: string;
  priceDiff?: PriceData;
  products: AxfoodCartProductViewModel[];
  /** @format int32 */
  recipeCount?: number;
  recipes: AxfoodRecipeEntryViewModel[];
  removedVouchers?: VoucherData[];
  reservedAmount?: string;
  serviceCost?: string;
  slot?: TimeSlotData;
  /** @format int32 */
  slotCloseDateTime?: number;
  /** @format int32 */
  slotCloseDay: number;
  slotCloseTime?: string;
  slotCloseTimeFormatted?: string;
  slotCode?: string;
  slotFormattedDate?: string;
  slotPickAndPackCost?: string;
  slotReservedTo?: string;
  /** @format int32 */
  slotStartDateTime?: number;
  /** @format int32 */
  slotStartDay: number;
  storeChangeAllowed: boolean;
  subTotalPrice: string;
  subTotalPriceWithDiscountsAndVouchers: string;
  subTotalPriceWithoutDiscounts?: string;
  subTotalPriceWithoutDiscountsNumber?: number;
  subTotalWithDiscounts?: string;
  subtotalWithDiscountsAndPercentageVouchers?: PriceData;
  suggestedPickUpStoreUid: string;
  swan: boolean;
  swedishBird: boolean;
  swedishFlag: boolean;
  swedishMeat: boolean;
  taxes?: Record<string, string>;
  /** @format int64 */
  timeUntilSlotCloseTime: number;
  totalDepositSum: string;
  totalDiscount: string;
  totalDiscountValue: number;
  totalGeneralDiscount: string;
  totalGeneralDiscountValue: number;
  /** @format int32 */
  totalItems: number;
  totalLoyaltyDiscount: string;
  totalPrice: string;
  totalPromotionDiscount: string;
  totalPromotionDiscountValue: number;
  totalTax: string;
  /** @format int32 */
  totalUnitCount: number;
  usersOrderReference: string;
}

export interface AxfoodCategorySearchResultViewModel {
  categories?: AxfoodBasicCategoryViewModel[];
  categoryCode?: string;
  categoryName?: string;
  /** @example false */
  facetCategories: boolean;
  pagination?: PaginationData;
  results?: AxfoodBasicProductViewModel[];
  subCategories?: AxfoodCategorySearchResultViewModel[];
  topCategories?: AxfoodBasicCategoryViewModel[];
  url?: string;
}

export interface AxfoodCheckB2BByIdDTO {
  customerFound: boolean;
  customerHasMembership?: boolean;
  customerNeedContactSupport?: boolean;
  email?: string;
  errorUserName?: string;
  isLocalB2B?: boolean;
  maskedMcn?: string;
  phone?: string;
  status: boolean;
}

export interface AxfoodCheckB2BDTO {
  companyFound: boolean;
  status: boolean;
}

export interface AxfoodCheckCustomerDTO {
  customerFound?: boolean;
  customerHasLoginAccount?: boolean;
  customerHasMembership?: boolean;
  email?: string;
  emailAndPhoneMissing?: boolean;
  maskedMcn?: string;
  maskedSsn?: string;
  mcnLoginUsed?: boolean;
  phone?: string;
  status?: boolean;
}

export interface AxfoodClickAndCollectOrderBoxesViewModel {
  boxes?: ClickAndCollectLockOrderBoxData[];
  orderCode?: string;
}

export interface AxfoodCollectResponse {
  hintCode?: string;
  ssn?: string;
  status?: string;
}

export interface AxfoodCombinedOrderHistoryDatesViewModel {
  combinedOrderHistoryDates?: string[];
}

export interface AxfoodCustomerServiceContactForm {
  attachedImages?: File;
  complaintProducts?: ComplaintProductType[];
  complaintReason?: string;
  /** @pattern ^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.[A-Za-z]{2,})$ */
  email: string;
  mcn?: string;
  message: string;
  /**
   * @minLength 1
   * @maxLength 2147483647
   */
  name: string;
  orderNo?: string;
  /**
   * @minLength 1
   * @maxLength 2147483647
   */
  reasonCode: string;
  sapId?: string;
  ssn?: string;
  store?: string;
}

export interface AxfoodDeliverabilityViewModel {
  deliverable?: boolean;
  deliverableBySelectedStore?: boolean;
  deliveryStoreId?: string;
  isDivided?: boolean;
}

export interface AxfoodDigitalReceiptElementViewModel {
  /** @format int32 */
  bookingDate?: number;
  digitalReceiptAvailable?: boolean;
  digitalReceiptReference?: string;
  memberCardNumber?: string;
  orderNumber?: string;
  receiptSource?: string;
  status?: string;
  storeCustomerId?: string;
}

export interface AxfoodExternalAllVoucherViewModel {
  offline?: VoucherContainer;
  online?: VoucherContainer;
}

export interface AxfoodExternalOrderHistoryForMonthViewModel {
  bonusLevelForMonth?: BonusStatementData[];
  ordersForMonth?: LoyaltyMemberData;
}

export interface AxfoodExternalOrderHistoryViewModel {
  bonusLevelForDate?: BonusStatementData[];
  loyaltyTransactionsInPage?: LoyaltyTransactionData[];
  paginationData?: PaginationData;
  /** @format double */
  totalDiscountCurrentYear: number;
  totalLoyaltyMemberDataForDate?: LoyaltyMemberData;
}

export interface AxfoodExternalVoucher {
  /** @example false */
  applicable: boolean;
  /** @example false */
  applied: boolean;
  description?: string;
  discountType?: string;
  discountValue?: number;
  expirationDate?: string;
  maxPurchaseDiscount?: number;
  medias?: MediaContainerData;
  minPurchaseAmount?: number;
  name?: string;
  productId?: string;
  productSubType?: string;
  status?: string;
  usedDate?: string;
  voucherChannelData?: VoucherChannelData;
  voucherId?: string;
  voucherNbr?: string;
}

export interface AxfoodExternalVoucherViewModel {
  bonusVouchers?: AxfoodExternalVoucher[];
  couponVouchers?: AxfoodExternalVoucher[];
  dormantCouponVouchers?: AxfoodExternalVoucher[];
  usedBonusVouchers?: AxfoodExternalVoucher[];
  usedCouponVouchers?: AxfoodExternalVoucher[];
}

export interface AxfoodFreeDeliveryOrPickupStatus {
  amountToShopFor?: PriceData;
  applied: boolean;
  eligible: boolean;
  threshold?: PriceData;
}

export interface AxfoodLinkComponentViewModel {
  id?: string;
  target?: string;
  title?: string;
  url?: string;
}

export interface AxfoodLoyaltyTransactionHistoryViewModel {
  loyaltyTransactionsForCurrentPeriod?: LoyaltyTransactionData[];
  loyaltyTransactionsForPreviousPeriod?: LoyaltyTransactionData[];
}

export interface AxfoodMergeCartViewModel {
  persistedCartFormattedDate?: string;
  /** @format int32 */
  totalPersistedCartProducts: number;
}

export interface AxfoodMiniCartViewModel {
  appliedVouchers?: VoucherViewModel[];
  deliveryAddress?: AddressData;
  deliveryModeCode?: string;
  pickQuantities?: Record<string, number>;
  products?: AxfoodCartProductViewModel[];
  recipes?: AxfoodRecipeEntryViewModel[];
  subTotalPrice?: string;
  /** @format int32 */
  totalItems?: number;
  totalPrice?: string;
  totalPromotionDiscount?: string;
  totalPromotionDiscountValue?: number;
  /** @format int32 */
  totalUnitCount?: number;
}

export interface AxfoodNavigationNodeViewModel {
  children?: AxfoodNavigationNodeViewModel[];
  links?: AxfoodLinkComponentViewModel[];
  title?: string;
}

export interface AxfoodOfflineProductDetailsViewModel {
  breadcrumbs?: AxfoodBreadCrumbsViewModel[];
  displayVolume?: string;
  energyDeclaration?: EnergyDeclaration;
  googleAnalyticsCategory?: string;
  image?: ImageData;
  inactivePotentialPromotions?: AxfoodOfflinePromotionViewModel[];
  labels?: string[];
  lowestHistoricalPrice?: PriceData;
  manufacturer?: string;
  name?: string;
  /** @example false */
  online: boolean;
  originalImage?: ImageData;
  potentialPromotions?: AxfoodOfflinePromotionViewModel[];
  price?: string;
  priceNoUnit?: string;
  priceUnit?: string;
  productBasketType?: ProductBasketTypeEnum;
  /** @format double */
  ranking?: number;
  /** @example false */
  showGoodPriceIcon: boolean;
  /** @format double */
  solrSearchScore?: number;
  thumbnail?: ImageData;
  title?: string;
}

export interface AxfoodOfflineProductViewModel {
  displayVolume?: string;
  energyDeclaration?: EnergyDeclaration;
  googleAnalyticsCategory?: string;
  image?: ImageData;
  inactivePotentialPromotions?: AxfoodOfflinePromotionViewModel[];
  labels?: string[];
  lowestHistoricalPrice?: PriceData;
  manufacturer?: string;
  name?: string;
  /** @example false */
  online: boolean;
  originalImage?: ImageData;
  potentialPromotions?: AxfoodOfflinePromotionViewModel[];
  price?: string;
  priceNoUnit?: string;
  priceUnit?: string;
  productBasketType?: ProductBasketTypeEnum;
  /** @format double */
  ranking?: number;
  /** @example false */
  showGoodPriceIcon: boolean;
  /** @format double */
  solrSearchScore?: number;
  thumbnail?: ImageData;
  title?: string;
}

export interface AxfoodOfflinePromotionViewModel {
  brands?: string[];
  campaignType?: string;
  cartLabel?: string;
  code?: string;
  comparePrice?: string;
  conditionLabel?: string;
  description?: string;
  discountPrice?: number;
  endDate?: string;
  mainProductCode?: string;
  name?: string;
  offerStatus?: string;
  /** @format double */
  price?: number;
  /** @format int32 */
  priority?: number;
  productCodes?: string[];
  /** @format double */
  promotionPercentage?: number;
  /** @format int32 */
  promotionRedeemLimit?: number;
  promotionTheme?: PromotionThemeData;
  promotionType?: string;
  /** @format int32 */
  qualifyingCount?: number;
  /** @example false */
  realMixAndMatch: boolean;
  /** @format int32 */
  redeemLimit?: number;
  redeemLimitLabel?: string;
  rewardLabel?: string;
  savePrice?: string;
  /** @format int32 */
  sorting?: number;
  splashTitleText?: string;
  startDate?: string;
  textLabel?: string;
  textLabelGenerated?: string;
  textLabelManual?: string;
  /** @format double */
  threshold?: number;
  /** @format int32 */
  timesUsed?: number;
  /** @format int32 */
  validUntil?: number;
  weightVolume?: string;
}

export interface AxfoodOrderElementViewModel {
  collected?: boolean;
  deliveryType?: DeliveryModeData;
  editable?: boolean;
  nextDay?: boolean;
  orderCode?: string;
  orderStatus?: string;
  placedByIdInSourceSystem?: string;
  /** @format int32 */
  slotEndDate?: number;
  /** @format int32 */
  slotStartDate?: number;
}

export interface AxfoodOrderHistoryViewModel {
  cancellable?: boolean;
  /** @example false */
  captured: boolean;
  collected?: boolean;
  complete?: boolean;
  completedStatus?: string;
  customerName?: string;
  /** @format int32 */
  deliveryDateFrom?: number;
  /** @format int32 */
  deliveryDateTo?: number;
  deliveryFormattedDate?: string;
  editable?: boolean;
  /** @format int32 */
  estimatedDelivery?: number;
  formattedOrderDate?: string;
  homeDelivery?: boolean;
  isocode?: string;
  /** @format int32 */
  modifiedTime?: number;
  orderNumber?: string;
  paymentType?: string;
  /** @format int32 */
  placed?: number;
  /** @format int32 */
  requestedDelivery?: number;
  reservedAmount?: string;
  status?: OrderStatus;
  statusDisplay?: string;
  store?: string;
  total?: string;
}

export interface AxfoodOrderProductViewModel {
  addToCartDisabled?: boolean;
  addToCartMessage?: string;
  appliedPromotions?: AxfoodPromotionCartViewModel[];
  /** @format double */
  averageWeight?: number;
  bargainProduct?: boolean;
  /**
   * Product may not be replaced. For example tobacco, nicotine or lottery products.
   * @example true
   */
  canBeReplaced: boolean;
  categoryCode?: string;
  categoryName?: string;
  code: string;
  comparePrice?: string;
  comparePriceUnit?: string;
  confirmRemoveRecipeProduct: boolean;
  /** @example false */
  depositItem: boolean;
  depositPrice?: string;
  /** @example false */
  displayDisclaimer: boolean;
  displayOriginalQuantity?: string;
  displayQuantity?: string;
  displayVolume?: string;
  /**
   * The customer has chosen that this entry should not be replaced. Set true For a product that should not be replaced
   * @example true
   */
  doNotReplace: boolean;
  energyDeclaration?: EnergyDeclaration;
  fruitAndVegetableVariety?: string;
  gdprTrackingIncompliant?: boolean;
  googleAnalyticsCategory?: string;
  hazards: string[];
  image?: ImageData;
  inactivePotentialPromotions: AxfoodPromotionViewModel[];
  includedInRecipeList: string[];
  /** @format double */
  incrementValue?: number;
  isDrugProduct?: boolean;
  labels?: string[];
  manufacturer?: string;
  name: string;
  newsSplashProduct?: boolean;
  nicotineMedicalProduct?: boolean;
  nonEkoProduct?: boolean;
  /** @example false */
  notAllowedAnonymous: boolean;
  /** @example false */
  notAllowedB2b: boolean;
  /** @example false */
  online: boolean;
  originalPickQuantity: number;
  /** @format int64 */
  originalQuantity?: number;
  originCountry?: string;
  otherCountries?: string;
  otherVarieties?: string;
  outOfStock?: boolean;
  pickQuantity: number;
  pickUnit?: UnitData;
  pickupProductLine2?: string;
  potentialPromotions: AxfoodPromotionViewModel[];
  precautionaryStatements: string[];
  price?: string;
  priceNoUnit?: string;
  priceUnit?: string;
  priceValue?: number;
  productBasketType?: ProductBasketTypeEnum;
  productLine2?: string;
  promotionCartLabel?: string;
  /** @format int64 */
  quantity: number;
  quantityIncludedInRecipe?: string;
  /** @format int64 */
  quantityInRefOrder: number;
  /** @format double */
  ranking?: number;
  /**
   * (Deprecated) The customer has chosen that this entry should not be replaced. Set false for not to be replaced
   * @example true
   */
  replacement: boolean;
  savingsAmount?: number;
  seoDescription?: string;
  seoTitle?: string;
  /** @example false */
  showGoodPriceIcon: boolean;
  showRecipeIcon: boolean;
  /** @format double */
  solrSearchScore?: number;
  thumbnail?: ImageData;
  tobaccoFreeNicotineProduct?: boolean;
  /** @example false */
  tobaccoProduct: boolean;
  totalDeposit?: string;
  totalDiscount?: string;
  totalDiscountedPrice?: string;
  totalDiscountedPriceWithDeposit?: string;
  totalDiscountNoUnit?: string;
  totalDiscountValue?: number;
  totalPrice?: string;
  unit?: UnitData;
}

export interface AxfoodOrderProductViewModelSimplified {
  code?: string;
  displayQuantity?: string;
  displayVolume?: string;
  image?: ImageData;
  manufacturer?: string;
  name?: string;
}

export interface AxfoodOrderViewModel {
  ageRestricted15: boolean;
  ageRestricted18: boolean;
  allergyApproved: boolean;
  amountToHigh?: string;
  amountToHighActuallyPaid?: string;
  anonymousOrder: boolean;
  anonymousOrderDetailLink?: string;
  appliedVouchers: VoucherViewModel[];
  ascFish: boolean;
  b2bCustomer: boolean;
  b2bSlot?: boolean;
  bufferedAmount?: string;
  bufferedPercent?: string;
  cancellable?: boolean;
  /** @example false */
  cancelled: boolean;
  /** @example false */
  captured: boolean;
  capturedAmount?: string;
  categoryOrderedDeliveredProducts?: OrderEntriesOrderedByCategory[];
  companyName?: string;
  companyStoreEmail?: string;
  companyStoreName?: string;
  companyStorePhone?: string;
  /** @example false */
  completed: boolean;
  coupons?: VoucherViewModel[];
  customerComment?: string;
  customerNewProgramEnabled: boolean;
  deliveryAddress?: AddressData;
  deliveryCost?: string;
  deliveryModeCode?: string;
  deliveryModeName?: string;
  deliveryOrPickUpOriginalFee?: PriceData;
  deliverySlot?: boolean;
  doorCode?: string;
  ecological: boolean;
  editable?: boolean;
  ekoReplacement: boolean;
  employeeDiscountTotal?: string;
  environmentalChoice: boolean;
  estimatedDeliveryFormattedDate?: string;
  estimatedDeliveryFormattedTime?: string;
  euEcological: boolean;
  euFlower: boolean;
  fairtrade: boolean;
  formattedOrderDate?: string;
  franchiseStore: boolean;
  freeDeliveryOrPickupThreshold?: PriceData;
  /** @example false */
  freeDeliveryOrPickupThresholdApplied: boolean;
  freePickAndPackCostThreshold?: string;
  freeShipping: boolean;
  freeShippingVoucher?: VoucherViewModel;
  frequentShopperPointsAmount?: string;
  frozen: boolean;
  ghsSignalWordDanger: boolean;
  ghsSignalWordNotApplicable: boolean;
  ghsSignalWordWarning: boolean;
  ghsSymbolCorrosion: boolean;
  ghsSymbolEnvironment: boolean;
  ghsSymbolExcalmationMark: boolean;
  ghsSymbolExplodingBomb: boolean;
  ghsSymbolFlame: boolean;
  ghsSymbolFlameOverCircle: boolean;
  ghsSymbolGasCylinder: boolean;
  ghsSymbolHealthHazard: boolean;
  ghsSymbolNoPictogram: boolean;
  ghsSymbolSkullAndCrossbones: boolean;
  glutenfree: boolean;
  hasNicotineMedical: boolean;
  isocode?: string;
  keyhole: boolean;
  klarnaPaymentMethod?: string;
  krav: boolean;
  laktosfree: boolean;
  localAgreementDiscountTotal?: string;
  localB2bCustomer: boolean;
  mscFish: boolean;
  notDeliveredProducts?: AxfoodOrderProductViewModel[];
  notEditableAfterOrder: boolean;
  oldOrderPrice?: PriceData;
  orderDate?: string;
  orderNumber?: string;
  orderReference?: string;
  orderStatus?: OrderStatus;
  partiallyDeliveredProducts?: AxfoodOrderProductViewModel[];
  paymentType?: string;
  pickAndPackCost?: string;
  pickQuantities?: Record<string, number>;
  pickUpCost?: string;
  pickUpExternalLocationCost?: string;
  previousOrder?: AxfoodAbstractOrderViewModelOfOrderData;
  priceDiff?: PriceData;
  recipeEntries?: AxfoodRecipeEntryViewModel[];
  replacedProducts?: AxfoodReplacedOrderProductViewModel[];
  requestedDeliveryFormattedDate?: string;
  requestedDeliveryFormattedTime?: string;
  reservedAmount?: string;
  serviceCost?: string;
  /** @example false */
  showLocalAgreementDiscountTotal: boolean;
  slot?: TimeSlotData;
  /** @format int32 */
  slotCloseDateTime?: number;
  /** @format int32 */
  slotCloseDay: number;
  slotCloseTime?: string;
  slotCloseTimeFormatted?: string;
  slotCode?: string;
  slotFormattedDate?: string;
  slotPickAndPackCost?: string;
  /** @format int32 */
  slotStartDateTime?: number;
  /** @format int32 */
  slotStartDay: number;
  statusDisplay?: string;
  storeChangeAllowed: boolean;
  storeDetails?: AxfoodStoreInfoViewModel;
  subTotalPrice: string;
  subTotalPriceWithDiscountsAndVouchers: string;
  subTotalPriceWithoutDiscounts?: string;
  subTotalPriceWithoutDiscountsNumber?: number;
  subTotalWithDiscounts?: string;
  subtotalWithDiscountsAndPercentageVouchers?: PriceData;
  swan: boolean;
  swedishBird: boolean;
  swedishFlag: boolean;
  swedishMeat: boolean;
  taxes?: Record<string, string>;
  totalDepositSum: string;
  totalDiscount: string;
  totalDiscountValue: number;
  totalGeneralDiscount: string;
  totalGeneralDiscountValue: number;
  /** @format int32 */
  totalItems: number;
  totalLoyaltyDiscount: string;
  totalPrice: string;
  totalPromotionDiscount: string;
  totalPromotionDiscountValue: number;
  totalTax: string;
  /** @format int32 */
  totalUnitCount: number;
  transactionNumber?: string;
  usersOrderReference: string;
}

export interface AxfoodPaginationViewModelOfAxfoodBasicProductViewModel {
  items?: AxfoodBasicProductViewModel[];
  links?: Record<string, string>;
  /** @format int32 */
  page: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
}

export interface AxfoodPaginationViewModelOfAxfoodProductDetailsViewModel {
  items?: AxfoodProductDetailsViewModel[];
  links?: Record<string, string>;
  /** @format int32 */
  page: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
}

export interface AxfoodPersonalElementViewModel {
  digitalReceipt?: AxfoodDigitalReceiptElementViewModel;
  order?: AxfoodOrderElementViewModel;
}

export interface AxfoodProductDetailSectionViewModel {
  productData?: ProductData;
  sectionType?: ProductDetailSectionEnum;
}

export interface AxfoodProductDetailsViewModel {
  activeSubstance?: string;
  addToCartDisabled?: boolean;
  addToCartMessage?: string;
  animalData?: AnimalData;
  areBatteriesIncluded: boolean;
  areBatteriesRequired: boolean;
  /** @format double */
  averageWeight?: number;
  bargainProduct?: boolean;
  batteryInformation: BatteryInformationData[];
  breadCrumbs: AxfoodBreadCrumbsViewModel[];
  categoryGroupText?: string;
  code: string;
  comparePrice?: string;
  comparePriceUnit?: string;
  compulsoryAdditiveLabelInformation?: string;
  consumerStorageInstructions?: string;
  consumerUsageInstructions?: string;
  countryOfOriginStatement?: string;
  depositPrice?: string;
  description?: string;
  dietTypeInformation?: string;
  displayVolume?: string;
  ean?: string;
  energyDeclaration?: EnergyDeclaration;
  extendedLabels?: AxfoodProductLabelsViewModel[];
  extraInfoCode?: string;
  foodContactAddress?: string;
  foodContactName?: string;
  fruitAndVegetableVariety?: string;
  gdprTrackingIncompliant?: boolean;
  googleAnalyticsCategory?: string;
  hazards: string[];
  healthClaimDescription?: string;
  image?: ImageData;
  inactivePotentialPromotions: AxfoodPromotionViewModel[];
  /** @format double */
  incrementValue?: number;
  ingredients?: string;
  instructions?: string;
  isDrugProduct?: boolean;
  labels?: string[];
  manufacturer?: string;
  maxStorageTemperature?: string;
  medicineLink?: string;
  medicineType?: string;
  minStorageTemperature?: string;
  name: string;
  newsSplashProduct?: boolean;
  nicotineMedicalProduct?: boolean;
  nonEkoProduct?: boolean;
  /** @example false */
  notAllowedAnonymous: boolean;
  /** @example false */
  notAllowedB2b: boolean;
  nutrientComponents: ProductInfoNutrientComponentData[];
  nutritionalClaim?: string;
  nutritionDescription?: string;
  nutritionFacts?: string;
  nutritionsFactList: NutrientFactData[];
  /** @example false */
  online: boolean;
  originCountry?: string;
  otherCountries: string[];
  outOfStock?: boolean;
  pickupProductLine2?: string;
  potentialPromotions: AxfoodPromotionViewModel[];
  precautionaryStatements: string[];
  preparationDescription?: string;
  preparationType?: string;
  price?: string;
  priceNoUnit?: string;
  priceUnit?: string;
  priceValue?: number;
  productBasketType?: ProductBasketTypeEnum;
  productLine2?: string;
  provinceStatement?: string;
  /** @format double */
  ranking?: number;
  regulatoryAct?: string;
  /** @example false */
  salableOnline: boolean;
  saleStop?: 'HARD' | 'SOFT';
  savingsAmount?: number;
  seoDescription?: string;
  seoTitle?: string;
  servingSize?: number;
  /** @example false */
  showGoodPriceIcon: boolean;
  /** @format double */
  solrSearchScore?: number;
  thumbnail?: ImageData;
  tobaccoFreeNicotineProduct?: boolean;
  /** @example false */
  tobaccoProduct: boolean;
  tradeItemCountryOfOrigin?: string;
  variantType?: string;
}

export interface AxfoodProductLabelsViewModel {
  code?: string;
  description?: string;
  imgUrl?: string;
  name?: string;
  thumbnailUrl?: string;
}

export interface AxfoodPromotionCartViewModel {
  /** @example false */
  applied: boolean;
  campaignType?: 'GENERAL' | 'LOYALTY' | 'SEGMENTED';
  cartLabel?: string;
  cartLabelFormatted?: string;
  code?: string;
  comparePrice?: string;
  conditionLabel?: string;
  conditionLabelFormatted?: string;
  lowestHistoricalPrice?: PriceData;
  mainProductCode?: string;
  offerStatus?: string;
  price?: PriceData;
  /** @format int32 */
  priority: number;
  productCodes?: string[];
  promotionData?: PromotionData;
  /** @format double */
  promotionPercentage?: number;
  /** @format int32 */
  promotionRedeemLimit?: number;
  promotionTheme?: PromotionThemeData;
  promotionType?: string;
  /** @format int32 */
  qualifyingCount?: number;
  /** @example false */
  realMixAndMatch: boolean;
  redeemLimitLabel?: string;
  rewardLabel?: string;
  splashTitleText?: string;
  textLabel?: string;
  textLabelGenerated?: string;
  textLabelManual?: string;
  /** @format double */
  threshold?: number;
  /** @format int32 */
  timesUsed?: number;
  /** @format int32 */
  validUntil?: number;
}

export interface AxfoodPromotionSuggestionsViewModel {
  headline?: string;
  suggestionSections?: SmartSuggestionsData[];
}

export interface AxfoodPromotionViewModel {
  /** @example false */
  applied: boolean;
  campaignType?: 'GENERAL' | 'LOYALTY' | 'SEGMENTED';
  cartLabel?: string;
  cartLabelFormatted?: string;
  code?: string;
  comparePrice?: string;
  conditionLabel?: string;
  conditionLabelFormatted?: string;
  lowestHistoricalPrice?: PriceData;
  mainProductCode?: string;
  offerStatus?: string;
  price?: PriceData;
  /** @format int32 */
  priority: number;
  productCodes?: string[];
  /** @format double */
  promotionPercentage?: number;
  /** @format int32 */
  promotionRedeemLimit?: number;
  promotionTheme?: PromotionThemeData;
  promotionType?: string;
  /** @format int32 */
  qualifyingCount?: number;
  /** @example false */
  realMixAndMatch: boolean;
  redeemLimitLabel?: string;
  rewardLabel?: string;
  splashTitleText?: string;
  textLabel?: string;
  textLabelGenerated?: string;
  textLabelManual?: string;
  /** @format double */
  threshold?: number;
  /** @format int32 */
  timesUsed?: number;
  /** @format int32 */
  validUntil?: number;
}

export interface AxfoodPurchasableRecipeInfoViewModel {
  allergenInfo?: string[];
  categories?: string[];
  /** @format int32 */
  cookingTime?: number;
  /** @format int32 */
  customNumberOfPortions?: number;
  description?: string;
  /** @example false */
  disableBuyButton: boolean;
  id: string;
  imageUrl?: string;
  ingredients?: string;
  keywords?: string[];
  /** @format int32 */
  maxPortions?: number;
  /** @format int32 */
  minPortions?: number;
  name: string;
  /** @format int32 */
  numberOfIngredients?: number;
  /** @format int32 */
  portions?: number;
  /** @format int32 */
  portionsInterval?: number;
  preparationsSteps?: PreparationStepData[];
  price?: PriceData;
  productsAtHome?: AxfoodRecipeProductEntryViewModel[];
  productsToBuy?: AxfoodRecipeProductEntryViewModel[];
  /** @example false */
  purchaseable: boolean;
  /** @example false */
  recipeInCart: boolean;
  sections?: SectionsData[];
  shortDescription?: string;
  wishlistId?: string;
}

export interface AxfoodRecipeBasicInfoViewModel {
  allergenInfo?: string;
  categories?: string[];
  containsAllergens?: boolean;
  /** @format int32 */
  cookingTime?: number;
  description?: string;
  id?: string;
  image?: AxfoodRecipeImageViewModel;
  ingredientsDescription?: string;
  keywords?: string;
  name?: string;
  /** @format int32 */
  numberOfIngredients?: number;
  /** @format int32 */
  portions?: number;
  /** @example false */
  purchasable: boolean;
  recipeGroups?: string[];
  recipeImages?: ImageData[];
  recipeMainIngredients?: string[];
  recipeTags?: string[];
  recipeTypesString?: string;
  shortDescription?: string;
  sortableRecipeCategories?: string[];
  sortableRecipeGroups?: string[];
  sortableRecipeTypes?: string[];
  wishListId?: string;
}

export interface AxfoodRecipeEntryViewModel {
  /** @format int32 */
  cookingTime?: number;
  id: string;
  name?: string;
  /** @format int32 */
  portions?: number;
  recipeImages?: AxfoodRecipeImageViewModel;
}

export interface AxfoodRecipeImageViewModel {
  cloudinaryAltText?: string;
  cloudinaryUrl?: string;
  urls?: ImageUrlViewModel[];
}

export interface AxfoodRecipeProductEntryViewModel {
  /** @example false */
  checked: boolean;
  /** @example false */
  notAvailable: boolean;
  notAvailableReason?: string;
  product?: AxfoodBasicProductViewModel;
  /** @format float */
  quantity?: number;
  quantityUnit?: string;
  totalPrice?: PriceData;
}

export interface AxfoodRegisterB2BCustomerViewModel {
  completed: boolean;
  sapId?: string;
}

export interface AxfoodReplacedOrderProductViewModel {
  addToCartDisabled?: boolean;
  addToCartMessage?: string;
  appliedPromotions?: AxfoodPromotionCartViewModel[];
  /** @format double */
  averageWeight?: number;
  bargainProduct?: boolean;
  /**
   * Product may not be replaced. For example tobacco, nicotine or lottery products.
   * @example true
   */
  canBeReplaced: boolean;
  categoryCode?: string;
  categoryName?: string;
  code: string;
  comparePrice?: string;
  comparePriceUnit?: string;
  confirmRemoveRecipeProduct: boolean;
  /** @example false */
  depositItem: boolean;
  depositPrice?: string;
  /** @example false */
  displayDisclaimer: boolean;
  displayOriginalQuantity?: string;
  displayQuantity?: string;
  displayVolume?: string;
  /**
   * The customer has chosen that this entry should not be replaced. Set true For a product that should not be replaced
   * @example true
   */
  doNotReplace: boolean;
  energyDeclaration?: EnergyDeclaration;
  fruitAndVegetableVariety?: string;
  gdprTrackingIncompliant?: boolean;
  googleAnalyticsCategory?: string;
  hazards: string[];
  image?: ImageData;
  inactivePotentialPromotions: AxfoodPromotionViewModel[];
  includedInRecipeList: string[];
  /** @format double */
  incrementValue?: number;
  isDrugProduct?: boolean;
  labels?: string[];
  manufacturer?: string;
  name: string;
  newsSplashProduct?: boolean;
  nicotineMedicalProduct?: boolean;
  nonEkoProduct?: boolean;
  /** @example false */
  notAllowedAnonymous: boolean;
  /** @example false */
  notAllowedB2b: boolean;
  /** @example false */
  online: boolean;
  originalPickQuantity: number;
  /** @format int64 */
  originalQuantity?: number;
  originCountry?: string;
  otherCountries?: string;
  otherVarieties?: string;
  outOfStock?: boolean;
  pickQuantity: number;
  pickUnit?: UnitData;
  pickupProductLine2?: string;
  potentialPromotions: AxfoodPromotionViewModel[];
  precautionaryStatements: string[];
  price?: string;
  priceNoUnit?: string;
  priceUnit?: string;
  priceValue?: number;
  productBasketType?: ProductBasketTypeEnum;
  productLine2?: string;
  promotionCartLabel?: string;
  /** @format int64 */
  quantity: number;
  quantityIncludedInRecipe?: string;
  /** @format int64 */
  quantityInRefOrder: number;
  /** @format double */
  ranking?: number;
  /**
   * (Deprecated) The customer has chosen that this entry should not be replaced. Set false for not to be replaced
   * @example true
   */
  replacement: boolean;
  replacementProducts?: AxfoodOrderProductViewModel[];
  savingsAmount?: number;
  seoDescription?: string;
  seoTitle?: string;
  /** @example false */
  showGoodPriceIcon: boolean;
  showRecipeIcon: boolean;
  /** @format double */
  solrSearchScore?: number;
  thumbnail?: ImageData;
  tobaccoFreeNicotineProduct?: boolean;
  /** @example false */
  tobaccoProduct: boolean;
  totalDeposit?: string;
  totalDiscount?: string;
  totalDiscountedPrice?: string;
  totalDiscountedPriceWithDeposit?: string;
  totalDiscountNoUnit?: string;
  totalDiscountValue?: number;
  totalPrice?: string;
  unit?: UnitData;
}

export interface AxfoodReplacementProductViewModel {
  addToCartDisabled?: boolean;
  addToCartMessage?: string;
  /** @format double */
  averageWeight?: number;
  bargainProduct?: boolean;
  code: string;
  comparePrice?: string;
  comparePriceUnit?: string;
  depositPrice?: string;
  displayVolume?: string;
  energyDeclaration?: EnergyDeclaration;
  forceUseNewReplacementQty?: boolean;
  gdprTrackingIncompliant?: boolean;
  googleAnalyticsCategory?: string;
  hazards: string[];
  image?: ImageData;
  inactivePotentialPromotions: AxfoodPromotionViewModel[];
  /** @format double */
  incrementValue?: number;
  isDrugProduct?: boolean;
  labels?: string[];
  manufacturer?: string;
  name: string;
  newsSplashProduct?: boolean;
  nicotineMedicalProduct?: boolean;
  nonEkoProduct?: boolean;
  /** @example false */
  notAllowedAnonymous: boolean;
  /** @example false */
  notAllowedB2b: boolean;
  /** @example false */
  online: boolean;
  outOfStock?: boolean;
  pickupProductLine2?: string;
  potentialPromotions: AxfoodPromotionViewModel[];
  precautionaryStatements: string[];
  price?: string;
  priceNoUnit?: string;
  priceUnit?: string;
  priceValue?: number;
  productBasketType?: ProductBasketTypeEnum;
  productLine2?: string;
  /** @format double */
  ranking?: number;
  /** @format double */
  replacementQty?: number;
  savingsAmount?: number;
  seoDescription?: string;
  seoTitle?: string;
  /** @example false */
  showGoodPriceIcon: boolean;
  /** @format double */
  solrSearchScore?: number;
  thumbnail?: ImageData;
  tobaccoFreeNicotineProduct?: boolean;
  /** @example false */
  tobaccoProduct: boolean;
}

export interface AxfoodSearchResultViewModel {
  categories?: AxfoodBasicCategoryViewModel[];
  /** @example false */
  facetCategories: boolean;
  pagination?: PaginationData;
  results?: AxfoodBasicProductViewModel[];
  topCategories?: AxfoodBasicCategoryViewModel[];
}

export interface AxfoodSlotsScheduleViewModel {
  days?: DaySlotDataViewModel[];
  endDate: string;
  isocode?: string;
  minimumFreePickingThreshold?: PriceData;
  minimumPickingCost?: PriceData;
  selectedSlot?: AxfoodTimeSlotViewModel;
  /** @example false */
  showExternalPickupLocationNotice: boolean;
  slots: AxfoodTimeSlotViewModel[];
  startDate: string;
  /** @example false */
  tmsSlots: boolean;
}

export interface AxfoodStoreInfoViewModel {
  /** @example false */
  activelySelected: boolean;
  address?: AddressData;
  /** @example false */
  b2BClickAndCollect: boolean;
  b2bDeliveryCost?: string;
  b2bFreeDeliveryThresholdFormatted?: string;
  b2bPickingCostForCollectPlusPickupCost?: string;
  b2bPickingCostForDelivery?: string;
  b2bPickingCostForDeliveryPlusDeliveryCost?: string;
  b2bPickupInStoreCost?: string;
  /** @example false */
  clickAndCollect: boolean;
  customerServiceEmail?: string;
  customerServicePhone?: string;
  deliveryCost?: string;
  /** @example false */
  external: boolean;
  /** @example false */
  externalPickupLocation: boolean;
  extraPickUpInformation?: string;
  flyerURL?: string;
  /** @example false */
  franchiseHomeDeliveryEnabled: boolean;
  /** @example false */
  franchiseStore: boolean;
  freeB2BPickingCostThresholdForCollectFormatted?: string;
  freeB2BPickingCostThresholdForDeliveryFormatted?: string;
  freeDeliveryThresholdFormatted?: string;
  freePickingCostThresholdForCollectFormatted?: string;
  freePickingCostThresholdForDeliveryFormatted?: string;
  geoPoint?: GeoPoint;
  name?: string;
  /** @example false */
  onlineStore: boolean;
  /** @example false */
  open: boolean;
  openingHours?: string[];
  openingHoursMessageKey?: string;
  openingStoreMessageValue?: string;
  pickingCostForCollect?: string;
  pickingCostForCollectPlusPickupCost?: string;
  pickingCostForDelivery?: string;
  pickingCostForDeliveryPlusDeliveryCost?: string;
  pickupInStoreCost?: string;
  specialOpeningHours?: string[];
  /** @example false */
  storeChangeAllowed: boolean;
  storeId?: string;
  /** @example false */
  willysHemma: boolean;
}

export interface AxfoodTimeSlotViewModel {
  /** @example false */
  available: boolean;
  /** @example false */
  b2b: boolean;
  /** @format int32 */
  closeTime?: number;
  closeTimeFormatted: string;
  code: string;
  dayOfTheWeek: string;
  deliveryCost?: PriceData;
  /** @example false */
  deliverySlot: boolean;
  /** @example false */
  earlyCloseTime: boolean;
  /** @format int32 */
  endTime?: number;
  /** @example false */
  expressSlot: boolean;
  formattedTime: string;
  freeDeliveryThreshold?: PriceData;
  freePickingThreshold?: PriceData;
  freePickUpThreshold?: PriceData;
  /** @format int32 */
  orderEarliestDeliveryTime?: number;
  /** @format int32 */
  orderLatestDeliveryTime?: number;
  pickingCost?: PriceData;
  pickUpCost?: PriceData;
  pickUpExternalLocationCost?: PriceData;
  pickupStoreId?: string;
  slotBaseStoreId: string;
  /** @format int32 */
  startTime?: number;
  tmsDeliveryWindowReference?: TMSDeliveryWindowData;
  totalCost?: PriceData;
}

export interface AxfoodVerifyPhoneResponseViewModel {
  encryptedToken: string;
  status: boolean;
  uid: string;
}

export interface AxfoodWishListEntryViewModel {
  categoryName?: string;
  /** @example false */
  checked: boolean;
  /** @format int32 */
  checkedOrder?: number;
  entryType?: string;
  freeTextProduct?: string;
  pickUnit?: string;
  product?: AxfoodBasicProductViewModel;
  promotion?: AxfoodOfflineProductViewModel;
  /** @format double */
  quantity: number;
  /** @example false */
  salableInStore: boolean;
}

export interface AxfoodWishListViewModel {
  description?: string;
  entries?: AxfoodWishListEntryViewModel[];
  id: string;
  image?: MediaData;
  modifiedTime?: string;
  name: string;
  /** @format int32 */
  numberOfProducts?: number;
  sharedUntil?: string;
  sorting?: string;
}

export interface BankIdForm {
  generateQrData?: boolean;
  mobile?: boolean;
}

export interface BaseOptionData {
  options?: VariantOptionData[];
  selected?: VariantOptionData;
  variantType?: string;
}

export interface BatteryInformationData {
  areBatteriesBuiltIn?: boolean;
  batteriesQuantityRequired?: string;
  batteryQuantityBuiltIn?: string;
  batteryTechnologyTypeCode?: string;
  batteryType?: string;
  batteryWeight?: string;
}

export interface BonusBenefitInfoData {
  /** @format int32 */
  availableFromLevel?: number;
  code?: string;
  description?: string;
  graphicValue?: string;
  /** @format int32 */
  priority?: number;
  title?: string;
}

export interface BonusInfoData {
  amountForCurrentTier?: string;
  amountToNextTier?: string;
  bonusAmountCurrentMonth?: string;
  bonusAmountNeededToStayOnCurrentLevel?: string;
  currentBonusAmount?: string;
  currentBonusLevelAmountLeftToNextLevel?: string;
  currentBonusLevelEndDate?: string;
  /** @format int32 */
  currentBonusLevelNumber?: number;
  currentBonusVoucherPoints?: number;
  currentTierName?: string;
  currentTierRegained?: boolean;
  /** @format int32 */
  currentTierSeqNumber?: number;
  /** @format int32 */
  daysLeftOnCurrentPeriod?: number;
  lifetimeCharityAmount?: string;
  nextTierName?: string;
  /** @format int32 */
  nextTierSeqNumber?: number;
  /** @format int32 */
  nextVoucherValue?: number;
  totalBonusApplicableAmount?: string;
  totalDiscountCurrentMonth?: string;
  totalDiscountCurrentYear?: string;
  totalPointsInCurrentPeriod?: string;
}

export interface BonusStatementData {
  accruedBonusAmount?: number;
  /** @format int32 */
  endDate?: number;
  memberId?: string;
  /** @format int32 */
  startDate?: number;
  tierName?: string;
  /** @format int32 */
  tierSeqNumber?: number;
  totalPurchaseAmount?: number;
}

export interface BreadcrumbDataOfobject {
  facetCode?: string;
  facetName?: string;
  facetValueCode?: string;
  facetValueName?: string;
  removeQuery?: object;
  truncateQuery?: object;
}

export interface BreadcrumbDataOfSearchStateData {
  facetCode?: string;
  facetName?: string;
  facetValueCode?: string;
  facetValueName?: string;
  removeQuery?: SearchStateData;
  truncateQuery?: SearchStateData;
}

export interface CancelResponse {
  details?: string;
  errorCode?: string;
}

export interface CardTypeData {
  code?: string;
  name?: string;
}

export interface CartStoreChangeAllowed {
  allowed?: boolean;
}

export interface CategoryBreadCrumbsData {
  categoryCode?: string;
  linkClass?: string;
  name?: string;
  url?: string;
}

export interface CategoryData {
  code?: string;
  description?: string;
  image?: ImageData;
  name?: string;
  parentCategoryName?: string;
  seoDescription?: string;
  seoTitle?: string;
  /** @format int32 */
  sequence?: number;
  url?: string;
}

export interface CategoryNavigationNodeData {
  category?: string;
  children?: CategoryNavigationNodeData[];
  id?: string;
  title?: string;
  url?: string;
  valid?: boolean;
}

export interface CCPaymentInfoData {
  accountHolderName?: string;
  billingAddress?: AddressData;
  cardNumber?: string;
  cardType?: string;
  cardTypeData?: CardTypeData;
  defaultPaymentInfo?: boolean;
  expiryMonth?: string;
  expiryYear?: string;
  id?: string;
  issueNumber?: string;
  saved?: boolean;
  startMonth?: string;
  startYear?: string;
  subscriptionId?: string;
}

export interface ChangePortionsRequest {
  /** @format int32 */
  portions?: number;
  recipeId?: string;
}

export interface CheckCustomerForm {
  socialSecurityNumber?: string;
  socialSecurityNumberKey?: string;
}

export interface Chronology {
  zone?: DateTimeZone;
}

export interface ClassificationData {
  code?: string;
  features?: FeatureData[];
  name?: string;
}

export interface ClickAndCollectLockOrderBoxData {
  id?: string;
  locked?: boolean;
  occupied?: boolean;
  open?: boolean;
}

export interface ClickAndCollectOrderBoxData {
  ageControlled?: boolean;
  collected?: boolean;
  id?: string;
  tempIssue?: boolean;
}

export interface CommentData {
  author?: PrincipalData;
  code?: string;
  /** @format int32 */
  creationDate?: number;
  fromCustomer?: boolean;
  text?: string;
}

export interface CompanySearchViewModel {
  companyAddress?: string;
  companyName?: string;
  companyPostalCode?: string;
  companyTown?: string;
  registrationNumber?: string;
}

export interface ComplaintProductType {
  code?: string;
  productPage?: ProductFormValueType;
}

export interface CompleteMemberEmailFlowForm {
  charityFlag?: boolean;
  checkPassword?: string;
  checkPasswordKey?: string;
  emailToken?: string;
  /** @pattern ^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])([a-zåäöA-ZÅÄÖ0-9\w/\\//<>_~@$%^&*+=`|{}:;!.,?\"\[\]-]{6,30})$ */
  password: string;
  passwordKey?: string;
  /** @pattern ^[\+[0-9]{1,3}]?\s?[\s0-9]{6,16}$ */
  phone: string;
  rememberMe?: boolean;
  sendDigitalReceiptByEmail?: boolean;
  useDigitalReceipt?: boolean;
}

export interface CompleteMemberPhoneFlowForm {
  charityFlag?: boolean;
  checkPassword?: string;
  checkPasswordKey?: string;
  /**
   * @minLength 0
   * @maxLength 250
   * @pattern ^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.[A-Za-z]{2,})$
   */
  email: string;
  encryptedToken?: string;
  /** @pattern ^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])([a-zåäöA-ZÅÄÖ0-9\w/\\//<>_~@$%^&*+=`|{}:;!.,?\"\[\]-]{6,30})$ */
  password: string;
  passwordKey?: string;
  rememberMe?: boolean;
  sendDigitalReceiptByEmail?: boolean;
  smsToken?: string;
  uid?: string;
  useDigitalReceipt?: boolean;
}

export interface ConfigurationInfoData {
  configurationLabel?: string;
  configurationValue?: string;
  configuratorType?: ConfiguratorType;
  status?: ProductInfoStatus;
}

export interface ConfiguratorType {
  code?: string;
  type?: string;
}

export interface ConsignmentData {
  code?: string;
  deliveryPointOfService?: PointOfServiceData;
  entries?: ConsignmentEntryData[];
  shippingAddress?: AddressData;
  status?: ConsignmentStatus;
  /** @format int32 */
  statusDate?: number;
  statusDisplay?: string;
  trackingID?: string;
}

export interface ConsignmentEntryData {
  orderEntry?: OrderEntryData;
  /** @format int64 */
  quantity?: number;
  /** @format int64 */
  shippedQuantity?: number;
}

export interface ConsignmentStatus {
  code?: string;
  type?: string;
}

export interface CoordinatesData {
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface CountryData {
  isocode?: string;
  name?: string;
}

export interface CouponData {
  active?: boolean;
  couponCode?: string;
  couponId?: string;
  /** @format int32 */
  endDate?: number;
  name?: string;
  /** @format int32 */
  startDate?: number;
}

export interface CreateSessionPaymentResponse {
  client_token?: string;
  payment_method_categories?: PaymentMethodCategory[];
  session_id?: string;
}

export interface CreditCardPaymentInfoData {
  cardType?: string;
  defaultCard?: boolean;
  expired?: boolean;
  expireDate?: string;
  id?: string;
  maskedNumber?: string;
}

export interface CreditInformationData {
  creditApproved?: boolean;
  creditBlocked?: boolean;
  /** @format double */
  creditLimit?: number;
  /** @format double */
  creditSaldo?: number;
  invoiceOption?: string;
  invoiceRef?: string;
  /** @format double */
  orderLimit?: number;
}

export interface CurrencyData {
  active?: boolean;
  isocode?: string;
  name?: string;
  symbol?: string;
}

export interface CustomerContactData {
  careOf?: string;
  cellphone?: string;
  companyName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface CustomerContactForm {
  /**
   * @minLength 0
   * @maxLength 250
   * @pattern ^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-\/]*$
   */
  careOf?: string;
  /**
   * @minLength 0
   * @maxLength 250
   * @pattern ^[\+[0-9]{1,3}]?\s?[\s0-9]{6,16}$
   */
  cellphone: string;
  /**
   * @minLength 0
   * @maxLength 40
   */
  companyName?: string;
  /**
   * @minLength 0
   * @maxLength 250
   * @pattern ^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.[A-Za-z]{2,})$
   */
  email: string;
  /**
   * @minLength 0
   * @maxLength 250
   */
  firstName?: string;
  /**
   * @minLength 0
   * @maxLength 250
   */
  lastName?: string;
  saveToMyPages?: boolean;
}

export interface CustomerContactReason {
  code?: string;
  name?: string;
}

export interface CustomerData {
  activeTripExists?: boolean;
  bonusInfo?: BonusInfoData;
  charityFlag?: boolean;
  companyAlternativeName?: string;
  companyName?: string;
  companyStoreId?: string;
  companyStoreName?: string;
  creditInformation?: CreditInformationData;
  currency?: CurrencyData;
  customerId?: string;
  /** @format int32 */
  deactivationDate?: number;
  defaultBillingAddress?: AddressData;
  defaultShippingAddress?: AddressData;
  digitalReceiptAvailable?: boolean;
  digitalReceiptEmailCommChannel?: boolean;
  digitalReceiptReference?: string;
  digitalVoucherFlag?: boolean;
  displayUid?: string;
  email?: string;
  existsInLDAP?: boolean;
  firstName?: string;
  homeStoreId?: string;
  idInSourceSystem?: string;
  isAllowedCreditPayment?: boolean;
  isB2BCustomer?: boolean;
  isDeletedinLDAP?: boolean;
  isLocalB2BCustomer?: boolean;
  isPrimary?: boolean;
  language?: LanguageData;
  lastName?: string;
  lastUsedLogin?: string;
  latestLoginIsAutoLogin?: boolean;
  linkedAccounts?: CustomerLinkedAccount[];
  linkedCards?: CreditCardPaymentInfoData[];
  localCustomerAgreement?: LocalCustomerAgreementData;
  memberCreationDateFull?: string;
  memberCreationMonthAndYear?: string;
  name?: string;
  newCustomer?: boolean;
  noMonthlyCommunication?: boolean;
  orgNumber?: string;
  postalCode?: string;
  sapId?: string;
  savedCards?: CreditCardPaymentInfoData[];
  sendDigitalReceipt?: boolean;
  socialSecurityNumer?: string;
  storeId?: string;
  suppressAllEmails?: boolean;
  suppressAllSMS?: boolean;
  title?: string;
  titleCode?: string;
  uid?: string;
}

export interface CustomerExtraData {
  commentFromCustomer?: string;
  doorCode?: string;
  usersOrderReference?: string;
}

export interface CustomerLinkedAccount {
  isPrimary?: boolean;
  name?: string;
}

export interface DateTimeZone {
  fixed?: boolean;
  id?: string;
}

export interface DaySlotDataViewModel {
  date?: string;
  slots: AxfoodTimeSlotViewModel[];
}

export interface DeliveryModeData {
  code?: string;
  deliveryCost?: PriceData;
  description?: string;
  name?: string;
}

export interface DeliveryOrderEntryGroupData {
  deliveryAddress?: AddressData;
  entries?: OrderEntryData[];
  /** @format int64 */
  quantity?: number;
  totalPriceWithTax?: PriceData;
}

export interface DeliveryStatus {
  code?: string;
  type?: string;
}

export interface DepositData {
  deposit?: PriceData;
  totalDeposit?: PriceData;
  totalPriceInclDeposit?: PriceData;
}

export interface EncryptedTokenAssertionForm {
  encryptedToken?: string;
  /** @pattern ^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])([a-zåäöA-ZÅÄÖ0-9\w/\\//<>_~@$%^&*+=`|{}:;!.,?\"\[\]-]{6,30})$ */
  pwd: string;
  pwdKey?: string;
  rememberMe?: boolean;
  token?: string;
  uid?: string;
}

export interface EnergyDeclaration {
  image?: string;
  link?: string;
  value?: string;
}

export interface EntryGroupData {
  children?: EntryGroupData[];
  erroneous?: boolean;
  externalReferenceId?: string;
  /** @format int32 */
  groupNumber?: number;
  groupType?: GroupType;
  label?: string;
  orderEntries?: OrderEntryData[];
  parent?: EntryGroupData;
  /** @format int32 */
  priority?: number;
  rootGroup?: EntryGroupData;
}

export interface FacetDataOfobject {
  category?: boolean;
  code?: string;
  multiSelect?: boolean;
  name?: string;
  /** @format int32 */
  priority?: number;
  topValues?: FacetValueDataOfobject[];
  values?: FacetValueDataOfobject[];
  visible?: boolean;
}

export interface FacetDataOfSearchStateData {
  category?: boolean;
  code?: string;
  multiSelect?: boolean;
  name?: string;
  /** @format int32 */
  priority?: number;
  topValues?: FacetValueDataOfSearchStateData[];
  values?: FacetValueDataOfSearchStateData[];
  visible?: boolean;
}

export interface FacetRefinementOfSearchStateData {
  breadcrumbs?: BreadcrumbDataOfSearchStateData[];
  /** @format int64 */
  count?: number;
  facets?: FacetDataOfSearchStateData[];
}

export interface FacetSearchPageData {
  breadcrumbs?: BreadcrumbDataOfobject[];
  currentQuery?: object;
  facets?: FacetDataOfobject[];
  pagination?: PaginationData;
  relatedResults?: object[];
  relatedResultsPagination?: PaginationData;
  results?: object[];
  sorts?: SortData[];
}

export interface FacetSearchPageDataOfSearchStateDataAndProductPromotionDataV2 {
  breadcrumbs?: BreadcrumbDataOfSearchStateData[];
  currentQuery?: SearchStateData;
  facets?: FacetDataOfSearchStateData[];
  pagination?: PaginationData;
  relatedResults?: ProductPromotionDataV2[];
  relatedResultsPagination?: PaginationData;
  results?: ProductPromotionDataV2[];
  sorts?: SortData[];
}

export interface FacetValueDataOfobject {
  code?: string;
  /** @format int64 */
  count?: number;
  name?: string;
  query?: object;
  selected?: boolean;
}

export interface FacetValueDataOfSearchStateData {
  code?: string;
  /** @format int64 */
  count?: number;
  name?: string;
  query?: SearchStateData;
  selected?: boolean;
}

export interface FAQEntryData {
  categoryUid?: string;
  content?: string;
  title?: string;
}

export interface FeatureData {
  code?: string;
  comparable?: boolean;
  description?: string;
  featureUnit?: FeatureUnitData;
  featureValues?: FeatureValueData[];
  name?: string;
  range?: boolean;
  type?: string;
}

export interface FeatureToggle {
  description?: string;
  enabled?: boolean;
  name?: string;
}

export interface FeatureUnitData {
  name?: string;
  symbol?: string;
  unitType?: string;
}

export interface FeatureValueData {
  value?: string;
}

export interface FeedingData {
  feedingDetails?: FeedingDetailsData[];
  lifeStage?: string;
  weight?: string;
}

export interface FeedingDetailsData {
  amount?: string[];
  /** @format int32 */
  feedingFrequencyId?: number;
}

export interface FeedingFrequencyData {
  /** @format int32 */
  id?: number;
  value?: string;
}

export interface File {
  absolute?: boolean;
  absoluteFile?: File;
  absolutePath?: string;
  canonicalFile?: File;
  canonicalPath?: string;
  directory?: boolean;
  executable?: boolean;
  file?: boolean;
  /** @format int64 */
  freeSpace?: number;
  hidden?: boolean;
  /** @format int64 */
  lastModified?: number;
  name?: string;
  parent?: string;
  parentFile?: File;
  path?: string;
  readable?: boolean;
  /** @format int64 */
  totalSpace?: number;
  /** @format int64 */
  usableSpace?: number;
  writable?: boolean;
}

export interface FutureStockData {
  /** @format int32 */
  date?: number;
  formattedDate?: string;
  stock?: StockData;
}

export interface GenericProductData {
  description?: string;
  id?: string;
  pluralName?: string;
  products?: ProductData[];
  readMoreLink?: string;
  shortDescription?: string;
  singularName?: string;
}

export interface GeoPoint {
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface GroupType {
  code?: string;
  type?: string;
}

export interface ImageData {
  altText?: string;
  format?: string;
  /** @format int32 */
  galleryIndex?: number;
  imageType?: 'PRIMARY' | 'GALLERY';
  url?: string;
  /** @format int32 */
  width?: number;
}

export interface ImageUrlViewModel {
  altText?: string;
  url?: string;
  /** @format int32 */
  width?: number;
}

export interface IngredientData {
  genericProduct?: GenericProductData;
  /** @format float */
  ingredientAmount?: number;
  ingredientUnit?: string;
  isStandardIngredient?: boolean;
  name?: string;
}

export type InputStream = object;

export interface IntegrationErrorMsgData {
  endDateTime?: string;
  hasError?: boolean;
  startDateTime?: string;
}

export interface Interval {
  afterNow?: boolean;
  beforeNow?: boolean;
  chronology?: Chronology;
  /** @format date-time */
  end?: string;
  /** @format int64 */
  endMillis?: number;
  /** @format date-time */
  start?: string;
  /** @format int64 */
  startMillis?: number;
}

export interface JobAdvertData {
  applicationUrl?: string;
  applyButtonEnabled?: boolean;
  contractTypeLabel?: string;
  contractTypeValue?: string;
  description?: string;
  detailsUrl?: string;
  /** @format int64 */
  id?: number;
  location?: string;
  /** @format int32 */
  postingEndDate?: number;
  /** @format int32 */
  postingStartDate?: number;
  region?: string;
  title?: string;
}

export interface LanguageData {
  active?: boolean;
  isocode?: string;
  name?: string;
  nativeName?: string;
  required?: boolean;
}

export interface LocalCustomerAgreementData {
  id?: string;
  /** @format double */
  percentage?: number;
  storeId?: string;
  storeName?: string;
}

export interface Loop54SearchWrapperData {
  autocompleteResultData?: AutocompleteResultData;
  productSearchPageData?: ProductSearchPageData;
}

export interface LoyaltyMemberData {
  loyaltyTransactions?: LoyaltyTransactionData[];
  totalBonusApplicable?: number;
  totalBonusApplicablePriceData?: PriceData;
  totalDiscountAmount?: number;
  totalDiscountAmountPriceData?: PriceData;
  totalMemberDiscountAmount?: number;
  totalMemberDiscountAmountPriceData?: PriceData;
  totalPurchaseSum?: number;
  totalPurchaseSumPriceData?: PriceData;
}

export interface LoyaltyTransactionData {
  amount?: number;
  amountPriceData?: PriceData;
  bonusAppliableAmount?: number;
  bonusAppliableAmountPriceData?: PriceData;
  /** @format int32 */
  bookingDate?: number;
  contactFirstName?: string;
  contactLastName?: string;
  deliveryMethod?: string;
  digitalReceiptAvailable?: boolean;
  digitalReceiptReference?: string;
  generalDiscount?: number;
  generalDiscountPriceData?: PriceData;
  id?: string;
  memberCardNumber?: string;
  memberDiscount?: number;
  memberDiscountPriceData?: PriceData;
  orderNumber?: string;
  partner?: string;
  paymentType?: string;
  points?: string;
  product?: string;
  productUnitOfMeasure?: string;
  receiptSource?: string;
  status?: string;
  storeCustomerId?: string;
  storeName?: string;
  transactionSubType?: string;
  transactionType?: string;
}

export interface MediaContainerData {
  medias?: MediaData[];
  name?: string;
}

export interface MediaData {
  code?: string;
  url?: string;
}

export interface NewsItemData {
  content?: string;
  /** @format int32 */
  date?: number;
  title?: string;
  uid?: string;
}

export type Number = object;

export interface NutrientFactData {
  measurementPrecisionCode?: string;
  typeCode?: string;
  unitCode?: string;
  value?: string;
}

export interface OpeningScheduleData {
  code?: string;
  name?: string;
  specialDayOpeningList?: SpecialOpeningDayData[];
  weekDayOpeningList?: WeekdayOpeningDayData[];
}

export interface OrderData {
  /** @format int32 */
  actualDeliveryDate?: number;
  amountToHigh?: PriceData;
  amountToHighActuallyPaid?: PriceData;
  anonymousOrder?: boolean;
  anonymousOrderDetailLink?: string;
  appliedOrderPromotions?: PromotionResultData[];
  appliedProductPromotions?: PromotionResultData[];
  appliedVoucherData?: VoucherData[];
  appliedVouchers?: string[];
  b2bCustomer?: boolean;
  bufferedAmount?: PriceData;
  bufferedPercentage?: string;
  calculated?: boolean;
  cancelable?: boolean;
  capturedAmount?: PriceData;
  code?: string;
  collected?: boolean;
  comments?: CommentData[];
  companyName?: string;
  companyStoreEmail?: string;
  companyStoreName?: string;
  companyStorePhone?: string;
  completed?: boolean;
  consignments?: ConsignmentData[];
  /** @format int32 */
  created?: number;
  customerIdInSourceSystem?: string;
  customerName?: string;
  customerNewProgramEnabled?: boolean;
  deliveryAddress?: AddressData;
  deliveryCost?: PriceData;
  deliveryCustomerExtra?: CustomerExtraData;
  /** @format int64 */
  deliveryItemsQuantity?: number;
  deliveryMode?: DeliveryModeData;
  deliveryOrderGroups?: DeliveryOrderEntryGroupData[];
  deliveryOrPickUpOriginalFee?: PriceData;
  deliveryStatus?: DeliveryStatus;
  deliveryStatusDisplay?: string;
  deliveryTimeSlot?: TimeSlotData;
  description?: string;
  editable?: boolean;
  ekoReplacement?: boolean;
  employeeDiscountTotal?: PriceData;
  entries?: OrderEntryData[];
  /** @format int32 */
  estimatedDelivery?: number;
  /** @format int32 */
  expirationTime?: number;
  franchiseStore?: boolean;
  freeDeliveryOrPickupThreshold?: PriceData;
  freeDeliveryOrPickupThresholdApplied?: boolean;
  freePickingCostThreshold?: PriceData;
  frequentShopperPoints?: PriceData;
  generalDiscount?: PriceData;
  guestCustomer?: boolean;
  guid?: string;
  klarnaPaymentMethod?: string;
  localAgreementDiscountTotal?: PriceData;
  localB2bCustomer?: boolean;
  loyaltyDiscount?: PriceData;
  name?: string;
  net?: boolean;
  notEditableAfterOrder?: boolean;
  oldOrderPrice?: PriceData;
  orderDate?: string;
  orderDiscounts?: PriceData;
  orderReference?: string;
  /** @format int32 */
  orderReferenceSlotCloseTime?: number;
  orderReferenceSlotCode?: string;
  paymentInfo?: CCPaymentInfoData;
  paymentType?: string;
  pickingCost?: PriceData;
  pickingSystem?: string;
  pickUpCost?: PriceData;
  pickUpExternalLocationCost?: PriceData;
  /** @format int64 */
  pickupItemsQuantity?: number;
  pickupOrderGroups?: PickupOrderEntryGroupData[];
  placedBy?: string;
  pointOfServiceDescription?: string;
  priceDiff?: PriceData;
  productDiscounts?: PriceData;
  quoteCode?: string;
  quoteDiscounts?: PriceData;
  /** @format double */
  quoteDiscountsRate?: number;
  quoteDiscountsType?: string;
  recipeEntries?: RecipeEntryData[];
  /** @format int32 */
  requestedDelivery?: number;
  reservedAmount?: PriceData;
  rootGroups?: EntryGroupData[];
  showCnCTrackingLink?: boolean;
  site?: string;
  /** @format int32 */
  slotCloseTime?: number;
  status?: OrderStatus;
  statusDisplay?: string;
  store?: string;
  storeChangeAllowed?: boolean;
  storeName?: string;
  subTotal?: PriceData;
  subTotalWithDiscounts?: PriceData;
  subtotalWithDiscountsAndPercentageVouchers?: PriceData;
  subTotalWithDiscountsAndVouchers?: PriceData;
  subtotalWithoutDiscounts?: PriceData;
  subTotalWithoutQuoteDiscounts?: PriceData;
  taxes?: TaxData[];
  totalDepositSum?: PriceData;
  totalDiscount?: PriceData;
  totalDiscounts?: PriceData;
  totalDiscountsWithQuoteDiscounts?: PriceData;
  /** @format int32 */
  totalItems?: number;
  totalPrice?: PriceData;
  totalPriceWithTax?: PriceData;
  totalServiceCost?: PriceData;
  totalTax?: PriceData;
  /** @format int32 */
  totalUnitCount?: number;
  trackingId?: string;
  transactionNumber?: string;
  unconsignedEntries?: OrderEntryData[];
  user?: PrincipalData;
}

export interface OrderDataV2 {
  allergyApproved?: boolean;
  amountTooHigh?: PriceData;
  amountTooHighActuallyPaid?: PriceData;
  anonymousOrder?: boolean;
  anonymousOrderDetailLink?: string;
  appliedVouchersData?: VoucherData[];
  ascFish?: boolean;
  b2bCustomer?: boolean;
  b2bSlot?: boolean;
  bufferedAmount?: PriceData;
  bufferedPercentage?: string;
  cancelable?: boolean;
  cancelled?: boolean;
  captured?: boolean;
  capturedAmount?: PriceData;
  categoryOrderedDeliveredProducts?: Record<string, AxfoodOrderProductViewModel[]>;
  collected?: boolean;
  companyName?: string;
  companyStoreEmail?: string;
  companyStoreName?: string;
  companyStorePhone?: string;
  completed?: boolean;
  coupons?: VoucherData[];
  customerComment?: string;
  customerIdInSourceSystem?: string;
  customerName?: string;
  customerNewProgramEnabled?: boolean;
  deliveryAddress?: AddressData;
  deliveryCost?: PriceData;
  deliveryCustomerExtra?: CustomerExtraData;
  deliveryModeCode?: string;
  deliveryModeName?: string;
  deliveryOrPickUpOriginalFee?: PriceData;
  deliverySlot?: boolean;
  deliveryTimeSlot?: TimeSlotData;
  diffReservedAmount?: PriceData;
  doorCode?: string;
  ecological?: boolean;
  editable?: boolean;
  ekoReplacement?: boolean;
  employeeDiscountTotal?: PriceData;
  environmentalChoice?: boolean;
  /** @format int32 */
  estimatedDelivery?: number;
  estimatedDeliveryFormattedDate?: string;
  estimatedDeliveryFormattedTime?: string;
  euEcological?: boolean;
  euFlower?: boolean;
  fairtrade?: boolean;
  formattedOrderDate?: string;
  franchiseStore?: boolean;
  freeDeliveryOrPickupThreshold?: PriceData;
  freeDeliveryOrPickupThresholdApplied?: boolean;
  freePickingCostThreshold?: PriceData;
  freeShipping?: boolean;
  freeShippingVoucher?: VoucherData;
  frequentShopperPoints?: PriceData;
  frozen?: boolean;
  generalDiscount?: PriceData;
  ghsSignalWordDanger?: boolean;
  ghsSignalWordNotApplicable?: boolean;
  ghsSignalWordWarning?: boolean;
  ghsSymbolCorrosion?: boolean;
  ghsSymbolEnvironment?: boolean;
  ghsSymbolExclamationMark?: boolean;
  ghsSymbolExplodingBomb?: boolean;
  ghsSymbolFlame?: boolean;
  ghsSymbolFlameOverCircle?: boolean;
  ghsSymbolGasCylinder?: boolean;
  ghsSymbolHealthHazard?: boolean;
  ghsSymbolNoPictogram?: boolean;
  ghsSymbolSkullAndCrossbonesMark?: boolean;
  glutenfree?: boolean;
  guid?: string;
  hasNicotineMedical?: boolean;
  isocode?: string;
  keyhole?: boolean;
  klarnaPaymentMethod?: string;
  krav?: boolean;
  lactosefree?: boolean;
  localAgreementDiscountTotal?: PriceData;
  localB2bCustomer?: boolean;
  loyaltyDiscount?: PriceData;
  mscFish?: boolean;
  nettoTotalCost?: PriceData;
  notDeliveredProducts?: AxfoodOrderProductViewModel[];
  notEditableAfterOrder?: boolean;
  oldOrderPrice?: PriceData;
  orderDate?: string;
  orderNumber?: string;
  orderReference?: string;
  /** @format int32 */
  orderReferenceSlotCloseTime?: number;
  orderReferenceSlotCode?: string;
  orderStatus?: OrderStatus;
  partiallyDeliveredProducts?: AxfoodOrderProductViewModel[];
  paymentType?: string;
  pickAndPackCost?: PriceData;
  pickingCost?: PriceData;
  pickQuantities?: Record<string, number>;
  pickUpCost?: PriceData;
  pickUpExternalLocationCost?: PriceData;
  pointOfServiceDescription?: string;
  previousOrder?: OrderDataV2;
  priceDiff?: PriceData;
  recipeEntries?: RecipeEntryData[];
  replacedProducts?: AxfoodReplacedOrderProductViewModel[];
  /** @format int32 */
  requestedDelivery?: number;
  requestedDeliveryFormattedDate?: string;
  requestedDeliveryFormattedTime?: string;
  reservedAmount?: PriceData;
  /** @format int32 */
  restrictionAge?: number;
  serviceCost?: PriceData;
  shippingCost?: PriceData;
  showCnCTrackingLink?: boolean;
  showLocalAgreementDiscountTotal?: boolean;
  slot?: TimeSlotData;
  /** @format int32 */
  slotCloseDateTime?: number;
  /** @format int32 */
  slotCloseDay?: number;
  slotCloseTime?: string;
  slotCloseTimeFormatted?: string;
  slotCode?: string;
  slotFormattedDate?: string;
  slotPickAndPackCost?: PriceData;
  /** @format int32 */
  slotStartDateTime?: number;
  /** @format int32 */
  slotStartDay?: number;
  statusDisplay?: string;
  storeChangeAllowed?: boolean;
  storeDetails?: StoreInfoData;
  storeName?: string;
  subTotalPrice?: PriceData;
  subTotalWithDiscounts?: PriceData;
  subtotalWithDiscountsAndPercentageVouchers?: PriceData;
  subTotalWithDiscountsAndVouchers?: PriceData;
  subtotalWithoutDiscounts?: PriceData;
  swan?: boolean;
  swedishBird?: boolean;
  swedishFlag?: boolean;
  swedishMeat?: boolean;
  taxes?: TaxData[];
  totalDepositSum?: PriceData;
  totalDiscount?: PriceData;
  totalDiscountValue?: Number;
  totalGeneralDiscount?: PriceData;
  totalGeneralDiscountValue?: Number;
  /** @format int32 */
  totalItems?: number;
  totalPrice?: PriceData;
  totalPromotionDiscount?: PriceData;
  totalPromotionDiscountValue?: Number;
  totalServiceCost?: PriceData;
  totalTax?: PriceData;
  /** @format int32 */
  totalUnitCount?: number;
  trackingId?: string;
  transactionNumber?: string;
  usersOrderReference?: string;
}

export interface OrderDataV2Response {
  allergyApproved?: boolean;
  amountTooHigh?: PriceData;
  amountTooHighActuallyPaid?: PriceData;
  anonymousOrder?: boolean;
  anonymousOrderDetailLink?: string;
  appliedVouchersData?: VoucherData[];
  ascFish?: boolean;
  b2bCustomer?: boolean;
  b2bSlot?: boolean;
  bufferedAmount?: PriceData;
  bufferedPercentage?: string;
  cancelable?: boolean;
  cancelled?: boolean;
  captured?: boolean;
  capturedAmount?: PriceData;
  categoryOrderedDeliveredProducts?: Record<string, AxfoodOrderProductViewModel[]>;
  collected?: boolean;
  companyName?: string;
  companyStoreEmail?: string;
  companyStoreName?: string;
  companyStorePhone?: string;
  completed?: boolean;
  coupons?: VoucherData[];
  customerComment?: string;
  customerIdInSourceSystem?: string;
  customerName?: string;
  customerNewProgramEnabled?: boolean;
  deliveryAddress?: AddressData;
  deliveryCost?: PriceData;
  deliveryCustomerExtra?: CustomerExtraData;
  deliveryModeCode?: string;
  deliveryModeName?: string;
  deliveryOrPickUpOriginalFee?: PriceData;
  deliverySlot?: boolean;
  deliveryTimeSlot?: TimeSlotData;
  diffReservedAmount?: PriceData;
  doorCode?: string;
  ecological?: boolean;
  editable?: boolean;
  ekoReplacement?: boolean;
  employeeDiscountTotal?: PriceData;
  environmentalChoice?: boolean;
  /** @format int32 */
  estimatedDelivery?: number;
  estimatedDeliveryFormattedDate?: string;
  estimatedDeliveryFormattedTime?: string;
  euEcological?: boolean;
  euFlower?: boolean;
  fairtrade?: boolean;
  formattedOrderDate?: string;
  franchiseStore?: boolean;
  freeDeliveryOrPickupThreshold?: PriceData;
  freeDeliveryOrPickupThresholdApplied?: boolean;
  freePickingCostThreshold?: PriceData;
  freeShipping?: boolean;
  freeShippingVoucher?: VoucherData;
  frequentShopperPoints?: PriceData;
  frozen?: boolean;
  generalDiscount?: PriceData;
  ghsSignalWordDanger?: boolean;
  ghsSignalWordNotApplicable?: boolean;
  ghsSignalWordWarning?: boolean;
  ghsSymbolCorrosion?: boolean;
  ghsSymbolEnvironment?: boolean;
  ghsSymbolExclamationMark?: boolean;
  ghsSymbolExplodingBomb?: boolean;
  ghsSymbolFlame?: boolean;
  ghsSymbolFlameOverCircle?: boolean;
  ghsSymbolGasCylinder?: boolean;
  ghsSymbolHealthHazard?: boolean;
  ghsSymbolNoPictogram?: boolean;
  ghsSymbolSkullAndCrossbonesMark?: boolean;
  glutenfree?: boolean;
  guid?: string;
  hasNicotineMedical?: boolean;
  isocode?: string;
  keyhole?: boolean;
  klarnaPaymentMethod?: string;
  krav?: boolean;
  lactosefree?: boolean;
  localAgreementDiscountTotal?: PriceData;
  localB2bCustomer?: boolean;
  loyaltyDiscount?: PriceData;
  mscFish?: boolean;
  nettoTotalCost?: PriceData;
  notDeliveredProducts?: AxfoodOrderProductViewModel[];
  notEditableAfterOrder?: boolean;
  oldOrderPrice?: PriceData;
  orderDate?: string;
  orderNumber?: string;
  orderReference?: string;
  /** @format int32 */
  orderReferenceSlotCloseTime?: number;
  orderReferenceSlotCode?: string;
  orderStatus?: OrderStatus;
  partiallyDeliveredProducts?: AxfoodOrderProductViewModel[];
  paymentType?: string;
  pickAndPackCost?: PriceData;
  pickingCost?: PriceData;
  pickQuantities?: Record<string, number>;
  pickUpCost?: PriceData;
  pickUpExternalLocationCost?: PriceData;
  pointOfServiceDescription?: string;
  previousOrder?: OrderDataV2;
  priceDiff?: PriceData;
  recipeEntries?: RecipeEntryData[];
  replacedProducts?: AxfoodReplacedOrderProductViewModel[];
  /** @format int32 */
  requestedDelivery?: number;
  requestedDeliveryFormattedDate?: string;
  requestedDeliveryFormattedTime?: string;
  reservedAmount?: PriceData;
  /** @format int32 */
  restrictionAge?: number;
  serviceCost?: PriceData;
  shippingCost?: PriceData;
  showCnCTrackingLink?: boolean;
  showLocalAgreementDiscountTotal?: boolean;
  slot?: TimeSlotData;
  /** @format int32 */
  slotCloseDateTime?: number;
  /** @format int32 */
  slotCloseDay?: number;
  slotCloseTime?: string;
  slotCloseTimeFormatted?: string;
  slotCode?: string;
  slotFormattedDate?: string;
  slotPickAndPackCost?: PriceData;
  /** @format int32 */
  slotStartDateTime?: number;
  /** @format int32 */
  slotStartDay?: number;
  statusDisplay?: string;
  storeChangeAllowed?: boolean;
  storeDetails?: StoreInfoData;
  storeName?: string;
  subTotalPrice?: PriceData;
  subTotalWithDiscounts?: PriceData;
  subtotalWithDiscountsAndPercentageVouchers?: PriceData;
  subTotalWithDiscountsAndVouchers?: PriceData;
  subtotalWithoutDiscounts?: PriceData;
  swan?: boolean;
  swedishBird?: boolean;
  swedishFlag?: boolean;
  swedishMeat?: boolean;
  taxes?: TaxData[];
  totalDepositSum?: PriceData;
  totalDiscount?: PriceData;
  totalDiscountValue?: Number;
  totalGeneralDiscount?: PriceData;
  totalGeneralDiscountValue?: Number;
  /** @format int32 */
  totalItems?: number;
  totalPrice?: PriceData;
  totalPromotionDiscount?: PriceData;
  totalPromotionDiscountValue?: Number;
  totalServiceCost?: PriceData;
  totalTax?: PriceData;
  /** @format int32 */
  totalUnitCount?: number;
  trackingId?: string;
  transactionNumber?: string;
  usersOrderReference?: string;
}

export interface OrderEntriesOrderedByCategory {
  categoryName?: string;
  /** @format int32 */
  productCount: number;
  products?: AxfoodOrderProductViewModel[];
}

export interface OrderEntryData {
  basePrice?: PriceData;
  canBeReplaced?: boolean;
  comments?: CommentData[];
  configurationInfos?: ConfigurationInfoData[];
  confirmRemoveRecipeProduct?: boolean;
  defaultFruitAndVegetableOriginCountry?: string;
  deliveryMode?: DeliveryModeData;
  deliveryPointOfService?: PointOfServiceData;
  depositData?: DepositData;
  entries?: OrderEntryData[];
  entryGroupNumbers?: number[];
  /** @format int32 */
  entryNumber?: number;
  fruitAndVegetableVariety?: string;
  includedInRecipes?: string[];
  noReplacement?: boolean;
  /** @format int32 */
  originalEntryNumber?: number;
  originalPickQuantity?: number;
  /** @format int64 */
  originalQuantity?: number;
  otherCountries?: string;
  otherVarieties?: string;
  pickBasePrice?: PriceData;
  pickQuantity?: number;
  product?: ProductData;
  productPickUnit?: UnitData;
  productSaleUnit?: UnitData;
  /** @format int64 */
  quantity?: number;
  quantityIncludedInRecipesString?: string;
  /** @format int64 */
  quantityInRefOrder?: number;
  /** @format int32 */
  replacesEntryNumber?: number;
  statusSummaryMap?: Record<string, number>;
  supportedActions?: string[];
  topLevelCategory?: CategoryData;
  totalBasePriceWithDeposit?: PriceData;
  totalDiscount?: PriceData;
  totalDiscountedPrice?: PriceData;
  totalDiscountedPriceWithDeposit?: PriceData;
  totalPrice?: PriceData;
  updateable?: boolean;
}

export interface OrderHistoryData {
  cancelable?: boolean;
  code?: string;
  collected?: boolean;
  completed?: boolean;
  consignmentsExists?: boolean;
  customerIdInSourceSystem?: string;
  deliveryTimeInterval?: Interval;
  editable?: boolean;
  /** @format int32 */
  estimatedDelivery?: number;
  formattedDeliveryTime?: string;
  guid?: string;
  homeDelivery?: boolean;
  linkedAccountFullName?: string;
  /** @format int32 */
  modifiedTime?: number;
  paymentType?: string;
  /** @format int32 */
  placed?: number;
  /** @format int32 */
  requestedDelivery?: number;
  reservedAmount?: PriceData;
  status?: OrderStatus;
  statusDisplay?: string;
  store?: string;
  total?: PriceData;
}

export interface OrderStatus {
  code?: string;
  type?: string;
}

export interface OrderTrackingStatusHistoryData {
  cncCollectStatus?: string;
  collectBoxes?: ClickAndCollectOrderBoxData[];
  deliveryAddressCoordinates?: CoordinatesData;
  deliveryStatus?: string;
  eta?: string;
  followDeliveryEta?: string;
  guestCustomerOrder?: boolean;
  images?: Record<string, string>;
  integrationError?: IntegrationErrorMsgData;
  mapToBeShown?: boolean;
  /** @format int32 */
  minimumAge?: number;
  orderCode?: string;
  orderCollectTime?: string;
  orderDetailsId?: string;
  /** @format int32 */
  refreshRateInMs?: number;
  slotTime?: string;
  statusHistory?: StatusHistoryData[];
  /** @format int32 */
  stopsBeforeDelivery?: number;
  storeId?: string;
  trackingUrl?: string;
  truckCoordinates?: CoordinatesData;
  website?: string;
}

export interface PaginationData {
  /** @format int64 */
  allProductsInCategoriesCount?: number;
  /** @format int64 */
  allProductsInSearchCount?: number;
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  numberOfPages?: number;
  /** @format int32 */
  pageSize?: number;
  sort?: string;
  /** @format int64 */
  totalNumberOfResults?: number;
}

export interface PaymentMethodCategory {
  asset_urls?: AssetUrls;
  identifier?: string;
  name?: string;
}

export interface PickupOrderEntryGroupData {
  deliveryPointOfService?: PointOfServiceData;
  /** @format double */
  distance?: number;
  entries?: OrderEntryData[];
  /** @format int64 */
  quantity?: number;
  totalPriceWithTax?: PriceData;
}

export interface PointOfServiceData {
  address?: AddressData;
  description?: string;
  displayName?: string;
  /** @format double */
  distanceKm?: number;
  features?: Record<string, string>;
  flyerURL?: string;
  formattedDistance?: string;
  geoPoint?: GeoPoint;
  isWillysHemma?: boolean;
  mapIcon?: ImageData;
  name?: string;
  /** @format int32 */
  openingDate?: number;
  openingHours?: OpeningScheduleData;
  storeContent?: string;
  storeImages?: ImageData[];
  url?: string;
}

export interface PreparationStepData {
  description?: string;
  preparationStepName?: string;
  /** @format int32 */
  preparationStepNumber?: number;
  recipeId?: string;
}

export interface PriceData {
  currencyIso?: string;
  formattedValue?: string;
  /** @format int64 */
  maxQuantity?: number;
  /** @format int64 */
  minQuantity?: number;
  priceType?: 'BUY' | 'FROM';
  value?: number;
}

export interface PriceRangeData {
  maxPrice?: PriceData;
  minPrice?: PriceData;
}

export interface PrincipalData {
  name?: string;
  uid?: string;
}

export interface ProductBasketType {
  code?: string;
  type?: string;
}

export interface ProductBasketTypeEnum {
  code?: string;
  type?: string;
}

export interface ProductCategorySearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModelAndCategoryData {
  breadcrumbs?: BreadcrumbDataOfSearchStateData[];
  categoryBreadcrumbs?: CategoryBreadCrumbsData;
  categoryCode?: string;
  categoryInfo?: CategoryData;
  categoryName?: string;
  currentQuery?: SearchStateData;
  customSuggestion?: SearchSuggestionData;
  facets?: FacetDataOfSearchStateData[];
  freeTextSearch?: string;
  keywordRedirectUrl?: string;
  navigationCategories?: CategoryBreadCrumbsData[];
  pagination?: PaginationData;
  relatedResults?: AxfoodBasicProductViewModel[];
  relatedResultsPagination?: PaginationData;
  restrictedProductTypes?: string;
  results?: AxfoodBasicProductViewModel[];
  sorts?: SortData[];
  spellingSuggestion?: SpellingSuggestionDataOfSearchStateData;
  subCategories?: CategoryData[];
  superCategories?: CategoryData[];
  usedQuery?: string;
}

export interface ProductData {
  activeSubstance?: string;
  addToCartDisabled?: boolean;
  addToCartDisabledMessage?: string;
  addToCartMessage?: string;
  availableForPickup?: boolean;
  /** @format double */
  averageRating?: number;
  /** @format double */
  averageWeight?: number;
  bargainProduct?: boolean;
  baseOptions?: BaseOptionData[];
  baseProduct?: string;
  bulkyProduct?: boolean;
  /** @format double */
  campaignRanking?: number;
  categories?: CategoryData[];
  category?: string;
  categoryGroupText?: string;
  classifications?: ClassificationData[];
  code?: string;
  commercialName1?: string;
  commercialName2?: string;
  commercialName3?: string;
  comparePrice?: string;
  comparePriceUnit?: string;
  configurable?: boolean;
  configuratorType?: string;
  depositPrice?: PriceData;
  description?: string;
  ean?: string;
  energyDeclaration?: EnergyDeclaration;
  extraInfoCode?: string;
  firstCategoryNameList?: SolrFirstVariantCategoryEntryData[];
  fruitAndVegetableVariety?: string;
  futureStocks?: FutureStockData[];
  gdprTrackingIncompliant?: boolean;
  images?: ImageData[];
  inactivePotentialPromotions?: PromotionData[];
  /** @format double */
  incrementValue?: number;
  infoAttachmentData?: ProductInfoAttachmentData;
  isDrugProduct?: boolean;
  keywords?: string[];
  labels?: string[];
  loyaltySaveValue?: number;
  manufacturer?: string;
  manufacturingCountry?: string;
  medicineLink?: string;
  medicineType?: string;
  multidimensional?: boolean;
  name?: string;
  newsSplashProduct?: boolean;
  nicotineMedicalProduct?: boolean;
  nonEkoProduct?: boolean;
  notAllowedAnonymous?: boolean;
  notAllowedB2b?: boolean;
  /** @format int32 */
  numberOfReviews?: number;
  offlineProduct?: boolean;
  originCountry?: string;
  otherFruitAndVegetableCountries?: CountryData[];
  pickQuantity?: number;
  potentialPromotions?: PromotionData[];
  price?: PriceData;
  priceRange?: PriceRangeData;
  productBasketType?: ProductBasketTypeEnum;
  productReferences?: ProductReferenceData[];
  purchasable?: boolean;
  reviews?: ReviewData[];
  salableOnline?: boolean;
  saleStop?: 'HARD' | 'SOFT';
  seoDescription?: string;
  seoTitle?: string;
  /** @format double */
  solrSearchScore?: number;
  stock?: StockData;
  summary?: string;
  tags?: string[];
  tobaccoFreeNicotineProduct?: boolean;
  tobaccoProduct?: boolean;
  unit?: UnitData;
  url?: string;
  variantMatrix?: VariantMatrixElementData[];
  variantOptions?: VariantOptionData[];
  variantType?: string;
  volumePrices?: PriceData[];
  volumePricesFlag?: boolean;
}

export interface ProductDetailSectionEnum {
  code?: string;
  type?: string;
}

export interface ProductFormValueType {
  amount?: string;
  complaintCause?: string;
  complaintReason?: string;
  message?: string;
  product?: AxfoodOrderProductViewModelSimplified;
}

export interface ProductInfoAttachmentData {
  animalData?: AnimalData;
  areBatteriesIncluded?: boolean;
  areBatteriesRequired?: boolean;
  batteryInformation?: BatteryInformationData[];
  communicationAddress?: string;
  compulsoryAdditiveLabelInformation?: string;
  consumerStorageInstructions?: string;
  consumerUsageInstructions?: string;
  contactName?: string;
  countryOfOriginStatement?: string;
  description?: string;
  dietTypeInformation?: string;
  hazardStatementsDescription?: string[];
  healthClaimDescription?: string;
  ingredients?: string;
  instructions?: string;
  nutrientBasisQuantity?: string;
  nutrientBasisQuantityTypeCode?: string;
  nutrientComponents?: ProductInfoNutrientComponentData[];
  nutrientFacts?: string;
  nutrientFactsList?: NutrientFactData[];
  nutritionalClaim?: string;
  nutritionDescription?: string;
  precautionaryStatementsDescription?: string[];
  preparationDescription?: string;
  preparationType?: string;
  provinceStatement?: string;
  regulatoryAct?: string;
  servingSize?: number;
  storageMaximum?: string;
  storageMinimum?: string;
  tradeItemCountryOfOrigin?: string;
}

export interface ProductInfoNutrientComponentAlcoholInformationData {
  volume?: number;
  /** @format int32 */
  year?: number;
}

export interface ProductInfoNutrientComponentData {
  alcoholInformation?: ProductInfoNutrientComponentAlcoholInformationData;
  countryOfOriginCode?: string;
  countryOfOriginStatement?: string;
  description?: string;
  ingredientStatement?: string;
  netContent?: string;
  netContentMeasurementUnitCode?: string;
  nonfoodIngredientStatement?: string;
  nutrientHeaders?: ProductInfoNutrientHeaderData[];
  nutritionalClaim?: string;
  provincialStatement?: string;
  /** @format int32 */
  quantity?: number;
  /** @format int32 */
  sequenceNumber?: number;
}

export interface ProductInfoNutrientDetailData {
  dailyValueIntakePercent?: string;
  measurementPrecisionCode?: string;
  measurementUnitCode?: string;
  nutrientTypeCode?: string;
  quantityContained?: string;
}

export interface ProductInfoNutrientHeaderData {
  dailyValueIntakeReference?: string;
  nutrientBasisQuantity?: string;
  nutrientBasisQuantityMeasurementUnitCode?: string;
  nutrientBasisQuantityTypeCode?: string;
  nutrientDetails?: ProductInfoNutrientDetailData[];
  preparationStateCode?: string;
  servingSize?: string;
  servingSizeDescription?: string;
  servingsSizeMeasurementUnitCode?: string;
}

export interface ProductInfoStatus {
  code?: string;
  type?: string;
}

/** ProductPromotionData containing data regarding products with corresponding promotions. */
export interface ProductPromotionDataV2 {
  /** @format double */
  averageWeight?: number;
  /**
   * Identifier for this product
   * @example "101200548_ST"
   */
  code: string;
  /**
   * The compare price for the product
   * @example "8,33 kr/kg"
   */
  comparePrice: string;
  /**
   * Unit of compare price
   * @example "kg"
   */
  comparePriceUnit?: string;
  /**
   * The deposit price of a product.
   * @example "2,00 kr"
   */
  depositPrice: string;
  /** @example "200g" */
  displayVolume?: string;
  /** Image of energy class, eg used for bulbs */
  energyDeclaration?: EnergyDeclaration;
  /** @example false */
  gdprTrackingIncompliant: boolean;
  /**
   * Used to send category information to google analytics
   * @example "mejeri-ost-och-agg|filmjolk-och-yoghurt|naturell-yoghurt"
   */
  googleAnalyticsCategory?: string;
  /**
   * If the product has display price or not.
   * @example true
   */
  hasDeposit: boolean;
  /** Image of the product */
  image: ImageData;
  inactivePotentialPromotions?: PromotionData[];
  /**
   * How much of this product is possible to add as a minimum
   * @format double
   * @example 1
   */
  incrementValue?: 0 | 0.1 | 0.2 | 0.3 | 1 | 1.1 | 1.2;
  /**
   * If the product is drug or not
   * @example false
   */
  isDrugProduct: boolean;
  /**
   * What kind of labels this product have
   * @example "[swan,swedish_flag,keyhole]"
   */
  labels: string[];
  /** PIL Lowest historical price of the promotion */
  lowestHistoricalPrice?: number;
  /** @example "Garant" */
  manufacturer?: string;
  /**
   * The name of the product
   * @example "Bigarr�er Klass 1"
   */
  name: string;
  /**
   * If the product has new label or not
   * @example true
   */
  newsSplashProduct: boolean;
  /**
   * If the product is nicotine medical or not
   * @example false
   */
  nicotineMedicalProduct: boolean;
  /**
   * If the product is non-ecological or not
   * @example false
   */
  nonEkoProduct: boolean;
  /** @example false */
  notAllowedAnonymous: boolean;
  /** @example false */
  notAllowedB2b: boolean;
  /**
   * If the product is online or not
   * @example true
   */
  online: boolean;
  /** Original image of the product for offline promotions */
  originalImage?: ImageData;
  /**
   * If the product is out of stock or not
   * @example true
   */
  outOfStock: boolean;
  /**
   * Field containing extra information about the product. Usually the manufacturer and the display weight.
   * @example "GARANT, 350g"
   */
  pickupProductLine2: string;
  potentialPromotions?: PromotionDTO[];
  /**
   * Formatted price with currency
   * @example "88,82 kr"
   */
  price: string;
  /**
   * Price of product without unit
   * @example "12,99"
   */
  priceNoUnit?: string;
  /**
   * Price unit of the product
   * @example "/ST"
   */
  priceUnit: string;
  /**
   * Price value of the product, For gift products the price is always 0, for x for y promotions the price is always null
   * @example 10.95
   */
  priceValue: number;
  productBasketType?: ProductBasketType;
  /**
   * Cart type of the product
   * @example "ST"
   */
  productCartType?: string;
  /**
   * Field containing extra information about the product. Usually the manufacturer and the display weight.
   * @example "GARANT, 350g"
   */
  productLine2: string;
  promotions?: PromotionDataV2[];
  /**
   * campaign ranking that will determine order
   * @format double
   * @example 460.890558998619
   */
  ranking?: number;
  /**
   * Amount saved from original price
   * @example 12.99
   */
  savingsAmount: number;
  /**
   * If the product is nicotine medical or not
   * @example false
   */
  showGoodPriceIcon: boolean;
  /**
   * solr search score that will determine order, higher precedence then ranking
   * @format double
   * @example 460.890558998619
   */
  solrSearchScore?: number;
  thumbnail: ImageData;
  title?: string;
  /**
   * If the product is tobacco free nicotine or not
   * @example false
   */
  tobaccoFreeNicotineProduct: boolean;
}

export interface ProductPromotionDataV2Response {
  breadcrumbs?: BreadcrumbDataOfSearchStateData[];
  categoryBreadcrumbs?: CategoryBreadCrumbsData;
  categoryCode?: string;
  categoryName?: string;
  currentQuery?: SearchStateData;
  customSuggestion?: SearchSuggestionData;
  facets?: FacetDataOfSearchStateData[];
  freeTextSearch?: string;
  keywordRedirectUrl?: string;
  navigationCategories?: CategoryBreadCrumbsData[];
  pagination?: PaginationData;
  relatedResults?: ProductPromotionDataV2[];
  relatedResultsPagination?: PaginationData;
  results?: ProductPromotionDataV2[];
  sorts?: SortData[];
  spellingSuggestion?: SpellingSuggestionDataOfSearchStateData;
  usedQuery?: string;
}

export interface ProductReferenceData {
  description?: string;
  preselected?: boolean;
  /** @format int32 */
  quantity?: number;
  referenceType?:
    | 'ACCESSORIES'
    | 'BASE_PRODUCT'
    | 'CONSISTS_OF'
    | 'DIFF_ORDERUNIT'
    | 'FOLLOWUP'
    | 'MANDATORY'
    | 'SIMILAR'
    | 'SELECT'
    | 'SPAREPART'
    | 'OTHERS'
    | 'UPSELLING'
    | 'CROSSELLING';
  target?: ProductData;
}

export interface ProductSearchPageData {
  breadcrumbs?: BreadcrumbDataOfobject[];
  categoryBreadcrumbs?: CategoryBreadCrumbsData;
  categoryCode?: string;
  categoryName?: string;
  currentQuery?: object;
  customSuggestion?: SearchSuggestionData;
  facets?: FacetDataOfobject[];
  freeTextSearch?: string;
  keywordRedirectUrl?: string;
  navigationCategories?: CategoryBreadCrumbsData[];
  pagination?: PaginationData;
  relatedResults?: object[];
  relatedResultsPagination?: PaginationData;
  results?: object[];
  sorts?: SortData[];
  spellingSuggestion?: SpellingSuggestionDataOfobject;
  usedQuery?: string;
}

export interface ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel {
  breadcrumbs?: BreadcrumbDataOfSearchStateData[];
  categoryBreadcrumbs?: CategoryBreadCrumbsData;
  categoryCode?: string;
  categoryName?: string;
  currentQuery?: SearchStateData;
  customSuggestion?: SearchSuggestionData;
  facets?: FacetDataOfSearchStateData[];
  freeTextSearch?: string;
  keywordRedirectUrl?: string;
  navigationCategories?: CategoryBreadCrumbsData[];
  pagination?: PaginationData;
  relatedResults?: AxfoodBasicProductViewModel[];
  relatedResultsPagination?: PaginationData;
  results?: AxfoodBasicProductViewModel[];
  sorts?: SortData[];
  spellingSuggestion?: SpellingSuggestionDataOfSearchStateData;
  usedQuery?: string;
}

export interface PromotionData {
  applied?: boolean;
  brands?: string[];
  campaign?: string;
  campaignType?: 'GENERAL' | 'LOYALTY' | 'SEGMENTED';
  cartLabel?: string;
  category?: string;
  code?: string;
  commercialName2?: string;
  commercialName3?: string;
  comparePrice?: PriceData;
  comparisonPrice?: string;
  conditionLabel?: string;
  couldFireMessages?: string[];
  description?: string;
  discountPrice?: PriceData;
  enabled?: boolean;
  /** @format int32 */
  endDate?: number;
  energyDeclaration?: EnergyDeclaration;
  firedMessages?: string[];
  images?: ImageData[];
  labels?: string[];
  lowestHistoricalPrice?: PriceData;
  mainProductCode?: string;
  name?: string;
  offerStatus?: string;
  /** @format double */
  percentage?: number;
  pointOfServices?: string[];
  /** @format int32 */
  priority?: number;
  productBanner?: ImageData;
  productBasketType?: string;
  productCodes?: string[];
  /** @format double */
  productPrice?: number;
  promotionBanners?: MediaContainerData[];
  promotionGroup?: string;
  /** @format int32 */
  promotionLastModifiedForImageUpdate?: number;
  /** @format double */
  promotionPrice?: number;
  promotionTheme?: PromotionThemeData;
  promotionType?: string;
  /** @format int32 */
  qualifyingCount?: number;
  /** @format double */
  ranking?: number;
  realMixAndMatch?: boolean;
  /** @format int32 */
  redeemLimit?: number;
  redeemLimitLabel?: string;
  restrictions?: PromotionRestrictionData[];
  rewardLabel?: string;
  savePrice?: string;
  /** @format double */
  solrSearchScore?: number;
  /** @format int32 */
  sorting?: number;
  splashTitleText?: string;
  /** @format int32 */
  startDate?: number;
  textLabel?: string;
  textLabelGenerated?: string;
  textLabelManual?: string;
  /** @format double */
  threshold?: number;
  /** @format int32 */
  timesUsed?: number;
  title?: string;
  /** @format double */
  totalSavedValue?: number;
  /** @format int32 */
  validFrom?: number;
  /** @format int32 */
  validUntil?: number;
  weightVolume?: string;
}

export interface PromotionDataV2 {
  brands?: string[];
  campaignType?: string;
  cartLabel?: string;
  cartLabelFormatted?: string;
  category?: string;
  code?: string;
  comparePrice?: string;
  comparePriceWithUnit?: string;
  conditionLabel?: string;
  conditionLabelFormatted?: string;
  description?: string;
  discountPrice?: number;
  endDate?: string;
  isMixAndMatch?: boolean;
  isPromotionUsed?: boolean;
  lowestHistoricalPrice?: PriceData;
  mainProductCode?: string;
  name?: string;
  offerStatus?: string;
  price?: PriceData;
  /** @format int32 */
  priority?: number;
  productCodes?: string[];
  /** @format double */
  promotionPercentage?: number;
  promotionPrice?: number;
  /** @format int32 */
  promotionRedeemLimit?: number;
  promotionTheme?: PromotionThemeData;
  promotionThemeCode?: string;
  promotionType?: string;
  /** @format int32 */
  qualifyingCount?: number;
  realMixAndMatch?: boolean;
  redeemLimitLabel?: string;
  rewardLabel?: string;
  rewardLabels?: string[];
  savePrice?: string;
  /** @format int32 */
  sorting?: number;
  splashTitleText?: string;
  startDate?: string;
  textLabelGenerated?: string;
  textLabelManual?: string;
  /** @format double */
  threshold?: number;
  /** @format int32 */
  timesUsed?: number;
  /** @format int32 */
  validUntil?: number;
  weightVolume?: string;
}

export interface PromotionDTO {
  brands?: string[];
  campaignType?: string;
  cartLabel?: string;
  cartLabelFormatted?: string;
  category?: string;
  code?: string;
  comparePrice?: string;
  comparePriceWithUnit?: string;
  conditionLabel?: string;
  conditionLabelFormatted?: string;
  description?: string;
  discountPrice?: number;
  endDate?: string;
  isMixAndMatch?: boolean;
  isPromotionUsed?: boolean;
  lowestHistoricalPrice?: PriceData;
  mainProductCode?: string;
  name?: string;
  offerStatus?: string;
  price?: PriceData;
  /** @format int32 */
  priority?: number;
  productCodes?: string[];
  /** @format double */
  promotionPercentage?: number;
  promotionPrice?: number;
  /** @format int32 */
  promotionRedeemLimit?: number;
  promotionTheme?: PromotionThemeData;
  promotionThemeCode?: string;
  promotionType?: string;
  /** @format int32 */
  qualifyingCount?: number;
  realMixAndMatch?: boolean;
  redeemLimitLabel?: string;
  rewardLabel?: string;
  rewardLabels?: string[];
  savePrice?: string;
  /** @format int32 */
  sorting?: number;
  splashTitleText?: string;
  startDate?: string;
  textLabelGenerated?: string;
  textLabelManual?: string;
  /** @format double */
  threshold?: number;
  /** @format int32 */
  timesUsed?: number;
  /** @format int32 */
  validUntil?: number;
  weightVolume?: string;
}

export interface PromotionOrderEntryConsumedData {
  /** @format double */
  adjustedUnitPrice?: number;
  code?: string;
  /** @format int32 */
  orderEntryNumber?: number;
  /** @format int64 */
  quantity?: number;
}

export interface PromotionRestrictionData {
  description?: string;
  restrictionType?: string;
}

export interface PromotionResultData {
  consumedEntries?: PromotionOrderEntryConsumedData[];
  description?: string;
  giveAwayCouponCodes?: CouponData[];
  promotionData?: PromotionData;
  totalSavedValue?: PriceData;
}

export interface PromotionSearchPageDataOfSearchStateDataAndAxfoodOfflineProductViewModel {
  breadcrumbs?: BreadcrumbDataOfSearchStateData[];
  currentQuery?: SearchStateData;
  facets?: FacetDataOfSearchStateData[];
  pagination?: PaginationData;
  relatedResults?: AxfoodOfflineProductViewModel[];
  relatedResultsPagination?: PaginationData;
  results?: AxfoodOfflineProductViewModel[];
  sorts?: SortData[];
}

export interface PromotionThemeData {
  code?: string;
  visible?: boolean;
}

export interface QName {
  localPart?: string;
  namespaceURI?: string;
  prefix?: string;
}

export interface ReactTranslationData {
  namespace?: string;
  values?: object;
}

export interface RecipeEntryData {
  cloudinaryImageAltText?: string;
  cloudinaryImageUrl?: string;
  /** @format int32 */
  cookingTime?: number;
  id?: string;
  name?: string;
  /** @format int32 */
  portions?: number;
  recipeImages?: ImageData[];
}

export interface RecipeSearchPageDataOfSearchStateDataAndAxfoodRecipeBasicInfoViewModel {
  breadcrumbs?: BreadcrumbDataOfSearchStateData[];
  currentQuery?: SearchStateData;
  facets?: FacetDataOfSearchStateData[];
  pagination?: PaginationData;
  relatedResults?: AxfoodRecipeBasicInfoViewModel[];
  relatedResultsPagination?: PaginationData;
  results?: AxfoodRecipeBasicInfoViewModel[];
  sorts?: SortData[];
}

export interface RegionData {
  countryIso?: string;
  isocode?: string;
  isocodeShort?: string;
  name?: string;
}

export interface RegisterB2BCustomerForm {
  appliedForCreditFlag: boolean;
  billingAddress?: AddressData;
  captcha?: string;
  checkPassword?: string;
  checkPasswordKey?: string;
  /**
   * @minLength 0
   * @maxLength 40
   */
  companyAlternativeName?: string;
  /**
   * @minLength 0
   * @maxLength 40
   */
  companyName?: string;
  deliveryAddress: AddressData;
  /**
   * @minLength 0
   * @maxLength 241
   * @pattern ^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.[A-Za-z]{2,})$
   */
  email: string;
  /**
   * @minLength 0
   * @maxLength 35
   */
  firstName?: string;
  formReSubmitted?: boolean;
  /**
   * @minLength 0
   * @maxLength 10
   */
  invoiceOption?: string;
  /**
   * @minLength 0
   * @maxLength 40
   */
  invoiceReference?: string;
  /**
   * @minLength 0
   * @maxLength 35
   */
  lastName?: string;
  /** @format double */
  orderLimitAmount?: number;
  /**
   * @minLength 0
   * @maxLength 40
   * @pattern ^((([25789])(\d{5})-(\d{4}))|(([0-9]{2})(([0])([1-9])|([1])([0-2]))(([0])([1-9])|([1-2])([0-9])|([3])([0-1]))-[0-9]{4}))$
   */
  orgNumber?: string;
  /** @pattern ^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])([a-zåäöA-ZÅÄÖ0-9\w/\\//<>_~@$%^&*+=`|{}:;!.,?\"\[\]-]{6,30})$ */
  password: string;
  passwordKey?: string;
  /**
   * @minLength 0
   * @maxLength 30
   * @pattern ^[\+[0-9]{1,3}]?\s?[\s0-9]{6,16}$
   */
  phone: string;
  sapId?: string;
  /**
   * @minLength 0
   * @maxLength 40
   */
  serviceProvider?: string;
  storeId?: string;
}

export interface RegisterCustomerForm {
  charityFlag?: boolean;
  checkPassword?: string;
  checkPasswordKey?: string;
  /**
   * @minLength 0
   * @maxLength 250
   * @pattern ^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.[A-Za-z]{2,})$
   */
  email: string;
  /** @pattern ^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])([a-zåäöA-ZÅÄÖ0-9\w/\\//<>_~@$%^&*+=`|{}:;!.,?\"\[\]-]{6,30})$ */
  password: string;
  passwordKey?: string;
  /** @pattern ^[\+[0-9]{1,3}]?\s?[\s0-9]{6,16}$ */
  phone: string;
  referrerMemberCardNumber?: string;
  sendDigitalReceiptByEmail?: boolean;
  socialSecurityNumber?: string;
  socialSecurityNumberKey?: string;
  storeId?: string;
  useDigitalReceipt?: boolean;
}

export interface RemoveLinkedCardForm {
  cardId: string;
}

export interface RemoveRecipeFromCartRequestBody {
  recipeId: string;
}

export interface Resource {
  description?: string;
  file?: File;
  filename?: string;
  inputStream?: InputStream;
  open?: boolean;
  readable?: boolean;
  uri?: URI;
  url?: URL;
}

export interface ResponseEntity {
  body?: object;
  statusCode?:
    | '100 CONTINUE'
    | '101 SWITCHING_PROTOCOLS'
    | '102 PROCESSING'
    | '103 CHECKPOINT'
    | '200 OK'
    | '201 CREATED'
    | '202 ACCEPTED'
    | '203 NON_AUTHORITATIVE_INFORMATION'
    | '204 NO_CONTENT'
    | '205 RESET_CONTENT'
    | '206 PARTIAL_CONTENT'
    | '207 MULTI_STATUS'
    | '208 ALREADY_REPORTED'
    | '226 IM_USED'
    | '300 MULTIPLE_CHOICES'
    | '301 MOVED_PERMANENTLY'
    | '302 FOUND'
    | '302 MOVED_TEMPORARILY'
    | '303 SEE_OTHER'
    | '304 NOT_MODIFIED'
    | '305 USE_PROXY'
    | '307 TEMPORARY_REDIRECT'
    | '308 PERMANENT_REDIRECT'
    | '400 BAD_REQUEST'
    | '401 UNAUTHORIZED'
    | '402 PAYMENT_REQUIRED'
    | '403 FORBIDDEN'
    | '404 NOT_FOUND'
    | '405 METHOD_NOT_ALLOWED'
    | '406 NOT_ACCEPTABLE'
    | '407 PROXY_AUTHENTICATION_REQUIRED'
    | '408 REQUEST_TIMEOUT'
    | '409 CONFLICT'
    | '410 GONE'
    | '411 LENGTH_REQUIRED'
    | '412 PRECONDITION_FAILED'
    | '413 PAYLOAD_TOO_LARGE'
    | '413 REQUEST_ENTITY_TOO_LARGE'
    | '414 URI_TOO_LONG'
    | '414 REQUEST_URI_TOO_LONG'
    | '415 UNSUPPORTED_MEDIA_TYPE'
    | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
    | '417 EXPECTATION_FAILED'
    | '418 I_AM_A_TEAPOT'
    | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
    | '420 METHOD_FAILURE'
    | '421 DESTINATION_LOCKED'
    | '422 UNPROCESSABLE_ENTITY'
    | '423 LOCKED'
    | '424 FAILED_DEPENDENCY'
    | '425 TOO_EARLY'
    | '426 UPGRADE_REQUIRED'
    | '428 PRECONDITION_REQUIRED'
    | '429 TOO_MANY_REQUESTS'
    | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
    | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
    | '500 INTERNAL_SERVER_ERROR'
    | '501 NOT_IMPLEMENTED'
    | '502 BAD_GATEWAY'
    | '503 SERVICE_UNAVAILABLE'
    | '504 GATEWAY_TIMEOUT'
    | '505 HTTP_VERSION_NOT_SUPPORTED'
    | '506 VARIANT_ALSO_NEGOTIATES'
    | '507 INSUFFICIENT_STORAGE'
    | '508 LOOP_DETECTED'
    | '509 BANDWIDTH_LIMIT_EXCEEDED'
    | '510 NOT_EXTENDED'
    | '511 NETWORK_AUTHENTICATION_REQUIRED';
  /** @format int32 */
  statusCodeValue?: number;
}

export interface ReviewData {
  alias?: string;
  comment?: string;
  /** @format int32 */
  date?: number;
  headline?: string;
  id?: string;
  principal?: PrincipalData;
  /** @format double */
  rating?: number;
}

export interface SearchCompanyForm {
  /** @pattern ^((([25789])(\d{5})-(\d{4}))|(([0-9]{2})(([0])([1-9])|([1])([0-2]))(([0])([1-9])|([1-2])([0-9])|([3])([0-1]))-[0-9]{4}))$ */
  registrationNumber?: string;
}

export interface SearchFilterQueryData {
  key?: string;
  operator?: 'AND' | 'OR';
  values?: string[];
}

export interface SearchQueryData {
  filterQueries?: SearchFilterQueryData[];
  searchQueryContext?: 'DEFAULT' | 'SUGGESTIONS';
  searchType?: string;
  value?: string;
}

export interface SearchStateData {
  query?: SearchQueryData;
  url?: string;
}

export interface SearchSuggestionData {
  linkText?: string;
  linkUrl?: string;
  text?: string;
}

export interface SectionsData {
  header?: string;
  ingredients?: IngredientData[];
}

export interface SetPaymentModeJson {
  mode: string;
}

export interface SmartSuggestionsData {
  headline?: string;
  linkTarget?: string;
  linkText?: string;
  offlineSavingsValueOne?: PriceData;
  offlineSavingsValueThree?: PriceData;
  offlineSavingsValueTwo?: PriceData;
  products?: object[];
  showSavingsText?: boolean;
  totalSavings?: PriceData;
  uid?: string;
}

export interface SolrFirstVariantCategoryEntryData {
  categoryName?: string;
  variantCode?: string;
}

export interface SortData {
  code?: string;
  name?: string;
  selected?: boolean;
}

export interface SpecialOpeningDayData {
  closed?: boolean;
  closingTime?: TimeData;
  comment?: string;
  /** @format int32 */
  date?: number;
  formattedDate?: string;
  name?: string;
  openingTime?: TimeData;
}

export interface SpellingSuggestionDataOfobject {
  query?: object;
  suggestion?: string;
}

export interface SpellingSuggestionDataOfSearchStateData {
  query?: SearchStateData;
  suggestion?: string;
}

export interface StartClickAndCollectProcessData {
  attendantNotified?: boolean;
  staffAttentionNeeded?: boolean;
}

export interface StatusHistoryData {
  subtitleTranslationKey?: string;
  timeExecuted?: string;
  type?: string;
}

export interface StockData {
  /** @format int64 */
  stockLevel?: number;
  stockLevelStatus?: StockLevelStatus;
  /** @format int32 */
  stockThreshold?: number;
}

export interface StockLevelEntryData {
  /** @format float */
  articleATS?: number;
  articleBaseUom?: string;
  articleId?: string;
  /** @format float */
  articleQty?: number;
  available?: boolean;
}

export interface StockLevelStatus {
  code?: string;
  type?: string;
}

export interface StoreInfoData {
  activelySelected?: boolean;
  b2bClickAndCollect?: boolean;
  b2bDeliveryCost?: PriceData;
  b2bFreeDeliveryThreshold?: PriceData;
  b2bPickingCostForCollect?: PriceData;
  b2bPickingCostForCollectPlusPickupCost?: PriceData;
  b2bPickingCostForDelivery?: PriceData;
  b2bPickingCostForDeliveryPlusDeliveryCost?: PriceData;
  b2bPickUpExternalLocationCost?: PriceData;
  b2bPickUpInStoreCost?: PriceData;
  clickAndCollect?: boolean;
  customerServiceMail?: string;
  customerServicePhone?: string;
  deliveryCost?: PriceData;
  external?: boolean;
  externalPickupLocation?: boolean;
  franchiseHomeDeliveryEnabled?: boolean;
  franchiseStore?: boolean;
  freeB2BPickingCostThresholdForCollect?: PriceData;
  freeB2BPickingCostThresholdForDelivery?: PriceData;
  freeDeliveryThreshold?: PriceData;
  freePickingCostThresholdForCollect?: PriceData;
  freePickingCostThresholdForDelivery?: PriceData;
  name?: string;
  onlineStore?: boolean;
  open?: boolean;
  openingHoursMessageKey?: string;
  openingStoreMessageValue?: string;
  pickingCostForCollect?: PriceData;
  pickingCostForCollectPlusPickupCost?: PriceData;
  pickingCostForDelivery?: PriceData;
  pickingCostForDeliveryPlusDeliveryCost?: PriceData;
  pickUpExternalLocationCost?: PriceData;
  pickUpInStoreCost?: PriceData;
  pointOfService?: PointOfServiceData;
  postalCodes?: string[];
  storeChangeAllowed?: boolean;
  storeId?: string;
}

export interface SuggestAddressResponse {
  charset?: string;
  name?: string;
  /** @format int32 */
  status?: number;
  status_text?: string;
  suggestions?: Suggestion[];
  type?: string;
  version?: string;
}

export interface Suggestion {
  adm1?: string;
  adm1_code?: string;
  adm2?: string;
  adm2_code?: string;
  extra_number?: string;
  letter?: string;
  locality?: string;
  postalcode?: string;
  street?: string;
  street_number?: string;
}

export interface SustainableData {
  actionAchieved?: boolean;
  description?: string;
  iconUrl?: string;
  key?: string;
  /** @format int32 */
  numberOfTimesExecuted?: number;
  validTo?: string;
  value?: string;
}

export interface TaxData {
  percentage?: string;
  value?: PriceData;
}

export interface TimeData {
  formattedHour?: string;
  /** @format byte */
  hour?: string;
  /** @format byte */
  minute?: string;
}

export interface TimeSlotData {
  available?: boolean;
  /** @format int32 */
  availableCapacity?: number;
  b2b?: boolean;
  /** @format int32 */
  closeTime?: number;
  closeTimeFormatted?: string;
  code?: string;
  /** @format int32 */
  consumedCapacity?: number;
  dayOfTheWeek?: string;
  deliveryCost?: PriceData;
  deliverySlot?: boolean;
  earlyCloseTime?: boolean;
  /** @format int32 */
  endTime?: number;
  expressSlot?: boolean;
  formattedTime?: string;
  freeDeliveryThreshold?: PriceData;
  freePickingThreshold?: PriceData;
  freePickUpThreshold?: PriceData;
  /** @format int32 */
  maxCapacity?: number;
  /** @format int32 */
  orderEarliestDeliveryTime?: number;
  /** @format int32 */
  orderLatestDeliveryTime?: number;
  pickingCost?: PriceData;
  pickUpCost?: PriceData;
  pickUpExternalLocationCost?: PriceData;
  pickupStoreId?: string;
  /** @format int32 */
  reservedCapacity?: number;
  slotBaseStoreId?: string;
  /** @format int32 */
  startTime?: number;
  tmsDeliveryWindowReference?: TMSDeliveryWindowData;
  totalCost?: PriceData;
}

export interface TMSDeliveryWindowData {
  earliestDateTime?: XMLGregorianCalendar;
  latestDateTime?: XMLGregorianCalendar;
  precedingStopId?: number;
  /** @format float */
  profitability?: number;
  resourceKey?: string;
  routeID?: number;
  scheduleKey?: string;
  stopNumber?: number;
}

export interface TmsDeliveryWindowJson {
  precedingStopId?: number;
  resourceKey?: string;
  routeID?: number;
  scheduleKey?: string;
  stopNumber?: number;
}

export interface UnitData {
  code?: string;
  name?: string;
}

export interface UpdateCommunicationPreferencesForm {
  digitalReceipt?: boolean;
  digitalReceiptEmailCommChannel?: boolean;
  emailNews?: boolean;
  postalOffer?: boolean;
  smsNews?: boolean;
}

export interface UpdateEmailPhoneAndDigitalReceiptForm {
  chkEmail: string;
  email: string;
  phone: string;
  sendDigitalReceiptByEmail?: boolean;
  useDigitalReceipt?: boolean;
}

export interface URI {
  absolute?: boolean;
  authority?: string;
  fragment?: string;
  host?: string;
  opaque?: boolean;
  path?: string;
  /** @format int32 */
  port?: number;
  query?: string;
  rawAuthority?: string;
  rawFragment?: string;
  rawPath?: string;
  rawQuery?: string;
  rawSchemeSpecificPart?: string;
  rawUserInfo?: string;
  scheme?: string;
  schemeSpecificPart?: string;
  userInfo?: string;
}

export interface URL {
  authority?: string;
  content?: object;
  /** @format int32 */
  defaultPort?: number;
  file?: string;
  host?: string;
  path?: string;
  /** @format int32 */
  port?: number;
  protocol?: string;
  query?: string;
  ref?: string;
  userInfo?: string;
}

export interface VariantCategoryData {
  hasImage?: boolean;
  name?: string;
  /** @format int32 */
  priority?: number;
}

export interface VariantMatrixElementData {
  elements?: VariantMatrixElementData[];
  isLeaf?: boolean;
  parentVariantCategory?: VariantCategoryData;
  variantOption?: VariantOptionData;
  variantValueCategory?: VariantValueCategoryData;
}

export interface VariantOptionData {
  code?: string;
  priceData?: PriceData;
  stock?: StockData;
  url?: string;
  variantOptionQualifiers?: VariantOptionQualifierData[];
}

export interface VariantOptionQualifierData {
  image?: ImageData;
  name?: string;
  qualifier?: string;
  value?: string;
}

export interface VariantValueCategoryData {
  name?: string;
  /** @format int32 */
  sequence?: number;
  superCategories?: VariantCategoryData[];
}

export interface VerifyB2BCustomerForm {
  axboId?: string;
  cellPhone?: string;
  email?: string;
  sapIdNumber?: string;
}

export interface VerifyCustomerForm {
  cellPhone?: string;
  email?: string;
  membershipNumber?: string;
  socialSecurityNumber?: string;
  username?: string;
}

export interface VerifyMembersEmailFlowForm {
  /**
   * @minLength 0
   * @maxLength 250
   * @pattern ^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.[A-Za-z]{2,})$
   */
  email?: string;
  encryptedUserName?: string;
  idInSourceSystem?: string;
  socialSecurityNumber?: string;
  userNameCipherKey?: string;
}

export interface VerifyMembersPhoneFlowForm {
  encryptedUserName?: string;
  idInSourceSystem?: string;
  /**
   * @minLength 0
   * @maxLength 250
   * @pattern ^[\+[0-9]{1,3}]?\s?[\s0-9]{6,16}$
   */
  phone?: string;
  socialSecurityNumber?: string;
  userNameCipherKey?: string;
}

export interface VoucherChannelData {
  checkout?: boolean;
  omni?: boolean;
  online?: boolean;
  selfcheckout?: boolean;
  selfscan?: boolean;
}

export interface VoucherContainer {
  bonusVouchers?: AxfoodExternalVoucher[];
  couponVouchers?: AxfoodExternalVoucher[];
  usedBonusVouchers?: AxfoodExternalVoucher[];
  usedCouponVouchers?: AxfoodExternalVoucher[];
}

export interface VoucherData {
  appliedValue?: PriceData;
  code?: string;
  currency?: CurrencyData;
  description?: string;
  freeShipping?: boolean;
  isExternal?: boolean;
  /** @format double */
  minPurchaseAmount?: number;
  name?: string;
  savedValue?: PriceData;
  /** @format double */
  value?: number;
  valueFormatted?: string;
  valueString?: string;
  voucherCode?: string;
  voucherCodes?: string[];
}

export interface VoucherViewModel {
  appliedValue?: PriceData;
  code: string;
  currency?: CurrencyData;
  description: string;
  freeShipping: boolean;
  isExternal: boolean;
  /** @format double */
  minPurchaseAmount: number;
  name: string;
  savedValue?: PriceData;
  /** @format double */
  value: number;
  valueFormatted: string;
  valueString: string;
  voucherCode: string;
  voucherCodes: string[];
  voucherData?: PriceData;
}

export interface WeekdayOpeningDayData {
  closed?: boolean;
  closingTime?: TimeData;
  openingTime?: TimeData;
  weekDay?: string;
}

export interface WishlistEntryForm {
  checked?: boolean;
  ean?: string;
  /** @pattern PRODUCT|PROMOTION|EAN|FREETEXT */
  entryType: string;
  freeTextProduct?: string;
  overrideCartQuantity?: boolean;
  pickUnit?: string;
  productCode?: string;
  promotionCode?: string;
  /**
   * @format double
   * @min 0
   * @exclusiveMin false
   */
  quantity?: number;
}

export interface WishlistForm {
  entries?: WishlistEntryForm[];
  name?: string;
  /** @pattern DATE|CATEGORY|STORE */
  sorting?: string;
  storeId?: string;
}

export interface XMLGregorianCalendar {
  /** @format int32 */
  day?: number;
  eon?: number;
  eonAndYear?: number;
  fractionalSecond?: number;
  /** @format int32 */
  hour?: number;
  /** @format int32 */
  millisecond?: number;
  /** @format int32 */
  minute?: number;
  /** @format int32 */
  month?: number;
  /** @format int32 */
  second?: number;
  /** @format int32 */
  timezone?: number;
  valid?: boolean;
  xmlschemaType?: QName;
  /** @format int32 */
  year?: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '//willys.local.se:9002';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: FormData) =>
      (Array.from(input.keys()) || []).reduce((formData, key) => {
        const property = input.get(key);
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title axfoodstorefront API
 * @version 1.0.0
 * @license axfoodstorefront
 * @baseUrl //willys.local.se:9002
 *
 * axfoodstorefront Documentation
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  articleNavigationMenuComponent = {
    /**
     * No description
     *
     * @tags Component
     * @name GetNavigationDataUsingGet
     * @summary Get navigation data for the given component and page
     * @request GET:/articleNavigationMenuComponent/{componentId}
     * @secure
     */
    getNavigationDataUsingGet: (
      componentId: string,
      query: {
        /** pageLabel */
        pageLabel: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodNavigationNodeViewModel, void>({
        path: `/articleNavigationMenuComponent/${componentId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  axfood = {
    /**
     * No description
     *
     * @tags Account
     * @name UpdateOfferStatusUsingPost
     * @summary Update offer status
     * @request POST:/axfood/rest/account/activateOffer
     * @secure
     */
    updateOfferStatusUsingPost: (
      query: {
        /** offerNumber */
        offerNumber: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/activateOffer`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name GetBonusPointsHistoryUsingGet
     * @summary Get bonus points history
     * @request GET:/axfood/rest/account/bonusPointsHistory
     * @secure
     */
    getBonusPointsHistoryUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodLoyaltyTransactionHistoryViewModel, void>({
        path: `/axfood/rest/account/bonusPointsHistory`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdateCharityUsingPost
     * @summary Update charity
     * @request POST:/axfood/rest/account/charity
     * @secure
     */
    updateCharityUsingPost: (
      query: {
        /** charity */
        charity: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/charity`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdateCommunicationPreferencesUsingPost
     * @summary Update multiple communication options at once
     * @request POST:/axfood/rest/account/communicationPreferences
     * @secure
     */
    updateCommunicationPreferencesUsingPost: (
      data: {
        /** true if customer wants to receive postal offers, false otherwise */
        postalOffer?: boolean;
        /** true if customer wants to receive email news, false otherwise */
        emailNews?: boolean;
        /** true if customer wants to receive sms news, false otherwise */
        smsNews?: boolean;
        /** true if customer wants to receive digital receipts, false otherwise */
        digitalReceipt?: boolean;
        /** true if customer wants to receive digital receipts via email, false otherwise */
        digitalReceiptEmailCommChannel?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/communicationPreferences`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdateSendDigitalReceiptUsingPost
     * @summary Update digital receipt
     * @request POST:/axfood/rest/account/digitalReceipt
     * @secure
     */
    updateSendDigitalReceiptUsingPost: (
      query: {
        /** digitalReceipt */
        digitalReceipt: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/digitalReceipt`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdateDigitalReceiptEmailCommChannelUsingPost
     * @summary Update digital receipt email comm channel
     * @request POST:/axfood/rest/account/digitalReceiptEmailCommChannel
     * @secure
     */
    updateDigitalReceiptEmailCommChannelUsingPost: (
      query: {
        /** digitalReceiptEmailCommChannel */
        digitalReceiptEmailCommChannel: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/digitalReceiptEmailCommChannel`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdateEmailUsingPost
     * @summary Update email
     * @request POST:/axfood/rest/account/email
     * @secure
     */
    updateEmailUsingPost: (
      query?: {
        email?: string;
        chkEmail?: string;
        password?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/email`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdateEmailAndPhoneUsingPost
     * @summary Update email and phone number
     * @request POST:/axfood/rest/account/emailAndPhone
     * @secure
     */
    updateEmailAndPhoneUsingPost: (
      query?: {
        email?: string;
        chkEmail?: string;
        phone?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/emailAndPhone`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name EmailAndPhoneAndDigitalReceiptUsingPost
     * @summary Update email and phone number And Digital Receipt
     * @request POST:/axfood/rest/account/emailAndPhoneAndDigitalReceipt
     * @secure
     */
    emailAndPhoneAndDigitalReceiptUsingPost: (
      updateEmailPhoneAndDigitalReceiptForm: UpdateEmailPhoneAndDigitalReceiptForm,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/emailAndPhoneAndDigitalReceipt`,
        method: 'POST',
        body: updateEmailPhoneAndDigitalReceiptForm,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdateEmailNewsUsingPost
     * @summary Update email news
     * @request POST:/axfood/rest/account/emailNews
     * @secure
     */
    updateEmailNewsUsingPost: (
      query: {
        /** emailNews */
        emailNews: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/emailNews`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name IsActivatedOfferInCartUsingPost
     * @summary Check if activated offer is in cart re-calc or not
     * @request POST:/axfood/rest/account/isActivatedOfferInCart
     * @secure
     */
    isActivatedOfferInCartUsingPost: (
      query: {
        /** offerNumber */
        offerNumber: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/isActivatedOfferInCart`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdateMonthlyCommunicationUsingPost
     * @summary Update monthly communication
     * @request POST:/axfood/rest/account/monthlyCommunication
     * @secure
     */
    updateMonthlyCommunicationUsingPost: (
      query: {
        /** postalOffer */
        postalOffer: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/monthlyCommunication`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name GetOrderHistoryOfflineAndBonusUsingGet
     * @summary Get external order history and bonus for month
     * @request GET:/axfood/rest/account/orderBonus
     * @secure
     */
    getOrderHistoryOfflineAndBonusUsingGet: (
      query?: {
        /**
         * toDate
         * @format date
         */
        toDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodExternalOrderHistoryForMonthViewModel, void>({
        path: `/axfood/rest/account/orderBonus`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name GetOrderHistoryAndBonusUsingGet
     * @summary Get internal, external order history and bonus for month to date
     * @request GET:/axfood/rest/account/orderBonusCombined
     * @secure
     */
    getOrderHistoryAndBonusUsingGet: (
      query?: {
        /**
         * toDate
         * @format date
         */
        toDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodExternalOrderHistoryForMonthViewModel, void>({
        path: `/axfood/rest/account/orderBonusCombined`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name GetOrderHistoryUsingGet
     * @summary Get order history
     * @request GET:/axfood/rest/account/orders
     * @secure
     */
    getOrderHistoryUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodOrderHistoryViewModel[], void>({
        path: `/axfood/rest/account/orders`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name GetPagedOrderHistoryAndBonusUsingGet
     * @summary Get paged internal, external order history and bonus for month to date
     * @request GET:/axfood/rest/account/pagedOrderBonusCombined
     * @secure
     */
    getPagedOrderHistoryAndBonusUsingGet: (
      query?: {
        /**
         * fromDate
         * @format date
         */
        fromDate?: string;
        /**
         * toDate
         * @format date
         */
        toDate?: string;
        /**
         * currentPage
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * pageSize
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodExternalOrderHistoryViewModel, void>({
        path: `/axfood/rest/account/pagedOrderBonusCombined`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdatePasswordUsingPost
     * @summary Update password
     * @request POST:/axfood/rest/account/password
     * @secure
     */
    updatePasswordUsingPost: (
      data: {
        currentPasswordKey: string;
        newPasswordKey: string;
        checkNewPasswordKey: string;
        currentPassword: string;
        newPassword: string;
        checkNewPassword: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdateCellPhoneUsingPost
     * @summary Update phone
     * @request POST:/axfood/rest/account/phone
     * @secure
     */
    updateCellPhoneUsingPost: (
      query: {
        /** phoneNumber */
        phoneNumber: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/phone`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name GetRecentCombinedOrderHistoryDatesUsingGet
     * @summary Get paged internal, external order history and bonus for month to date
     * @request GET:/axfood/rest/account/recentCombinedOrderHistoryDates
     * @secure
     */
    getRecentCombinedOrderHistoryDatesUsingGet: (
      query?: {
        /**
         * toDate
         * @format date
         */
        toDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCombinedOrderHistoryDatesViewModel, void>({
        path: `/axfood/rest/account/recentCombinedOrderHistoryDates`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name RemoveLinkedCardUsingPost
     * @summary Remove connection to willys+ or Hemköp Club of a Card
     * @request POST:/axfood/rest/account/removeLinkedCard
     * @secure
     */
    removeLinkedCardUsingPost: (removeLinkedCardForm: RemoveLinkedCardForm, params: RequestParams = {}) =>
      this.request<CustomerData, void>({
        path: `/axfood/rest/account/removeLinkedCard`,
        method: 'POST',
        body: removeLinkedCardForm,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name ResetLatestLoginIsAutoLoginUsingPost
     * @summary Set latest login auto login false
     * @request POST:/axfood/rest/account/resetLatestLoginIsAutoLogin
     * @secure
     */
    resetLatestLoginIsAutoLoginUsingPost: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/axfood/rest/account/resetLatestLoginIsAutoLogin`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name UpdateSmsNewsUsingPost
     * @summary Update sms news
     * @request POST:/axfood/rest/account/smsNews
     * @secure
     */
    updateSmsNewsUsingPost: (
      query: {
        /** smsNews */
        smsNews: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/account/smsNews`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name GetAccountInfoUsingGet
     * @summary Get account info
     * @request GET:/axfood/rest/account/user-info
     * @secure
     */
    getAccountInfoUsingGet: (params: RequestParams = {}) =>
      this.request<CustomerData, void>({
        path: `/axfood/rest/account/user-info`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Voucher
     * @name GetAllExternalVouchersOnlineAndOfflineUsingGet
     * @summary Get all external vouchers
     * @request GET:/axfood/rest/allexternalvouchers
     * @deprecated
     * @secure
     */
    getAllExternalVouchersOnlineAndOfflineUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodExternalAllVoucherViewModel, void>({
        path: `/axfood/rest/allexternalvouchers`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name GetArticlesUsingGet
     * @summary Get articles
     * @request GET:/axfood/rest/articles/{type}
     * @secure
     */
    getArticlesUsingGet: (
      type: string,
      query?: {
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 200
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ArticleContentPageData[], void>({
        path: `/axfood/rest/articles/${type}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name RegisterB2BCustomerUsingPost
     * @summary Register b2b customer
     * @request POST:/axfood/rest/b2bcustomers/register
     * @secure
     */
    registerB2BCustomerUsingPost: (form: RegisterB2BCustomerForm, params: RequestParams = {}) =>
      this.request<AxfoodRegisterB2BCustomerViewModel, void>({
        path: `/axfood/rest/b2bcustomers/register`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name LookupB2BCustomerUsingPost
     * @summary Lookup b2b customer
     * @request POST:/axfood/rest/b2bcustomers/search
     * @secure
     */
    lookupB2BCustomerUsingPost: (form: SearchCompanyForm, params: RequestParams = {}) =>
      this.request<CompanySearchViewModel, void>({
        path: `/axfood/rest/b2bcustomers/search`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductUsingGet
     * @summary Get offline product details
     * @request GET:/axfood/rest/c/{storeId}/{campaignCode}
     * @secure
     */
    getProductUsingGet: (storeId: string, campaignCode: string, params: RequestParams = {}) =>
      this.request<AxfoodOfflineProductDetailsViewModel, void>({
        path: `/axfood/rest/c/${storeId}/${campaignCode}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name ClearCartUsingDelete
     * @summary Cancel session cart
     * @request DELETE:/axfood/rest/cart
     * @secure
     */
    clearCartUsingDelete: (
      query?: {
        /**
         * Whether possible continuation of the cart should be cancelled
         * @default true
         */
        cancelPossibleContinueCart?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart`,
        method: 'DELETE',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetCartUsingGet
     * @summary Get session cart
     * @request GET:/axfood/rest/cart
     * @secure
     */
    getCartUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name PutToCartUsingPost
     * @summary Add product to cart
     * @request POST:/axfood/rest/cart/addProduct
     * @secure
     */
    putToCartUsingPost: (
      query?: {
        /** @format double */
        qty?: number;
        productCodePost?: string;
        pickUnit?: string;
        noReplacementFlag?: boolean;
        hideDiscountToolTip?: boolean;
        /**
         * calculateCart
         * @default true
         */
        calculateCart?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/addProduct`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name AddToCartUsingPost
     * @summary Add products to cart
     * @request POST:/axfood/rest/cart/addProducts
     * @secure
     */
    addToCartUsingPost: (
      formListWrapper: AddToCartFormListWrapper,
      query?: {
        /**
         * calculateCart
         * @default true
         */
        calculateCart?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/addProducts`,
        method: 'POST',
        query: query,
        body: formListWrapper,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name ContinueShopForOrderUsingPost
     * @summary Continue shop for order
     * @request POST:/axfood/rest/cart/carts
     * @secure
     */
    continueShopForOrderUsingPost: (
      query: {
        /** orderNumber */
        orderNumber: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/carts`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetCustomerAddressesUsingGet
     * @summary Get customer addresses
     * @request GET:/axfood/rest/cart/customer-addresses
     * @secure
     */
    getCustomerAddressesUsingGet: (params: RequestParams = {}) =>
      this.request<AddressData[], void>({
        path: `/axfood/rest/cart/customer-addresses`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetCustomerContactUsingGet
     * @summary Get customer contact
     * @request GET:/axfood/rest/cart/customer-contact-info
     * @secure
     */
    getCustomerContactUsingGet: (params: RequestParams = {}) =>
      this.request<CustomerContactData, void>({
        path: `/axfood/rest/cart/customer-contact-info`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name SetCustomerContactUsingPost
     * @summary Set customer contact info
     * @request POST:/axfood/rest/cart/customer-contact-info
     * @secure
     */
    setCustomerContactUsingPost: (form: CustomerContactForm, params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/customer-contact-info`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetCustomerExtraInfoUsingGet
     * @summary Get customer extra info
     * @request GET:/axfood/rest/cart/customer-extra-info
     * @secure
     */
    getCustomerExtraInfoUsingGet: (params: RequestParams = {}) =>
      this.request<CustomerExtraData, void>({
        path: `/axfood/rest/cart/customer-extra-info`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name SetCustomerExtraUsingPost
     * @summary Set customer extra door code, user comment and users order preference
     * @request POST:/axfood/rest/cart/customer-extra-info
     * @secure
     */
    setCustomerExtraUsingPost: (
      query?: {
        commentFromCustomer?: string;
        doorCode?: string;
        usersOrderReference?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/customer-extra-info`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetCustomerLikelyDeliveryAddressUsingGet
     * @summary Get customer likely delivery address
     * @request GET:/axfood/rest/cart/customer-likely-delivery-address
     * @secure
     */
    getCustomerLikelyDeliveryAddressUsingGet: (params: RequestParams = {}) =>
      this.request<AddressData, void>({
        path: `/axfood/rest/cart/customer-likely-delivery-address`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetDeliveryAddressUsingGet
     * @summary Get delivery address
     * @request GET:/axfood/rest/cart/delivery-address
     * @secure
     */
    getDeliveryAddressUsingGet: (params: RequestParams = {}) =>
      this.request<AddressData, void>({
        path: `/axfood/rest/cart/delivery-address`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name SetDeliveryAddressUsingPost
     * @summary Set delivery address
     * @request POST:/axfood/rest/cart/delivery-address
     * @secure
     */
    setDeliveryAddressUsingPost: (
      query?: {
        firstName?: string;
        lastName?: string;
        addressLine1?: string;
        addressLine2?: string;
        postalCode?: string;
        town?: string;
        addressId?: string;
        email?: string;
        cellphone?: string;
        companyName?: string;
        /** @format double */
        longitude?: number;
        /** @format double */
        latitude?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/delivery-address`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name SetDeliveryModeUsingPost
     * @summary Set delivery mode
     * @request POST:/axfood/rest/cart/delivery-mode/{deliveryModeCode}
     * @secure
     */
    setDeliveryModeUsingPost: (
      deliveryModeCode: string,
      query?: {
        /** newSuggestedStoreId */
        newSuggestedStoreId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/delivery-mode/${deliveryModeCode}`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name CheckEccStockLevelsForSessionCartUsingGet
     * @summary ECC StockLevels
     * @request GET:/axfood/rest/cart/ecc/stocklevels
     * @secure
     */
    checkEccStockLevelsForSessionCartUsingGet: (params: RequestParams = {}) =>
      this.request<StockLevelEntryData[], void>({
        path: `/axfood/rest/cart/ecc/stocklevels`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetMergablePersistentCartUsingGet
     * @summary Get mergeable persistent cart
     * @request GET:/axfood/rest/cart/merge
     * @secure
     */
    getMergablePersistentCartUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodMergeCartViewModel, void>({
        path: `/axfood/rest/cart/merge`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name SetPostalCodeUsingPost
     * @summary Set postal code
     * @request POST:/axfood/rest/cart/postal-code
     * @secure
     */
    setPostalCodeUsingPost: (
      query: {
        /** postalCode */
        postalCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/cart/postal-code`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name UpdateDoNotReplaceFlagUsingPost
     * @summary Update do not replace flag for product
     * @request POST:/axfood/rest/cart/product/{productCode}/do-not-replace
     * @secure
     */
    updateDoNotReplaceFlagUsingPost: (
      productCode: string,
      query: {
        /** doNotReplace */
        doNotReplace: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/product/${productCode}/do-not-replace`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name UpdateDoNotReplaceFlagUsingPost1
     * @summary Update do not replace flag for products on cart
     * @request POST:/axfood/rest/cart/products/do-not-replace
     * @secure
     */
    updateDoNotReplaceFlagUsingPost1: (
      query: {
        /** doNotReplace */
        doNotReplace: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/products/do-not-replace`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name PutToCartUsingPost1
     * @summary Add order to cart
     * @request POST:/axfood/rest/cart/put/order/{order}
     * @secure
     */
    putToCartUsingPost1: (order: string, params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/put/order/${order}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name RestoreCartUsingPost
     * @summary Restore cart
     * @request POST:/axfood/rest/cart/restore
     * @secure
     */
    restoreCartUsingPost: (
      query: {
        /** action */
        action: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/restore`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name RestoreCartAndCloseOpenOrdersUsingPost
     * @summary Restore cart and close open orders
     * @request POST:/axfood/rest/cart/restore-v2
     * @secure
     */
    restoreCartAndCloseOpenOrdersUsingPost: (
      query: {
        /** action */
        action: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartRestorationViewModel, void>({
        path: `/axfood/rest/cart/restore-v2`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name CheckCartStatusUsingGet
     * @summary Cart Status
     * @request GET:/axfood/rest/cart/status
     * @secure
     */
    checkCartStatusUsingGet: (
      query?: {
        /** slotCode */
        slotCode?: string;
        /** storeId */
        storeId?: string;
        /**
         * includeStock
         * @default true
         */
        includeStock?: boolean;
        /**
         * onlyCheckExternal
         * @default false
         */
        onlyCheckExternal?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartStatusViewModel, void>({
        path: `/axfood/rest/cart/status`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetConflictingEntriesUsingGet
     * @summary Status of conflicting cart entries
     * @request GET:/axfood/rest/cart/status/conflicts
     * @secure
     */
    getConflictingEntriesUsingGet: (
      query: {
        /** slotCode */
        slotCode?: string;
        /** storeId */
        storeId?: string;
        /** b2b */
        b2b?: boolean;
        /**
         * onlyCheckExternal
         * @default false
         */
        onlyCheckExternal?: boolean;
        /** type */
        type:
          | 'all'
          | 'allExceptNotAllowedAnonymous'
          | 'allExceptPartialWithReplacementsAndNotAllowed'
          | 'replacable'
          | 'nonReplacable'
          | 'notAllowedAnonymous';
      },
      params: RequestParams = {}
    ) =>
      this.request<Record<string, object>, void>({
        path: `/axfood/rest/cart/status/conflicts`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetChangesOnRestoredCartFromLastSessionUsingGet
     * @summary Changes on restored cart from last session
     * @request GET:/axfood/rest/cart/status/lastsession
     * @secure
     */
    getChangesOnRestoredCartFromLastSessionUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodCartEntryStatusViewModel[], void>({
        path: `/axfood/rest/cart/status/lastsession`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetCartStatusForFreeDeliveryOrPickupUsingGet
     * @summary Threshold status for free delivery or pickup
     * @request GET:/axfood/rest/cart/status/threshold
     * @secure
     */
    getCartStatusForFreeDeliveryOrPickupUsingGet: (params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/axfood/rest/cart/status/threshold`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetStoreChangeAllowedUsingGet
     * @summary Is Change Store Allowed
     * @request GET:/axfood/rest/cart/store/change-allowed
     * @secure
     */
    getStoreChangeAllowedUsingGet: (params: RequestParams = {}) =>
      this.request<CartStoreChangeAllowed, void>({
        path: `/axfood/rest/cart/store/change-allowed`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name UpdateCartModificationsUsingPost
     * @summary Update cart modifications
     * @request POST:/axfood/rest/cart/updatecartmodifications
     * @secure
     */
    updateCartModificationsUsingPost: (params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/updatecartmodifications`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name UpdateEkoReplacementFlagUsingPost
     * @summary Update eko replacement flag on cart entries
     * @request POST:/axfood/rest/cart/update-ekoreplacement
     * @secure
     */
    updateEkoReplacementFlagUsingPost: (
      query: {
        /** replaceEko */
        replaceEko: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/update-ekoreplacement`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name UpdateNoReplacementFlagUsingPost
     * @summary (Depricated) Update no replacement flag on cart entries
     * @request POST:/axfood/rest/cart/update-noreplacement
     * @secure
     */
    updateNoReplacementFlagUsingPost: (params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/update-noreplacement`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name ApplyVoucherToCartUsingPost
     * @summary Apply voucher to cart
     * @request POST:/axfood/rest/cart/voucher
     * @secure
     */
    applyVoucherToCartUsingPost: (
      query: {
        /** voucherCode */
        voucherCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/voucher`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name ReleaseVoucherFromCartUsingDelete
     * @summary Release voucher from cart
     * @request DELETE:/axfood/rest/cart/voucher/{voucherCode}
     * @secure
     */
    releaseVoucherFromCartUsingDelete: (voucherCode: string, params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/cart/voucher/${voucherCode}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name InitializeCheckoutUsingGet
     * @summary Initialize checkout
     * @request GET:/axfood/rest/checkout
     * @secure
     */
    initializeCheckoutUsingGet: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/axfood/rest/checkout`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name BankIdAuthUsingPost
     * @summary Authorize with bankId
     * @request POST:/axfood/rest/checkout/bankid/auth
     * @secure
     */
    bankIdAuthUsingPost: (form: BankIdForm, params: RequestParams = {}) =>
      this.request<AxfoodAuthSignResponse, void>({
        path: `/axfood/rest/checkout/bankid/auth`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name BankCancelUsingPost
     * @summary Cancel bankId sign
     * @request POST:/axfood/rest/checkout/bankid/cancel
     * @secure
     */
    bankCancelUsingPost: (orderRef: Record<string, string>, params: RequestParams = {}) =>
      this.request<CancelResponse, void>({
        path: `/axfood/rest/checkout/bankid/cancel`,
        method: 'POST',
        body: orderRef,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name BankIdCollectUsingPost
     * @summary Collect with bankId
     * @request POST:/axfood/rest/checkout/bankid/collect
     * @secure
     */
    bankIdCollectUsingPost: (orderRef: Record<string, string>, params: RequestParams = {}) =>
      this.request<AxfoodCollectResponse, void>({
        path: `/axfood/rest/checkout/bankid/collect`,
        method: 'POST',
        body: orderRef,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name BankIdCollectLoginUsingPost
     * @summary Collect with bankId login
     * @request POST:/axfood/rest/checkout/bankid/collect-login
     * @secure
     */
    bankIdCollectLoginUsingPost: (orderRef: Record<string, string>, params: RequestParams = {}) =>
      this.request<AxfoodCollectResponse, void>({
        path: `/axfood/rest/checkout/bankid/collect-login`,
        method: 'POST',
        body: orderRef,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name BankIdQrDataUsingPost
     * @summary Refresh qr data
     * @request POST:/axfood/rest/checkout/bankid/qr
     * @secure
     */
    bankIdQrDataUsingPost: (params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/axfood/rest/checkout/bankid/qr`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name BankIdSignUsingPost
     * @summary Sign with bankId
     * @request POST:/axfood/rest/checkout/bankid/sign
     * @secure
     */
    bankIdSignUsingPost: (form: BankIdForm, params: RequestParams = {}) =>
      this.request<AxfoodAuthSignResponse, void>({
        path: `/axfood/rest/checkout/bankid/sign`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name SetPaymentModeUsingPost
     * @summary Set payment mode for user
     * @request POST:/axfood/rest/checkout/paymentmode
     * @secure
     */
    setPaymentModeUsingPost: (paymentModeJson: SetPaymentModeJson, params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/checkout/paymentmode`,
        method: 'POST',
        body: paymentModeJson,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name GetValidPaymentModesForUserUsingGet
     * @summary Get payment modes for user
     * @request GET:/axfood/rest/checkout/paymentmodes
     * @secure
     */
    getValidPaymentModesForUserUsingGet: (params: RequestParams = {}) =>
      this.request<
        (
          | 'PAYEX'
          | 'PAYEXALL'
          | 'PSPPAYEXALL'
          | 'PAYEX_SCANANDGO'
          | 'KREDITERING'
          | 'HYBRISKUPONG'
          | 'INVOICE'
          | 'KLARNA'
          | 'SWISH'
          | 'UNKNOWN'
        )[],
        void
      >({
        path: `/axfood/rest/checkout/paymentmodes`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name GetCsrfTokenUsingGet
     * @summary Get a csrf token for session
     * @request GET:/axfood/rest/csrf-token
     * @secure
     */
    getCsrfTokenUsingGet: (params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/axfood/rest/csrf-token`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetSessionInformationUsingGet
     * @summary Get session information for customer
     * @request GET:/axfood/rest/customer
     * @secure
     */
    getSessionInformationUsingGet: (params: RequestParams = {}) =>
      this.request<CustomerData, void>({
        path: `/axfood/rest/customer`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name AppReactEligibleUsingGet
     * @summary App react eligible
     * @request GET:/axfood/rest/customer/app-react-eligible
     * @secure
     */
    appReactEligibleUsingGet: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/axfood/rest/customer/app-react-eligible`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetBonusBenefitInfoUsingGet
     * @summary Get bonus benefit info
     * @request GET:/axfood/rest/customer/bonusBenefitInfo
     * @secure
     */
    getBonusBenefitInfoUsingGet: (params: RequestParams = {}) =>
      this.request<BonusBenefitInfoData[], void>({
        path: `/axfood/rest/customer/bonusBenefitInfo`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name NewHeaderFooterRedesignEnabledUsingGet
     * @summary Customer header footer design enabled
     * @request GET:/axfood/rest/customer/customer-headerfooterredesign-enabled
     * @secure
     */
    newHeaderFooterRedesignEnabledUsingGet: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/axfood/rest/customer/customer-headerfooterredesign-enabled`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name NewCustomerProgramEnabledUsingGet
     * @summary Customer new program enabled
     * @request GET:/axfood/rest/customer/customer-newprogram-enabled
     * @secure
     */
    newCustomerProgramEnabledUsingGet: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/axfood/rest/customer/customer-newprogram-enabled`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GenerateDataMatrixCodeForCustomerSsnUsingGet
     * @summary Data matrix code for customer ssn
     * @request GET:/axfood/rest/customer/data-matrix-code
     * @secure
     */
    generateDataMatrixCodeForCustomerSsnUsingGet: (
      query?: {
        /**
         * width
         * @format int32
         * @default 300
         */
        width?: number;
        /**
         * height
         * @format int32
         * @default 200
         */
        height?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/axfood/rest/customer/data-matrix-code`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name DeleteAgreementUsingDelete
     * @summary Delete agreement
     * @request DELETE:/axfood/rest/customer/savedcard/{agreementId}
     * @secure
     */
    deleteAgreementUsingDelete: (agreementId: string, params: RequestParams = {}) =>
      this.request<CustomerData, void>({
        path: `/axfood/rest/customer/savedcard/${agreementId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name ScanAndGoEligibleUsingGet
     * @summary Scan an go eligible
     * @request GET:/axfood/rest/customer/scan-and-go-eligible
     * @secure
     */
    scanAndGoEligibleUsingGet: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/axfood/rest/customer/scan-and-go-eligible`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetSustainablesUsingGet
     * @summary Get sustainables
     * @request GET:/axfood/rest/customer/sustainables
     * @secure
     */
    getSustainablesUsingGet: (params: RequestParams = {}) =>
      this.request<SustainableData[], void>({
        path: `/axfood/rest/customer/sustainables`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer service
     * @name GetAllReasonsUsingGet
     * @summary Get all reasons
     * @request GET:/axfood/rest/customer-service/reasons
     * @secure
     */
    getAllReasonsUsingGet: (params: RequestParams = {}) =>
      this.request<CustomerContactReason[], void>({
        path: `/axfood/rest/customer-service/reasons`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Postal
     * @name IsDeliveryAvailableForAddressLineUsingGet
     * @summary Is delivery available for address line
     * @request GET:/axfood/rest/delivery/addressline
     * @secure
     */
    isDeliveryAvailableForAddressLineUsingGet: (
      query: {
        /** line */
        line: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Record<string, boolean>, void>({
        path: `/axfood/rest/delivery/addressline`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Postal
     * @name IsDeliveryAvailableUsingGet
     * @summary Is delivery available
     * @request GET:/axfood/rest/delivery/delivery-available
     * @secure
     */
    isDeliveryAvailableUsingGet: (
      query: {
        /** street */
        street: string;
        /** postalcode */
        postalcode: string;
        /** locality */
        locality: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Record<string, boolean>, void>({
        path: `/axfood/rest/delivery/delivery-available`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name RegisterDeviceUsingGet
     * @summary Register device
     * @request GET:/axfood/rest/device/register
     * @secure
     */
    registerDeviceUsingGet: (
      query: {
        /** customerId */
        customerId: string;
        /** appToken */
        appToken: string;
        /** endpointARN */
        endpointARN?: string;
        /** deviceType */
        deviceType: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResponseEntity, void>({
        path: `/axfood/rest/device/register`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Voucher
     * @name GetExternalVoucherUsingGet
     * @summary Get external vouchers
     * @request GET:/axfood/rest/externalvoucher
     * @secure
     */
    getExternalVoucherUsingGet: (
      query?: {
        /**
         * isOnline
         * @default false
         */
        isOnline?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodExternalVoucherViewModel, void>({
        path: `/axfood/rest/externalvoucher`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Voucher
     * @name ActivateExternalVoucherUsingPut
     * @summary Activate external voucher
     * @request PUT:/axfood/rest/externalvoucher/activate/{voucherCode}
     * @secure
     */
    activateExternalVoucherUsingPut: (voucherCode: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/axfood/rest/externalvoucher/activate/${voucherCode}`,
        method: 'PUT',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Voucher
     * @name GetExternalVoucherForClubInfoUsingGet
     * @summary Get external voucher for club info
     * @request GET:/axfood/rest/externalvoucher/clubinfo
     * @secure
     */
    getExternalVoucherForClubInfoUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodExternalVoucherViewModel, void>({
        path: `/axfood/rest/externalvoucher/clubinfo`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Voucher
     * @name GetExternalVoucherCountUsingGet
     * @summary Get all external vouchers
     * @request GET:/axfood/rest/externalvoucher/count
     * @secure
     */
    getExternalVoucherCountUsingGet: (params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/axfood/rest/externalvoucher/count`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Voucher
     * @name GetInvalidExternalVoucherUsingGet
     * @summary Get invalid external voucher
     * @request GET:/axfood/rest/externalvoucher/invalid/{orderId}
     * @secure
     */
    getInvalidExternalVoucherUsingGet: (orderId: string, params: RequestParams = {}) =>
      this.request<string[], void>({
        path: `/axfood/rest/externalvoucher/invalid/${orderId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name GetEntriesForCategoryUsingGet
     * @summary Get entries for category
     * @request GET:/axfood/rest/faq/category/{categoryUid}/entries
     * @secure
     */
    getEntriesForCategoryUsingGet: (categoryUid: string, params: RequestParams = {}) =>
      this.request<FAQEntryData[], void>({
        path: `/axfood/rest/faq/category/${categoryUid}/entries`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feature
     * @name FeaturesUsingGet
     * @summary Get features
     * @request GET:/axfood/rest/feature
     * @secure
     */
    featuresUsingGet: (params: RequestParams = {}) =>
      this.request<FeatureToggle[], void>({
        path: `/axfood/rest/feature`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feature
     * @name GetFeatureToggleUsingGet
     * @summary Get feature toggle
     * @request GET:/axfood/rest/feature/{featureName}
     * @secure
     */
    getFeatureToggleUsingGet: (featureName: string, params: RequestParams = {}) =>
      this.request<FeatureToggle, void>({
        path: `/axfood/rest/feature/${featureName}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feature
     * @name GetFeaturesForServiceUsingGet
     * @summary Get features for a service
     * @request GET:/axfood/rest/feature/service/{serviceName}
     * @secure
     */
    getFeaturesForServiceUsingGet: (serviceName: string, params: RequestParams = {}) =>
      this.request<FeatureToggle[], void>({
        path: `/axfood/rest/feature/service/${serviceName}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name GetFullUserWishListsUsingGet
     * @summary Get full user wish lists
     * @request GET:/axfood/rest/full-user-wishlist
     * @secure
     */
    getFullUserWishListsUsingGet: (
      query?: {
        /**
         * sorting
         * @default "LAST_UPDATED_DESC"
         */
        sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC';
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodWishListViewModel[], void>({
        path: `/axfood/rest/full-user-wishlist`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name GetJobAdvertsUsingGet
     * @summary Get job adverts
     * @request GET:/axfood/rest/jobs
     * @secure
     */
    getJobAdvertsUsingGet: (params: RequestParams = {}) =>
      this.request<JobAdvertData[], void>({
        path: `/axfood/rest/jobs`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CancelOrderUsingPost1
     * @summary Cancel klarna order
     * @request POST:/axfood/rest/klarna/cancel-order
     * @secure
     */
    cancelOrderUsingPost1: (
      query: {
        /** orderCode */
        orderCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/axfood/rest/klarna/cancel-order`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CleanKlarnaSessionUsingGet
     * @summary Clean klarna session
     * @request GET:/axfood/rest/klarna/clean-session
     * @secure
     */
    cleanKlarnaSessionUsingGet: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/axfood/rest/klarna/clean-session`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CreateSessionUsingGet
     * @summary Create klarna payment session
     * @request GET:/axfood/rest/klarna/payment-session
     * @secure
     */
    createSessionUsingGet: (params: RequestParams = {}) =>
      this.request<CreateSessionPaymentResponse, void>({
        path: `/axfood/rest/klarna/payment-session`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name SetPaymentMethodUsingPost
     * @summary Set klarna payment method
     * @request POST:/axfood/rest/klarna/set-klarna-paymentmethod
     * @secure
     */
    setPaymentMethodUsingPost: (
      query: {
        /** paymentmethod */
        paymentmethod: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/klarna/set-klarna-paymentmethod`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name VerifyEmailUsingPost
     * @summary Verify email
     * @request POST:/axfood/rest/lost-password/verify-email
     * @secure
     */
    verifyEmailUsingPost: (form: VerifyMembersEmailFlowForm, params: RequestParams = {}) =>
      this.request<AxfoodBasicStatusResponseViewModel, void>({
        path: `/axfood/rest/lost-password/verify-email`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name VerifyMcnUsingPost
     * @summary Verify membership number
     * @request POST:/axfood/rest/lost-password/verify-membership-number
     * @secure
     */
    verifyMcnUsingPost: (form: VerifyMembersEmailFlowForm, params: RequestParams = {}) =>
      this.request<AxfoodBasicStatusResponseViewModel, void>({
        path: `/axfood/rest/lost-password/verify-membership-number`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name VerifyPhoneUsingPost
     * @summary Verify phone
     * @request POST:/axfood/rest/lost-password/verify-phone
     * @secure
     */
    verifyPhoneUsingPost: (form: VerifyMembersPhoneFlowForm, params: RequestParams = {}) =>
      this.request<AxfoodVerifyPhoneResponseViewModel, void>({
        path: `/axfood/rest/lost-password/verify-phone`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name ClearCartUsingDelete1
     * @summary Cancel session cart
     * @request DELETE:/axfood/rest/minicart
     * @secure
     */
    clearCartUsingDelete1: (
      query?: {
        /**
         * Whether possible continuation of the cart should be cancelled
         * @default true
         */
        cancelPossibleContinueCart?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodMiniCartViewModel, void>({
        path: `/axfood/rest/minicart`,
        method: 'DELETE',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name GetCartUsingGet1
     * @summary Get session cart
     * @request GET:/axfood/rest/minicart
     * @secure
     */
    getCartUsingGet1: (params: RequestParams = {}) =>
      this.request<AxfoodMiniCartViewModel, void>({
        path: `/axfood/rest/minicart`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name PutToCartUsingPost2
     * @summary Add product to cart
     * @request POST:/axfood/rest/minicart/addProduct
     * @secure
     */
    putToCartUsingPost2: (
      query?: {
        /** @format double */
        qty?: number;
        productCodePost?: string;
        pickUnit?: string;
        noReplacementFlag?: boolean;
        hideDiscountToolTip?: boolean;
        /**
         * calculateCart
         * @default true
         */
        calculateCart?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodMiniCartViewModel, void>({
        path: `/axfood/rest/minicart/addProduct`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name AddToCartUsingPost1
     * @summary Add products to cart
     * @request POST:/axfood/rest/minicart/addProducts
     * @secure
     */
    addToCartUsingPost1: (
      formListWrapper: AddToCartFormListWrapper,
      query?: {
        /**
         * calculateCart
         * @default true
         */
        calculateCart?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodMiniCartViewModel, void>({
        path: `/axfood/rest/minicart/addProducts`,
        method: 'POST',
        query: query,
        body: formListWrapper,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cart
     * @name PutToCartUsingPost3
     * @summary Add order to cart
     * @request POST:/axfood/rest/minicart/put/order/{order}
     * @secure
     */
    putToCartUsingPost3: (order: string, params: RequestParams = {}) =>
      this.request<AxfoodMiniCartViewModel, void>({
        path: `/axfood/rest/minicart/put/order/${order}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name GetItemsForYearUsingGet
     * @summary Get items for year
     * @request GET:/axfood/rest/news/year/{yearUid}/items
     * @secure
     */
    getItemsForYearUsingGet: (yearUid: string, params: RequestParams = {}) =>
      this.request<NewsItemData[], void>({
        path: `/axfood/rest/news/year/${yearUid}/items`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name GetAppVersionNonStaticUsingGet
     * @summary Get supported app version from db
     * @request GET:/axfood/rest/non-static-supported-app-version
     * @secure
     */
    getAppVersionNonStaticUsingGet: (
      query: {
        /** deviceType */
        deviceType: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AppVersionData, void>({
        path: `/axfood/rest/non-static-supported-app-version`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name CancelOrderUsingPost
     * @summary Cancel order
     * @request POST:/axfood/rest/order/cancelOrder/{orderCode}
     * @secure
     */
    cancelOrderUsingPost: (orderCode: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/axfood/rest/order/cancelOrder/${orderCode}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name LockOrderBoxUsingPost
     * @summary Locks a box of a click and collect order
     * @request POST:/axfood/rest/order/clickandcollect/{orderCode}/boxes/{boxId}/lock
     * @secure
     */
    lockOrderBoxUsingPost: (orderCode: string, boxId: string, params: RequestParams = {}) =>
      this.request<AxfoodClickAndCollectOrderBoxesViewModel, void>({
        path: `/axfood/rest/order/clickandcollect/${orderCode}/boxes/${boxId}/lock`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name UnlockOrderBoxUsingPost
     * @summary Unlocks a box of a click and collect order
     * @request POST:/axfood/rest/order/clickandcollect/{orderCode}/boxes/{boxId}/unlock
     * @secure
     */
    unlockOrderBoxUsingPost: (
      orderCode: string,
      boxId: string,
      query?: {
        /** previousOpened */
        previousOpened?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodClickAndCollectOrderBoxesViewModel, void>({
        path: `/axfood/rest/order/clickandcollect/${orderCode}/boxes/${boxId}/unlock`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name FinishCollectProcessUsingPost
     * @summary Finishes the collect process of an order
     * @request POST:/axfood/rest/order/clickandcollect/{orderCode}/finish
     * @secure
     */
    finishCollectProcessUsingPost: (orderCode: string, params: RequestParams = {}) =>
      this.request<AxfoodClickAndCollectOrderBoxesViewModel, void>({
        path: `/axfood/rest/order/clickandcollect/${orderCode}/finish`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name StartCollectProcessUsingPost
     * @summary Starts the collect process of an order
     * @request POST:/axfood/rest/order/clickandcollect/{orderCode}/start
     * @secure
     */
    startCollectProcessUsingPost: (orderCode: string, params: RequestParams = {}) =>
      this.request<StartClickAndCollectProcessData, void>({
        path: `/axfood/rest/order/clickandcollect/${orderCode}/start`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name IsOrderProcessFinishedUsingGet
     * @summary Is order process finished
     * @request GET:/axfood/rest/order/isOrderProccessFinished/{orderCode}
     * @secure
     */
    isOrderProcessFinishedUsingGet: (orderCode: string, params: RequestParams = {}) =>
      this.request<boolean, void>({
        path: `/axfood/rest/order/isOrderProccessFinished/${orderCode}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetLastOrderLinesUsingGet
     * @summary Get last order lines
     * @request GET:/axfood/rest/order/last/lines
     * @secure
     */
    getLastOrderLinesUsingGet: (
      query: {
        /** baseStore */
        baseStore: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodBasicProductViewModel[], void>({
        path: `/axfood/rest/order/last/lines`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetOrderHistoryUsingGet1
     * @summary Get order history
     * @request GET:/axfood/rest/order/orderHistory
     * @secure
     */
    getOrderHistoryUsingGet1: (params: RequestParams = {}) =>
      this.request<OrderHistoryData[], void>({
        path: `/axfood/rest/order/orderHistory`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetOrderUsingGet
     * @summary Get order
     * @request GET:/axfood/rest/order/orders/{orderCode}
     * @secure
     */
    getOrderUsingGet: (orderCode: string, params: RequestParams = {}) =>
      this.request<AxfoodOrderViewModel, void>({
        path: `/axfood/rest/order/orders/${orderCode}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetOrderReceiptUsingGet
     * @summary Get order receipt
     * @request GET:/axfood/rest/order/orders/{orderCode}/receipt
     * @secure
     */
    getOrderReceiptUsingGet: (orderCode: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/axfood/rest/order/orders/${orderCode}/receipt`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetOrdersUsingGet
     * @summary Get orders for complaint
     * @request GET:/axfood/rest/order/orders/complaint
     * @secure
     */
    getOrdersUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodBasicOrderViewModel[], void>({
        path: `/axfood/rest/order/orders/complaint`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetDigitalReceiptUsingGet
     * @summary Is digital receipt
     * @request GET:/axfood/rest/order/orders/digitalreceipt/{getDigitalReceiptReference}
     * @secure
     */
    getDigitalReceiptUsingGet: (
      getDigitalReceiptReference: string,
      query: {
        /** date */
        date: string;
        /** storeId */
        storeId: string;
        /**
         * source
         * @default "axcrm"
         */
        source?: string;
        /** memberCardNumber */
        memberCardNumber: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/order/orders/digitalreceipt/${getDigitalReceiptReference}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name ValidateOrderEntriesUsingGet
     * @summary Validate order entries
     * @request GET:/axfood/rest/order/validate/{orderCode}
     * @secure
     */
    validateOrderEntriesUsingGet: (orderCode: string, params: RequestParams = {}) =>
      this.request<AxfoodCartEntryStatusViewModel[], void>({
        path: `/axfood/rest/order/validate/${orderCode}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetFullOrderDataUsingGet
     * @summary Get data for an order
     * @request GET:/axfood/rest/orderdata
     * @secure
     */
    getFullOrderDataUsingGet: (
      query: {
        /** Order Code Used in query to database */
        q: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderDataV2Response, ApiError | void>({
        path: `/axfood/rest/orderdata`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetFullOrderDataUsingGet1
     * @summary Get data for an order
     * @request GET:/axfood/rest/orderdata/
     * @secure
     */
    getFullOrderDataUsingGet1: (
      query: {
        /** Order Code Used in query to database */
        q: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderDataV2Response, ApiError | void>({
        path: `/axfood/rest/orderdata/`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name GetOrderTrackingStatusUsingGet
     * @summary Get order tracking status
     * @request GET:/axfood/rest/orderDeliveryStatus/{trackingID}
     * @secure
     */
    getOrderTrackingStatusUsingGet: (trackingId: string, params: RequestParams = {}) =>
      this.request<OrderTrackingStatusHistoryData, void>({
        path: `/axfood/rest/orderDeliveryStatus/${trackingId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name GetOngoingTrackingStatusesUsingGet
     * @summary Get order tracking status for ongoing orders of customer
     * @request GET:/axfood/rest/orderDeliveryStatus/ongoing-tracking-statuses
     * @secure
     */
    getOngoingTrackingStatusesUsingGet: (params: RequestParams = {}) =>
      this.request<OrderTrackingStatusHistoryData[], void>({
        path: `/axfood/rest/orderDeliveryStatus/ongoing-tracking-statuses`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductUsingGet1
     * @summary Get product details
     * @request GET:/axfood/rest/p/{productCode}
     * @secure
     */
    getProductUsingGet1: (productCode: string, params: RequestParams = {}) =>
      this.request<AxfoodProductDetailsViewModel, void>({
        path: `/axfood/rest/p/${productCode}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductV2UsingGet
     * @summary Get product detail in Sections
     * @request GET:/axfood/rest/p/{productCode}/section/{sectionName}
     * @secure
     */
    getProductV2UsingGet: (productCode: string, sectionName: string, params: RequestParams = {}) =>
      this.request<AxfoodProductDetailSectionViewModel, void>({
        path: `/axfood/rest/p/${productCode}/section/${sectionName}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personal Elements
     * @name GetPersonalElementUsingGet
     * @summary Get personal element
     * @request GET:/axfood/rest/personalElement
     * @secure
     */
    getPersonalElementUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodPersonalElementViewModel, void>({
        path: `/axfood/rest/personalElement`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personal Elements
     * @name GetListOfPersonalElementsUsingGet
     * @summary Get list of personal elements
     * @request GET:/axfood/rest/personalElementList
     * @secure
     */
    getListOfPersonalElementsUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodPersonalElementViewModel[], void>({
        path: `/axfood/rest/personalElementList`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name PingUsingGet
     * @summary ping
     * @request GET:/axfood/rest/ping
     * @secure
     */
    pingUsingGet: (params: RequestParams = {}) =>
      this.request<ResponseEntity, void>({
        path: `/axfood/rest/ping`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductRecommendationUsingGet
     * @summary Get product recommendations
     * @request GET:/axfood/rest/products/{productCode}/{displayType}
     * @secure
     */
    getProductRecommendationUsingGet: (
      productCode: string,
      displayType: string,
      query?: {
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * pageSize
         * @format int32
         * @default 20
         */
        pageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodPaginationViewModelOfAxfoodProductDetailsViewModel, void>({
        path: `/axfood/rest/products/${productCode}/${displayType}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetProductsFromPromotionUsingGet
     * @summary Get products for given promotion code and store excluding products in parameter
     * @request GET:/axfood/rest/promotionproduct/{promotionCode}/products
     * @secure
     */
    getProductsFromPromotionUsingGet: (
      promotionCode: string,
      query: {
        /** List of excluded products */
        excludeProducts?: string[];
        /** The search query */
        storeId: string;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductPromotionDataV2Response, ApiError | void>({
        path: `/axfood/rest/promotionproduct/${promotionCode}/products`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetMixPromotionsUsingGet
     * @summary Get mix promotions
     * @request GET:/axfood/rest/promotionproduct/mix
     * @secure
     */
    getMixPromotionsUsingGet: (
      query: {
        /** The search query */
        q: string;
        /** The customer type */
        type?: string;
        /**
         * Current page to start at
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * Size of each page
         * @format int32
         * @default 20
         */
        size?: number;
        /**
         * Number of online promotions displayed in previous pages
         * @format int32
         * @default 0
         */
        onlineSize?: number;
        /**
         * Number of offline promotions displayed in previous pages
         * @format int32
         * @default 0
         */
        offlineSize?: number;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductPromotionDataV2Response, ApiError | void>({
        path: `/axfood/rest/promotionproduct/mix`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetOfflinePromotionsUsingGet
     * @summary Get offline promotions
     * @request GET:/axfood/rest/promotionproduct/offline
     * @secure
     */
    getOfflinePromotionsUsingGet: (
      query: {
        /** The search query */
        q: string;
        /** The customer type */
        type?: string;
        /**
         * Current page to start at
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * Size of each page
         * @format int32
         * @default 20
         */
        size?: number;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductPromotionDataV2Response, ApiError | void>({
        path: `/axfood/rest/promotionproduct/offline`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetOnlinePromotionsUsingGet
     * @summary Get online promotions
     * @request GET:/axfood/rest/promotionproduct/online
     * @secure
     */
    getOnlinePromotionsUsingGet: (
      query: {
        /** The search query */
        q: string;
        /** The customer type */
        type?: string;
        /**
         * Current page to start at
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * Size of each page
         * @format int32
         * @default 20
         */
        size?: number;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductPromotionDataV2Response, ApiError | void>({
        path: `/axfood/rest/promotionproduct/online`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetPromotionProductsUsingGet
     * @summary Get promotion products
     * @request GET:/axfood/rest/promotions/{promotionCode}/products
     * @secure
     */
    getPromotionProductsUsingGet: (
      promotionCode: string,
      query?: {
        /** excludeProducts */
        excludeProducts?: string[];
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * pageSize
         * @format int32
         * @default 20
         */
        pageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodPaginationViewModelOfAxfoodBasicProductViewModel, void>({
        path: `/axfood/rest/promotions/${promotionCode}/products`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name GetRecipeUsingGet
     * @summary Get recipe
     * @request GET:/axfood/rest/recipe/{recipeId}
     * @secure
     */
    getRecipeUsingGet: (recipeId: string, params: RequestParams = {}) =>
      this.request<AxfoodPurchasableRecipeInfoViewModel, void>({
        path: `/axfood/rest/recipe/${recipeId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name AddEntryToRecipeCartUsingPost
     * @summary Add entry to recipe cart
     * @request POST:/axfood/rest/recipe/addEntryToRecipeCart
     * @secure
     */
    addEntryToRecipeCartUsingPost: (
      query: {
        /** productCode */
        productCode: string;
        /** recipeId */
        recipeId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodPurchasableRecipeInfoViewModel, void>({
        path: `/axfood/rest/recipe/addEntryToRecipeCart`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name AddReplacementProductUsingPost
     * @summary Add replacement products
     * @request POST:/axfood/rest/recipe/addReplacementProducts
     * @secure
     */
    addReplacementProductUsingPost: (body: AddRecipeReplacementProducts, params: RequestParams = {}) =>
      this.request<AxfoodPurchasableRecipeInfoViewModel, void>({
        path: `/axfood/rest/recipe/addReplacementProducts`,
        method: 'POST',
        body: body,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name AddRecipeToCartUsingPost
     * @summary Add recipe to cart
     * @request POST:/axfood/rest/recipe/addToCart
     * @secure
     */
    addRecipeToCartUsingPost: (body: AddRecipeToCartRequestBody, params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/recipe/addToCart`,
        method: 'POST',
        body: body,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name ChangePortionsUsingPatch
     * @summary Change portions to recipe
     * @request PATCH:/axfood/rest/recipe/changePortions
     * @secure
     */
    changePortionsUsingPatch: (request: ChangePortionsRequest, params: RequestParams = {}) =>
      this.request<AxfoodPurchasableRecipeInfoViewModel, void>({
        path: `/axfood/rest/recipe/changePortions`,
        method: 'PATCH',
        body: request,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name FindRecipeReplacementProductsUsingGet
     * @summary Find recipe replacement products
     * @request GET:/axfood/rest/recipe/findRecipeReplacementProducts/{recipeId}
     * @secure
     */
    findRecipeReplacementProductsUsingGet: (
      recipeId: string,
      query: {
        /** productCode */
        productCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Record<string, object>, void>({
        path: `/axfood/rest/recipe/findRecipeReplacementProducts/${recipeId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name GetRelatedRecipesUsingGet
     * @summary Get related recipes
     * @request GET:/axfood/rest/recipe/getRelatedRecipes/{recipeId}
     * @secure
     */
    getRelatedRecipesUsingGet: (recipeId: string, params: RequestParams = {}) =>
      this.request<AxfoodRecipeBasicInfoViewModel[], void>({
        path: `/axfood/rest/recipe/getRelatedRecipes/${recipeId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name GetRecipeUsingGet1
     * @summary Get recipe
     * @request GET:/axfood/rest/recipe/preview/{recipeId}
     * @secure
     */
    getRecipeUsingGet1: (recipeId: string, params: RequestParams = {}) =>
      this.request<AxfoodPurchasableRecipeInfoViewModel, void>({
        path: `/axfood/rest/recipe/preview/${recipeId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RemoveEntryFromRecipeCartUsingPost
     * @summary Remove entry from recipe cart
     * @request POST:/axfood/rest/recipe/removeEntryFromRecipeCart
     * @secure
     */
    removeEntryFromRecipeCartUsingPost: (
      query: {
        /** productCode */
        productCode: string;
        /** recipeId */
        recipeId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodPurchasableRecipeInfoViewModel, void>({
        path: `/axfood/rest/recipe/removeEntryFromRecipeCart`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RemoveRecipeFromCartUsingPost
     * @summary Remove recipe from cart
     * @request POST:/axfood/rest/recipe/removeFromCart
     * @secure
     */
    removeRecipeFromCartUsingPost: (body: RemoveRecipeFromCartRequestBody, params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/recipe/removeFromCart`,
        method: 'POST',
        body: body,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name GetRecipeWishlistUsingGet
     * @summary Get recipe wish list
     * @request GET:/axfood/rest/recipe-wishlist/{id}
     * @secure
     */
    getRecipeWishlistUsingGet: (id: string, params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel, void>({
        path: `/axfood/rest/recipe-wishlist/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CompleteCustomerInfoUsingPost
     * @summary Register complete customer info
     * @request POST:/axfood/rest/register/complete-customer-info
     * @secure
     */
    completeCustomerInfoUsingPost: (form: CompleteMemberEmailFlowForm, params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/axfood/rest/register/complete-customer-info`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CompleteMembershipUsingPost
     * @summary Register complete membership
     * @request POST:/axfood/rest/register/complete-membership
     * @secure
     */
    completeMembershipUsingPost: (form: CompleteMemberPhoneFlowForm, params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/axfood/rest/register/complete-membership`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name VerifyEmailUsingPost1
     * @summary Verify email
     * @request POST:/axfood/rest/register/verify-email
     * @secure
     */
    verifyEmailUsingPost1: (form: VerifyMembersEmailFlowForm, params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/axfood/rest/register/verify-email`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name VerifyIdInSourceSystemUsingPost
     * @summary Verify id in source system
     * @request POST:/axfood/rest/register/verify-membership-number
     * @secure
     */
    verifyIdInSourceSystemUsingPost: (form: VerifyMembersEmailFlowForm, params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/axfood/rest/register/verify-membership-number`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name VerifyPhoneUsingPost1
     * @summary Verify phone
     * @request POST:/axfood/rest/register/verify-phone
     * @secure
     */
    verifyPhoneUsingPost1: (form: VerifyMembersPhoneFlowForm, params: RequestParams = {}) =>
      this.request<AxfoodVerifyPhoneResponseViewModel, void>({
        path: `/axfood/rest/register/verify-phone`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetStoreUsingGet
     * @summary Searches for stores
     * @request GET:/axfood/rest/search/store
     * @secure
     */
    getStoreUsingGet: (
      query: {
        /** The search query */
        q: string;
        /**
         * Current page index. Used for pagination
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * Size of a single page of results.
         * @format int32
         * @default 400
         */
        size?: number;
        /**
         * Whether to show all the stores or a page of results.
         * @default "Page"
         */
        show?: 'Page' | 'All';
        /** Field to use for sorting the results */
        sort: string;
        /** If true, only CnC stores will be returned */
        searchHasClickAndCollect?: boolean;
        /** If true, only CnC stores available for B2B will be returned */
        searchHasB2bClickAndCollect?: boolean;
        /** If true, only ScanAndGo stores will be returned */
        scanAndGo?: boolean;
        /** If true, only stores marked as external pickup location will be returned */
        externalPickupLocation?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<FacetSearchPageData, void>({
        path: `/axfood/rest/search/store`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shared wishlist
     * @name GetSharedWishlistUsingGet
     * @summary Get shared wishlist by ID
     * @request GET:/axfood/rest/shared-wishlist/{wishlistId}
     * @secure
     */
    getSharedWishlistUsingGet: (wishlistId: string, params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel, void>({
        path: `/axfood/rest/shared-wishlist/${wishlistId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shared wishlist
     * @name ShareWishlistUsingPost
     * @summary Make the given user wishlist shareable
     * @request POST:/axfood/rest/shared-wishlist/{wishlistId}/share
     * @secure
     */
    shareWishlistUsingPost: (wishlistId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/axfood/rest/shared-wishlist/${wishlistId}/share`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name GetDeliverabilityUsingGet
     * @summary getDeliverability
     * @request GET:/axfood/rest/shipping/delivery/{postalCode}/deliverability
     * @secure
     */
    getDeliverabilityUsingGet: (
      postalCode: string,
      query?: {
        /** b2b */
        b2b?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodDeliverabilityViewModel, void>({
        path: `/axfood/rest/shipping/delivery/${postalCode}/deliverability`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name GetWishListsUsingGet
     * @summary Get site wish lists
     * @request GET:/axfood/rest/site-wishlist
     * @secure
     */
    getWishListsUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel[], void>({
        path: `/axfood/rest/site-wishlist`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name GetSiteWishlistUsingGet
     * @summary Get site wish list
     * @request GET:/axfood/rest/site-wishlist/{id}
     * @secure
     */
    getSiteWishlistUsingGet: (id: string, params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel, void>({
        path: `/axfood/rest/site-wishlist/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slot
     * @name IsCurrentSlotAvailableForPostalCodeUsingGet
     * @summary Is current slot available for postal code
     * @request GET:/axfood/rest/slot/currentSlotAvailableForPostalCode
     * @secure
     */
    isCurrentSlotAvailableForPostalCodeUsingGet: (
      query: {
        /** postalCode */
        postalCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, void>({
        path: `/axfood/rest/slot/currentSlotAvailableForPostalCode`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slot
     * @name GetHomeDeliverySlotsUsingGet
     * @summary Get home delivery slots
     * @request GET:/axfood/rest/slot/homeDelivery
     * @secure
     */
    getHomeDeliverySlotsUsingGet: (
      query?: {
        /** postalCode */
        postalCode?: string;
        /** b2b */
        b2b?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodSlotsScheduleViewModel, void>({
        path: `/axfood/rest/slot/homeDelivery`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slot
     * @name GetHomeDeliverySlotsForDayUsingGet
     * @summary Get home delivery slots for day
     * @request GET:/axfood/rest/slot/homeDelivery/daysAvailability
     * @secure
     */
    getHomeDeliverySlotsForDayUsingGet: (
      query: {
        /** postalCode */
        postalCode?: string;
        /** b2b */
        b2b?: boolean;
        /**
         * fromDate
         * @format date
         */
        fromDate: string;
        /**
         * nrOfDays
         * @format int32
         */
        nrOfDays: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodSlotsScheduleViewModel, void>({
        path: `/axfood/rest/slot/homeDelivery/daysAvailability`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slot
     * @name GetPickInStoreSlotsUsingGet
     * @summary Get pick in store slots
     * @request GET:/axfood/rest/slot/pickInStore
     * @secure
     */
    getPickInStoreSlotsUsingGet: (
      query?: {
        /** storeId */
        storeId?: string;
        /** b2b */
        b2b?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodSlotsScheduleViewModel, void>({
        path: `/axfood/rest/slot/pickInStore`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slot
     * @name GetPickInStoreSlotsForDayUsingGet
     * @summary Get pick in store slots for day
     * @request GET:/axfood/rest/slot/pickInStore/daysAvailability
     * @secure
     */
    getPickInStoreSlotsForDayUsingGet: (
      query: {
        /** storeId */
        storeId?: string;
        /** b2b */
        b2b?: boolean;
        /**
         * fromDate
         * @format date
         */
        fromDate: string;
        /**
         * nrOfDays
         * @format int32
         */
        nrOfDays: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodSlotsScheduleViewModel, void>({
        path: `/axfood/rest/slot/pickInStore/daysAvailability`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slot
     * @name RemoveSlotFromCartUsingDelete
     * @summary Remove slot from cart
     * @request DELETE:/axfood/rest/slot/slotInCart
     * @secure
     */
    removeSlotFromCartUsingDelete: (params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/slot/slotInCart`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slot
     * @name GetSlotSetToCartUsingGet
     * @summary Get slot in cart
     * @request GET:/axfood/rest/slot/slotInCart
     * @secure
     */
    getSlotSetToCartUsingGet: (params: RequestParams = {}) =>
      this.request<TimeSlotData, void>({
        path: `/axfood/rest/slot/slotInCart`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slot
     * @name SetSlotToCartUsingPost
     * @summary Set slot to cart
     * @request POST:/axfood/rest/slot/slotInCart/{slotCode}
     * @secure
     */
    setSlotToCartUsingPost: (
      slotCode: string,
      tmsDeliveryWindowJson: TmsDeliveryWindowJson,
      query?: {
        /** isTmsSlot */
        isTmsSlot?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/slot/slotInCart/${slotCode}`,
        method: 'POST',
        query: query,
        body: tmsDeliveryWindowJson,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetSmartSuggestionComponentsUsingGet
     * @summary Get smart suggestion promotions
     * @request GET:/axfood/rest/smart-suggestions/{storeId}/promotion-component/{promotionComponentUID}
     * @secure
     */
    getSmartSuggestionComponentsUsingGet: (
      storeId: string,
      promotionComponentUid: string,
      query?: {
        /**
         * offline
         * @default false
         */
        offline?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodPromotionSuggestionsViewModel, void>({
        path: `/axfood/rest/smart-suggestions/${storeId}/promotion-component/${promotionComponentUid}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetStoresUsingGet
     * @summary Get available stores
     * @request GET:/axfood/rest/store
     * @secure
     */
    getStoresUsingGet: (
      query?: {
        /**
         * online
         * @default false
         */
        online?: boolean;
        /** clickAndCollect */
        clickAndCollect?: boolean;
        /** b2bClickAndCollect */
        b2bClickAndCollect?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodStoreInfoViewModel[], void>({
        path: `/axfood/rest/store`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetStoreInformationUsingDelete1
     * @summary Get store information
     * @request DELETE:/axfood/rest/store/{storeId}
     * @secure
     */
    getStoreInformationUsingDelete1: (storeId: string, params: RequestParams = {}) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/store/${storeId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetStoreInformationUsingGet1
     * @summary Get store information
     * @request GET:/axfood/rest/store/{storeId}
     * @secure
     */
    getStoreInformationUsingGet1: (storeId: string, params: RequestParams = {}) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/store/${storeId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetStoreInformationUsingHead1
     * @summary Get store information
     * @request HEAD:/axfood/rest/store/{storeId}
     * @secure
     */
    getStoreInformationUsingHead1: (storeId: string, params: RequestParams = {}) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/store/${storeId}`,
        method: 'HEAD',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetStoreInformationUsingOptions1
     * @summary Get store information
     * @request OPTIONS:/axfood/rest/store/{storeId}
     * @secure
     */
    getStoreInformationUsingOptions1: (storeId: string, params: RequestParams = {}) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/store/${storeId}`,
        method: 'OPTIONS',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetStoreInformationUsingPatch1
     * @summary Get store information
     * @request PATCH:/axfood/rest/store/{storeId}
     * @secure
     */
    getStoreInformationUsingPatch1: (storeId: string, params: RequestParams = {}) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/store/${storeId}`,
        method: 'PATCH',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetStoreInformationUsingPost1
     * @summary Get store information
     * @request POST:/axfood/rest/store/{storeId}
     * @secure
     */
    getStoreInformationUsingPost1: (storeId: string, params: RequestParams = {}) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/store/${storeId}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetStoreInformationUsingPut1
     * @summary Get store information
     * @request PUT:/axfood/rest/store/{storeId}
     * @secure
     */
    getStoreInformationUsingPut1: (storeId: string, params: RequestParams = {}) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/store/${storeId}`,
        method: 'PUT',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CheckIfPointIsCloseToStoreUsingGet
     * @summary Is the given geopoint close to the given store
     * @request GET:/axfood/rest/store/{storeId}/pointClose
     * @secure
     */
    checkIfPointIsCloseToStoreUsingGet: (
      storeId: string,
      query: {
        /**
         * latitude
         * @format double
         */
        latitude: number;
        /**
         * longitude
         * @format double
         */
        longitude: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodBasicStatusResponseViewModel, void>({
        path: `/axfood/rest/store/${storeId}/pointClose`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name ActivateStoreInSessionUsingPost
     * @summary Set active store in session
     * @request POST:/axfood/rest/store/activate
     * @secure
     */
    activateStoreInSessionUsingPost: (
      query: {
        /** storeId */
        storeId: string;
        /** activelySelected */
        activelySelected: boolean;
        /**
         * forceAsPickingStore
         * @default false
         */
        forceAsPickingStore?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/store/activate`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetCurrentBaseStoreUsingGet
     * @summary Get current base store
     * @request GET:/axfood/rest/store/active
     * @secure
     */
    getCurrentBaseStoreUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/store/active`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetClickAndCollectStoresUsingGet
     * @summary Get click and collect stores
     * @request GET:/axfood/rest/store/clickcollect
     * @secure
     */
    getClickAndCollectStoresUsingGet: (
      query?: {
        /**
         * online
         * @default false
         */
        online?: boolean;
        /**
         * isB2B
         * @default false
         */
        isB2B?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodStoreInfoViewModel[], void>({
        path: `/axfood/rest/store/clickcollect`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CheckIfStoreIsNotPermanentlyClosedUsingGet
     * @summary Is store permanently closed
     * @request GET:/axfood/rest/store/isPermanentlyClosed
     * @secure
     */
    checkIfStoreIsNotPermanentlyClosedUsingGet: (
      query: {
        /** storeId */
        storeId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, void>({
        path: `/axfood/rest/store/isPermanentlyClosed`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetSuggestedPickUpStoreUsingGet
     * @summary Get customer suggested pickup store
     * @request GET:/axfood/rest/store/suggested-pickup-store
     * @secure
     */
    getSuggestedPickUpStoreUsingGet: (
      query?: {
        /**
         * defaultToBaseStore
         * @default true
         */
        defaultToBaseStore?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/store/suggested-pickup-store`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name GetCurrentStoreDetailsUsingGet
     * @summary Get current store details
     * @request GET:/axfood/rest/storeDetails
     * @secure
     */
    getCurrentStoreDetailsUsingGet: (params: RequestParams = {}) =>
      this.request<AxfoodStoreInfoViewModel, void>({
        path: `/axfood/rest/storeDetails`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Postal
     * @name GetAddressSuggestionsUsingGet
     * @summary Get address suggestions for addressline
     * @request GET:/axfood/rest/suggest/addressline
     * @secure
     */
    getAddressSuggestionsUsingGet: (
      query: {
        /** addressline */
        addressline: string;
        /**
         * maxRows
         * @default "5"
         */
        maxRows?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuggestAddressResponse, void>({
        path: `/axfood/rest/suggest/addressline`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Postal
     * @name GetLocalitySuggestionsUsingGet
     * @summary Get locality suggestions
     * @request GET:/axfood/rest/suggest/locality
     * @secure
     */
    getLocalitySuggestionsUsingGet: (
      query: {
        /** query */
        query: string;
        /**
         * maxRows
         * @default "5"
         */
        maxRows?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/axfood/rest/suggest/locality`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Postal
     * @name GetPostalCodeSuggestionsUsingGet
     * @summary Get postal code suggestions
     * @request GET:/axfood/rest/suggest/postalcode
     * @secure
     */
    getPostalCodeSuggestionsUsingGet: (
      query: {
        /** code */
        code: string;
        /**
         * maxRows
         * @default "5"
         */
        maxRows?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/axfood/rest/suggest/postalcode`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Postal
     * @name GetAddressSuggestionsUsingGet1
     * @summary Get address suggestions
     * @request GET:/axfood/rest/suggest/street
     * @secure
     */
    getAddressSuggestionsUsingGet1: (
      query: {
        /** query */
        query: string;
        /** postalCode */
        postalCode?: string;
        /**
         * maxRows
         * @default "5"
         */
        maxRows?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SuggestAddressResponse, void>({
        path: `/axfood/rest/suggest/street`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name GetAppVersionUsingGet
     * @summary Get supported app version with static value from nginx
     * @request GET:/axfood/rest/supported-app-version
     * @secure
     */
    getAppVersionUsingGet: (
      query: {
        /** deviceType */
        deviceType: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AppVersionData, void>({
        path: `/axfood/rest/supported-app-version`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name GetTranslationsUsingGet
     * @summary Get translations
     * @request GET:/axfood/rest/translation
     * @secure
     */
    getTranslationsUsingGet: (
      query: {
        /** site */
        site: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/axfood/rest/translation`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name GetReactTranslationsUsingGet
     * @summary Get react translations
     * @request GET:/axfood/rest/translation/react
     * @secure
     */
    getReactTranslationsUsingGet: (
      query: {
        /** site */
        site: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ReactTranslationData[], void>({
        path: `/axfood/rest/translation/react`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name GetReactTranslationsForNamespaceUsingGet
     * @summary Get react translations for namespace
     * @request GET:/axfood/rest/translation/react/{namespace}
     * @secure
     */
    getReactTranslationsForNamespaceUsingGet: (
      namespace: string,
      query: {
        /** site */
        site: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ReactTranslationData, void>({
        path: `/axfood/rest/translation/react/${namespace}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name GetUserWishListsUsingGet
     * @summary Get user wish lists
     * @request GET:/axfood/rest/user-wishlist
     * @secure
     */
    getUserWishListsUsingGet: (
      query?: {
        /**
         * basic
         * @default false
         */
        basic?: boolean;
        /**
         * sorting
         * @default "LAST_UPDATED_DESC"
         */
        sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC';
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodBasicWishListViewModel[], void>({
        path: `/axfood/rest/user-wishlist`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name CreateWishlistUsingPost
     * @summary Create user wish list
     * @request POST:/axfood/rest/user-wishlist
     * @secure
     */
    createWishlistUsingPost: (wishlistForm: WishlistForm, params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel, void>({
        path: `/axfood/rest/user-wishlist`,
        method: 'POST',
        body: wishlistForm,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name DeleteUserWishListUsingDelete
     * @summary Delete user wish list
     * @request DELETE:/axfood/rest/user-wishlist/{id}
     * @secure
     */
    deleteUserWishListUsingDelete: (id: string, params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel[], void>({
        path: `/axfood/rest/user-wishlist/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name GetUserWishlistUsingGet
     * @summary Get user wish list
     * @request GET:/axfood/rest/user-wishlist/{id}
     * @secure
     */
    getUserWishlistUsingGet: (id: string, params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel, void>({
        path: `/axfood/rest/user-wishlist/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name UpdateWishlistUsingPost
     * @summary Check entries availability
     * @request POST:/axfood/rest/user-wishlist/{id}
     * @secure
     */
    updateWishlistUsingPost: (id: string, wishlistForm: WishlistForm, params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel, void>({
        path: `/axfood/rest/user-wishlist/${id}`,
        method: 'POST',
        body: wishlistForm,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name AddProductsToUserWishlistUsingPost
     * @summary Add products to user wish list
     * @request POST:/axfood/rest/user-wishlist/{id}/add
     * @secure
     */
    addProductsToUserWishlistUsingPost: (id: string, wishlistForm: WishlistForm, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/axfood/rest/user-wishlist/${id}/add`,
        method: 'POST',
        body: wishlistForm,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name CheckEntriesInUserWishlistUsingPut
     * @summary Check entries in user wish list
     * @request PUT:/axfood/rest/user-wishlist/{id}/check
     * @secure
     */
    checkEntriesInUserWishlistUsingPut: (id: string, wishlistForm: WishlistForm, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/axfood/rest/user-wishlist/${id}/check`,
        method: 'PUT',
        body: wishlistForm,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name UpdateWishlistSortingUsingPut
     * @summary Update wish list sorting
     * @request PUT:/axfood/rest/user-wishlist/{id}/sorting
     * @secure
     */
    updateWishlistSortingUsingPut: (id: string, wishlistForm: WishlistForm, params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel, void>({
        path: `/axfood/rest/user-wishlist/${id}/sorting`,
        method: 'PUT',
        body: wishlistForm,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name RemoveEntryFromUserWishlistUsingDelete
     * @summary Remove entry from user wish list
     * @request DELETE:/axfood/rest/user-wishlist/{listId}/product/{productCode}
     * @secure
     */
    removeEntryFromUserWishlistUsingDelete: (listId: string, productCode: string, params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel, void>({
        path: `/axfood/rest/user-wishlist/${listId}/product/${productCode}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name RemoveEntriesFromUserWishlistUsingDelete
     * @summary Remove entries from user wish list
     * @request DELETE:/axfood/rest/user-wishlist/{listId}/products
     * @secure
     */
    removeEntriesFromUserWishlistUsingDelete: (listId: string, identifiers: string[], params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel, void>({
        path: `/axfood/rest/user-wishlist/${listId}/products`,
        method: 'DELETE',
        body: identifiers,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name DeleteUserWishListsUsingDelete
     * @summary Delete multiple user wishlists
     * @request DELETE:/axfood/rest/user-wishlist/remove-many
     * @secure
     */
    deleteUserWishListsUsingDelete: (wishlistIds: string[], params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel[], void>({
        path: `/axfood/rest/user-wishlist/remove-many`,
        method: 'DELETE',
        body: wishlistIds,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name GetUserWishlistFilterNonSalableUsingGet
     * @summary Get user wish list filter non salable
     * @request GET:/axfood/rest/user-wishlist-filter-non-salable/{id}
     * @secure
     */
    getUserWishlistFilterNonSalableUsingGet: (id: string, params: RequestParams = {}) =>
      this.request<AxfoodWishListViewModel, void>({
        path: `/axfood/rest/user-wishlist-filter-non-salable/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Postal
     * @name GetValidatedAddressUsingGet
     * @summary Validate address
     * @request GET:/axfood/rest/validate
     * @secure
     */
    getValidatedAddressUsingGet: (
      query?: {
        /** street */
        street?: string;
        /** postalcode */
        postalcode?: string;
        /** locality */
        locality?: string;
        /**
         * suggestions
         * @default "1"
         */
        suggestions?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodAddressValidationResultViewModel, void>({
        path: `/axfood/rest/validate`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Postal
     * @name GetValidatedAddressLineUsingGet
     * @summary Validate address line
     * @request GET:/axfood/rest/validate/addressline
     * @secure
     */
    getValidatedAddressLineUsingGet: (
      query: {
        /** line */
        line: string;
        /**
         * suggestions
         * @default "1"
         */
        suggestions?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodAddressValidationResultViewModel, void>({
        path: `/axfood/rest/validate/addressline`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name AddProductsToCartUsingPost
     * @summary Add product to cart from wish list
     * @request POST:/axfood/rest/wishlist/{id}/cart
     * @secure
     */
    addProductsToCartUsingPost: (id: string, wishlistForm: WishlistForm, params: RequestParams = {}) =>
      this.request<AxfoodCartViewModel, void>({
        path: `/axfood/rest/wishlist/${id}/cart`,
        method: 'POST',
        body: wishlistForm,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name CheckEntriesAvailabilityUsingGet
     * @summary Check entries availability
     * @request GET:/axfood/rest/wishlist/changes/{id}
     * @secure
     */
    checkEntriesAvailabilityUsingGet: (id: string, params: RequestParams = {}) =>
      this.request<AxfoodCartEntryStatusViewModel[], void>({
        path: `/axfood/rest/wishlist/changes/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name ValidateOrderEntriesUsingGet1
     * @summary Validate list entries, with replacement for conflicts
     * @request GET:/axfood/rest/wishlist/validate/{listId}
     * @secure
     */
    validateOrderEntriesUsingGet1: (listId: string, params: RequestParams = {}) =>
      this.request<AxfoodCartEntryStatusViewModel[], void>({
        path: `/axfood/rest/wishlist/validate/${listId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name ValidateWishlistEntriesUsingPost
     * @summary Validate list entries, with replacement for conflicts
     * @request POST:/axfood/rest/wishlist/validate-entries
     * @secure
     */
    validateWishlistEntriesUsingPost: (wishlistForm: WishlistForm, params: RequestParams = {}) =>
      this.request<AxfoodCartEntryStatusViewModel[], void>({
        path: `/axfood/rest/wishlist/validate-entries`,
        method: 'POST',
        body: wishlistForm,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wish list
     * @name AddSiteWishlistToUserWishlistUsingPost
     * @summary Add site wish list to user wish list
     * @request POST:/axfood/rest/wishlist-to-wishlist
     * @secure
     */
    addSiteWishlistToUserWishlistUsingPost: (
      query: {
        /** siteWishlistId */
        siteWishlistId: string;
        /** userWishlistId */
        userWishlistId: string;
        /** createNew */
        createNew?: boolean;
        /** name */
        name?: string;
        /** description */
        description?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/axfood/rest/wishlist-to-wishlist`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  buyAllProductsComponent = {
    /**
     * No description
     *
     * @tags Component
     * @name GetProductsForBuyAllBannerUsingGet
     * @summary Get products for a buy all component
     * @request GET:/buyAllProductsComponent/{componentId}
     * @secure
     */
    getProductsForBuyAllBannerUsingGet: (componentId: string, params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/buyAllProductsComponent/${componentId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  customers = {
    /**
     * No description
     *
     * @tags Customer
     * @name RegisterCustomerUsingPost
     * @summary Register customer
     * @request POST:/customers
     * @secure
     */
    registerCustomerUsingPost: (
      form: RegisterCustomerForm,
      query?: {
        /**
         * autoLogin
         * @default true
         */
        autoLogin?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/customers`,
        method: 'POST',
        query: query,
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name RegisterAdditionalUsingPost
     * @summary Register additional subaccount
     * @request POST:/customers/subaccount
     * @secure
     */
    registerAdditionalUsingPost: (
      data: {
        phone?: string;
        email: string;
        socialSecurityNumber: string;
      },
      query?: {
        socialSecurityNumberKey?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/customers/subaccount`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name RegisterWillysPlusAccountUsingPost
     * @summary Register willys plus account
     * @request POST:/customers/willysplus
     * @secure
     */
    registerWillysPlusAccountUsingPost: (
      query?: {
        email?: string;
        socialSecurityNumber?: string;
        socialSecurityNumberKey?: string;
        phone?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/customers/willysplus`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  genericPageBannerListComponent = {
    /**
     * No description
     *
     * @tags Component
     * @name GetPagesForPagesBannerUsingGet1
     * @summary Get pages for page banner
     * @request GET:/genericPageBannerListComponent/{componentId}
     * @secure
     */
    getPagesForPagesBannerUsingGet1: (
      componentId: string,
      query?: {
        /**
         * page
         * @default "0"
         */
        page?: string;
        /**
         * size
         * @default "30"
         */
        size?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/genericPageBannerListComponent/${componentId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  leftMenu = {
    /**
     * No description
     *
     * @tags Component
     * @name GetCategoryNavigationNodeDataUsingGet
     * @summary Get category navigation node data
     * @request GET:/leftMenu/categories
     * @secure
     */
    getCategoryNavigationNodeDataUsingGet: (componentId: string, params: RequestParams = {}) =>
      this.request<CategoryNavigationNodeData[], void>({
        path: `/leftMenu/categories`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name GetCategoryNavigationNodeDataUsingGet1
     * @summary Get category navigation node data
     * @request GET:/leftMenu/categories/{componentId}
     * @secure
     */
    getCategoryNavigationNodeDataUsingGet1: (componentId: string, params: RequestParams = {}) =>
      this.request<CategoryNavigationNodeData[], void>({
        path: `/leftMenu/categories/${componentId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name GetCategoryNavigationTreeUsingGet
     * @summary Get category navigation tree
     * @request GET:/leftMenu/categorytree
     * @secure
     */
    getCategoryNavigationTreeUsingGet: (
      query?: {
        /** storeId */
        storeId?: string;
        /** deviceType */
        deviceType?: 'ANDROID' | 'IOS' | 'OTHER';
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/leftMenu/categorytree`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name GetLinksNavigationNodeDataUsingGet
     * @summary Get links navigation node data
     * @request GET:/leftMenu/links
     * @secure
     */
    getLinksNavigationNodeDataUsingGet: (componentId: string, params: RequestParams = {}) =>
      this.request<AxfoodNavigationNodeViewModel, void>({
        path: `/leftMenu/links`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name GetLinksNavigationNodeDataUsingGet1
     * @summary Get links navigation node data
     * @request GET:/leftMenu/links/{componentId}
     * @secure
     */
    getLinksNavigationNodeDataUsingGet1: (componentId: string, params: RequestParams = {}) =>
      this.request<AxfoodNavigationNodeViewModel, void>({
        path: `/leftMenu/links/${componentId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  login = {
    /**
     * No description
     *
     * @tags Password Reset
     * @name ChangePasswordUsingPost
     * @summary Change
     * @request POST:/login/pw/change
     * @secure
     */
    changePasswordUsingPost: (
      query?: {
        token?: string;
        pwd?: string;
        checkPwd?: string;
        rememberMe?: boolean;
        pwdKey?: string;
        checkPwdKey?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/login/pw/change`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Password Reset
     * @name CheckExistingB2BCustomerUsingGet
     * @summary Check B2B Customer
     * @request GET:/login/pw/checkb2bcustomer
     * @secure
     */
    checkExistingB2BCustomerUsingGet: (
      query: {
        /** organisationNumber */
        organisationNumber: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCheckB2BDTO, void>({
        path: `/login/pw/checkb2bcustomer`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Password Reset
     * @name CheckExistingB2BCustomerBySapIdUsingGet
     * @summary Check B2B Customer By Sapid
     * @request GET:/login/pw/checkb2bcustomerbysapid
     * @secure
     */
    checkExistingB2BCustomerBySapIdUsingGet: (
      query: {
        /** sapid */
        sapid: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCheckB2BByIdDTO, void>({
        path: `/login/pw/checkb2bcustomerbysapid`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Password Reset
     * @name CheckExistingB2BCustomerBySapIdOrAxboIdUsingGet
     * @summary Check B2B Customer By Sapid Or AxboId
     * @request GET:/login/pw/checkb2bcustomerbysapidOrAxboId
     * @secure
     */
    checkExistingB2BCustomerBySapIdOrAxboIdUsingGet: (
      query: {
        /** username */
        username: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCheckB2BByIdDTO, void>({
        path: `/login/pw/checkb2bcustomerbysapidOrAxboId`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Password Reset
     * @name CheckExistingCustomerUsingGet
     * @summary Check Customer
     * @request GET:/login/pw/checkcustomer
     * @deprecated
     * @secure
     */
    checkExistingCustomerUsingGet: (
      query: {
        /** username */
        username: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodCheckCustomerDTO, void>({
        path: `/login/pw/checkcustomer`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Password Reset
     * @name CheckCustomerExistsUsingPost
     * @summary Check Existing Customer
     * @request POST:/login/pw/checkExistingCustomer
     * @secure
     */
    checkCustomerExistsUsingPost: (form: CheckCustomerForm, params: RequestParams = {}) =>
      this.request<AxfoodCheckCustomerDTO, void>({
        path: `/login/pw/checkExistingCustomer`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Password Reset
     * @name VerifyNewCustomerUsingPost
     * @summary Recover account
     * @request POST:/login/pw/recoveraccount
     * @deprecated
     * @secure
     */
    verifyNewCustomerUsingPost: (form: VerifyCustomerForm, params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/login/pw/recoveraccount`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Password Reset
     * @name RecoverB2BCustomerUsingPost
     * @summary Recover B2B Account
     * @request POST:/login/pw/recoverb2baccount
     * @secure
     */
    recoverB2BCustomerUsingPost: (form: VerifyB2BCustomerForm, params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/login/pw/recoverb2baccount`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Password Reset
     * @name ValidateEmailTokenUsingPost
     * @summary Validate generated pwd reset Token
     * @request POST:/login/pw/validatetoken
     * @secure
     */
    validateEmailTokenUsingPost: (
      query?: {
        token?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/login/pw/validatetoken`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Password Reset
     * @name VerifySmsTokenUsingPost
     * @summary Verify SMS Token
     * @request POST:/login/pw/verifysmstoken
     * @secure
     */
    verifySmsTokenUsingPost: (form: EncryptedTokenAssertionForm, params: RequestParams = {}) =>
      this.request<AxfoodBasicStatusResponseViewModel, void>({
        path: `/login/pw/verifysmstoken`,
        method: 'POST',
        body: form,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  pageBannerListComponent = {
    /**
     * No description
     *
     * @tags Component
     * @name GetPagesForPagesBannerUsingGet
     * @summary Get pages for page banner
     * @request GET:/pageBannerListComponent/{componentId}
     * @secure
     */
    getPagesForPagesBannerUsingGet: (
      componentId: string,
      query?: {
        /**
         * page
         * @default "0"
         */
        page?: string;
        /**
         * size
         * @default "30"
         */
        size?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/pageBannerListComponent/${componentId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  productBannerComponent = {
    /**
     * No description
     *
     * @tags Component
     * @name GetProductsForProductBannerUsingGet
     * @summary Get products for product banner
     * @request GET:/productBannerComponent/{componentId}
     * @secure
     */
    getProductsForProductBannerUsingGet: (
      componentId: string,
      query?: {
        /**
         * page
         * @default "0"
         */
        page?: string;
        /**
         * size
         * @default "30"
         */
        size?: string;
        /** promotionStoreUid */
        promotionStoreUid?: string;
        /** q */
        q?: string;
        /** sort */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/productBannerComponent/${componentId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name GetPackagesForProductBannerUsingGet
     * @summary Get packages for product banner
     * @request GET:/productBannerComponent/packages/{componentId}
     * @secure
     */
    getPackagesForProductBannerUsingGet: (componentId: string, params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/productBannerComponent/packages/${componentId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  productCarouselComponent = {
    /**
     * No description
     *
     * @tags Component
     * @name GetProductsForProductBannerUsingGet1
     * @summary Get products for product banner
     * @request GET:/productCarouselComponent/{componentId}
     * @secure
     */
    getProductsForProductBannerUsingGet1: (
      componentId: string,
      query?: {
        /**
         * page
         * @default "0"
         */
        page?: string;
        /**
         * size
         * @default "30"
         */
        size?: string;
        /** promotionStoreUid */
        promotionStoreUid?: string;
        /** q */
        q?: string;
        /** sort */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/productCarouselComponent/${componentId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name GetPackagesForProductBannerUsingGet1
     * @summary Get packages for product banner
     * @request GET:/productCarouselComponent/packages/{componentId}
     * @secure
     */
    getPackagesForProductBannerUsingGet1: (componentId: string, params: RequestParams = {}) =>
      this.request<InputStream, void>({
        path: `/productCarouselComponent/packages/${componentId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  search = {
    /**
     * No description
     *
     * @tags Product
     * @name GetSearchResultsUsingGet1
     * @summary Get search results for products
     * @request GET:/search
     * @secure
     */
    getSearchResultsUsingGet1: (
      query?: {
        /** q */
        q?: string;
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 10
         */
        size?: number;
        /**
         * show
         * @default "Page"
         */
        show?: 'Page' | 'All';
        /** sort */
        sort?: string;
        /**
         * directSearch
         * @default false
         */
        directSearch?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel, void>({
        path: `/search`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetSearchResultsAsYouTypeUsingGet
     * @summary Get search results for products for as you type
     * @request GET:/search/asutype
     * @secure
     */
    getSearchResultsAsYouTypeUsingGet: (
      query: {
        /** q */
        q: string;
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 10
         */
        size?: number;
        /**
         * show
         * @default "Page"
         */
        show?: 'Page' | 'All';
        /** sort */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel, void>({
        path: `/search/asutype`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetAutocompleteSuggestionsUsingGet
     * @summary Get autocomplete suggestions
     * @request GET:/search/autocomplete/{componentUid}
     * @secure
     */
    getAutocompleteSuggestionsUsingGet: (
      componentUid: string,
      query: {
        /** term */
        term: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AutocompleteResultData, void>({
        path: `/search/autocomplete/${componentUid}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetPromotedProductsUsingGet
     * @summary Get promoted products
     * @request GET:/search/campaigns
     * @secure
     */
    getPromotedProductsUsingGet: (
      query?: {
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 8
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel, void>({
        path: `/search/campaigns`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetMixedCampaignsUsingGet
     * @summary Get online and offline campaigns
     * @request GET:/search/campaigns/mix
     * @secure
     */
    getMixedCampaignsUsingGet: (
      query: {
        /** q */
        q: string;
        /** type */
        type?: string;
        /**
         * size
         * @format int32
         * @default 20
         */
        size?: number;
        /**
         * onlineSize
         * @format int32
         * @default 0
         */
        onlineSize?: number;
        /**
         * offlineSize
         * @format int32
         * @default 0
         */
        offlineSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<FacetSearchPageData, void>({
        path: `/search/campaigns/mix`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetOfflineCampaignsUsingGet
     * @summary Get offline campaigns
     * @request GET:/search/campaigns/offline
     * @secure
     */
    getOfflineCampaignsUsingGet: (
      query: {
        /** q */
        q: string;
        /** type */
        type?: string;
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 20
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<FacetSearchPageData, void>({
        path: `/search/campaigns/offline`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetOnlineCampaignsUsingGet
     * @summary Get online campaigns
     * @request GET:/search/campaigns/online
     * @secure
     */
    getOnlineCampaignsUsingGet: (
      query: {
        /** q */
        q: string;
        /** type */
        type?: string;
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 20
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel, void>({
        path: `/search/campaigns/online`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name GetOfflineCampaignsPreviewUsingGet
     * @summary Get promoted previews
     * @request GET:/search/campaigns/preview/offline
     * @secure
     */
    getOfflineCampaignsPreviewUsingGet: (
      query?: {
        /** q */
        q?: string;
        /** fields */
        fields?: string[];
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 20
         */
        size?: number;
        /**
         * dateFrom
         * @format int32
         */
        dateFrom?: number;
        /**
         * dateTo
         * @format int32
         */
        dateTo?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PromotionSearchPageDataOfSearchStateDataAndAxfoodOfflineProductViewModel, void>({
        path: `/search/campaigns/preview/offline`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetSearchCleanResultsUsingGet
     * @summary Get clean search results for products
     * @request GET:/search/clean
     * @secure
     */
    getSearchCleanResultsUsingGet: (
      query: {
        /** q */
        q: string;
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 10
         */
        size?: number;
        /**
         * show
         * @default "Page"
         */
        show?: 'Page' | 'All';
        /** sort */
        sort?: string;
        /**
         * directSearch
         * @default false
         */
        directSearch?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel, void>({
        path: `/search/clean`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetFacetsUsingGet
     * @summary Get facets for product
     * @request GET:/search/facets
     * @secure
     */
    getFacetsUsingGet: (
      query: {
        /** q */
        q: string;
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * show
         * @default "Page"
         */
        show?: 'Page' | 'All';
        /** sort */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<FacetRefinementOfSearchStateData, void>({
        path: `/search/facets`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetMultiSearchResultsAsYouTypeUsingGet
     * @summary Get search results for products and related navigation categories for as you type and autoComplete results
     * @request GET:/search/multisearchAsutype
     * @secure
     */
    getMultiSearchResultsAsYouTypeUsingGet: (
      query: {
        /** q */
        q: string;
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 10
         */
        size?: number;
        /**
         * show
         * @default "Page"
         */
        show?: 'Page' | 'All';
        /** sort */
        sort?: string;
        /** type */
        type?: 'PRODUCTS' | 'RECIPES' | 'STORES';
      },
      params: RequestParams = {}
    ) =>
      this.request<Loop54SearchWrapperData, void>({
        path: `/search/multisearchAsutype`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetMultiSearchResultsUsingGet
     * @summary Get search results for products and related navigation categories for as you type and autoComplete results
     * @request GET:/search/multisearchComplete
     * @secure
     */
    getMultiSearchResultsUsingGet: (
      query: {
        /** q */
        q: string;
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 10
         */
        size?: number;
        /**
         * show
         * @default "Page"
         */
        show?: 'Page' | 'All';
        /** sort */
        sort?: string;
        /** type */
        type?: 'PRODUCTS' | 'RECIPES' | 'STORES';
      },
      params: RequestParams = {}
    ) =>
      this.request<Loop54SearchWrapperData, void>({
        path: `/search/multisearchComplete`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name GetSearchResultsUsingGet
     * @summary Searches for recipes
     * @request GET:/search/recipe
     * @secure
     */
    getSearchResultsUsingGet: (
      query: {
        /** The search query */
        q: string;
        /**
         * Current page index. Used for pagination
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * Size of a single page of results.
         * @format int32
         * @default 20
         */
        size?: number;
        /**
         * Whether to show all the stores or a page of results.
         * @default "Page"
         */
        show?: 'Page' | 'All';
        /** Field to use for sorting the results */
        sort: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<RecipeSearchPageDataOfSearchStateDataAndAxfoodRecipeBasicInfoViewModel, void>({
        path: `/search/recipe`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetRelatedResultsUsingGet
     * @summary Get related search results for products
     * @request GET:/search/related-results
     * @secure
     */
    getRelatedResultsUsingGet: (
      query?: {
        /** q */
        q?: string;
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * size
         * @format int32
         * @default 10
         */
        size?: number;
        /**
         * show
         * @default "Page"
         */
        show?: 'Page' | 'All';
        /** sort */
        sort?: string;
        /**
         * directSearch
         * @default false
         */
        directSearch?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodBasicProductViewModel[], void>({
        path: `/search/related-results`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetResultsUsingGet1
     * @summary Get results for products
     * @request GET:/search/results
     * @secure
     */
    getResultsUsingGet1: (
      query: {
        /** q */
        q: string;
        /**
         * page
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * show
         * @default "Page"
         */
        show?: 'Page' | 'All';
        /** sort */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AxfoodSearchResultViewModel, void>({
        path: `/search/results`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  singlestepcheckout = {
    /**
     * No description
     *
     * @tags Checkout
     * @name DisplayCheckoutPageUsingGet
     * @summary Display checkout page
     * @request GET:/singlestepcheckout
     * @secure
     */
    displayCheckoutPageUsingGet: (params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/singlestepcheckout`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CancelPaymentUsingDelete1
     * @summary Cancel payment
     * @request DELETE:/singlestepcheckout/cancelPayment/{orderCode}
     * @secure
     */
    cancelPaymentUsingDelete1: (orderCode: string, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/singlestepcheckout/cancelPayment/${orderCode}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CancelPaymentUsingGet1
     * @summary Cancel payment
     * @request GET:/singlestepcheckout/cancelPayment/{orderCode}
     * @secure
     */
    cancelPaymentUsingGet1: (orderCode: string, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/singlestepcheckout/cancelPayment/${orderCode}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CancelPaymentUsingHead1
     * @summary Cancel payment
     * @request HEAD:/singlestepcheckout/cancelPayment/{orderCode}
     * @secure
     */
    cancelPaymentUsingHead1: (orderCode: string, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/singlestepcheckout/cancelPayment/${orderCode}`,
        method: 'HEAD',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CancelPaymentUsingOptions1
     * @summary Cancel payment
     * @request OPTIONS:/singlestepcheckout/cancelPayment/{orderCode}
     * @secure
     */
    cancelPaymentUsingOptions1: (orderCode: string, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/singlestepcheckout/cancelPayment/${orderCode}`,
        method: 'OPTIONS',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CancelPaymentUsingPatch1
     * @summary Cancel payment
     * @request PATCH:/singlestepcheckout/cancelPayment/{orderCode}
     * @secure
     */
    cancelPaymentUsingPatch1: (orderCode: string, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/singlestepcheckout/cancelPayment/${orderCode}`,
        method: 'PATCH',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CancelPaymentUsingPost1
     * @summary Cancel payment
     * @request POST:/singlestepcheckout/cancelPayment/{orderCode}
     * @secure
     */
    cancelPaymentUsingPost1: (orderCode: string, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/singlestepcheckout/cancelPayment/${orderCode}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CancelPaymentUsingPut1
     * @summary Cancel payment
     * @request PUT:/singlestepcheckout/cancelPayment/{orderCode}
     * @secure
     */
    cancelPaymentUsingPut1: (orderCode: string, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/singlestepcheckout/cancelPayment/${orderCode}`,
        method: 'PUT',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CompletePaymentUsingDelete1
     * @summary Complete payment for order
     * @request DELETE:/singlestepcheckout/completePayment/{orderCode}
     * @secure
     */
    completePaymentUsingDelete1: (
      orderCode: string,
      query?: {
        /** orderRef */
        orderRef?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/singlestepcheckout/completePayment/${orderCode}`,
        method: 'DELETE',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CompletePaymentUsingGet1
     * @summary Complete payment for order
     * @request GET:/singlestepcheckout/completePayment/{orderCode}
     * @secure
     */
    completePaymentUsingGet1: (
      orderCode: string,
      query?: {
        /** orderRef */
        orderRef?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/singlestepcheckout/completePayment/${orderCode}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CompletePaymentUsingHead1
     * @summary Complete payment for order
     * @request HEAD:/singlestepcheckout/completePayment/{orderCode}
     * @secure
     */
    completePaymentUsingHead1: (
      orderCode: string,
      query?: {
        /** orderRef */
        orderRef?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/singlestepcheckout/completePayment/${orderCode}`,
        method: 'HEAD',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CompletePaymentUsingOptions1
     * @summary Complete payment for order
     * @request OPTIONS:/singlestepcheckout/completePayment/{orderCode}
     * @secure
     */
    completePaymentUsingOptions1: (
      orderCode: string,
      query?: {
        /** orderRef */
        orderRef?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/singlestepcheckout/completePayment/${orderCode}`,
        method: 'OPTIONS',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CompletePaymentUsingPatch1
     * @summary Complete payment for order
     * @request PATCH:/singlestepcheckout/completePayment/{orderCode}
     * @secure
     */
    completePaymentUsingPatch1: (
      orderCode: string,
      query?: {
        /** orderRef */
        orderRef?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/singlestepcheckout/completePayment/${orderCode}`,
        method: 'PATCH',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CompletePaymentUsingPost1
     * @summary Complete payment for order
     * @request POST:/singlestepcheckout/completePayment/{orderCode}
     * @secure
     */
    completePaymentUsingPost1: (
      orderCode: string,
      query?: {
        /** orderRef */
        orderRef?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/singlestepcheckout/completePayment/${orderCode}`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name CompletePaymentUsingPut1
     * @summary Complete payment for order
     * @request PUT:/singlestepcheckout/completePayment/{orderCode}
     * @secure
     */
    completePaymentUsingPut1: (
      orderCode: string,
      query?: {
        /** orderRef */
        orderRef?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/singlestepcheckout/completePayment/${orderCode}`,
        method: 'PUT',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name OrderConfirmationUsingGet
     * @summary Get order confirmation
     * @request GET:/singlestepcheckout/orderconfirmation/{orderCode}
     * @secure
     */
    orderConfirmationUsingGet: (orderCode: string, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/singlestepcheckout/orderconfirmation/${orderCode}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Checkout
     * @name SimplifiedPlaceOrderUsingPost
     * @summary Place order
     * @request POST:/singlestepcheckout/placeOrder
     * @secure
     */
    simplifiedPlaceOrderUsingPost: (
      data: {
        saveCard?: boolean;
        selectedCard?: string;
        orderRef?: string;
        klarnaAuthorizationToken?: string;
        userClickedContinue?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/singlestepcheckout/placeOrder`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        ...params,
      }),
  };
  twoTilesButtonAndProductBannerComponent = {
    /**
     * No description
     *
     * @tags Component
     * @name GetProductsForProductBannerUsingGet2
     * @summary Get products for product banner
     * @request GET:/twoTilesButtonAndProductBannerComponent/{componentId}
     * @secure
     */
    getProductsForProductBannerUsingGet2: (
      componentId: string,
      query?: {
        /**
         * page
         * @default "0"
         */
        page?: string;
        /**
         * size
         * @default "30"
         */
        size?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputStream, void>({
        path: `/twoTilesButtonAndProductBannerComponent/${componentId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
