import { Api, OrderDataV2 } from '@api/storeFrontApi';

const api = new Api({
  baseUrl: '',
});

export const cancelOrder = (orderID: string) => {
  return api.axfood.cancelOrderUsingPost(orderID);
};

export interface OrderDataV2WithOrderNumber extends OrderDataV2 {
  orderNumber: string;
}
export const getOrder = async (orderId: string) => {
  const response = await api.axfood.getFullOrderDataUsingGet({ q: orderId });
  return response.data as OrderDataV2WithOrderNumber;
};

export const getOrderStatus = async (orderID: string) => {
  const response = await api.axfood.validateOrderEntriesUsingGet(orderID);
  return response.data;
};

export const getComplaintOrders = async () => {
  const response = await api.axfood.getOrdersUsingGet();
  return response.data;
};

export const startChangingOrder = (orderID?: string) =>
  api.axfood.continueShopForOrderUsingPost({ orderNumber: orderID! });

export const getOrderProcess = (orderID: string) => api.axfood.isOrderProcessFinishedUsingGet(orderID);
