import { Api } from '@api/storeFrontApi';

const api = new Api({
  baseUrl: '',
});

export const getOnlineAndOfflineVouchers = async () => {
  const response = await api.axfood.getAllExternalVouchersOnlineAndOfflineUsingGet();
  return response.data;
};

export const addVoucher = async (voucherCode: string) => {
  const response = await api.axfood.applyVoucherToCartUsingPost({ voucherCode });
  return response.data;
};

export const removeVoucher = async (voucherCode: string) => {
  const response = await api.axfood.releaseVoucherFromCartUsingDelete(voucherCode);
  return response.data;
};
