import { CancelToken } from 'axios';
import { ProductApi } from '@api/generated/storefront';
import { Api } from '@api/storeFrontApi';

const productApi = new ProductApi(undefined, '');

const api = new Api({
  baseUrl: '',
});
export const getSearchResultsAsYouType = async (query: string, page: number, size: number) =>
  api.search.getSearchResultsAsYouTypeUsingGet(
    {
      q: query,
      page,
      size,
    },
    { format: 'json' }
  );

export const getSearchResults = async (
  query: string,
  page: number,
  size: number,
  show?: 'Page' | 'All',
  sort?: string
) => {
  const response = await productApi.getSearchResultsUsingGET1(query, page, size, show, sort);
  return response.data;
};

export const getSuggestedSearchTerms = (query: string) =>
  api.search.getAutocompleteSuggestionsUsingGet('SearchBox', { term: query });

export const getSearchCleanResults = (q: string, page: number, size: number) =>
  productApi.getSearchCleanResultsUsingGET(q, page, size);

export const getProduct = async (productCode: string, cancelToken?: CancelToken) => {
  const response = await productApi.getProductUsingGET1(productCode, {
    cancelToken,
  });
  return response.data;
};

export const getPromotionProduct = async (storeId: string, promotionCode: string, cancelToken?: CancelToken) => {
  const response = await productApi.getProductUsingGET(storeId, promotionCode, { cancelToken });
  return response.data;
};

interface GetMarketingProductsParams {
  productCode: string;
  variant: string;
  page: number;
  pageSize: number;
}

export const getMarketingProducts = (
  { productCode, variant: displayType, page, pageSize }: GetMarketingProductsParams,
  signal: AbortSignal
) => api.axfood.getProductRecommendationUsingGet(productCode, displayType, { page, pageSize }, { signal });

interface GetPromotionProductsParams {
  promotion: string;
  excludeProducts: string;
  page: number;
  pageSize: number;
}
export const getPromotionProducts = ({ promotion: promotionCode }: GetPromotionProductsParams, signal: AbortSignal) =>
  api.axfood.getPromotionProductsUsingGet(promotionCode, {}, { signal });
