import { rem } from 'polished';
import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import Icon from '@atoms/Icon/Icon';
import { AccordionButtonProps } from './AccordionButton';

type ButtonProps = Pick<AccordionButtonProps, 'active' | 'isCartPage'>;

export const StyledAccordion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const StyledAccordionContent = styled.div`
  padding: ${rem(20)};
`;

export const StyledAccordionButton = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${rem(64)};
  background: ${variables.colors.white};
  border-radius: 0;
  border-width: 0;
  padding: 0;

  ${(props) =>
    !props.isCartPage &&
    css`
      border-top: solid ${variables.colors.lightGray};
    `}

  ${(props) =>
    props.active &&
    !props.isCartPage &&
    css`
      background: ${variables.colors.lightGray};
    `}
  
    ${(props) =>
    props.isCartPage &&
    css`
      margin-bottom: ${rem(-20)};
      justify-content: flex-start;
    `}
`;

export const StyledAccordionButtonIcon = styled(Icon)<ButtonProps>`
  transition: 0.25s ease;
  transform: rotate(360deg);
  margin-left: ${rem(8)};

  ${(props) =>
    props.active &&
    css`
      transform: rotate(180deg);
    `}
`;
