import getTwoTileProductList from '@api/cms/getTwoTileProductList';
import logger from '@logger';
import axios from 'axios';
import { DependencyList, useEffect, useState } from 'react';
import useAsyncError from '@hooks/useAsyncError';
import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';

const useTwoTilesProductFetcher = (show: boolean, uid: string, deps?: DependencyList | undefined) => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [products, setProducts] = useState<Array<AxfoodProductDetailsViewModel>>([]);
  const throwError = useAsyncError();

  // Get products from api
  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      setShowSpinner(true);
      try {
        const response = await getTwoTileProductList(uid, source.token);
        if (response.status === 200) {
          const _products = response.data?.products;
          setProducts(_products.length ? _products : []);
          setShowSpinner(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          logger.error(error);
          throwError(error);
        }
      }
    };

    if (show) {
      fetchData();
    }

    return () => {
      source.cancel(`Cancel TwoTileProductCarousel with uid: ${uid}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { showSpinner, products };
};

export default useTwoTilesProductFetcher;
