/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
export interface Address {
  /** Cellphone number */
  cellphone?: string;
  /** Company Name */
  companyName?: string;
  /** Country where address is located */
  country?: Country;
  /**
   * Boolean flag if address is default
   * @example false
   */
  defaultAddress?: boolean;
  /** District name */
  district?: string;
  /** Email address */
  email?: string;
  /** First name of the address person */
  firstName: string;
  /** Boolean flag if address is formatted */
  formattedAddress?: string;
  /** Unique id value of the address which is optional while creating new address. While performing other address operations this value is the key */
  id?: string;
  /** Last name of the address person */
  lastName: string;
  /** First line of the address */
  line1: string;
  /** Second line of the address */
  line2?: string;
  /** Phone number */
  phone?: string;
  /** Postal code of the address */
  postalCode: string;
  /** Region where address belongs to */
  region?: Region;
  /**
   * Boolean flag if address is for shipping
   * @example false
   */
  shippingAddress?: boolean;
  /** Title of the address person */
  title?: string;
  /** Code of the title */
  titleCode: string;
  /** Town, field required */
  town: string;
  /**
   * Boolean flag if address is visible in the Address Book
   * @example false
   */
  visibleInAddressBook?: boolean;
}

/** Representation of an Address list */
export interface AddressList {
  /** List of addresses */
  addresses?: Address[];
}

/** Representation of an Address Validation */
export interface AddressValidation {
  /** Decision */
  decision?: string;
  /** List of errors */
  errors?: ErrorList;
  /** List of suggested addresses */
  suggestedAddresses?: Address[];
}

/** Representation of a Base Ooption */
export interface BaseOption {
  /** List of all variant options */
  options?: VariantOption[];
  /** Variant option selected */
  selected?: VariantOption;
  /** Variant type of base option */
  variantType?: string;
}

/** Representation of a Breadcrumb */
export interface Breadcrumb {
  /** Code of the facet */
  facetCode?: string;
  /** Name of the facet */
  facetName?: string;
  /** Value code of the facet */
  facetValueCode?: string;
  /** Value name of the facet */
  facetValueName?: string;
  /** Remove query */
  removeQuery?: SearchState;
  /** Truncate query */
  truncateQuery?: SearchState;
}

export interface BreadcrumbWsDTO {
  categoryCode?: string;
  linkClass?: string;
  name?: string;
  url?: string;
}

/** Representation of a Card Type */
export interface CardType {
  /** Card type code */
  code?: string;
  /** Card type name */
  name?: string;
}

/** Representation of a Card Type List */
export interface CardTypeList {
  /** List of card types */
  cardTypes?: CardType[];
}

/** Representation of a Cart */
export interface Cart {
  /** List of applied order promotions */
  appliedOrderPromotions?: PromotionResult[];
  /** List of applied product promotions */
  appliedProductPromotions?: PromotionResult[];
  /** List of applied vouchers */
  appliedVouchers?: Voucher[];
  /**
   * Flag showing if order is calculated
   * @example false
   */
  calculated?: boolean;
  /** Code number of order */
  code?: string;
  /** Delivery address */
  deliveryAddress?: Address;
  /** Delivery cost */
  deliveryCost?: Price;
  /**
   * Quantity of delivery items
   * @format int64
   */
  deliveryItemsQuantity?: number;
  /** Delivery mode information */
  deliveryMode?: DeliveryMode;
  /** List of delivery order entries group */
  deliveryOrderGroups?: DeliveryOrderEntryGroup[];
  /** Description of the cart */
  description?: string;
  /** List of order entries */
  entries?: OrderEntry[];
  /** List of entry groups */
  entryGroups?: EntryGroup[];
  /**
   * Date of cart expiration time
   * @format date-time
   */
  expirationTime?: string;
  /** Guest user id identifier */
  guid?: string;
  /** Name of the cart */
  name?: string;
  /**
   * Flag stating iv value is net-value
   * @example false
   */
  net?: boolean;
  /** Order discounts */
  orderDiscounts?: Price;
  /** Payment information */
  paymentInfo?: PaymentDetails;
  /**
   * Quantity of pickup items
   * @format int64
   */
  pickupItemsQuantity?: number;
  /** List of pickup order entry group */
  pickupOrderGroups?: PickupOrderEntryGroup[];
  /** List of potential order promotions for cart */
  potentialOrderPromotions?: PromotionResult[];
  /** List of potential product promotions for cart */
  potentialProductPromotions?: PromotionResult[];
  /** Product discounts */
  productDiscounts?: Price;
  /**
   * Date of saving cart
   * @format date-time
   */
  saveTime?: string;
  /** Information about person who saved cart */
  savedBy?: Principal;
  /** Site */
  site?: string;
  /** Store */
  store?: string;
  /** Subtotal price */
  subTotal?: Price;
  /** Total discounts */
  totalDiscounts?: Price;
  /** @format int32 */
  totalItems?: number;
  /** Total price value */
  totalPrice?: Price;
  /** Total price with tax */
  totalPriceWithTax?: Price;
  /** Total tax price */
  totalTax?: Price;
  /**
   * Total unit count
   * @format int32
   */
  totalUnitCount?: number;
  /** User information */
  user?: Principal;
}

/** Representation of a Cart list */
export interface CartList {
  /** List of carts */
  carts?: Cart[];
}

/** Representation of a Cart modification */
export interface CartModification {
  /**
   * Delivery mode changed
   * @example false
   */
  deliveryModeChanged?: boolean;
  /** Order entry */
  entry?: OrderEntry;
  /**
   * Final quantity after cart modification
   * @format int64
   */
  quantity?: number;
  /**
   * Quantity added with cart modification
   * @format int64
   */
  quantityAdded?: number;
  /** Status code of cart modification */
  statusCode?: string;
  /** Status message */
  statusMessage?: string;
}

/** Representation of an Catalog */
export interface Catalog {
  /** List of versions of catalog */
  catalogVersions?: CatalogVersion[];
  /** Identifier of abstract catalog item */
  id?: string;
  /**
   * Date of last modification
   * @format date-time
   */
  lastModified?: string;
  /** Name of abstract catalog item */
  name?: string;
  /** Url address of abstract catalog item */
  url?: string;
}

/** Representation of a Catalog List */
export interface CatalogList {
  /** List of catalog items */
  catalogs?: Catalog[];
}

/** Representation of a Catalog Version */
export interface CatalogVersion {
  /** List of category hierarchies */
  categories?: CategoryHierarchy[];
  /** Identifier of abstract catalog item */
  id?: string;
  /**
   * Date of last modification
   * @format date-time
   */
  lastModified?: string;
  /** Name of abstract catalog item */
  name?: string;
  /** Url address of abstract catalog item */
  url?: string;
}

/** Representation of a Category */
export interface Category {
  /** Code of the category */
  code?: string;
  /** Category image */
  image?: Image;
  /** Name of the category */
  name?: string;
  /** URL of the category */
  url?: string;
}

/** Representation of a Category Hierarchy */
export interface CategoryHierarchy {
  /** Identifier of abstract catalog item */
  id?: string;
  /**
   * Date of last modification
   * @format date-time
   */
  lastModified?: string;
  /** Name of abstract catalog item */
  name?: string;
  /** List of subcategory hierarchies */
  subcategories?: CategoryHierarchy[];
  /** Url address of abstract catalog item */
  url?: string;
}

/** Representation of a Classification */
export interface Classification {
  /** Code of the classification */
  code?: string;
  /** List of features for given classification */
  features?: Feature[];
  /** Name of the classification */
  name?: string;
}

export interface CmsLinkComponentWsDTO {
  category?: string;
  contentPage?: string;
  external?: boolean;
  linkName?: Record<string, string>;
  otherProperties?: object;
  product?: string;
  target?: boolean;
  url?: string;
}

export interface ColumnComponentAdaptData {
  columnContainerData?: ColumnComponentAdaptData[];
  /** @format date-time */
  modifiedTime: string;
  name?: string;
  navigationNode?: NavigationNodeAdaptedData;
  products?: Product[];
  typeCode: string;
  uid: string;
  uuid: string;
}

export interface ComponentAdaptedData {
  columnContainerData?: ColumnComponentAdaptData[];
  /** @format date-time */
  modifiedTime: string;
  name?: string;
  navigationNode?: NavigationNodeAdaptedData;
  products?: Product[];
  typeCode: string;
  uid: string;
  uuid: string;
}

export interface ComponentIDListWsDTO {
  idList?: string[];
}

export interface ComponentListWsDTO {
  component?: ComponentWsDTO[];
}

export interface ComponentWsDTO {
  /** @format date-time */
  modifiedtime?: string;
  name?: string;
  typeCode: string;
  uid: string;
  uuid: string;
}

/** Representation of a Configuration Info */
export interface ConfigurationInfo {
  /** Label of configuration info */
  configurationLabel?: string;
  /** Value of configuration info */
  configurationValue?: string;
  /** Type of configuration info */
  configuratorType?: string;
  /** Status of configuration info */
  status?: string;
}

/** Representation of a Consignment */
export interface Consignment {
  /** Consignment code */
  code?: string;
  /** Delivery point of service */
  deliveryPointOfService?: PointOfService;
  /** List of consignment entries */
  entries?: ConsignmentEntry[];
  /** Shipping address */
  shippingAddress?: Address;
  /** Consignment status */
  status?: string;
  /**
   * Consignment status date
   * @format date-time
   */
  statusDate?: string;
  /** Consignment status display */
  statusDisplay?: string;
  /** Consignment tracking identifier */
  trackingID?: string;
}

/** Representation of a Consignment Entry */
export interface ConsignmentEntry {
  /** Order entry of Consignment entry */
  orderEntry?: OrderEntry;
  /**
   * Quantity value of Consignment entry
   * @format int64
   */
  quantity?: number;
  /**
   * Shipped quantity
   * @format int64
   */
  shippedQuantity?: number;
}

export interface ContentSlotListWsDTO {
  contentSlot?: ContentSlotWsDTO;
}

export interface ContentSlotWsDTO {
  components?: ComponentListWsDTO;
  name?: string;
  position?: string;
  slotId: string;
  slotShared?: boolean;
  slotStatus?: string;
  slotUuid: string;
}

/** Response body fields which will be returned while fetching the list of countries. The DTO is in XML or .json format */
export interface Country {
  /** Country code in iso format */
  isocode?: string;
  /** Name of the country */
  name?: string;
}

/** List of countries */
export interface CountryList {
  /** This is the list of Country fields that should be returned in the response body */
  countries?: Country[];
}

/** Representation of a Currency */
export interface Currency {
  /**
   * Boolean flag whether currency is active
   * @example false
   */
  active?: boolean;
  /** Code of the currency in iso format */
  isocode?: string;
  /** Name of the currency */
  name?: string;
  /** Symbol of the currency */
  symbol?: string;
}

/** Representation of a Currency List */
export interface CurrencyList {
  /** List of currencies */
  currencies?: Currency[];
}

/** Representation of a Delivery mode */
export interface DeliveryMode {
  /** Code of the delivery mode */
  code?: string;
  /** Cost of the delivery */
  deliveryCost?: Price;
  /** Description of the delivery mode */
  description?: string;
  /** Name of the delivery mode */
  name?: string;
}

/** Representation of a Delivery mode list */
export interface DeliveryModeList {
  /** List of delivery modes */
  deliveryModes?: DeliveryMode[];
}

/** Representation of a Delivery Order Entry Group */
export interface DeliveryOrderEntryGroup {
  /** Delivery address for order entry group */
  deliveryAddress?: Address;
  /** List of order entries */
  entries?: OrderEntry[];
  /**
   * Quantity of order entries in a group
   * @format int64
   */
  quantity?: number;
  /** Total price with tax */
  totalPriceWithTax?: Price;
}

/** Representation of an Entry Group */
export interface EntryGroup {
  /** List of order entries */
  entries?: OrderEntry[];
  /**
   * Identifier of the entry group
   * @format int32
   * @example 1
   */
  entryGroupNumber?: number;
  /** List of child entry groups */
  entryGroups?: EntryGroup[];
  /**
   * Indicates if the entry group is in an error state
   * @example true
   */
  erroneous?: boolean;
  /**
   * Label for the entry group
   * @example "Photo On The Go Package"
   */
  label?: string;
  /**
   * Indicates type of the group, possible values are STANDALONE, CONFIGURABLEBUNDLE or any customer implemented type for any new provider
   * @example "STANDALONE"
   */
  type?: string;
}

/** Representation of a Facet */
export interface Facet {
  /**
   * Flag stating if facet is category facet
   * @example false
   */
  category?: boolean;
  /**
   * Flag stating if facet is multiSelect
   * @example false
   */
  multiSelect?: boolean;
  /** Name of the facet */
  name?: string;
  /**
   * Priority value of the facet
   * @format int32
   */
  priority?: number;
  /** List of top facet values */
  topValues?: FacetValue[];
  /** List of all facet values */
  values?: FacetValue[];
  /**
   * Flag stating if facet is visible
   * @example false
   */
  visible?: boolean;
}

/** Representation of a Facet Value */
export interface FacetValue {
  /**
   * Count of the facet value
   * @format int64
   */
  count?: number;
  /** Name of the facet value */
  name?: string;
  /** Query of the facet value */
  query?: SearchState;
  /**
   * Flag stating if facet value is selected
   * @example false
   */
  selected?: boolean;
}

/** Representation of a Feature */
export interface Feature {
  /** Code of the feature */
  code?: string;
  /**
   * Flag defining it feature is comparable
   * @example false
   */
  comparable?: boolean;
  /** Description of the feature */
  description?: string;
  /** Feature unit */
  featureUnit?: FeatureUnit;
  /** List of feature values */
  featureValues?: FeatureValue[];
  /** Name of the feature */
  name?: string;
  /**
   * Range number of the reature
   * @example false
   */
  range?: boolean;
  /** Type of the feature */
  type?: string;
}

/** Representation of a Feature Unit */
export interface FeatureUnit {
  /** Name of the feature unit */
  name?: string;
  /** Symbol of the feature unit */
  symbol?: string;
  /** Type of the feature unit */
  unitType?: string;
}

/** Representation of a Feature Value */
export interface FeatureValue {
  /** Value of the feature */
  value?: string;
}

/** Representation of a Future Stock */
export interface FutureStock {
  /**
   * Date of future stock
   * @format date-time
   */
  date?: string;
  /** Date of future stock expressed in text value */
  formattedDate?: string;
  /** Stock information of future stock */
  stock?: Stock;
}

/** Representation of a GeoPoint */
export interface GeoPoint {
  /**
   * Geopoint latitude
   * @format double
   */
  latitude?: number;
  /**
   * Geopoint longitude
   * @format double
   */
  longitude?: number;
}

/** Representation of an Image */
export interface Image {
  /** Tooltip content which is visible while image mouse hovering */
  altText?: string;
  /** Format of the image, can be zoom, product, thumbnail, store, cartIcon, etc. */
  format?: string;
  /**
   * Index of the image while displayed in gallery
   * @format int32
   */
  galleryIndex?: number;
  /** Type of the image, can be PRIMARY or GALLERY */
  imageType?: 'PRIMARY' | 'GALLERY';
  /** URL address of the image */
  url?: string;
}

/** Representation of a Language */
export interface Language {
  /**
   * true/false indicator when the language is active
   * @example false
   */
  active?: boolean;
  /** iso code of the language */
  isocode?: string;
  /** name of the language */
  name?: string;
  /** name the language in native form */
  nativeName?: string;
}

/** Lists all available languages (all languages used for a particular store). If the list of languages for a base store is empty, a list of all languages available in the system will be returned */
export interface LanguageList {
  /** This is the list of Language fields that should be returned in the response body */
  languages?: Language[];
}

export interface ListAdaptedComponents {
  component?: ComponentAdaptedData[];
  /** Pagination info */
  pagination?: Pagination;
  sorts?: Sort[];
}

/** Representation of a Member List */
export interface MemberList {
  /** List of member */
  members?: Principal[];
}

export interface NavigationEntryWsDTO {
  itemId?: string;
  itemSuperType?: string;
  itemType?: string;
}

export interface NavigationNodeAdaptedData {
  children?: NavigationNodeWsDTO[];
  entries?: NavigationEntryWsDTO[];
  links?: CmsLinkComponentWsDTO[];
  localizedTitle?: string;
  name?: string;
  /** @format int32 */
  position?: number;
  title?: string;
  uid: string;
  uuid: string;
}

export interface NavigationNodeWsDTO {
  children?: NavigationNodeWsDTO[];
  entries?: NavigationEntryWsDTO[];
  links?: CmsLinkComponentWsDTO[];
  localizedTitle?: string;
  name?: string;
  /** @format int32 */
  position?: number;
  title?: string;
  uid: string;
  uuid: string;
}

/** Representation of an Opening schedule */
export interface OpeningSchedule {
  /** Code of the opening schedule */
  code?: string;
  /** Name of the opening schedule */
  name?: string;
  /** List of special opening days */
  specialDayOpeningList?: SpecialOpeningDay[];
  /** List of weekday opening days */
  weekDayOpeningList?: WeekdayOpeningDay[];
}

/** Representation of an Order */
export interface Order {
  /** List of applied order promotions */
  appliedOrderPromotions?: PromotionResult[];
  /** List of applied product promotions */
  appliedProductPromotions?: PromotionResult[];
  /** List of applied vouchers */
  appliedVouchers?: Voucher[];
  /**
   * Flag showing if order is calculated
   * @example false
   */
  calculated?: boolean;
  /**
   * Boolean flag showing if order is cancellable
   * @example true
   */
  cancellable?: boolean;
  /** Code number of order */
  code?: string;
  /** List of consignment */
  consignments?: Consignment[];
  /**
   * Date of order creation
   * @format date-time
   */
  created?: string;
  /** Delivery address */
  deliveryAddress?: Address;
  /** Delivery cost */
  deliveryCost?: Price;
  /**
   * Quantity of delivery items
   * @format int64
   */
  deliveryItemsQuantity?: number;
  /** Delivery mode information */
  deliveryMode?: DeliveryMode;
  /** List of delivery order entries group */
  deliveryOrderGroups?: DeliveryOrderEntryGroup[];
  /** Order delivery status */
  deliveryStatus?: string;
  /** Order delivery status display */
  deliveryStatusDisplay?: string;
  /** List of order entries */
  entries?: OrderEntry[];
  /** List of entry groups */
  entryGroups?: EntryGroup[];
  /**
   * Flag showing if customer is Guest customer
   * @example false
   */
  guestCustomer?: boolean;
  /** Guest user id identifier */
  guid?: string;
  /**
   * Flag stating iv value is net-value
   * @example false
   */
  net?: boolean;
  /** Order discounts */
  orderDiscounts?: Price;
  /** Payment information */
  paymentInfo?: PaymentDetails;
  /**
   * Quantity of pickup items
   * @format int64
   */
  pickupItemsQuantity?: number;
  /** List of pickup order entry group */
  pickupOrderGroups?: PickupOrderEntryGroup[];
  /** Product discounts */
  productDiscounts?: Price;
  /**
   * Boolean flag showing if order is returnable
   * @example true
   */
  returnable?: boolean;
  /** Site */
  site?: string;
  /** Status of order */
  status?: string;
  /** Status display */
  statusDisplay?: string;
  /** Store */
  store?: string;
  /** Subtotal price */
  subTotal?: Price;
  /** Total discounts */
  totalDiscounts?: Price;
  /** @format int32 */
  totalItems?: number;
  /** Total price value */
  totalPrice?: Price;
  /** Total price with tax */
  totalPriceWithTax?: Price;
  /** Total tax price */
  totalTax?: Price;
  /** List of unconsigned order entries */
  unconsignedEntries?: OrderEntry[];
  /** User information */
  user?: Principal;
}

/** Representation of an Order entry */
export interface OrderEntry {
  /** Base price of order entry item */
  basePrice?: Price;
  /**
   * Quantity number of cancellable items in order entry
   * @format int64
   * @example 5
   */
  cancellableQuantity?: number;
  /** Total price of cancelled items which belong to the order entry item */
  cancelledItemsPrice?: Price;
  /** Configuration info of order entry */
  configurationInfos?: ConfigurationInfo[];
  /** Delivery mode */
  deliveryMode?: DeliveryMode;
  /** Point of service associated with order entry */
  deliveryPointOfService?: PointOfService;
  /**
   * Entry number of the order entry
   * @format int32
   */
  entryNumber?: number;
  /** Product details of order entry */
  product?: Product;
  /**
   * Quantity number of items in order entry
   * @format int64
   */
  quantity?: number;
  /**
   * Quantity number of returnable items in order entry
   * @format int64
   * @example 5
   */
  returnableQuantity?: number;
  /** Total price of returned items which belong to the order entry item */
  returnedItemsPrice?: Price;
  /** List of aggregated status information per entry, relevant if the entry is configurable and its configuration contains one or many issues in different severities. Note that configurators typically raise such issues only in case the parent document is changeable. In this case the issues (depending on their severity) need to be fixed before a checkout can be done. This means this segment can be present for a cart entry, for order entries it will always be empty */
  statusSummaryList?: StatusSummary[];
  /** Total price of order entry item */
  totalPrice?: Price;
  /**
   * Flag defining if order entry item is updateable
   * @example false
   */
  updateable?: boolean;
}

/** Representation of an Order entry list consumed */
export interface OrderEntryList {
  /** List of order entries */
  orderEntries?: OrderEntry[];
}

/** Representation of an Order History */
export interface OrderHistory {
  /** Code of Order History */
  code?: string;
  /** Guest user identifier */
  guid?: string;
  /**
   * Date of placing order
   * @format date-time
   */
  placed?: string;
  /** Status of Order History */
  status?: string;
  /** Status display */
  statusDisplay?: string;
  /** Total price */
  total?: Price;
}

/** Representation of an Order History List */
export interface OrderHistoryList {
  /** List of order history items */
  orders?: OrderHistory[];
  /** Pagination items */
  pagination?: DeprecatedPagination;
  /** List of sorts */
  sorts?: Sort[];
}

/** Representation of an Order Status Update Element */
export interface OrderStatusUpdateElement {
  /** BaseSite identifier */
  baseSiteId?: string;
  /** Code of update element of order status */
  code?: string;
  /** Status of update element */
  status?: string;
}

/** Representation of an Order Status Update Element List */
export interface OrderStatusUpdateElementList {
  /** List of order status update elements */
  orderStatusUpdateElements?: OrderStatusUpdateElement[];
}

export interface PageAdaptedData {
  breadcrumbs?: BreadcrumbWsDTO[];
  canonical?: string;
  contentSlots: ContentSlotListWsDTO;
  defaultPage?: boolean;
  description?: string;
  name?: string;
  template?: string;
  title?: string;
  typeCode: string;
  uid: string;
  uuid: string;
}

/** Representation of a Payment Details */
export interface PaymentDetails {
  /** Name of account holder */
  accountHolderName?: string;
  /** Address details to be considered as billing address */
  billingAddress?: Address;
  /** Payment card number */
  cardNumber?: string;
  /** Type of payment card */
  cardType?: CardType;
  /**
   * Flag to mark if payment the default one
   * @example false
   */
  defaultPayment?: boolean;
  /** Month of expiration of payment */
  expiryMonth?: string;
  /** Year of expiration of payment */
  expiryYear?: string;
  /** Unique identifier of payment detail */
  id?: string;
  /** Issue number */
  issueNumber?: string;
  /**
   * Flag to mark if payment is saved one
   * @example false
   */
  saved?: boolean;
  /** Start month from which payment is valid */
  startMonth?: string;
  /** Start year from which payment is valid */
  startYear?: string;
  /** Identifier of subscription */
  subscriptionId?: string;
}

/** Representation of a Payment details list */
export interface PaymentDetailsList {
  /** List of payment details */
  payments?: PaymentDetails[];
}

/** Representation of a Pickup Order Entry Group */
export interface PickupOrderEntryGroup {
  /** Delivery point of service */
  deliveryPointOfService?: PointOfService;
  /**
   * Distance calculated to pickup place
   * @format double
   */
  distance?: number;
  /** List of order entries */
  entries?: OrderEntry[];
  /**
   * Quantity of order entries in a group
   * @format int64
   */
  quantity?: number;
  /** Total price with tax */
  totalPriceWithTax?: Price;
}

/** Representation of a Point of service */
export interface PointOfService {
  /** Address information of point of service */
  address?: Address;
  /** Description of the point of service */
  description?: string;
  /** Display name of the point of service */
  displayName?: string;
  /**
   * Distance to the point of service as number value
   * @format double
   */
  distanceKm?: number;
  /** List of features for a given point of service */
  features?: Record<string, string>;
  /** Distance to the point of service as text value */
  formattedDistance?: string;
  /** Geopoint localization info about point of service */
  geoPoint?: GeoPoint;
  /** Image associated with the point of service */
  mapIcon?: Image;
  /** Name of the point of service */
  name?: string;
  /** Opening hours of point of service */
  openingHours?: OpeningSchedule;
  /** Store content of given point of service */
  storeContent?: string;
  /** Collection of images associated with a point of service */
  storeImages?: Image[];
  /** Url address of the point of service */
  url?: string;
}

/** Representation of a Point Of Service Stock */
export interface PointOfServiceStock {
  /** Address information of point of service */
  address?: Address;
  /** Description of the point of service */
  description?: string;
  /** Display name of the point of service */
  displayName?: string;
  /**
   * Distance to the point of service as number value
   * @format double
   */
  distanceKm?: number;
  /** List of features for a given point of service */
  features?: Record<string, string>;
  /** Distance to the point of service as text value */
  formattedDistance?: string;
  /** Geopoint localization info about point of service */
  geoPoint?: GeoPoint;
  /** Image associated with the point of service */
  mapIcon?: Image;
  /** Name of the point of service */
  name?: string;
  /** Opening hours of point of service */
  openingHours?: OpeningSchedule;
  /** Stock information about point of service */
  stockInfo?: Stock;
  /** Store content of given point of service */
  storeContent?: string;
  /** Collection of images associated with a point of service */
  storeImages?: Image[];
  /** Url address of the point of service */
  url?: string;
}

/** Representation of a Price */
export interface Price {
  /** Currency iso format */
  currencyIso?: string;
  /** Value of price formatted */
  formattedValue?: string;
  /**
   * Maximum quantity of the price value
   * @format int64
   */
  maxQuantity?: number;
  /**
   * Minimum quantity of the price value
   * @format int64
   */
  minQuantity?: number;
  /** Type of the price */
  priceType?: 'BUY' | 'FROM';
  /** Value of price in BigDecimal format */
  value?: number;
}

/** Representation of a Price Range */
export interface PriceRange {
  /** Maximum value of the Price Range */
  maxPrice?: Price;
  /** Minium value of the Price Range */
  minPrice?: Price;
}

/** Representation of a Principal webservice DTO used for defining User data types */
export interface Principal {
  /** Name of the user */
  name?: string;
  /** Unique user identifier */
  uid?: string;
}

/** Representation of a Product */
export interface Product {
  /**
   * Flag defining if product is available for pickup
   * @example false
   */
  availableForPickup?: boolean;
  /**
   * Rating number of average value
   * @format double
   */
  averageRating?: number;
  /** List of base options related to the product */
  baseOptions?: BaseOption[];
  /** Information about base product */
  baseProduct?: string;
  /** List of categories product belongs to */
  categories?: Category[];
  /** List of classifications related to the product */
  classifications?: Classification[];
  /** Code of the product */
  code?: string;
  /**
   * Flag stating if product is configurable
   * @example false
   */
  configurable?: boolean;
  /** Configurator type related to the product */
  configuratorType?: string;
  /** Description of the product */
  description?: string;
  /** List of future stocks */
  futureStocks?: FutureStock[];
  /** List of images linked to product */
  images?: Image[];
  /** Data of product manufacturer */
  manufacturer?: string;
  /**
   * Flag stating if product is multidimentional
   * @example false
   */
  multidimensional?: boolean;
  /** Name of the product */
  name?: string;
  /**
   * Number of reviews associated with the product
   * @format int32
   */
  numberOfReviews?: number;
  /** List of potential promotions related to the product */
  potentialPromotions?: Promotion[];
  /** Price of the product */
  price?: Price;
  /** Price range assigned to the product */
  priceRange?: PriceRange;
  /** List of product references */
  productReferences?: ProductReference[];
  /**
   * Flag defining if product is purchasable
   * @example false
   */
  purchasable?: boolean;
  /** List of reviews associated with the product */
  reviews?: Review[];
  /** Stock value of the product */
  stock?: Stock;
  /** Product summary */
  summary?: string;
  /** Tags associated with the product */
  tags?: string[];
  /** Url address of the product */
  url?: string;
  /** List of variant matrixes associated with the product */
  variantMatrix?: VariantMatrixElement[];
  /** List of variant options related to the product */
  variantOptions?: VariantOption[];
  /** Variant type of the product */
  variantType?: string;
  /** List of volume prices */
  volumePrices?: Price[];
  /**
   * Flag stating if volume price should be displayed
   * @example false
   */
  volumePricesFlag?: boolean;
}

/** Representation of a Product Express Update Element */
export interface ProductExpressUpdateElement {
  /** Catalog identifier */
  catalogId?: string;
  /** Catalog version */
  catalogVersion?: string;
  /** Code of product express update element */
  code?: string;
}

/** Representation of a Product Express Update Element List */
export interface ProductExpressUpdateElementList {
  /** List of product express update element */
  productExpressUpdateElements?: ProductExpressUpdateElement[];
}

/** Representation of a Product List */
export interface ProductList {
  /** Catalog of product list */
  catalog?: string;
  /**
   * Number of current page
   * @format int32
   */
  currentPage?: number;
  /** List of products */
  products?: Product[];
  /**
   * Total page count
   * @format int32
   */
  totalPageCount?: number;
  /**
   * Total product count
   * @format int32
   */
  totalProductCount?: number;
  /** Version of product list */
  version?: string;
}

/** Representation of a Product Reference */
export interface ProductReference {
  /** Reference description */
  description?: string;
  /**
   * Flag stating if product reference is preselected
   * @example false
   */
  preselected?: boolean;
  /**
   * Reference quantity
   * @format int32
   */
  quantity?: number;
  /** Reference type */
  referenceType?: string;
  /** Target product */
  target?: Product;
}

/** Representation of a Product Reference List */
export interface ProductReferenceList {
  /** List of product references */
  references?: ProductReference[];
}

/** Representation of a Product Search Page */
export interface ProductSearchPage {
  /** List of breadcrumbs info */
  breadcrumbs?: Breadcrumb[];
  /** Code of category */
  categoryCode?: string;
  /** Current query */
  currentQuery?: SearchState;
  /** List of facets */
  facets?: Facet[];
  /** Free text search */
  freeTextSearch?: string;
  /** Redirect url address keyword */
  keywordRedirectUrl?: string;
  /** Pagination number */
  pagination?: DeprecatedPagination;
  /** List of products */
  products?: Product[];
  /** List of sorts */
  sorts?: Sort[];
  /** Spelling suggestion */
  spellingSuggestion?: SpellingSuggestion;
}

/** Representation of a Promotion */
export interface Promotion {
  /** Code of the promotion */
  code?: string;
  /** Message about promotion which is displayed when planning potential promotion. This field has higher priority over promotion description */
  couldFireMessages?: string[];
  /** Description of the promotion */
  description?: string;
  /**
   * Boolean flag if promotion is enabled
   * @example false
   */
  enabled?: boolean;
  /**
   * Last date of validity of the promotion
   * @format date-time
   */
  endDate?: string;
  /** Message fired while the promotion is active. This is info how much you will get when applying the promotion */
  firedMessages?: string[];
  /**
   * Priority index as numeric value of the promotion. Higher number means higher priority
   * @format int32
   */
  priority?: number;
  /** Image banner of the promotion */
  productBanner?: Image;
  /** Group of the promotion */
  promotionGroup?: string;
  /** Type of the promotion */
  promotionType?: string;
  /** List of promotion restrictions */
  restrictions?: PromotionRestriction[];
  /**
   * The initial date of the promotion
   * @format date-time
   */
  startDate?: string;
  /** Promotion title */
  title?: string;
}

/** Representation of a Promotion list */
export interface PromotionList {
  /** List of promotions */
  promotions?: Promotion[];
}

/** Representation of a Promotion order entry consumed */
export interface PromotionOrderEntryConsumed {
  /**
   * Adjusted unit price for promotion order entry
   * @format double
   */
  adjustedUnitPrice?: number;
  /** Order entry code */
  code?: string;
  /**
   * Order entry number
   * @format int32
   */
  orderEntryNumber?: number;
  /**
   * Quantity of promotion order entry
   * @format int64
   */
  quantity?: number;
}

/** Representation of a Promotion Restriction */
export interface PromotionRestriction {
  /** Description of the promotion restriction */
  description?: string;
  /** Type of the promotion restriction */
  restrictionType?: string;
}

/** Representation of a Promotion result */
export interface PromotionResult {
  /** List of promotion order entries consumed */
  consumedEntries?: PromotionOrderEntryConsumed[];
  /** Description of promotion result */
  description?: string;
  /** Promotion information for given promotion result */
  promotion?: Promotion;
}

/** Representation of a Promotion result list */
export interface PromotionResultList {
  /** List of promotion results */
  promotions?: PromotionResult[];
}

/** Response body fields which will be returned while fetching the list of country's regions. */
export interface Region {
  /** Country code in iso format */
  countryIso?: string;
  /** Country and Region code in iso format */
  isocode?: string;
  /** Region code in short iso form */
  isocodeShort?: string;
  /** Name of the region */
  name?: string;
}

/** Representation of a Review */
export interface Review {
  /** Alias name for the review */
  alias?: string;
  /** Review comment */
  comment?: string;
  /**
   * Date of the review
   * @format date-time
   */
  date?: string;
  /** Review headline */
  headline?: string;
  /** Identifier of review */
  id?: string;
  /** Person related to the review */
  principal?: User;
  /**
   * Review rating value
   * @format double
   */
  rating?: number;
}

/** Representation of a Review List */
export interface ReviewList {
  /** List of reviews */
  reviews?: Review[];
}

/** Representation of a Save Cart Result */
export interface SaveCartResult {
  /** Cart data information for saved cart */
  savedCartData?: Cart;
}

/** Representation of a Search Query */
export interface SearchQuery {
  /** Value of search query */
  value?: string;
}

/** Representation of a Search State */
export interface SearchState {
  /** Query of search state */
  query?: SearchQuery;
  /** Url address of search state */
  url?: string;
}

/** Representation a Sort option */
export interface Sort {
  /** Code of Sort */
  code?: string;
  /** Name of Sort */
  name?: string;
  /**
   * Flag stating when Sort is selected
   * @example false
   */
  selected?: boolean;
}

/** Representation of a special opening day */
export interface SpecialOpeningDay {
  /**
   * Flag stating if special opening day is closed
   * @example false
   */
  closed?: boolean;
  /** Closing time of opening day */
  closingTime?: Time;
  /** Comment field */
  comment?: string;
  /**
   * Date of special opening day
   * @format date-time
   */
  date?: string;
  /** Text representation of the date of special opening day */
  formattedDate?: string;
  /** Name of the special opening day event */
  name?: string;
  /** Starting time of opening day */
  openingTime?: Time;
}

/** Representation of a Spell Checker Suggestion */
export interface SpellingSuggestion {
  /** Query for spelling suggestion */
  query?: string;
  /** Spelling suggestion */
  suggestion?: string;
}

/** Representation of a status summary, an aggregated view on issues for a specific status or severity. These issues are attached to configurations of products or order entries */
export interface StatusSummary {
  /**
   * Number of issues per status
   * @format int32
   * @example 3
   */
  numberOfIssues?: number;
  /**
   * Status or severity indicator, can be one of ERROR, WARNING, INFO or SUCCESS
   * @example "ERROR"
   */
  status?: string;
}

/** Representation of a Stock */
export interface Stock {
  /**
   * Indicate whether Stock level value is rounded
   * @example false
   */
  isValueRounded?: boolean;
  /**
   * Stock level expressed as number
   * @format int64
   */
  stockLevel?: number;
  /** Status of stock level */
  stockLevelStatus?: string;
}

/** Representation of a Store finder search page */
export interface StoreFinderSearchPage {
  /**
   * Bound east longitude
   * @format double
   */
  boundEastLongitude?: number;
  /**
   * Bound north latitude
   * @format double
   */
  boundNorthLatitude?: number;
  /**
   * Bound south latitude
   * @format double
   */
  boundSouthLatitude?: number;
  /**
   * Bound west longitude
   * @format double
   */
  boundWestLongitude?: number;
  /** Location text */
  locationText?: string;
  /** Pagination */
  pagination?: DeprecatedPagination;
  /** List of sortings */
  sorts?: Sort[];
  /**
   * Source latitude
   * @format double
   */
  sourceLatitude?: number;
  /**
   * Source longitude
   * @format double
   */
  sourceLongitude?: number;
  /** List of stores */
  stores?: PointOfService[];
}

/** Representation of a Store Finder Stock Search Page */
export interface StoreFinderStockSearchPage {
  /**
   * Bound to east longitude
   * @format double
   */
  boundEastLongitude?: number;
  /**
   * Bound to north latitude
   * @format double
   */
  boundNorthLatitude?: number;
  /**
   * Bound to south latitude
   * @format double
   */
  boundSouthLatitude?: number;
  /**
   * Bound to west longitude
   * @format double
   */
  boundWestLongitude?: number;
  /** Location text */
  locationText?: string;
  /** Pagination */
  pagination?: DeprecatedPagination;
  /** Product */
  product?: Product;
  /** List of sorts */
  sorts?: Sort[];
  /**
   * Source latitude
   * @format double
   */
  sourceLatitude?: number;
  /**
   * Source longitude
   * @format double
   */
  sourceLongitude?: number;
  /** List of stores */
  stores?: PointOfServiceStock[];
}

/** Representation of a Suggestion */
export interface Suggestion {
  /** Suggestion value */
  value?: string;
}

/** Representation of a Suggestion List */
export interface SuggestionList {
  /** List of suggestions */
  suggestions?: Suggestion[];
}

/** Representation of a Time */
export interface Time {
  /** Formatted hour */
  formattedHour?: string;
  /**
   * Hour part of the time data
   * @format byte
   */
  hour?: string;
  /**
   * Minute part of the time data
   * @format byte
   */
  minute?: string;
}

/** Representation of a Title */
export interface Title {
  /** Title code */
  code?: string;
  /** Title name */
  name?: string;
}

/** Representation of a Title List */
export interface TitleList {
  /** List of titles */
  titles?: Title[];
}

/** Representation of an User */
export interface User {
  /** User preferred currency */
  currency?: Currency;
  /** Customer identifier */
  customerId?: string;
  /**
   * Deactivation date
   * @format date-time
   */
  deactivationDate?: string;
  /** User address */
  defaultAddress?: Address;
  /** User identifier */
  displayUid?: string;
  /** User first name */
  firstName?: string;
  /** User preferred language */
  language?: Language;
  /** User last name */
  lastName?: string;
  /** Name of the user */
  name?: string;
  /** User title */
  title?: string;
  /** User title code */
  titleCode?: string;
  /** Unique user identifier */
  uid?: string;
}

/** Representation of an User Group */
export interface UserGroup {
  /** List of members */
  members?: Principal[];
  /**
   * Number of members
   * @format int32
   */
  membersCount?: number;
  /** Name of the user */
  name?: string;
  /** List of subgroups */
  subGroups?: UserGroup[];
  /** Unique user identifier */
  uid?: string;
}

/** Representation of an User Group List */
export interface UserGroupList {
  /**
   * Current page
   * @format int32
   */
  currentPage?: number;
  /**
   * Number of pages
   * @format int32
   */
  numberOfPages?: number;
  /**
   * Page size
   * @format int32
   */
  pageSize?: number;
  /**
   * Total number
   * @format int32
   */
  totalNumber?: number;
  /** List of user groups */
  userGroups?: UserGroup[];
}

/** Representation of an UserSignUp. Consists of fields required to register new customer */
export interface UserSignUp {
  /** first name of the user */
  firstName: string;
  /** last name of the user */
  lastName: string;
  /** user password */
  password: string;
  titleCode?: string;
  /** user id, unique string required to create new user. It can be email */
  uid: string;
}

/** Representation of a Variant Category */
export interface VariantCategory {
  /**
   * Flag if varian category has image assigned
   * @example false
   */
  hasImage?: boolean;
  /** Variant category name */
  name?: string;
  /**
   * Priority number of variant category
   * @format int32
   */
  priority?: number;
}

/** Representation of a Variant Matrix Element */
export interface VariantMatrixElement {
  /** List of elements with the type of variant matrix element */
  elements?: VariantMatrixElement[];
  /** @example false */
  isLeaf?: boolean;
  /** Parent variant category for variant matrix element */
  parentVariantCategory?: VariantCategory;
  /** Variant option for variant matrix element */
  variantOption?: VariantOption;
  /** Variant value category for variant matrix element */
  variantValueCategory?: VariantValueCategory;
}

/** Representation of a Variant Option */
export interface VariantOption {
  /** Code of the variant option */
  code?: string;
  /** Price data information of the variant option */
  priceData?: Price;
  /** Stock value of the variant option */
  stock?: Stock;
  /** Url address of the variant option */
  url?: string;
  /** List of variant option qualifiers */
  variantOptionQualifiers?: VariantOptionQualifier[];
}

/** Representation of a Variant Option Qualifier */
export interface VariantOptionQualifier {
  /** Image associated with variant option qualifier */
  image?: Image;
  /** Name of variant option qualifier */
  name?: string;
  /** Qualifier */
  qualifier?: string;
  /** Value of variant option qualifier */
  value?: string;
}

/** Representation of a Variant Value Category */
export interface VariantValueCategory {
  /** Name of the variant value category */
  name?: string;
  /**
   * Sequence number of variant value category
   * @format int32
   */
  sequence?: number;
  /** Parent category of variant value category */
  superCategories?: VariantCategory[];
}

/** Representation of a Voucher */
export interface Voucher {
  /** Applied value when using this voucher */
  appliedValue?: Price;
  /** The identifier of the Voucher. This is the first part of voucher code which holds first 3 letters, like: 123 */
  code?: string;
  /** Currency of the voucher */
  currency?: Currency;
  /** Description of the voucher */
  description?: string;
  /**
   * Specifies if the order this voucher is applied to is shipped for free (true) or not (false). Defaults to false.
   * @example false
   */
  freeShipping?: boolean;
  /** Name of the voucher */
  name?: string;
  /**
   * Value of the voucher. Example of such value is: 15.0d
   * @format double
   */
  value?: number;
  /** Formatted value of the voucher */
  valueFormatted?: string;
  /** The value of the voucher to display. Example: 15.0% */
  valueString?: string;
  /** Voucher code, is the holder for keeping specific occasional voucher related to business usage. It can be generated and looks like: 123-H8BC-Y3D5-34AL */
  voucherCode?: string;
}

/** Representation of a Voucher List */
export interface VoucherList {
  /** List of vouchers */
  vouchers?: Voucher[];
}

/** Representation of a Weekday Opening Day */
export interface WeekdayOpeningDay {
  /**
   * Flag stating if weekday opening day is closed
   * @example false
   */
  closed?: boolean;
  /** Closing time of opening day */
  closingTime?: Time;
  /** Starting time of opening day */
  openingTime?: Time;
  /** Text representation of week day opening day */
  weekDay?: string;
}

/** Representation of a search results pagination */
export interface DeprecatedPagination {
  /**
   * The current page number. The first page is number zero (0), the second page is number one (1), and so on
   * @format int32
   */
  currentPage?: number;
  /**
   * The number of results per page. A page may have less results if there are less than a full page of results, only on the last page in the results
   * @format int32
   */
  pageSize?: number;
  /** The selected sort code */
  sort?: string;
  /**
   * The total number of pages. This is the number of pages, each of pageSize, required to display the totalResults.
   * @format int32
   */
  totalPages?: number;
  /**
   * The total number of matched results across all pages
   * @format int64
   */
  totalResults?: number;
}

/** Error message */
export interface Error {
  /** Error code */
  errorCode?: string;
  exceptionMessage?: string;
  /** @example "English" */
  language?: string;
  /** Descriptive, human readable error message. */
  message?: string;
  /**
   * @format int32
   * @example 1
   */
  position?: number;
  /** Additional classification specific for each error type e.g. 'noStock'. */
  reason?: string;
  /** Identifier of the related object e.g. '1'. */
  subject?: string;
  /** Type of the object related to the error e.g. 'entry'. */
  subjectType?: string;
  /** Type of the error e.g. 'LowStockError'. */
  type?: string;
}

/** List of errors */
export interface ErrorList {
  errors?: Error[];
}

/** Pagination info */
export interface Pagination {
  /**
   * Number of elements on this page
   * @format int32
   */
  count?: number;
  /**
   * Indicates if there is next page
   * @example false
   */
  hasNext?: boolean;
  /**
   * Indicates if there is previous page
   * @example false
   */
  hasPrevious?: boolean;
  /**
   * Current page number
   * @format int32
   */
  page?: number;
  /**
   * Total number of elements
   * @format int64
   */
  totalCount?: number;
  /**
   * Total number of pages
   * @format int32
   */
  totalPages?: number;
}

/** Sort option */
export interface Sort {
  /** @example false */
  asc?: boolean;
  code?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '//www.willys.inttest.occdev.axfood.se/axfoodcommercewebservices/v2';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: FormData) =>
      (Array.from(input.keys()) || []).reduce((formData, key) => {
        const property = input.get(key);
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Commerce Webservices V2
 * @version 2.0
 * @baseUrl //www.willys.inttest.occdev.axfood.se/axfoodcommercewebservices/v2
 *
 * Commerce Webservices Version 2
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  baseSiteId = {
    /**
     * @description Lists supported payment card types.
     *
     * @tags Miscs
     * @name GetCardTypesUsingGet
     * @summary Get a list of supported payment card types.
     * @request GET:/{baseSiteId}/cardtypes
     * @secure
     */
    getCardTypesUsingGet: (
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<CardTypeList, void>({
        path: `/${baseSiteId}/cardtypes`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all catalogs with versions defined for the base store.
     *
     * @tags Catalogs
     * @name GetCatalogsUsingGet
     * @summary Get a list of catalogs
     * @request GET:/{baseSiteId}/catalogs
     * @secure
     */
    getCatalogsUsingGet: (
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<CatalogList, void>({
        path: `/${baseSiteId}/catalogs`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns information about a catalog based on its ID, along with the versions defined for the current base store.
     *
     * @tags Catalogs
     * @name GetCatalogUsingGet
     * @summary Get a catalog
     * @request GET:/{baseSiteId}/catalogs/{catalogId}
     * @secure
     */
    getCatalogUsingGet: (
      catalogId: string,
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Catalog, void>({
        path: `/${baseSiteId}/catalogs/${catalogId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns information about the catalog version that exists for the current base store.
     *
     * @tags Catalogs
     * @name GetCatalogVersionUsingGet
     * @summary Get information about catalog version
     * @request GET:/{baseSiteId}/catalogs/{catalogId}/{catalogVersionId}
     * @secure
     */
    getCatalogVersionUsingGet: (
      catalogId: string,
      catalogVersionId: string,
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<CatalogVersion, void>({
        path: `/${baseSiteId}/catalogs/${catalogId}/${catalogVersionId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns information about a specified category that exists in a catalog version available for the current base store.
     *
     * @tags Catalogs
     * @name GetCategoriesUsingGet
     * @summary Get information about catagory in a catalog version
     * @request GET:/{baseSiteId}/catalogs/{catalogId}/{catalogVersionId}/categories/{categoryId}
     * @secure
     */
    getCategoriesUsingGet: (
      catalogId: string,
      catalogVersionId: string,
      categoryId: string,
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<CategoryHierarchy, void>({
        path: `/${baseSiteId}/catalogs/${catalogId}/${catalogVersionId}/categories/${categoryId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Given a list of component identifiers, return cms component data.
     *
     * @tags Component
     * @name FindComponentsByIds
     * @summary Find component data by id(s)
     * @request GET:/{baseSiteId}/cms/components
     * @secure
     */
    findComponentsByIds: (
      baseSiteId: string,
      query?: {
        /** List of Component identifiers */
        componentIds?: string[];
        /** Catalog code */
        catalogCode?: string;
        /** Product code */
        productCode?: string;
        /** Category code */
        categoryCode?: string;
        /** Store id */
        storeId?: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Optional pagination parameter. Default value 0.
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * Optional pagination parameter. Default value 10.
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** Optional sort criterion. No default value. */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ListAdaptedComponents, void>({
        path: `/${baseSiteId}/cms/components`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Given a list of component identifiers in body, return cms component data.
     *
     * @tags Component
     * @name SearchComponentsByIds
     * @summary Get components' data by id given in body
     * @request POST:/{baseSiteId}/cms/components
     * @secure
     */
    searchComponentsByIds: (
      baseSiteId: string,
      componentIdList: ComponentIDListWsDTO,
      query?: {
        /** Catalog code */
        catalogCode?: string;
        /** Product code */
        productCode?: string;
        /** Category code */
        categoryCode?: string;
        /** Store id */
        storeId?: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Optional pagination parameter. Default value 0.
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * Optional pagination parameter. Default value 10.
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** Optional sort criterion. No default value. */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ListAdaptedComponents, void>({
        path: `/${baseSiteId}/cms/components`,
        method: 'POST',
        query: query,
        body: componentIdList,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Given a component identifier, return cms component data.
     *
     * @tags Component
     * @name GetComponentById
     * @summary Get component data
     * @request GET:/{baseSiteId}/cms/components/{componentId}
     * @secure
     */
    getComponentById: (
      componentId: string,
      baseSiteId: string,
      query?: {
        /** Catalog code */
        catalogCode?: string;
        /** Product code */
        productCode?: string;
        /** Category code */
        categoryCode?: string;
        /** Store id */
        storeId?: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<ComponentAdaptedData, void>({
        path: `/${baseSiteId}/cms/components/${componentId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Given a page identifier, return the page data with a list of cms content slots, each of which contains a list of cms component data.
     *
     * @tags Page
     * @name GetPage
     * @summary Get page data with list of cms content slots
     * @request GET:/{baseSiteId}/cms/pages
     * @secure
     */
    getPage: (
      baseSiteId: string,
      query?: {
        /**
         * page type
         * @default "ContentPage"
         */
        pageType?: 'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage';
        /** Page Label or Id; if no page has a label that matches the provided label exactly, try to find pages whose label starts with a section of the provided label. Otherwise, try to find the page by id. Note: URL encoding on the page label should be applied when the label contains special characters */
        pageLabelOrId?: string;
        /** If pageType is ProductPage, code should be product code; if pageType is CategoryPage, code should be category code; if pageType is CatalogPage, code should be catalog code */
        code?: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /** Ticket ID of the CMS preview. Unused in the actual controller, just needed for the schema generator. */
        cmsTicketId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageAdaptedData, void>({
        path: `/${baseSiteId}/cms/pages`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Given a page identifier, return the page data with a list of cms content slots, each of which contains a list of cms component data.
     *
     * @tags Page
     * @name GetPageById
     * @summary Get page data with list of cms content slots
     * @request GET:/{baseSiteId}/cms/pages/{pageId}
     * @secure
     */
    getPageById: (
      pageId: string,
      baseSiteId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<PageAdaptedData, void>({
        path: `/${baseSiteId}/cms/pages/${pageId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lists all available currencies (all usable currencies for the current store). If the list of currencies for a base store is empty, a list of all currencies available in the system is returned.
     *
     * @tags Miscs
     * @name GetCurrenciesUsingGet
     * @summary Get a list of available currencies.
     * @request GET:/{baseSiteId}/currencies
     * @secure
     */
    getCurrenciesUsingGet: (
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<CurrencyList, void>({
        path: `/${baseSiteId}/currencies`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all customer groups that are direct subgroups of a customergroup. To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     *
     * @tags Customer Groups
     * @name GetAllCustomerGroupsUsingGet
     * @summary Get all subgroups of a customergroup.
     * @request GET:/{baseSiteId}/customergroups
     * @secure
     */
    getAllCustomerGroupsUsingGet: (
      baseSiteId: string,
      query?: {
        /**
         * Current page number (starts with 0).
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * Number of customer group returned in one page.
         * @format int32
         * @default 20
         */
        pageSize?: number;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "BASIC"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<UserGroupList, void>({
        path: `/${baseSiteId}/customergroups`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new customer group that is a direct subgroup of a customergroup. To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     *
     * @tags Customer Groups
     * @name CreateNewCustomerGroupUsingPost1
     * @summary Creates a new customer group.
     * @request POST:/{baseSiteId}/customergroups
     * @secure
     */
    createNewCustomerGroupUsingPost1: (baseSiteId: string, userGroup: UserGroup, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/${baseSiteId}/customergroups`,
        method: 'POST',
        body: userGroup,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns a customer group with a specific groupId. To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     *
     * @tags Customer Groups
     * @name GetCustomerGroupUsingGet
     * @summary Get a specific customer group.
     * @request GET:/{baseSiteId}/customergroups/{groupId}
     * @secure
     */
    getCustomerGroupUsingGet: (
      groupId: string,
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "BASIC"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<UserGroup, void>({
        path: `/${baseSiteId}/customergroups/${groupId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets members for a user group. The list of existing members is overwritten with a new one. To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     *
     * @tags Customer Groups
     * @name SetUserListForCustomerGroupUsingPut1
     * @summary Sets members for a user group.
     * @request PUT:/{baseSiteId}/customergroups/{groupId}/members
     * @secure
     */
    setUserListForCustomerGroupUsingPut1: (
      groupId: string,
      baseSiteId: string,
      members: MemberList,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/customergroups/${groupId}/members`,
        method: 'PUT',
        body: members,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Assigns user(s) to a customer group. To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     *
     * @tags Customer Groups
     * @name AssignUserToCustomerGroupUsingPatch1
     * @summary Assigns user(s) to a customer group.
     * @request PATCH:/{baseSiteId}/customergroups/{groupId}/members
     * @secure
     */
    assignUserToCustomerGroupUsingPatch1: (
      groupId: string,
      baseSiteId: string,
      members: MemberList,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/customergroups/${groupId}/members`,
        method: 'PATCH',
        body: members,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes user from a customer group. To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     *
     * @tags Customer Groups
     * @name RemoveUsersFromCustomerGroupUsingDelete
     * @summary Delete a user from a customer group.
     * @request DELETE:/{baseSiteId}/customergroups/{groupId}/members/{userId}
     * @secure
     */
    removeUsersFromCustomerGroupUsingDelete: (
      groupId: string,
      userId: string,
      baseSiteId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/customergroups/${groupId}/members/${userId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Lists all supported delivery countries for the current store. The list is sorted alphabetically.
     *
     * @tags Miscs
     * @name GetDeliveryCountriesUsingGet
     * @summary Get a list of supported countries.
     * @request GET:/{baseSiteId}/deliverycountries
     * @secure
     */
    getDeliveryCountriesUsingGet: (
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<CountryList, void>({
        path: `/${baseSiteId}/deliverycountries`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Used for product export. Depending on the timestamp parameter, it can return all products or only products modified after the given time.
     *
     * @tags Export
     * @name ExportProductsUsingGet
     * @summary Get a list of product exports.
     * @request GET:/{baseSiteId}/export/products
     * @secure
     */
    exportProductsUsingGet: (
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * The current result page requested.
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * The number of results returned per page.
         * @format int32
         * @default 20
         */
        pageSize?: number;
        /** The catalog to retrieve products from. The catalog must be provided along with the version. */
        catalog?: string;
        /** The catalog version. The catalog version must be provided along with the catalog. */
        version?: string;
        /** When this parameter is set, only products modified after the given time will be returned. This parameter should be in ISO-8601 format (for example, 2018-01-09T16:28:45+0000). */
        timestamp?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductList, void>({
        path: `/${baseSiteId}/export/products`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the orders that have changed status. Returns only the elements from the current baseSite that have been updated after the provided timestamp.
     *
     * @tags Feeds
     * @name OrderStatusFeedUsingGet
     * @summary Get a list of orders with status updates
     * @request GET:/{baseSiteId}/feeds/orders/statusfeed
     * @secure
     */
    orderStatusFeedUsingGet: (
      baseSiteId: string,
      query: {
        /** Only items newer than the given parameter are retrieved. This parameter should be in ISO-8601 format (for example, 2018-01-09T16:28:45+0000). */
        timestamp: string;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderStatusUpdateElementList, void>({
        path: `/${baseSiteId}/feeds/orders/statusfeed`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Generates a token to restore a customer's forgotten password.
     *
     * @tags Forgotten Passwords
     * @name RestorePasswordUsingPost
     * @summary Generates a token to restore a customer's forgotten password.
     * @request POST:/{baseSiteId}/forgottenpasswordtokens
     * @secure
     */
    restorePasswordUsingPost: (
      baseSiteId: string,
      query: {
        /** Customer's user id. Customer user id is case insensitive. */
        userId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/forgottenpasswordtokens`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Lists all available languages (all languages used for a particular store). If the list of languages for a base store is empty, a list of all languages available in the system will be returned.
     *
     * @tags Miscs
     * @name GetLanguagesUsingGet
     * @summary Get a list of available languages.
     * @request GET:/{baseSiteId}/languages
     * @secure
     */
    getLanguagesUsingGet: (
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<LanguageList, void>({
        path: `/${baseSiteId}/languages`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns details of a specific order based on the order GUID (Globally Unique Identifier) or the order CODE. The response contains detailed order information.
     *
     * @tags Orders
     * @name GetOrderUsingGet
     * @summary Get a order
     * @request GET:/{baseSiteId}/orders/{code}
     * @secure
     */
    getOrderUsingGet: (
      code: string,
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Order, void>({
        path: `/${baseSiteId}/orders/${code}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns products added to the express update feed. Returns only elements updated after the provided timestamp. The queue is cleared using a defined cronjob.
     *
     * @tags Products
     * @name ExpressUpdateUsingGet
     * @summary Get products added to the express update feed
     * @request GET:/{baseSiteId}/products/expressupdate
     * @secure
     */
    expressUpdateUsingGet: (
      baseSiteId: string,
      query: {
        /** Only items newer than the given parameter are retrieved from the queue. This parameter should be in ISO-8601 format. */
        timestamp: string;
        /** Only products from this catalog are returned. Format: catalogId:catalogVersion */
        catalog?: string;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductExpressUpdateElementList, void>({
        path: `/${baseSiteId}/products/expressupdate`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of products and additional data, such as available facets, available sorting, and pagination options. It can also include spelling suggestions. To make spelling suggestions work, you need to make sure that "enableSpellCheck" on the SearchQuery is set to "true" (by default, it should already be set to "true"). You also need to have indexed properties configured to be used for spellchecking.
     *
     * @tags Products
     * @name SearchProductsUsingGet
     * @summary Get a list of products and additional data
     * @request GET:/{baseSiteId}/products/search
     * @secure
     */
    searchProductsUsingGet: (
      baseSiteId: string,
      query?: {
        /** Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2 */
        query?: string;
        /**
         * The current result page requested.
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * The number of results returned per page.
         * @format int32
         * @default 20
         */
        pageSize?: number;
        /** Response configuration. This is the list of fields that should be returned in the response body. */
        sort?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * The context to be used in the search query.
         * @default "DEFAULT"
         */
        fields?: string;
        /** searchQueryContext */
        searchQueryContext?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductSearchPage, void>({
        path: `/${baseSiteId}/products/search`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description In the response header, the "x-total-count" indicates the total number of products satisfying a query.
     *
     * @tags Products
     * @name CountSearchProductsUsingHead
     * @summary Get a header with total number of products
     * @request HEAD:/{baseSiteId}/products/search
     * @secure
     */
    countSearchProductsUsingHead: (
      baseSiteId: string,
      query?: {
        /** Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2 */
        query?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/products/search`,
        method: 'HEAD',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns a list of all available suggestions related to a given term and limits the results to a specific value of the max parameter.
     *
     * @tags Products
     * @name GetSuggestionsUsingGet
     * @summary Get a list of available suggestions
     * @request GET:/{baseSiteId}/products/suggestions
     * @secure
     */
    getSuggestionsUsingGet: (
      baseSiteId: string,
      query: {
        /** Specified term */
        term: string;
        /**
         * Specifies the limit of results.
         * @format int32
         * @default 10
         */
        max: number;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<SuggestionList, void>({
        path: `/${baseSiteId}/products/suggestions`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns details of a single product according to a product code.
     *
     * @tags Products
     * @name GetProductByCodeUsingGet
     * @summary Get product details
     * @request GET:/{baseSiteId}/products/{productCode}
     * @secure
     */
    getProductByCodeUsingGet: (
      productCode: string,
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Product, void>({
        path: `/${baseSiteId}/products/${productCode}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns references for a product with a given product code. Reference type specifies which references to return.
     *
     * @tags Products
     * @name ExportProductReferencesUsingGet
     * @summary Get a product reference
     * @request GET:/{baseSiteId}/products/{productCode}/references
     * @secure
     */
    exportProductReferencesUsingGet: (
      productCode: string,
      baseSiteId: string,
      query: {
        /**
         * Maximum size of returned results.
         * @format int32
         * @default 2147483647
         */
        pageSize?: number;
        /** Reference type according to enum ProductReferenceTypeEnum */
        referenceType: string;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductReferenceList, void>({
        path: `/${baseSiteId}/products/${productCode}/references`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the reviews for a product with a given product code.
     *
     * @tags Products
     * @name GetProductReviewsUsingGet
     * @summary Get reviews for a product
     * @request GET:/{baseSiteId}/products/{productCode}/reviews
     * @secure
     */
    getProductReviewsUsingGet: (
      productCode: string,
      baseSiteId: string,
      query?: {
        /**
         * Maximum count of reviews
         * @format int32
         */
        maxCount?: number;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<ReviewList, void>({
        path: `/${baseSiteId}/products/${productCode}/reviews`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new customer review as an anonymous user.
     *
     * @tags Products
     * @name CreateReviewUsingPost1
     * @summary Creates a new customer review as an anonymous user
     * @request POST:/{baseSiteId}/products/{productCode}/reviews
     * @secure
     */
    createReviewUsingPost1: (
      productCode: string,
      baseSiteId: string,
      review: Review,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Review, void>({
        path: `/${baseSiteId}/products/${productCode}/reviews`,
        method: 'POST',
        query: query,
        body: review,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a product's stock levels sorted by distance from the specified location, which is provided using the free-text "location" parameter, or by using the longitude and latitude parameters. The following two sets of parameters are available: location (required), currentPage (optional), pageSize (optional); or longitude (required), latitude (required), currentPage (optional), pageSize(optional).
     *
     * @tags Products
     * @name SearchProductStockByLocationUsingGet
     * @summary Get a product's stock level
     * @request GET:/{baseSiteId}/products/{productCode}/stock
     * @secure
     */
    searchProductStockByLocationUsingGet: (
      productCode: string,
      baseSiteId: string,
      query?: {
        /** Free-text location */
        location?: string;
        /**
         * Latitude location parameter.
         * @format double
         */
        latitude?: number;
        /**
         * Longitude location parameter.
         * @format double
         */
        longitude?: number;
        /**
         * The current result page requested.
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * The number of results returned per page.
         * @format int32
         * @default 20
         */
        pageSize?: number;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<StoreFinderStockSearchPage, void>({
        path: `/${baseSiteId}/products/${productCode}/stock`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description In the response header, the "x-total-count" indicates the total number of a product's stock levels. The following two sets of parameters are available: location (required); or longitude (required), and latitude (required).
     *
     * @tags Products
     * @name CountSearchProductStockByLocationUsingHead
     * @summary Get header with a total number of product's stock levels
     * @request HEAD:/{baseSiteId}/products/{productCode}/stock
     * @secure
     */
    countSearchProductStockByLocationUsingHead: (
      productCode: string,
      baseSiteId: string,
      query?: {
        /** Free-text location */
        location?: string;
        /**
         * Latitude location parameter.
         * @format double
         */
        latitude?: number;
        /**
         * Longitude location parameter.
         * @format double
         */
        longitude?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/products/${productCode}/stock`,
        method: 'HEAD',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns a product's stock level for a particular store (in other words, for a particular point of sale).
     *
     * @tags Products
     * @name GetStockDataUsingGet
     * @summary Get a product's stock level for a store
     * @request GET:/{baseSiteId}/products/{productCode}/stock/{storeName}
     * @secure
     */
    getStockDataUsingGet: (
      baseSiteId: string,
      productCode: string,
      storeName: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Stock, void>({
        path: `/${baseSiteId}/products/${productCode}/stock/${storeName}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns promotions defined for a current base site. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers and therefore some of them are currently not compatible with the new promotion engine.
     *
     * @tags Promotions
     * @name GetPromotionsUsingGet1
     * @summary Get a list of promotions
     * @request GET:/{baseSiteId}/promotions
     * @secure
     */
    getPromotionsUsingGet1: (
      baseSiteId: string,
      query: {
        /** Defines what type of promotions should be returned. Values supported for that parameter are: <ul><li>all: All available promotions are returned</li><li>product: Only product promotions are returned</li><li>order: Only order promotions are returned</li></ul> */
        type: 'all' | 'product' | 'order';
        /** Only promotions from this group are returned */
        promotionGroup?: string;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "BASIC"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<PromotionList, void>({
        path: `/${baseSiteId}/promotions`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns details of a single promotion specified by a promotion code. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers and therefore some of them are currently not compatible with the new promotion engine.
     *
     * @tags Promotions
     * @name GetPromotionByCodeUsingGet
     * @summary Get a promotion based on code
     * @request GET:/{baseSiteId}/promotions/{code}
     * @secure
     */
    getPromotionByCodeUsingGet: (
      code: string,
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "BASIC"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Promotion, void>({
        path: `/${baseSiteId}/promotions/${code}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lists all store locations that are near the location specified in a query or based on latitude and longitude.
     *
     * @tags Stores
     * @name LocationSearchUsingGet
     * @summary Get a list of store locations
     * @request GET:/{baseSiteId}/stores
     * @secure
     */
    locationSearchUsingGet: (
      baseSiteId: string,
      query?: {
        /** Location in natural language i.e. city or country. */
        query?: string;
        /**
         * Coordinate that specifies the north-south position of a point on the Earth's surface.
         * @format double
         */
        latitude?: number;
        /**
         * Coordinate that specifies the east-west position of a point on the Earth's surface.
         * @format double
         */
        longitude?: number;
        /**
         * The current result page requested.
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * The number of results returned per page.
         * @format int32
         * @default 20
         */
        pageSize?: number;
        /**
         * Sorting method applied to the return results.
         * @default "asc"
         */
        sort?: string;
        /**
         * Radius in meters. Max value: 40075000.0 (Earth's perimeter).
         * @format double
         * @default 100000
         */
        radius?: number;
        /**
         * Accuracy in meters.
         * @format double
         * @default 0
         */
        accuracy?: number;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<StoreFinderSearchPage, void>({
        path: `/${baseSiteId}/stores`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description In the response header, the "x-total-count" indicates the number of all store locations that are near the location specified in a query, or based on latitude and longitude.
     *
     * @tags Stores
     * @name CountLocationSearchUsingHead
     * @summary Get a header with the number of store locations
     * @request HEAD:/{baseSiteId}/stores
     * @secure
     */
    countLocationSearchUsingHead: (
      baseSiteId: string,
      query?: {
        /** Location in natural language i.e. city or country. */
        query?: string;
        /**
         * Coordinate that specifies the north-south position of a point on the Earth's surface.
         * @format double
         */
        latitude?: number;
        /**
         * Coordinate that specifies the east-west position of a point on the Earth's surface.
         * @format double
         */
        longitude?: number;
        /**
         * Radius in meters. Max value: 40075000.0 (Earth's perimeter).
         * @format double
         * @default 100000
         */
        radius?: number;
        /**
         * Accuracy in meters.
         * @format double
         * @default 0
         */
        accuracy?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/stores`,
        method: 'HEAD',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns store location based on its unique name.
     *
     * @tags Stores
     * @name LocationDetailsUsingGet
     * @summary Get a store location
     * @request GET:/{baseSiteId}/stores/{storeId}
     * @secure
     */
    locationDetailsUsingGet: (
      storeId: string,
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<PointOfService, void>({
        path: `/${baseSiteId}/stores/${storeId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lists all localized titles.
     *
     * @tags Miscs
     * @name GetTitlesUsingGet
     * @summary Get a list of all localized titles.
     * @request GET:/{baseSiteId}/titles
     * @secure
     */
    getTitlesUsingGet: (
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<TitleList, void>({
        path: `/${baseSiteId}/titles`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Registers a customer. There are two options for registering a customer. The first option requires the following parameters: login, password, firstName, lastName, titleCode. The second option converts a guest to a customer. In this case, the required parameters are: guid, password.
     *
     * @tags Users
     * @name RegisterUserUsingPost1
     * @summary  Registers a customer
     * @request POST:/{baseSiteId}/users
     * @secure
     */
    registerUserUsingPost1: (
      baseSiteId: string,
      user: UserSignUp,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<User, void>({
        path: `/${baseSiteId}/users`,
        method: 'POST',
        query: query,
        body: user,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns customer profile.
     *
     * @tags Users
     * @name GetUserUsingGet
     * @summary Get customer profile
     * @request GET:/{baseSiteId}/users/{userId}
     * @secure
     */
    getUserUsingGet: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<User, void>({
        path: `/${baseSiteId}/users/${userId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates customer profile. Attributes not provided in the request body will be defined again (set to null or default).
     *
     * @tags Users
     * @name PutUserUsingPut
     * @summary Updates customer profile
     * @request PUT:/{baseSiteId}/users/{userId}
     * @secure
     */
    putUserUsingPut: (baseSiteId: string, userId: string, user: User, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}`,
        method: 'PUT',
        body: user,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes customer profile.
     *
     * @tags Users
     * @name DeactivateUserUsingDelete
     * @summary Delete customer profile
     * @request DELETE:/{baseSiteId}/users/{userId}
     * @secure
     */
    deactivateUserUsingDelete: (baseSiteId: string, userId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates customer profile. Only attributes provided in the request body will be changed.
     *
     * @tags Users
     * @name UpdateUserUsingPatch1
     * @summary Updates customer profile
     * @request PATCH:/{baseSiteId}/users/{userId}
     * @secure
     */
    updateUserUsingPatch1: (baseSiteId: string, userId: string, user: User, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}`,
        method: 'PATCH',
        body: user,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns customer's addresses.
     *
     * @tags Users
     * @name GetAddressesUsingGet
     * @summary Get customer's addresses
     * @request GET:/{baseSiteId}/users/{userId}/addresses
     * @secure
     */
    getAddressesUsingGet: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<AddressList, void>({
        path: `/${baseSiteId}/users/${userId}/addresses`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new address.
     *
     * @tags Users
     * @name CreateAddressUsingPost
     * @summary Creates a new address.
     * @request POST:/{baseSiteId}/users/{userId}/addresses
     * @secure
     */
    createAddressUsingPost: (
      baseSiteId: string,
      userId: string,
      address: Address,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Address, void>({
        path: `/${baseSiteId}/users/${userId}/addresses`,
        method: 'POST',
        query: query,
        body: address,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Verifies address.
     *
     * @tags Users
     * @name VerifyAddressUsingPost1
     * @summary Verifies address
     * @request POST:/{baseSiteId}/users/{userId}/addresses/verification
     * @secure
     */
    verifyAddressUsingPost1: (
      baseSiteId: string,
      userId: string,
      address: Address,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<AddressValidation, void>({
        path: `/${baseSiteId}/users/${userId}/addresses/verification`,
        method: 'POST',
        query: query,
        body: address,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detailed information about address with a given id.
     *
     * @tags Users
     * @name GetAddressUsingGet
     * @summary Get info about address
     * @request GET:/{baseSiteId}/users/{userId}/addresses/{addressId}
     * @secure
     */
    getAddressUsingGet: (
      addressId: string,
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Address, void>({
        path: `/${baseSiteId}/users/${userId}/addresses/${addressId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the address. Attributes not provided in the request will be defined again (set to null or default).
     *
     * @tags Users
     * @name PutAddressUsingPut
     * @summary Updates the address
     * @request PUT:/{baseSiteId}/users/{userId}/addresses/{addressId}
     * @secure
     */
    putAddressUsingPut: (
      addressId: string,
      baseSiteId: string,
      userId: string,
      address: Address,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/addresses/${addressId}`,
        method: 'PUT',
        body: address,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes customer's address.
     *
     * @tags Users
     * @name DeleteAddressUsingDelete
     * @summary Delete customer's address
     * @request DELETE:/{baseSiteId}/users/{userId}/addresses/{addressId}
     * @secure
     */
    deleteAddressUsingDelete: (addressId: string, baseSiteId: string, userId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/addresses/${addressId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates the address. Only attributes provided in the request body will be changed.
     *
     * @tags Users
     * @name PatchAddressUsingPatch
     * @summary Updates the address
     * @request PATCH:/{baseSiteId}/users/{userId}/addresses/{addressId}
     * @secure
     */
    patchAddressUsingPatch: (
      addressId: string,
      baseSiteId: string,
      userId: string,
      address: Address,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/addresses/${addressId}`,
        method: 'PATCH',
        body: address,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Lists all customer carts.
     *
     * @tags Carts
     * @name GetCartsUsingGet
     * @summary Get all customer carts.
     * @request GET:/{baseSiteId}/users/{userId}/carts
     * @secure
     */
    getCartsUsingGet: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Optional parameter. If the parameter is provided and its value is true, only saved carts are returned.
         * @default false
         */
        savedCartsOnly?: boolean;
        /**
         * Optional pagination parameter in case of savedCartsOnly == true. Default value 0.
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * Optional {@link PaginationData} parameter in case of savedCartsOnly == true. Default value 20.
         * @format int32
         * @default 20
         */
        pageSize?: number;
        /** Optional sort criterion in case of savedCartsOnly == true. No default value. */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CartList, void>({
        path: `/${baseSiteId}/users/${userId}/carts`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new cart or restores an anonymous cart as a user's cart (if an old Cart Id is given in the request).
     *
     * @tags Carts
     * @name CreateCartUsingPost
     * @summary Creates or restore a cart for a user.
     * @request POST:/{baseSiteId}/users/{userId}/carts
     * @secure
     */
    createCartUsingPost: (
      baseSiteId: string,
      userId: string,
      query?: {
        /** Anonymous cart GUID. */
        oldCartId?: string;
        /** The GUID of the user's cart that will be merged with the anonymous cart. */
        toMergeCartGuid?: string;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Cart, void>({
        path: `/${baseSiteId}/users/${userId}/carts`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the cart with a given identifier.
     *
     * @tags Carts
     * @name GetCartUsingGet
     * @summary Get a cart with a given identifier.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}
     * @secure
     */
    getCartUsingGet: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Cart, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a cart with a given cart id.
     *
     * @tags Carts
     * @name DeleteCartUsingDelete
     * @summary Deletes a cart with a given cart id.
     * @request DELETE:/{baseSiteId}/users/{userId}/carts/{cartId}
     * @secure
     */
    deleteCartUsingDelete: (baseSiteId: string, userId: string, cartId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Creates an address and assigns it to the cart as the delivery address.
     *
     * @tags Carts
     * @name CreateAndSetAddressUsingPost1
     * @summary Creates a delivery address for the cart.
     * @request POST:/{baseSiteId}/users/{userId}/carts/{cartId}/addresses/delivery
     * @secure
     */
    createAndSetAddressUsingPost1: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      address: Address,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Address, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/addresses/delivery`,
        method: 'POST',
        query: query,
        body: address,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets a delivery address for the cart. The address country must be placed among the delivery countries of the current base store.
     *
     * @tags Carts
     * @name SetCartDeliveryAddressUsingPut
     * @summary Sets a delivery address for the cart.
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/addresses/delivery
     * @secure
     */
    setCartDeliveryAddressUsingPut: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query: {
        /** Address identifier */
        addressId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/addresses/delivery`,
        method: 'PUT',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes the delivery address from the cart.
     *
     * @tags Carts
     * @name RemoveCartDeliveryAddressUsingDelete
     * @summary Delete the delivery address from the cart.
     * @request DELETE:/{baseSiteId}/users/{userId}/carts/{cartId}/addresses/delivery
     * @secure
     */
    removeCartDeliveryAddressUsingDelete: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/addresses/delivery`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Explicitly clones a cart.
     *
     * @tags Save Cart
     * @name CloneSaveCartUsingPost
     * @summary Explicitly clones a cart.
     * @request POST:/{baseSiteId}/users/{userId}/carts/{cartId}/clonesavedcart
     * @secure
     */
    cloneSaveCartUsingPost: (
      cartId: string,
      baseSiteId: string,
      userId: string,
      query?: {
        /** The name that should be applied to the cloned cart. */
        name?: string;
        /** The description that should be applied to the cloned cart. */
        description?: string;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<SaveCartResult, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/clonesavedcart`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the delivery mode selected for the cart.
     *
     * @tags Carts
     * @name GetCartDeliveryModeUsingGet
     * @summary Get the delivery mode selected for the cart.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/deliverymode
     * @secure
     */
    getCartDeliveryModeUsingGet: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<DeliveryMode, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/deliverymode`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets the delivery mode with a given identifier for the cart.
     *
     * @tags Carts
     * @name SetCartDeliveryModeUsingPut
     * @summary Sets the delivery mode for a cart.
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/deliverymode
     * @secure
     */
    setCartDeliveryModeUsingPut: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query: {
        /** Delivery mode identifier (code) */
        deliveryModeId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/deliverymode`,
        method: 'PUT',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes the delivery mode from the cart.
     *
     * @tags Carts
     * @name RemoveDeliveryModeUsingDelete
     * @summary Delete the delivery mode from the cart.
     * @request DELETE:/{baseSiteId}/users/{userId}/carts/{cartId}/deliverymode
     * @secure
     */
    removeDeliveryModeUsingDelete: (baseSiteId: string, userId: string, cartId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/deliverymode`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns all delivery modes supported for the current base store and cart delivery address. A delivery address must be set for the cart, otherwise an empty list will be returned.
     *
     * @tags Carts
     * @name GetSupportedDeliveryModesUsingGet
     * @summary Get all delivery modes for the current store and delivery address.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/deliverymodes
     * @secure
     */
    getSupportedDeliveryModesUsingGet: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<DeliveryModeList, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/deliverymodes`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Assigns an email to the cart. This step is required to make a guest checkout.
     *
     * @tags Carts
     * @name GuestLoginUsingPut
     * @summary Assigns an email to the cart.
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/email
     * @secure
     */
    guestLoginUsingPut: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query: {
        /** Email of the guest user. It will be used during the checkout process. */
        email: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/email`,
        method: 'PUT',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns cart entries.
     *
     * @tags Carts
     * @name GetCartEntriesUsingGet
     * @summary Get cart entries.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/entries
     * @secure
     */
    getCartEntriesUsingGet: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderEntryList, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds a product to the cart.
     *
     * @tags Carts
     * @name AddCartEntryUsingPost
     * @summary Adds a product to the cart.
     * @request POST:/{baseSiteId}/users/{userId}/carts/{cartId}/entries
     * @secure
     */
    addCartEntryUsingPost: (
      userId: string,
      cartId: string,
      baseSiteId: string,
      entry: OrderEntry,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<CartModification, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries`,
        method: 'POST',
        query: query,
        body: entry,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of the cart entries.
     *
     * @tags Carts
     * @name GetCartEntryUsingGet
     * @summary Get the details of the cart entries.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}
     * @secure
     */
    getCartEntryUsingGet: (
      entryNumber: number,
      baseSiteId: string,
      userId: string,
      cartId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderEntry, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries/${entryNumber}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the quantity of a single cart entry and the details of the store where the cart entry will be picked up. Attributes not provided in request will be defined again (set to null or default)
     *
     * @tags Carts
     * @name SetCartEntryUsingPut1
     * @summary Set quantity and store details of a cart entry.
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}
     * @secure
     */
    setCartEntryUsingPut1: (
      entryNumber: number,
      userId: string,
      cartId: string,
      baseSiteId: string,
      entry: OrderEntry,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<CartModification, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries/${entryNumber}`,
        method: 'PUT',
        query: query,
        body: entry,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes cart entry.
     *
     * @tags Carts
     * @name RemoveCartEntryUsingDelete
     * @summary Deletes cart entry.
     * @request DELETE:/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}
     * @secure
     */
    removeCartEntryUsingDelete: (
      entryNumber: number,
      baseSiteId: string,
      userId: string,
      cartId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries/${entryNumber}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates the quantity of a single cart entry and the details of the store where the cart entry will be picked up.
     *
     * @tags Carts
     * @name UpdateCartEntryUsingPatch1
     * @summary Update quantity and store details of a cart entry.
     * @request PATCH:/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}
     * @secure
     */
    updateCartEntryUsingPatch1: (
      entryNumber: number,
      userId: string,
      cartId: string,
      baseSiteId: string,
      entry: OrderEntry,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<CartModification, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries/${entryNumber}`,
        method: 'PATCH',
        query: query,
        body: entry,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Flags a cart for deletion (the cart doesn't have corresponding save cart attributes anymore). The cart is not actually deleted from the database. But with the removal of the saved cart attributes, this cart will be taken care of by the cart removal job just like any other cart.
     *
     * @tags Save Cart
     * @name FlagForDeletionUsingPatch
     * @summary Flag a cart for deletion.
     * @request PATCH:/{baseSiteId}/users/{userId}/carts/{cartId}/flagForDeletion
     * @secure
     */
    flagForDeletionUsingPatch: (
      cartId: string,
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<SaveCartResult, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/flagForDeletion`,
        method: 'PATCH',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Defines the details of a new credit card, and assigns this payment option to the cart.
     *
     * @tags Carts
     * @name AddPaymentDetailsUsingPost
     * @summary Defines and assigns details of a new credit card payment to the cart.
     * @request POST:/{baseSiteId}/users/{userId}/carts/{cartId}/paymentdetails
     * @secure
     */
    addPaymentDetailsUsingPost: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      paymentDetails: PaymentDetails,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<PaymentDetails, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/paymentdetails`,
        method: 'POST',
        query: query,
        body: paymentDetails,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets credit card payment details for the cart.
     *
     * @tags Carts
     * @name SetPaymentDetailsUsingPut
     * @summary Sets credit card payment details for the cart.
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/paymentdetails
     * @secure
     */
    setPaymentDetailsUsingPut: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query: {
        /** Payment details identifier. */
        paymentDetailsId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/paymentdetails`,
        method: 'PUT',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns information about the promotions applied on the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
     *
     * @tags Carts
     * @name GetPromotionsUsingGet
     * @summary Get information about promotions applied on cart.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/promotions
     * @secure
     */
    getPromotionsUsingGet: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<PromotionResultList, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/promotions`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Enables a promotion for the order based on the promotionId defined for the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
     *
     * @tags Carts
     * @name ApplyPromotionUsingPost
     * @summary Enables promotions based on the promotionsId of the cart.
     * @request POST:/{baseSiteId}/users/{userId}/carts/{cartId}/promotions
     * @secure
     */
    applyPromotionUsingPost: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query: {
        /** Promotion identifier (code) */
        promotionId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/promotions`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns information about a promotion (with a specific promotionId), that has been applied on the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
     *
     * @tags Carts
     * @name GetPromotionUsingGet
     * @summary Get information about promotions applied on cart.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/promotions/{promotionId}
     * @secure
     */
    getPromotionUsingGet: (
      promotionId: string,
      baseSiteId: string,
      userId: string,
      cartId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<PromotionResultList, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/promotions/${promotionId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Disables the promotion for the order based on the promotionId defined for the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
     *
     * @tags Carts
     * @name RemovePromotionUsingDelete
     * @summary Disables the promotion based on the promotionsId of the cart.
     * @request DELETE:/{baseSiteId}/users/{userId}/carts/{cartId}/promotions/{promotionId}
     * @secure
     */
    removePromotionUsingDelete: (
      promotionId: string,
      baseSiteId: string,
      userId: string,
      cartId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/promotions/${promotionId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Restore a saved cart.
     *
     * @tags Save Cart
     * @name RestoreSavedCartUsingPatch
     * @summary Restore a saved cart.
     * @request PATCH:/{baseSiteId}/users/{userId}/carts/{cartId}/restoresavedcart
     * @secure
     */
    restoreSavedCartUsingPatch: (
      cartId: string,
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<SaveCartResult, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/restoresavedcart`,
        method: 'PATCH',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Explicitly saves a cart.
     *
     * @tags Save Cart
     * @name SaveCartUsingPatch
     * @summary Explicitly saves a cart.
     * @request PATCH:/{baseSiteId}/users/{userId}/carts/{cartId}/save
     * @secure
     */
    saveCartUsingPatch: (
      cartId: string,
      baseSiteId: string,
      userId: string,
      query?: {
        /** The name that should be applied to the saved cart. */
        saveCartName?: string;
        /** The description that should be applied to the saved cart. */
        saveCartDescription?: string;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<SaveCartResult, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/save`,
        method: 'PATCH',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a saved cart for an authenticated user. The cart is identified using the "cartId" parameter.
     *
     * @tags Save Cart
     * @name GetSavedCartUsingGet
     * @summary Get a saved cart.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/savedcart
     * @secure
     */
    getSavedCartUsingGet: (
      cartId: string,
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<SaveCartResult, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/savedcart`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of vouchers applied to the cart.
     *
     * @tags Carts
     * @name GetVouchersUsingGet
     * @summary Get a list of vouchers applied to the cart.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/vouchers
     * @secure
     */
    getVouchersUsingGet: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<VoucherList, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/vouchers`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Applies a voucher based on the voucherId defined for the cart.
     *
     * @tags Carts
     * @name ApplyVoucherForCartUsingPost
     * @summary Applies a voucher based on the voucherId defined for the cart.
     * @request POST:/{baseSiteId}/users/{userId}/carts/{cartId}/vouchers
     * @secure
     */
    applyVoucherForCartUsingPost: (
      baseSiteId: string,
      userId: string,
      cartId: string,
      query: {
        /** Voucher identifier (code) */
        voucherId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/vouchers`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes a voucher based on the voucherId defined for the current cart.
     *
     * @tags Carts
     * @name ReleaseVoucherFromCartUsingDelete
     * @summary Delete a voucher defined for the current cart.
     * @request DELETE:/{baseSiteId}/users/{userId}/carts/{cartId}/vouchers/{voucherId}
     * @secure
     */
    releaseVoucherFromCartUsingDelete: (
      voucherId: string,
      baseSiteId: string,
      userId: string,
      cartId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/vouchers/${voucherId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns all customer groups of a customer.
     *
     * @tags Users
     * @name GetAllCustomerGroupsForCustomerUsingGet
     * @summary Get all customer groups of a customer.
     * @request GET:/{baseSiteId}/users/{userId}/customergroups
     * @secure
     */
    getAllCustomerGroupsForCustomerUsingGet: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<UserGroupList, void>({
        path: `/${baseSiteId}/users/${userId}/customergroups`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Changes a customer's login name. Requires the customer's current password.
     *
     * @tags Users
     * @name ChangeLoginUsingPut
     * @summary Changes customer's login name.
     * @request PUT:/{baseSiteId}/users/{userId}/login
     * @secure
     */
    changeLoginUsingPut: (
      baseSiteId: string,
      userId: string,
      query: {
        /** Customer's new login name. Customer login is case insensitive. */
        newLogin: string;
        /** Customer's current password. */
        password: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/login`,
        method: 'PUT',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns order history data for all orders placed by a specified user for a specified base store. The response can display the results across multiple pages, if required.
     *
     * @tags Orders
     * @name GetOrdersForUserUsingGet
     * @summary Get order history for user
     * @request GET:/{baseSiteId}/users/{userId}/orders
     * @secure
     */
    getOrdersForUserUsingGet: (
      baseSiteId: string,
      userId: string,
      query?: {
        /** Filters only certain order statuses. For example, statuses=CANCELLED,CHECKED_VALID would only return orders with status CANCELLED or CHECKED_VALID. */
        statuses?: string;
        /**
         * The current result page requested.
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * The number of results returned per page.
         * @format int32
         * @default 20
         */
        pageSize?: number;
        /** Sorting method applied to the return results. */
        sort?: string;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderHistoryList, void>({
        path: `/${baseSiteId}/users/${userId}/orders`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description In the response header, the "x-total-count" indicates the total number of orders placed by a specified user for a specified base store.
     *
     * @tags Orders
     * @name GetCountOrdersForUserUsingHead
     * @summary Get total number of orders
     * @request HEAD:/{baseSiteId}/users/{userId}/orders
     * @secure
     */
    getCountOrdersForUserUsingHead: (
      baseSiteId: string,
      userId: string,
      query?: {
        /** Filters only certain order statuses. For example, statuses=CANCELLED,CHECKED_VALID would only return orders with status CANCELLED or CHECKED_VALID. */
        statuses?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/orders`,
        method: 'HEAD',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorizes the cart and places the order. The response contains the new order data.
     *
     * @tags Orders
     * @name PlaceOrderUsingPost
     * @summary Post a order
     * @request POST:/{baseSiteId}/users/{userId}/orders
     * @secure
     */
    placeOrderUsingPost: (
      baseSiteId: string,
      userId: string,
      query: {
        /** Cart code for logged in user, cart GUID for guest checkout */
        cartId: string;
        /** CCV security code. */
        securityCode?: string;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Order, void>({
        path: `/${baseSiteId}/users/${userId}/orders`,
        method: 'POST',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns specific order details based on a specific order code. The response contains detailed order information.
     *
     * @tags Orders
     * @name GetOrderForUserByCodeUsingGet
     * @summary Get a order
     * @request GET:/{baseSiteId}/users/{userId}/orders/{code}
     * @secure
     */
    getOrderForUserByCodeUsingGet: (
      code: string,
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Order, void>({
        path: `/${baseSiteId}/users/${userId}/orders/${code}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Changes customer's password.
     *
     * @tags Users
     * @name ChangePasswordUsingPut
     * @summary Changes customer's password
     * @request PUT:/{baseSiteId}/users/{userId}/password
     * @secure
     */
    changePasswordUsingPut: (
      baseSiteId: string,
      userId: string,
      query: {
        /** Old password. Required only for ROLE_CUSTOMERGROUP */
        old?: string;
        /** New password. */
        new: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/password`,
        method: 'PUT',
        query: query,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Return customer's credit card payment details list.
     *
     * @tags Users
     * @name GetPaymentInfosUsingGet
     * @summary Get customer's credit card payment details list.
     * @request GET:/{baseSiteId}/users/{userId}/paymentdetails
     * @secure
     */
    getPaymentInfosUsingGet: (
      baseSiteId: string,
      userId: string,
      query: {
        /**
         * Type of payment details.
         * @default false
         */
        saved: boolean;
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<PaymentDetailsList, void>({
        path: `/${baseSiteId}/users/${userId}/paymentdetails`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a customer's credit card payment details for the specified paymentDetailsId.
     *
     * @tags Users
     * @name GetPaymentDetailsUsingGet
     * @summary Get customer's credit card payment details.
     * @request GET:/{baseSiteId}/users/{userId}/paymentdetails/{paymentDetailsId}
     * @secure
     */
    getPaymentDetailsUsingGet: (
      paymentDetailsId: string,
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<PaymentDetails, void>({
        path: `/${baseSiteId}/users/${userId}/paymentdetails/${paymentDetailsId}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates existing customer's credit card payment info based on the payment info ID. Attributes not given in request will be defined again (set to null or default).
     *
     * @tags Users
     * @name PutPaymentInfoUsingPut1
     * @summary Updates existing customer's credit card payment info.
     * @request PUT:/{baseSiteId}/users/{userId}/paymentdetails/{paymentDetailsId}
     * @secure
     */
    putPaymentInfoUsingPut1: (
      paymentDetailsId: string,
      baseSiteId: string,
      userId: string,
      paymentDetails: PaymentDetails,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/paymentdetails/${paymentDetailsId}`,
        method: 'PUT',
        body: paymentDetails,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes a customer's credit card payment details based on a specified paymentDetailsId.
     *
     * @tags Users
     * @name DeletePaymentInfoUsingDelete
     * @summary Delete customer's credit card payment details.
     * @request DELETE:/{baseSiteId}/users/{userId}/paymentdetails/{paymentDetailsId}
     * @secure
     */
    deletePaymentInfoUsingDelete: (
      paymentDetailsId: string,
      baseSiteId: string,
      userId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/paymentdetails/${paymentDetailsId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates an existing customer's credit card payment details based on the specified paymentDetailsId. Only those attributes provided in the request will be updated.
     *
     * @tags Users
     * @name UpdatePaymentInfoUsingPatch
     * @summary Updates existing customer's credit card payment details.
     * @request PATCH:/{baseSiteId}/users/{userId}/paymentdetails/{paymentDetailsId}
     * @secure
     */
    updatePaymentInfoUsingPatch: (
      paymentDetailsId: string,
      baseSiteId: string,
      userId: string,
      paymentDetails: PaymentDetails,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/${baseSiteId}/users/${userId}/paymentdetails/${paymentDetailsId}`,
        method: 'PATCH',
        body: paymentDetails,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns details of a single voucher that is specified by its voucher identification code.
     *
     * @tags Vouchers
     * @name GetVoucherByCodeUsingGet
     * @summary Get a voucher based on code
     * @request GET:/{baseSiteId}/vouchers/{code}
     * @secure
     */
    getVoucherByCodeUsingGet: (
      code: string,
      baseSiteId: string,
      query?: {
        /**
         * Response configuration. This is the list of fields that should be returned in the response body.
         * @default "BASIC"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {}
    ) =>
      this.request<Voucher, void>({
        path: `/${baseSiteId}/vouchers/${code}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
