import Config from '@config';
import { isOfflineProduct, isOnlineProduct } from '@helpers/typeGuards/product';
import type {
  AxfoodBasicProductViewModel,
  AxfoodOfflineProductViewModel,
  AxfoodOrderProductViewModel,
  AxfoodProductDetailsViewModel,
} from '@api/storeFrontApi';

export default function getQuantity(item: any): number {
  return parseFloat(
    String(item.pickQuantity > 0 ? item.pickQuantity : item.quantity > 0 ? item.quantity : item.qty > 0 ? item.qty : 0)
  );
}

// TODO Check product type
export const sumProductQuantities = (products: Array<any>): number => {
  return products.reduce((sum, productInCart) => {
    const quantity = getQuantity(productInCart);
    if (
      productInCart.pickUnit === 'pieces' ||
      (Object.hasOwnProperty.call(productInCart, 'productBasketType') && productInCart.productBasketType.code === 'ST')
    ) {
      return sum + quantity;
    }
    if (quantity > 0) {
      // weight units count as 1, as long as quantity > 0
      return sum + 1;
    }
    return sum;
  }, 0);
};

export const getValidProductLabels = (
  product:
    | AxfoodProductDetailsViewModel
    | AxfoodOfflineProductViewModel
    | AxfoodOrderProductViewModel
    | AxfoodBasicProductViewModel,
  limit: number = product?.labels?.length || 0
): Array<string> => {
  const labels = [...(product?.labels || [])];

  if (isOnlineProduct(product) && product?.isDrugProduct && !product?.nicotineMedicalProduct) {
    labels?.unshift('medicine-label');
  }

  return labels
    ?.filter((label: string) => !Config.EXCLUDED_LABELS.includes(label) && !label.endsWith('_facet'))
    .slice(0, limit);
};

export const getPdpAgeRestrictionImage = (
  product: AxfoodProductDetailsViewModel | AxfoodOfflineProductViewModel
): string => {
  if (isOfflineProduct(product)) {
    return '';
  }
  const labels = getValidProductLabels(product, 100);

  if (!labels.includes('age_restricted_18')) return '';

  if (product.tobaccoFreeNicotineProduct) {
    return 'age_restricted_18_nicotine_snuff.svg';
  } else if (product.tobaccoProduct) {
    return 'age_restricted_18_tobacco.svg';
  } else {
    return 'age_restricted_18.svg';
  }
};
