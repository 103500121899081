import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { setIsUpdatingCart } from '@slices/cartUpdatingSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useSnackBar from '@hooks/useSnackbar';
import useTranslation from 'next-translate/useTranslation';
import Config from '@config';
import {
  trackCancelRemoveFromCart,
  trackCancelRemoveFromCartShown,
} from '@helpers/analyticsHelpers/trackRemoveFromCart';
import type { AxfoodCartProductViewModel } from '@api/storeFrontApi';
import useCartActions from '@hooks/useCartActions';

export interface AddToCartItem {
  product: AxfoodCartProductViewModel;
  newQuantity: number;
  prevQuantity: number;
  listName: string;
  variant?: QuantityInputFieldType;
}

export interface AddProductQueueState {
  cartQueue: {
    [key: string]: AddToCartItem;
  };
}

export type AddToCartCallback = (item: AddToCartItem) => void;
export type CancelRemoveFromCartCallback = (productCode: string) => void;

export const DEFAULT_STATE: AddProductQueueState = {
  cartQueue: {},
};

const initialContext = {
  addToCart: () => {
    /**/
  },
  cancelRemoveFromCart: () => {
    /**/
  },
  itemsToBeRemoved: [],
};

export const AddProductQueueContext = React.createContext<{
  addToCart: AddToCartCallback;
  cancelRemoveFromCart: CancelRemoveFromCartCallback;
  itemsToBeRemoved: string[];
}>(initialContext);

export interface AddProductQueueProvider {
  children: React.ReactNode;
}

export const AddProductQueueProvider = ({ children }: AddProductQueueProvider) => {
  const [cartQueue, addToCartQueue] = useState(() => DEFAULT_STATE.cartQueue);
  const [localVariant, setLocalVariant] = useState('cart');
  const { postToCart } = useCartActions();
  const clearCartQueue = useCallback(() => addToCartQueue({}), []);
  const postAddProductTimeout = useRef<NodeJS.Timeout>();
  const itemsToBeRemoved = useMemo(
    () =>
      Object.values(cartQueue)
        .filter((item) => item.newQuantity === 0)
        .map((item) => item.product.code),
    [cartQueue]
  );

  const dispatch = useAppDispatch();
  const setSnack = useSnackBar();
  const { t } = useTranslation('productCard');

  const postAddProduct = useCallback(() => {
    postToCart(Object.values(cartQueue))
      .catch(() => {
        setSnack({ text: t('quantityInput->error->message'), icon: null });
      })
      .finally(() => {
        dispatch(setIsUpdatingCart(false));
        clearCartQueue();
      });
  }, [cartQueue]);

  useEffect(() => {
    if (Object.keys(cartQueue).length > 0) {
      const hasItemToBeRemoved = Object.values(cartQueue).some((item) => item.newQuantity === 0);
      postAddProductTimeout.current = setTimeout(
        postAddProduct,
        hasItemToBeRemoved && localVariant === 'cart'
          ? Config.TIMEOUT.removeProductQueueMs
          : Config.TIMEOUT.addProductQueueMs
      );
      if (hasItemToBeRemoved && localVariant === 'cart') {
        trackCancelRemoveFromCartShown();
      }
    }
    return () => {
      if (postAddProductTimeout.current) clearTimeout(postAddProductTimeout.current);
    };
  }, [cartQueue, postAddProductTimeout.current]);

  const cancelRemoveFromCart = (productCode: string) => {
    trackCancelRemoveFromCart();
    addToCartQueue((prevState) => {
      const queue = { ...prevState };
      delete queue[productCode];
      return queue;
    });
  };

  const addToCart = useCallback<AddToCartCallback>(
    ({ product, newQuantity, prevQuantity, listName, variant }) => {
      if (variant) setLocalVariant(variant);
      if (postAddProductTimeout.current) clearTimeout(postAddProductTimeout.current);
      addToCartQueue({
        ...cartQueue,
        [product.code]: {
          product,
          newQuantity,
          prevQuantity,
          listName,
        },
      });
    },
    [cartQueue, addToCartQueue]
  );

  return (
    <AddProductQueueContext.Provider
      value={{
        addToCart,
        cancelRemoveFromCart,
        itemsToBeRemoved,
      }}
    >
      {children}
    </AddProductQueueContext.Provider>
  );
};

export default () => useContext(AddProductQueueContext);
