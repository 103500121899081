import Config from '@config';
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { searchStores } from '@api/interfaces/storeApi';
import debounce from '@helpers/debounce';
import PickupLocationListItem from '@molecules/Autocomplete/ListItems/PickupLocationListItem';
import useAvailableStores from '@hooks/useAvailableStores';
import Geolocation from '@helpers/geolocation/geolocation';
import Modal from '@organisms/Modal/Modal';
import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';
import {
  StyledEmptySlot,
  StyledEmptySlotContainer,
  StyledEmptySlotHead,
} from '@molecules/PickupInStoreSearch/PickUpInStoreSearch.styles';
import Link from '@atoms/Link/Link';
import paths from '@constants/paths';
import useTranslation from 'next-translate/useTranslation';
import Icon from '@atoms/Icon/Icon';
import {
  trackDisabledLocationServices,
  trackFindClosestStore,
  trackMapClick,
  trackStoreFinderSearchField,
  trackStoreSuggestionClick,
  trackStoreSuggestionsEmpty,
  trackViewAllStoresList,
} from '@helpers/analyticsHelpers/trackStoreFinder';
import KEYS from '@helpers/keys';
import {
  StyledAutocomplete,
  StyledButton,
  StyledInputField,
  StyledMap,
  StyledStoreFinder,
  StyledStoreFinderFieldWrapper,
  StyledStoreFinderForm,
} from './StoreFinder.styles';
import Takeover from '@molecules/Takeover/Takeover';
import useResponsive from '@hooks/useResponsive';
import useFreezeBodyScroll from '@hooks/useFreezeBodyScroll';
import { AxfoodStoreInfoViewModel, GeoPoint, SortData } from '@api/generated/storefront';
import useConfig from '@hooks/useConfig';
import dynamic from 'next/dynamic';
import Location from '@icons/location.svg';

const DynamicMap = dynamic(
  () => import('@molecules/StoreFinderMap/StoreFinderMap' /* webpackChunkName 'StoreFinderMap' */)
);

// Stockholm
const defaultLocation: GeoPoint = {
  latitude: 59.32903445070856,
  longitude: 18.061227888571498,
};

const StoreFinder = () => {
  const { t } = useTranslation('storefinder');
  const inputRef = useRef(null);
  const { isMobile } = useResponsive();
  const autoCompleteRef = useRef<HTMLDivElement>(null);
  const [isFindingNearest, setIsFindingNearest] = useState(false);
  const [formData, setFormData] = useState({ store: '' });
  const [zoom, setZoom] = useState<number>(5);
  const { allStores } = useAvailableStores();
  const [selectedStore, setSelectedStore] = useState<AxfoodStoreInfoViewModel | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<GeoPoint>(defaultLocation);
  const [storeSuggestions, setStoreSuggestions] = useState<Array<SortData>>([]);
  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(false);
  const [showGeolocationErrorModal, setShowGeolocationErrorModal] = useState(false);
  const [takeoverVisible, setTakeoverVisible] = useState<boolean>(false);
  const { config } = useConfig();

  const findStore = () => {
    const GeoLocation = Geolocation();
    setIsFindingNearest(true);
    trackFindClosestStore();
    if (allStores?.length) {
      GeoLocation.getMyPosition(
        (latitude: number, longitude: number) => {
          const myCurrentLocation = { latitude, longitude };
          let dist = -1;
          let foundStore = null;
          for (const element of allStores) {
            const storeDist = GeoLocation.compareDistance(
              element.geoPoint?.latitude ?? 0,
              element.geoPoint?.longitude ?? 0,
              myCurrentLocation
            );
            if (dist === -1 || dist > storeDist) {
              dist = storeDist;
              foundStore = element;
            }
          }
          if (foundStore) {
            setZoom(14);
            setSelectedStore(foundStore);
            setIsFindingNearest(false);
            setFormData({ ...formData, store: foundStore.name || '' });
          }
        },
        () => {
          trackDisabledLocationServices();
          setShowGeolocationErrorModal(true);
          setIsFindingNearest(false);
        }
      );
    }
  };

  const onSearch = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2) {
      const res = await searchStores(e.target.value, 'score-desc');
      setStoreSuggestions(res?.results ?? []);
      setShouldShowSuggestions(true);
      if (!res.results) {
        trackStoreSuggestionsEmpty();
      }
    } else {
      setShouldShowSuggestions(false);
    }
  };

  const onListItemClick = (_a: any, _b: any, _c: any, store: AxfoodStoreInfoViewModel) => {
    setTakeoverVisible(false);
    setZoom(14);
    setSelectedStore(store);
    setShouldShowSuggestions(false);
    setFormData({ ...formData, store: store.name || '' });
    trackStoreSuggestionClick(store);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case KEYS.UP:
        e.preventDefault();
        setShouldShowSuggestions(false);
        break;
      case KEYS.DOWN:
        e.preventDefault();
        setShouldShowSuggestions(true);
        autoCompleteRef.current?.querySelector<HTMLOptionElement>('[role="option"]')?.focus();
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetPickupLocationSuggestions = useCallback(
    debounce(onSearch, Config.TIMEOUT.storeFinderGetPickupLocationSuggestionsMs),
    []
  );

  const onBlurHandler = () => {
    setShouldShowSuggestions(false);
    setTakeoverVisible(false);
  };

  useEffect(() => {
    if (selectedStore) setSelectedLocation(selectedStore.geoPoint || defaultLocation);
  }, [selectedStore]);

  useFreezeBodyScroll(takeoverVisible);

  return (
    <StyledStoreFinder>
      <StyledStoreFinderForm>
        <Takeover isVisible={takeoverVisible}>
          <StyledStoreFinderFieldWrapper isTakeover={takeoverVisible}>
            <StyledInputField
              ref={inputRef}
              name="find-store"
              variant="rounded"
              aria-label={t('search->placeholder')}
              value={formData.store}
              placeholder={t('search->placeholder')}
              onChange={(e) => {
                setFormData({ ...formData, store: e.currentTarget.value });
                debouncedGetPickupLocationSuggestions(e);
              }}
              onFocus={() => {
                isMobile && setTakeoverVisible(true);
                trackStoreFinderSearchField();
              }}
              open={shouldShowSuggestions}
              autoComplete="off"
              onKeyDown={onKeyDown}
              autoFocus={takeoverVisible}
              takeover={takeoverVisible}
              clearable
              onClear={() => {
                setFormData({ ...formData, store: '' });
                setStoreSuggestions([]);
                setShouldShowSuggestions(false);
              }}
              onCloseTakeover={onBlurHandler}
              showPrependIcon
            />
            {shouldShowSuggestions && (
              <StyledAutocomplete
                isTakeover={takeoverVisible}
                ref={autoCompleteRef}
                inputRef={inputRef}
                isOrderReopened={false}
                searchTerm={formData.store}
                suggestions={storeSuggestions}
                ListComponent={PickupLocationListItem}
                rounded
                listComponentOnClick={onListItemClick}
                emptySlot={
                  <StyledEmptySlot>
                    <StyledEmptySlotHead>
                      {t('storeNotFound->title', {
                        search: formData.store,
                      })}
                    </StyledEmptySlotHead>
                    <StyledEmptySlotContainer theme="deliveryPicker">
                      <Text>{t('storeNotFound->body')}</Text>
                    </StyledEmptySlotContainer>
                    <Link href={paths.ALL_STORES} onClick={trackViewAllStoresList}>
                      {t('storeNotFound->link')}
                    </Link>
                  </StyledEmptySlot>
                }
                selectedStore={selectedStore?.storeId || '2110'}
                scrollable
                showAsCards={isMobile}
                focusable
              />
            )}
          </StyledStoreFinderFieldWrapper>
        </Takeover>
        <StyledButton
          theme="primary"
          onClick={findStore}
          isSubmitting={isFindingNearest}
          disabled={!allStores || allStores?.length <= 0}
        >
          <Icon svg={Location} size={20} color="white" />
          {t('findNearestStore')}
        </StyledButton>
      </StyledStoreFinderForm>

      <StyledMap>
        <DynamicMap
          position={selectedLocation}
          stores={allStores || []}
          zoom={zoom}
          showMarkers={!!selectedStore}
          selectedStore={selectedStore}
          onMarkerClick={(store) => {
            trackMapClick(store);
          }}
          googleMapsApiKey={config?.GOOGLE_MAPS_API_KEY || ''}
        />
      </StyledMap>

      {showGeolocationErrorModal && (
        <Modal
          size="medium"
          onClose={() => setShowGeolocationErrorModal(false)}
          hasCloseIcon
          onConfirm={() => setShowGeolocationErrorModal(false)}
          confirmButtonText={t('modal:modal->close')}
        >
          <Heading variant="h3">{t('geoLocationDisabled->title')}</Heading>
          <Text type="body" size="small">
            {t('geoLocationDisabled->body')}
          </Text>
        </Modal>
      )}
    </StyledStoreFinder>
  );
};

export default StoreFinder;
