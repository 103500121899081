import { AnalyticsEcommerceItemType, convertProductToAnalyticsEcommerceItem } from './analyticsProduct';
import { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import type { AxfoodProductDetailsViewModel } from '@api/storeFrontApi';

export const trackProductImpressions = (
  products: AxfoodProductDetailsViewModel[],
  listName: string,
  startPosition: number
) => {
  const items: AnalyticsEcommerceItemType[] = products.map((product, i) => {
    return {
      ...convertProductToAnalyticsEcommerceItem(product, false, listName),
      index: startPosition + i + 1,
    };
  });

  pushGTMEcommerceEvent({
    event: EcommerceEvent.VIEW_LIST_ITEM,
    ecommerce: {
      item_list_name: listName,
      items,
    },
  });
};

export const trackProductImpressionsWithPosition = (
  products: { product: AxfoodProductDetailsViewModel; position: number }[],
  listName: string
) => {
  const items: AnalyticsEcommerceItemType[] = products.map(({ product, position }) => {
    return {
      ...convertProductToAnalyticsEcommerceItem(product, false, listName),
      item_list_name: listName,
      index: position,
    };
  });

  pushGTMEcommerceEvent({
    event: EcommerceEvent.VIEW_LIST_ITEM,
    ecommerce: {
      item_list_name: listName,
      items,
    },
  });
};
