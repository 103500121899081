import useTranslation from 'next-translate/useTranslation';
import Link from '@atoms/Link/Link';
import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';
import ScreenReaderMessage from '@atoms/ScreenReaderMessage/ScreenReaderMessage';

import Table from '@atoms/Table/Table';
import {
  StyledProductTableOfInformationCountryOrigin,
  StyledProductTableOfInformationSection,
  StyledHtmlElement,
} from './ProductTableOfInformation.styles';
import type { AxfoodProductDetailsViewModel, NutrientFactData } from '@api/storeFrontApi';
import Info from '@icons/info.svg';

interface Props {
  data: AxfoodProductDetailsViewModel;
}

const ProductTableOfInformation = ({ data }: Props) => {
  const { t } = useTranslation('product');

  return (
    <div>
      {data.activeSubstance && (
        <StyledProductTableOfInformationSection data-testid="activeSubstance">
          <Heading variant="h3" label>
            {t('product->activeSubstance')}
          </Heading>
          <Text type="body" color="black">
            {data.activeSubstance}
          </Text>
        </StyledProductTableOfInformationSection>
      )}

      {data.medicineType && (
        <StyledProductTableOfInformationSection data-testid="medicineType">
          <Heading variant="h3" label>
            {t('product->medicineType')}
          </Heading>
          <Text type="body" color="black">
            {data.medicineType}
          </Text>
        </StyledProductTableOfInformationSection>
      )}

      {data.medicineLink && (
        <StyledProductTableOfInformationSection data-testid="medicineLink">
          <Text type="body" color="black">
            <span>{`${t('product->medicineLeafletStart')} `}</span>
            <Link href={data.medicineLink} external target="_blank">
              {t('product->medicineLeafletLink')}
            </Link>
            <span>{` ${t('product->medicineLeafletEnd')}`}</span>
          </Text>
        </StyledProductTableOfInformationSection>
      )}

      {data.description && (
        <StyledProductTableOfInformationSection data-testid="description">
          <Text type="body" color="black">
            {data.description}
          </Text>
        </StyledProductTableOfInformationSection>
      )}

      {data.extraInfoCode === 'FFM' && (
        <StyledProductTableOfInformationSection data-testid="extraInfoCode">
          <Text type="body" color="black">
            {t('product->extraInfoCode')}
          </Text>
        </StyledProductTableOfInformationSection>
      )}

      {data.medicineType && data.ean && (
        <StyledProductTableOfInformationSection data-testid="ean">
          <Heading variant="h3" label>
            {t('product->gtin')}
          </Heading>
          <Text type="body" color="black">
            {data.ean}
          </Text>
        </StyledProductTableOfInformationSection>
      )}

      {data.categoryGroupText && (
        <StyledProductTableOfInformationSection data-testid="categoryGroupText">
          <StyledHtmlElement dangerouslySetInnerHTML={{ __html: data.categoryGroupText }} />
        </StyledProductTableOfInformationSection>
      )}

      {data?.otherCountries && data.otherCountries.length > 0 && (
        <StyledProductTableOfInformationSection data-testid="otherCountries">
          <Heading variant="h3" label>
            {t('product->otherCountries')}
          </Heading>
          <Text type="body" color="black">
            {data.otherCountries.join(', ')}
          </Text>
          <br />
          <Text type="body" color="black">
            {t('product->otherCountriesInfo')}
          </Text>
        </StyledProductTableOfInformationSection>
      )}

      {(data.provinceStatement || data.countryOfOriginStatement) && (
        <StyledProductTableOfInformationSection>
          <Heading variant="h3" label>
            {t('product->origin')}
          </Heading>
          {data.provinceStatement ? (
            <Text type="body" color="black" data-testid="provinceStatement">
              {data.provinceStatement}
            </Text>
          ) : (
            <Text type="body" color="black" data-testid="countryOfOriginStatement">
              {data.countryOfOriginStatement}
            </Text>
          )}
        </StyledProductTableOfInformationSection>
      )}

      {data.tradeItemCountryOfOrigin && (
        <StyledProductTableOfInformationSection data-testid="tradeItemCountryOfOrigin">
          <Heading variant="h3" label>
            {t('product->countryOfOrigin')}
            <ScreenReaderMessage>{t('tooltip:tooltip->originCountry')}</ScreenReaderMessage>
            <StyledProductTableOfInformationCountryOrigin
              content={t('tooltip:tooltip->originCountry')}
              iconProps={{ svg: Info, size: 16 }}
              align="center"
              position="bottom"
              color="black"
            />
          </Heading>
          <Text type="body" color="black">
            {data.tradeItemCountryOfOrigin}
          </Text>
        </StyledProductTableOfInformationSection>
      )}

      {data.nutritionsFactList?.length > 0 && (
        <>
          <StyledProductTableOfInformationSection data-testid="nutritionsFactList">
            <Table>
              <thead>
                <tr>
                  <td>
                    <Heading variant="h3" label>
                      {t('product->nutrition->title')}
                    </Heading>
                  </td>
                  <td>
                    {data.nutritionDescription && (
                      <Text type="body" size="small" color="gray">
                        {t('product->nutrition->description', { portion: data.nutritionDescription })}
                      </Text>
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                {data.nutritionsFactList?.map((nutrition: NutrientFactData, i: number) => (
                  <tr key={`${nutrition.typeCode}-${nutrition.unitCode}-${i}`}>
                    <td>{nutrition?.typeCode}</td>
                    <td>
                      {nutrition?.measurementPrecisionCode} {nutrition?.value} {nutrition?.unitCode}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </StyledProductTableOfInformationSection>
          <StyledProductTableOfInformationSection>
            <Text type="body" color="black" size="small">
              {t('product->disclaimer')}
            </Text>
          </StyledProductTableOfInformationSection>
        </>
      )}
    </div>
  );
};

export default ProductTableOfInformation;
