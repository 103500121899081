import useTranslation from 'next-translate/useTranslation';
import Text from '@atoms/Text/Text';
import useCustomer from '@hooks/useCustomer';
import { isOfflineProduct, isOfflinePromotion, isOnlineProduct } from '@helpers/typeGuards/product';
import { dateToNumeric } from '@helpers/date';
import type { AxfoodOfflineProductViewModel, AxfoodProductDetailsViewModel } from '@api/storeFrontApi';

interface Props {
  product: AxfoodOfflineProductViewModel | AxfoodProductDetailsViewModel;
}

const ProductDetailsPriceAndPromotionDetails = ({ product }: Props) => {
  const { t } = useTranslation('product');
  const promotion = product?.potentialPromotions?.[0];
  const lowestHistoricalPrice =
    (isOfflineProduct(product) && product?.lowestHistoricalPrice) ||
    (!isOfflinePromotion(promotion) && promotion?.lowestHistoricalPrice);
  const { customer } = useCustomer();
  const showTimesUsed = isOfflineProduct(product)
    ? !customer?.isAnonymous && Boolean(promotion?.promotionRedeemLimit)
    : (promotion?.timesUsed ?? 0) > 0;

  return (
    <>
      {(showTimesUsed || promotion?.redeemLimitLabel) && (
        <Text type="detail" color="red">
          {promotion?.redeemLimitLabel &&
            t('product->promotion->limitHousehold', { redeemLimitLabel: promotion.redeemLimitLabel })}
          {promotion?.redeemLimitLabel && showTimesUsed && ' • '}
          {showTimesUsed &&
            `${t('product->promotion->timesUsed', {
              timesUsed: promotion?.timesUsed,
              redeemLimit: promotion?.promotionRedeemLimit,
            })}`}
        </Text>
      )}
      {promotion?.validUntil && (
        <Text type="detail" color="red">
          {`${t('product->promotion->validUntil')} ${dateToNumeric(promotion?.validUntil)}`}
        </Text>
      )}

      {promotion && 'comparePrice' in promotion && (
        <Text type="detail" color="red">
          {promotion.comparePrice &&
            isOfflineProduct(product) &&
            isOfflinePromotion(promotion) &&
            `${t('product->price->compare')} ${promotion.comparePrice}${
              promotion.savePrice ? ` • ${promotion.savePrice}` : ''
            }`}
          {promotion.comparePrice &&
            isOnlineProduct(product) &&
            `${t('product->price->compare')} ${promotion.comparePrice}/${product.comparePriceUnit}${
              product.savingsAmount &&
              ((lowestHistoricalPrice && lowestHistoricalPrice.value === product.priceValue) || !lowestHistoricalPrice)
                ? ` • ${t('product->promotion->saveKr', {
                    amount: product.savingsAmount.toFixed(2).toString().replace('.', ','),
                  })}`
                : ''
            }`}
          {!promotion.comparePrice && t('product->price->compare-missing')}
        </Text>
      )}

      {'comparePrice' in product && product?.comparePrice && product?.comparePriceUnit && (
        <Text type="detail" color="gray">
          {product?.comparePrice
            ? `${t('product->price->compare')} ${product?.comparePrice}/${product?.comparePriceUnit}`
            : t('product->price->compare-missing')}
          {product.price && promotion && ` • ${t('product->price->default')} ${product.price}`}
        </Text>
      )}

      {!!lowestHistoricalPrice && (
        <Text type="detail" color="gray">
          {t('product->price->lowestHistorical', {
            price: lowestHistoricalPrice?.formattedValue,
          })}
        </Text>
      )}
    </>
  );
};

export default ProductDetailsPriceAndPromotionDetails;
