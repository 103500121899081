// noinspection JSUnusedGlobalSymbols
import { setDeliveryPickerIsOpen } from '@slices/deliveryPickerSlice';
import { Dispatch } from 'redux';
import { mutate } from 'swr';
import { restoreCart } from '@api/interfaces/cartApi';
import CART from '@helpers/cartKeys';
import { getActiveStoreEndpoint } from '@hooks/useStore';
import type { AxfoodCartViewModel } from '@api/storeFrontApi';
import { getCartEndpoint } from '@hooks/useCart';

const refreshCart = async (data?: AxfoodCartViewModel) => {
  await mutate((key: any) => key.endpoint === getCartEndpoint, data);
};

const refreshStore = async () => {
  await mutate((key: any) => key.endpoint === getActiveStoreEndpoint);
};

const axfoodAppToWebAPI = (dispatch: Dispatch) => {
  return {
    doRefreshCart: () => {
      refreshCart();
    },
    doRefreshDeliveryMethod: () => {
      // Update relevant data and close delivery picker
      refreshCart();
      refreshStore();
      dispatch(setDeliveryPickerIsOpen({ isOpen: false }));
    },
    doRefreshBasestore: () => {
      refreshStore();
    },
    doRefreshCreditCards: () => {
      refreshCart();
    },
    doRestoreCart: async () => {
      const cartResponse = await restoreCart(CART.KEEP_PERSISTENT);
      await refreshCart(cartResponse.data);
      await refreshStore();
    },
    // TODO: Implement when my-lists- and checkout pages are built.
    doRefreshVouchers: () => {},
    doRefreshShoppingLists: () => {
      // SWR API accepts a filter function, which returns which keys to revalidate.
      // The filter function is applied to all the existing cache keys
      const filterFn = (key: any) => key.endpoint === 'userWishlists';
      // mark the data as expired and trigger a refetch
      mutate(filterFn);
    },
    doHandlePurchaseComplete: () => {},
    // Functions called from native app - but only used in Hemkop (will generate errors if not on api)
    doNewCouponCheck: () => {},
    doCloseDeliveryModal: () => {},
    doHandleHandlaConfig: () => {},
    doHandleSwitchedTab: () => {},
  };
};

export default axfoodAppToWebAPI;
