import { computeAnalyticsEcommerceItemsTotalValue, convertProductToAnalyticsEcommerceItem } from './analyticsProduct';
import { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import type { AxfoodOfflineProductViewModel, AxfoodProductDetailsViewModel } from '@api/storeFrontApi';

const trackProductDetail = (product: AxfoodProductDetailsViewModel | AxfoodOfflineProductViewModel) => {
  const items = [convertProductToAnalyticsEcommerceItem(product, false)];
  pushGTMEcommerceEvent({
    event: EcommerceEvent.VIEW_ITEM,
    ecommerce: {
      currency: 'SEK',
      value: computeAnalyticsEcommerceItemsTotalValue(items),
      items,
    },
  });
};

export default trackProductDetail;
