import React, { ReactNode, useState } from 'react';
import Link from '@atoms/Link/Link';
import { cmsAlignToFlexAlign, cmsAlignToSectionAlign, cmsAlignToTextAlign } from '@helpers/cmsComponentHelpers';
import YoutubeModal from '@molecules/YoutubeModal/YoutubeModal';
import getPaddingFromCms from '@helpers/getPaddingFromCms';
import useCustomRouter from '@hooks/useCustomRouter';
import useTranslation from 'next-translate/useTranslation';
import HorizontalBannerComponentSkeleton from '@molecules/HorizontalBannerComponent/HorizontalBannerComponent.skeleton';
import DOMPurify from 'isomorphic-dompurify';
import Video from '@icons/video.svg';
import {
  StyledButton,
  StyledComponentWrapper,
  StyledDiv,
  StyledHeading,
  StyledPreamble,
  StyledSection,
  StyledText,
  StyledYoutubeIcon,
} from './HorizontalBannerComponent.styles';
import useCmsStoreContent from '@molecules/HorizontalBannerComponent/useCmsStoreData';
import type { ComponentWsDTO } from '@api/generated/cms';
import useResponsive from '@hooks/useResponsive';
import ClientsideTrackingComponent from '@molecules/ClientsideTrackingComponent/ClientsideTrackingComponent';

interface Props {
  data: ComponentWsDTO;
  trackingObject?: { logImpression: () => void; handleError: (error: Error) => void; contentId: string };
  onClick?: () => void;
}

const HorizontalBannerComponent = ({ data, trackingObject, onClick }: Props) => {
  const { t } = useTranslation('common');
  const router = useCustomRouter();
  const { isClient } = useResponsive();
  const [showYoutubeModal, setShowYoutubeModal] = useState(false);

  const { titleContent, preambleContent, isLoading, horizontalBannerData } = useCmsStoreContent(data.uid);

  const alignItems = cmsAlignToFlexAlign(horizontalBannerData?.textAlign);
  const textAlign = cmsAlignToTextAlign(horizontalBannerData?.textAlign);
  const alignSection = cmsAlignToSectionAlign(horizontalBannerData?.buttonPosition);
  const isTitleBanner = horizontalBannerData?.titleBanner === 'true';
  const isButtonEnabled = horizontalBannerData?.buttonEnabled === 'true';

  const button = (
    <StyledButton
      background={horizontalBannerData?.buttonColor}
      color={horizontalBannerData?.buttonTextColor}
      title={horizontalBannerData?.buttonText}
      onClick={() => {
        if (horizontalBannerData?.youtubeId) setShowYoutubeModal(true);
      }}
    >
      {horizontalBannerData?.youtubeId && (
        <StyledYoutubeIcon svg={Video} size={20} customColor={horizontalBannerData?.buttonTextColor} />
      )}
      {horizontalBannerData?.buttonText}
    </StyledButton>
  );

  const wrapWithLink = (content: ReactNode) => {
    return (
      <Link
        href={{ pathname: horizontalBannerData?.urlLink, query: { ...router.query } }}
        target={horizontalBannerData?.urlLinkInNewWindow === 'true' ? '_blank' : ''}
        data-testid="horizontalbanner-link"
      >
        {content}
      </Link>
    );
  };

  const banner = (
    <StyledSection
      fixed={isTitleBanner}
      clickable={!isButtonEnabled && !!horizontalBannerData?.urlLink}
      padding={getPaddingFromCms(horizontalBannerData)}
      style={{
        backgroundColor: horizontalBannerData?.backgroundColor,
        alignItems: alignSection,
      }}
      $media={horizontalBannerData?.media}
      onClick={onClick}
    >
      <StyledDiv
        style={{
          textAlign,
          alignItems,
        }}
      >
        {titleContent && (
          <StyledHeading
            color={horizontalBannerData?.titleTextColor as HeadingColors}
            variant={horizontalBannerData?.fontType === 'SMALL' ? 'h2' : 'h1'}
            size={!!horizontalBannerData?.fontType && horizontalBannerData?.fontType === 'SMALL' ? undefined : 'large'}
          >
            {titleContent}
          </StyledHeading>
        )}
        {horizontalBannerData?.openingHoursMessageKey && horizontalBannerData?.openingStoreMessageValue && (
          <StyledText color={horizontalBannerData?.titleTextColor as HeadingColors} type="label" size="large">{`${t(
            horizontalBannerData.openingHoursMessageKey
          )} ${
            typeof horizontalBannerData.openingStoreMessageValue === 'string'
              ? horizontalBannerData.openingStoreMessageValue
              : ''
          }`}</StyledText>
        )}
        {preambleContent && (
          <StyledText
            style={{ color: horizontalBannerData?.preambleTextColor }}
            type="intro"
            fontType={horizontalBannerData?.fontType?.toLowerCase()}
          >
            <StyledPreamble dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(preambleContent) }} />
          </StyledText>
        )}
        {isButtonEnabled && !isTitleBanner && (horizontalBannerData?.urlLink ? wrapWithLink(button) : button)}
      </StyledDiv>
    </StyledSection>
  );

  return (
    <StyledComponentWrapper>
      {isLoading ? (
        <HorizontalBannerComponentSkeleton />
      ) : !isButtonEnabled && horizontalBannerData?.urlLink ? (
        wrapWithLink(banner)
      ) : (
        banner
      )}
      {showYoutubeModal && (
        <YoutubeModal youtubeId={horizontalBannerData?.youtubeId} setShowModal={setShowYoutubeModal} />
      )}
      {isClient && trackingObject && <ClientsideTrackingComponent trackingObject={trackingObject} />}
    </StyledComponentWrapper>
  );
};

export default HorizontalBannerComponent;
