import Product, { Variant } from '@molecules/Product/Product';
import CmsComponent from '@organisms/CmsComponent/CmsComponent';
import type { AxfoodBasicProductViewModel, AxfoodProductDetailsViewModel } from '@api/storeFrontApi';
import TrackedComponent from '@organisms/TrackedComponent/TrackedComponent';
import useProductImpressionTracking from '@hooks/useProductImpressionsTracking';

interface Props {
  products: (AxfoodProductDetailsViewModel | AxfoodBasicProductViewModel | ImageGridComponentType)[];
  category: string;
  offline?: boolean;
  variant?: Variant;
  openMixMatchBeam?: string;
  setOpenMixMatchBeam?: (code: string) => void;
  containerRef?: HTMLDivElement | null;
  disableMixMatchButton?: boolean;
  hideAddToList?: boolean;
  className?: string;
  limit?: number;
  numberOfGridCols?: number;
  getKey?: (GeneratedOnlineProductType: AxfoodProductDetailsViewModel | GridPackageComponentType) => string;
  numberOfVouchers?: number;
}

const ProductsWithTracking = ({
  products,
  category,
  offline,
  variant,
  openMixMatchBeam,
  setOpenMixMatchBeam,
  containerRef,
  disableMixMatchButton,
  hideAddToList,
  className,
  limit = products.length,
  numberOfGridCols,
  getKey = (product) => product?.code || (product as GridPackageComponentType)?.uuid,
  numberOfVouchers = 0,
}: Props) => {
  const { onProductShown } = useProductImpressionTracking(category);
  return (
    <>
      {products?.slice(0, limit).map((product, i) => {
        if (Object.prototype.hasOwnProperty.call(product, 'typeCode')) {
          let key = getKey(product as AxfoodProductDetailsViewModel | GridPackageComponentType);
          if (!key) {
            key = (product as ImageGridComponentType).uuid;
          }
          return <CmsComponent key={key} component={{ ...product, gridCols: numberOfGridCols }} />;
        }

        return (
          <TrackedComponent
            index={i}
            product={product as AxfoodProductDetailsViewModel}
            key={getKey(product as AxfoodProductDetailsViewModel | GridPackageComponentType)}
            callback={() => onProductShown(product as AxfoodProductDetailsViewModel, i)}
          >
            <Product
              product={product as AxfoodProductDetailsViewModel | AxfoodBasicProductViewModel}
              variant={variant}
              category={category}
              offline={offline}
              listPos={numberOfVouchers + i}
              openMixMatchBeam={openMixMatchBeam}
              setOpenMixMatchBeam={setOpenMixMatchBeam}
              containerRef={containerRef}
              disableMixMatchButton={disableMixMatchButton}
              hideAddToList={hideAddToList}
              className={className}
            />
          </TrackedComponent>
        );
      })}
    </>
  );
};

export default ProductsWithTracking;
