import { useRouter } from 'next/router';
import trackLinkNavigation from '@helpers/analyticsHelpers/trackLinkNavigation';
import { StyledLink, StyledLinkWrapper, StyledNavigation, StyledNavigationWrapper } from './Nav.styles';
import { ComponentAdaptedData, NavigationNodeAdaptedData } from '@api/generated/cms';
import { ContentSlotWsDTOWithComponent } from '@api/interfaces/collectSlots';

interface Props {
  slot: ContentSlotWsDTOWithComponent;
}

const Nav = ({ slot }: Props) => {
  const components = slot.component as ComponentAdaptedData[];
  const router = useRouter();
  return (
    <>
      {components.map((component) => (
        <StyledNavigation key={`desktop-${component.uid}`}>
          {component.navigationNode && (
            <StyledNavigationWrapper>
              {component?.navigationNode?.children?.map((node: NavigationNodeAdaptedData) => (
                <StyledLinkWrapper key={`${component.uid}-${node.uid}`}>
                  <StyledLink
                    href={node.links?.[0]?.url || ''}
                    variant="nav"
                    active={
                      node.links?.[0].url === router.route ||
                      (router.route.indexOf(node.links?.[0].url || '') > -1 &&
                        router.asPath.indexOf(node.links?.[0].url || '') > -1)
                    }
                    key={`desktop-${node.uid}`}
                    onClick={() => trackLinkNavigation('header', node.title)}
                  >
                    {node.title}
                  </StyledLink>
                </StyledLinkWrapper>
              ))}
            </StyledNavigationWrapper>
          )}
        </StyledNavigation>
      ))}
    </>
  );
};
export default Nav;
