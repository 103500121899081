import useTranslation from 'next-translate/useTranslation';
import { Fragment, useEffect, useState } from 'react';
import getPaymentModes from '@api/getPaymentModes';
import Radio from '@atoms/Radio/Radio';
import useCustomer from '@hooks/useCustomer';
import { setCompleted, setEditing, setSelectedMode } from '@slices/checkoutSlice';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import IconTooltip from '@molecules/IconTooltip/IconTooltip';
import setPaymentMode from '@api/setPaymentMode';
import DeleteCardModal from '@components/organisms/DeleteCardModal/DeleteCardModal';
import ScreenReaderMessage from '@atoms/ScreenReaderMessage/ScreenReaderMessage';
import logger from '@logger';
import PayexOption from '@molecules/CheckoutPaymentOption/payex/PayexOption';
import KlarnaOption from '@molecules/CheckoutPaymentOption/klarna/KlarnaOption';
import Text from '@atoms/Text/Text';
import trackSelectedPaymentMode from '@helpers/analyticsHelpers/trackSelectedPaymentMode';
import { CreditCardPaymentInfoData } from '@api/generated/storefront';
import axios, { isAxiosError } from 'axios';
import { selectCheckoutPayment } from '@slices/checkoutSlice';
import {
  StyledCardIconImg,
  StyledDivider,
  StyledKlarnaIconImg,
  StyledPaymentIconWrapper,
  StyledPaymentModeDescription,
  StyledPaymentModes,
  StyledPaymentModesWrapper,
  StyledSubText,
} from './PaymentModes.styles';
import { ErrorDataType } from '@molecules/CheckoutPaymentButton/CheckoutPaymentButton';
import { PlaceOrderErrorResponse } from '@enums/PlaceOrderErrorResponse';
import Info from '@icons/info.svg';
import useCart from '@hooks/useCart';

export enum PaymentMode {
  INVOICE = 'Invoice',
  PAYEX = 'Payex',
  PAYEXALL = 'PayexAll',
  PSPPAYEXALL = 'PspPayexAll',
  KLARNA = 'Klarna',
}

interface Props {
  setError: (error: ErrorDataType) => void;
}

const PaymentModes = ({ setError }: Props) => {
  const { customer } = useCustomer();
  const [paymentModes, setPaymentModes] = useState<PaymentType[]>([]);
  const [savedCards, setSavedCards] = useState<CreditCardPaymentInfoData[]>([]);
  const [deleteCardId, setDeleteCardId] = useState('');
  const payment = useAppSelector(selectCheckoutPayment);
  const { cart, refreshCart } = useCart();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('paymentModes');
  const source = axios.CancelToken.source();

  const getPaymentIcons = (paymentMode: string) => {
    if (paymentMode === PaymentMode.KLARNA) {
      return <StyledKlarnaIconImg title={PaymentMode.KLARNA} src="/icons/logos/klarna.svg" />;
    }
    return (
      <>
        <StyledCardIconImg title="Visa" src="/icons/logos/Visa.svg" />
        <StyledCardIconImg title="Mastercard" src="/icons/logos/Mastercard.svg" />
        <StyledCardIconImg title="Amex" src="/icons/logos/Amex.svg" />
      </>
    );
  };

  const fetchPaymentModes = async () => {
    try {
      const paymentResponse = await getPaymentModes(source.token);
      setPaymentModes(paymentResponse.data);
    } catch (e) {
      if (isAxiosError(e)) {
        logger.error({ error: 'Could not fetch payment modes', message: e.message });
      }
    }
  };

  const onChangeHandler = async (mode: PaymentType) => {
    try {
      const resp = await setPaymentMode(mode);
      if (!resp.data.slot) {
        dispatch(setCompleted({ boxName: 'delivery', isCompleted: false }));
        dispatch(setEditing({ boxName: 'delivery', isEditing: true }));
        setError({ errorCode: PlaceOrderErrorResponse.NO_DELIVERY_SLOT });
      }
      await refreshCart(resp.data);

      dispatch(setSelectedMode(mode));
    } catch (e) {
      if (isAxiosError(e)) {
        logger.error({ error: 'Could not set payment mode', message: e.message });
      }
    }
  };

  /* Fetch and set paymentModes */
  useEffect(() => {
    fetchPaymentModes();

    if (cart?.orderReference) {
      onChangeHandler(cart.paymentType as PaymentType);
    }

    return () => {
      source.cancel('Canceled fetch of paymentModes');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Track selectedPaymentMode
   */
  useEffect(() => {
    if (payment.selectedMode) {
      trackSelectedPaymentMode(payment.selectedMode);
    }
  }, [payment.selectedMode]);

  /* Fetch and set savedCards */
  useEffect(() => {
    if (customer?.savedCards?.length) {
      const activeCards = customer.savedCards?.filter((card) => !card.expired);
      if (activeCards.length > 0) {
        activeCards.push({
          cardType: '',
          defaultCard: false,
          expireDate: '',
          expired: false,
          id: 'new',
          maskedNumber: t('card->new'),
        });
      }
      setSavedCards(activeCards);
    } else {
      setSavedCards([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.savedCards]);

  const shouldDisablePaymentMethod = (currentPaymentType: PaymentType) => {
    const selectedPaymentTypeInCart = cart?.paymentType;

    let klarnaNotAvailable = true;
    if (!customer?.isB2BCustomer) {
      klarnaNotAvailable = false;
    }
    return (
      (currentPaymentType === PaymentMode.KLARNA && klarnaNotAvailable) ||
      (Boolean(cart?.orderReference) && selectedPaymentTypeInCart !== currentPaymentType)
    );
  };

  const isCardPaymentMode = (paymentMode: string) => {
    return (
      paymentMode === PaymentMode.PAYEX ||
      paymentMode === PaymentMode.PAYEXALL ||
      paymentMode === PaymentMode.PSPPAYEXALL
    );
  };

  return (
    <StyledPaymentModes>
      <Text type="label">{t('title')}</Text>
      {paymentModes.length > 0 &&
        paymentModes.map((paymentMode, i) => {
          const disabled = shouldDisablePaymentMethod(paymentMode);
          return (
            <Fragment key={`paymentMode-${paymentMode}`}>
              {(payment.selectedMode !== PaymentMode.KLARNA ||
                i === 0 ||
                (isCardPaymentMode(paymentMode) && disabled)) && <StyledDivider first={i === 0} />}
              <StyledPaymentModesWrapper key={`paymentmode-${paymentMode}`} openOrder={!!cart?.orderReference}>
                <Radio
                  checked={
                    // This is because backend is sending different Payex identifiers, remove check if they ever fix it.
                    paymentMode === payment.selectedMode ||
                    (isCardPaymentMode(paymentMode) && isCardPaymentMode(payment.selectedMode))
                  }
                  onChange={() => onChangeHandler(paymentMode)}
                  changeOnKeyEnter
                  disabled={disabled}
                  id={paymentMode}
                >
                  <StyledPaymentModeDescription disabled={disabled} type="body">
                    {paymentMode === PaymentMode.KLARNA && <ScreenReaderMessage>{paymentMode}</ScreenReaderMessage>}
                    {t(`paymentModes->${paymentMode}`)}
                    {disabled && (
                      <IconTooltip
                        color={disabled ? 'mediumGray' : 'darkGray'}
                        iconProps={{ svg: Info, size: 16 }}
                        content={t('tooltip->cardDisabled')}
                      />
                    )}
                  </StyledPaymentModeDescription>

                  {paymentMode === PaymentMode.KLARNA && <StyledSubText>{t('klarnaSubText')}</StyledSubText>}
                </Radio>
                {paymentMode !== PaymentMode.INVOICE && (
                  <StyledPaymentIconWrapper disabled={disabled}>{getPaymentIcons(paymentMode)}</StyledPaymentIconWrapper>
                )}
              </StyledPaymentModesWrapper>

              {isCardPaymentMode(paymentMode) && isCardPaymentMode(payment.selectedMode) && !customer?.isAnonymous && (
                <PayexOption savedCards={savedCards} disabled={disabled} setDeleteCardId={setDeleteCardId} />
              )}

              {paymentMode === PaymentMode.KLARNA &&
                payment.selectedMode === PaymentMode.KLARNA &&
                !customer?.isB2BCustomer && <KlarnaOption />}
            </Fragment>
          );
        })}
      <DeleteCardModal cardId={deleteCardId} setCardId={setDeleteCardId} />
    </StyledPaymentModes>
  );
};

export default PaymentModes;
