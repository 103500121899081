const Config = {
  SITE_NAME: 'willys',
  HOME_DELIVERY: 'homeDelivery',
  PICKUP_IN_STORE: 'pickUpInStore',
  BREAKPOINTS: {
    IS_MOBILE_SMALL: '(max-width: 375px)',
    FROM_MOBILE: '(min-width: 375px)',
    FROM_MOBILE_WIDE: '(min-width: 478px)',
    IS_MOBILE: '(max-width: 767px)',
    FROM_TABLET_PORTRAIT: '(min-width: 768px)',
    IS_TABLET_PORTRAIT: '(min-width: 768px) and (max-width: 1023px)',
    FROM_TABLET_LANDSCAPE: '(min-width: 1024px)',
    IS_TABLET_LANDSCAPE: '(min-width: 1024px) and (max-width: 1279px)',
    IS_TABLET: '(min-width: 768px) and (max-width: 1279px)',
    FROM_DESKTOP: '(min-width: 1280px)',
    FROM_DESKTOP_MEDIUM: '(min-width: 1366px)',
    FROM_DESKTOP_LARGE: '(min-width: 1440px)',
    FROM_DESKTOP_XLARGE: '(min-width: 1920px)',
  },
  MEDIA: {
    IS_MOBILE_XSMALL: '@media screen and (max-width: 359px)',
    IS_MOBILE_SMALL: '@media screen and (max-width: 374px)',
    FROM_MOBILE: '@media screen and (min-width: 375px)',
    FROM_MOBILE_WIDE: '@media screen and (min-width: 478px)',
    IS_MOBILE: '@media screen and (max-width: 767px)',
    FROM_TABLET_PORTRAIT: '@media screen and (min-width: 768px)',
    IS_TABLET_PORTRAIT: '@media screen and (min-width: 768px) and (max-width: 1023px)',
    FROM_TABLET_LANDSCAPE: '@media screen and (min-width: 1024px)',
    IS_TABLET_LANDSCAPE: '@media screen and (min-width: 1024px) and (max-width: 1279px)',
    IS_TABLET: '@media screen and (min-width: 768px) and (max-width: 1279px)',
    BELOW_DESKTOP: '@media screen and (max-width: 1279px)',
    FROM_DESKTOP: '@media screen and (min-width: 1280px)',
    FROM_DESKTOP_MEDIUM: '@media screen and (min-width: 1366px)',
    IS_DESKTOP_MEDIUM: '@media screen and (min-width: 1280px) and (max-width: 1439px)',
    FROM_DESKTOP_LARGE: '@media screen and (min-width: 1440px)',
    IS_DESKTOP_LARGE: '@media screen and (min-width: 1280px) and (max-width: 1919px)',
    FROM_DESKTOP_XLARGE: '@media screen and (min-width: 1920px)',
    HIGH_CONTRAST: '@media screen and (forced-colors: active)',
  },
  DEVICE: {
    IS_TOUCH: '(hover: none), (pointer: coarse)',
  },
  ROUTES: {
    promotionpage: {
      ecommerce: 'ehandel',
      store: 'butik',
    },
  },
  PRODUCT: {
    THUMBNAIL: {
      SMALL: 48,
      LARGE: 72,
    },
    MAX_LABEL_ICONS: 3,
    MAX_AMOUNT: 999,
    MIN_AMOUNT: 0,
  },
  EXCLUDED_LABELS: [
    'laktosfree',
    'glutenfree',
    'frozen',
    // GHS Symbols
    'corrosion',
    'environment',
    'exclamation_mark',
    'exploding_bomb',
    'flame',
    'flame_over_circle',
    'gas_cylinder',
    'health_hazard',
    'no_pictogram',
    'skull_and_crossbones',
    // T5044 GHS Signal words code
    'danger',
    'not_applicable',
    'warning',
  ],
  GHS_SYMBOLS: [
    'corrosion',
    'environment',
    'exclamation_mark',
    'exploding_bomb',
    'flame',
    'flame_over_circle',
    'gas_cylinder',
    'health_hazard',
    'skull_and_crossbones',
  ],
  LOCAL_STORAGE_SELECTORS: {
    bankIdTabLastSelected: 'willys.bankIdTabLastSelected',
    deliveryModeReminderDone: 'willys.deliveryModeReminderDone',
    promotionStoreId: 'willys.promotionStoreId',
    promotionTab: 'promotion.tab',
  },
  TIMEOUT: {
    addProductQueueMs: 1000,
    removeProductQueueMs: 3000,
    snackBarAnimationMs: 500,
    snackBarDestroyMs: 5000,
    mixMatchBeamResizeMs: 250,
    mixMatchBeamAnimationMs: 700,
    experimentMs: 1000,
    orderDetailButtonsMs: 350,
    takeoverScrollToMs: 300,
    productCarouselResizeMs: 500,
    closeTooltipMs: 5000,
    addToListDropdownAutoCloseMs: 4000,
    addToListDropdownCloseBtnMs: 200,
    modalAnimationMs: 300,
    responsiveCMSMediaResizeMs: 150,
    usabilaApplyMs: 15000,
    // Debounce
    productCarouselCalculateSlidesMs: 500,
    checkoutPaymentOptionInvoiceSetValueMs: 500,
    checkoutHomeDeliverySetValidMs: 300,
    dropdownJustifyMs: 100,
    storeFinderGetPickupLocationSuggestionsMs: 500,
    searchGetSuggestionsMs: 350,
    searchGetSuggestionsShortMs: 200,
    productsWithTrackingTrackMs: 1000,
    addToWishListSetQueryMs: 500,
    B2BAddressFieldAutoCompleteMs: 300,
    promotionPageLoadMoreMs: 500,
    heightAdjustDeliveryDropdownMs: 300,
    addressSearchGetSuggestionsMs: 500,
    productBeamSetAmountOfGridColsMs: 200,
    inputValidatorSetIsDirtyMs: 1500,
    inputValidatorCheckValidityMs: 200,
    axiosInterceptorRedirectToLoginConfirmMs: 300,
    defaultCacheInvalidateMs: 30 * 60 * 1000,
  },
  INTERVAL: {
    bankIdCollectMs: 2000,
  },
  ERROR_MESSAGE: {
    unmounted: 'unmounted',
  },
  HEIGHTS: {
    STICKY_TOOLBAR: 60,
  },
  FEATURE_TOGGLES: {
    searchAsYouType: 'willys.enableSearchAsYouType',
    newBankIdLoginFlow: 'willys.enableBankIdLoginFlow',
  },
};

export default Config;
