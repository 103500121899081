import type {
  AxfoodProductDetailsViewModel,
  AxfoodWishListEntryViewModel,
  AxfoodWishListViewModel,
} from '@api/storeFrontApi';
import {
  AnalyticsEcommerceItemType,
  convertProductToAnalyticsEcommerceItem,
} from '@helpers/analyticsHelpers/analyticsProduct';
import pushGTMEvent, { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackCreateList = () =>
  pushGTMEvent({ event: 'Track', category: 'wish_list', action: 'created_new_list', label: '', value: 0 });

export const trackSortedList = (sortLabel: string) =>
  pushGTMEvent({ event: 'Track', category: 'wish_list', action: 'sorted_wish_list', label: sortLabel, value: 0 });

export const trackChangeListName = () =>
  pushGTMEvent({ event: 'Track', category: 'wish_list', action: 'changed_list_name', label: '', value: 0 });

export const trackRemovedList = () =>
  pushGTMEvent({ event: 'Track', category: 'wish_list', action: 'removed_list', label: '', value: 0 });

export const trackSharedList = () =>
  pushGTMEvent({ event: 'Track', category: 'wish_list', action: 'list_shared', label: '', value: 0 });

export const trackNoListsMessage = () =>
  pushGTMEvent({ event: 'Track', category: 'wish_list', action: 'no_lists_created', label: '', value: 0 });

export const trackChecklistSorting = (sortLabel: string) =>
  pushGTMEvent({ event: 'Track', category: 'wish_list', action: 'sorted_checklist', label: sortLabel, value: 0 });

export const trackResetCheckboxes = () =>
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: 'checklist_items_reset_all_unchecked',
    label: '',
    value: 0,
  });

export const trackCheckboxChange = (checked: boolean) =>
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: checked ? 'checklist_item_checked' : 'checklist_item_unchecked',
    label: '',
    value: 0,
  });

export const trackAllChecked = () =>
  pushGTMEvent({ event: 'Track', category: 'wish_list', action: 'checklist_completed', label: '', value: 0 });

export const trackSharedListUnavailable = () =>
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: 'shared_list_unavailable',
    label: '',
    value: 0,
  });

export const trackEmptyWishList = () =>
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: 'empty_wish_list',
    label: '',
    value: 0,
  });

export const trackSortedWishLists = (sortLabel: string) =>
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: 'sorted_lists',
    label: sortLabel,
    value: 0,
  });

export const trackShowSaveToWishListModal = (category: string) => {
  pushGTMEvent({
    event: 'Track',
    category,
    action: 'save_as_list_widget_shown',
    label: '',
    value: 0,
  });
};

export const trackRedirectToLoginFromAddToList = (category: string) => {
  pushGTMEvent({
    event: 'Track',
    category,
    action: 'save_as_list_redirected_to_login',
    label: '',
    value: 0,
  });
};
export const trackRemoveFromList = () =>
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: 'removed_from_list',
    label: '',
    value: 0,
  });

export const trackDisplayChecklist = () =>
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: 'displayed_as_checklist',
    label: '',
    value: 0,
  });

export const trackAddWishListToCart = (
  label: 'from_list_overview' | 'from_shopping_list_detail',
  wishList: AxfoodWishListViewModel
) => {
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: 'added_list_to_cart',
    label,
    value: 0,
  });

  const items = wishList?.entries
    ?.map((entry) => {
      const productOrPromotion = entry.product || entry.promotion;
      if (productOrPromotion) {
        return convertProductToAnalyticsEcommerceItem(productOrPromotion, entry.quantity, label);
      }
    })
    .filter((item): item is AnalyticsEcommerceItemType => item !== undefined);

  if (items) {
    pushGTMEcommerceEvent({
      event: EcommerceEvent.ADD_TO_CART,
      ecommerce: {
        items,
      },
    });
  }
};

export const trackWishListItemSearch = (query: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: 'search',
    label: query,
    value: 0,
  });
};

export const trackSaveToWishListFromSearch = (
  action: 'saved_to_list_from_search' | 'removed_to_list_from_search',
  label: string
) =>
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action,
    label,
    value: 0,
  });

export const trackWishListItemQuantity = (type: 'INCREASE' | 'DECREASE') =>
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: type === 'INCREASE' ? 'increased_item_quantity_from_list' : 'decreased_item_quantity_from_list',
    label: '',
    value: 0,
  });

export const trackWishListOpenProductSelectionMode = () =>
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: 'select_products_from_wish_list_displayed',
    label: '',
    value: 0,
  });

export const trackProductSelectionAddToCart = (entries: AxfoodWishListEntryViewModel[]) => {
  pushGTMEvent({
    event: 'Track',
    category: 'wish_list',
    action: 'added_products_to_cart',
    label: 'from_wish_list_detail',
    value: 0,
  });

  const items =
    entries
      ?.filter((entry) => entry.product || entry.promotion)
      .map((entry) =>
        convertProductToAnalyticsEcommerceItem(
          (entry.product || entry.promotion) as any as AxfoodProductDetailsViewModel,
          entry.quantity,
          'from_wish_list_detail'
        )
      ) || [];

  pushGTMEcommerceEvent({
    event: EcommerceEvent.ADD_TO_CART,
    ecommerce: {
      items,
    },
  });
};
