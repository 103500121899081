import {
  StyledSearchAsYouType,
  StyledSearchButton,
  StyledSearchButtonIcon,
  StyledSearchButtonSubmit,
  StyledSearchClearButton,
  StyledSearchContainer,
  StyledSearchField,
  StyledSearchForm,
} from './SearchAsYouType.styles';
import { setToolbarSearchIsExpanded } from '@slices/toolbarSlice';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import useResponsive from '@hooks/useResponsive';
import { selectToolbarSearchIsExpanded } from '@slices/toolbarSlice';
import Icon from '@atoms/Icon/Icon';
import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import debounce from '@helpers/debounce';
import Config from '@config';
import useCustomRouter from '@hooks/useCustomRouter';
import { getSearchResultsAsYouType, getSuggestedSearchTerms } from '@api/interfaces/productApi';
import type {
  AutocompleteResultData,
  ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel,
} from '@api/storeFrontApi';
import SearchAsYouTypeResults from './SearchAsYouTypeResults/SearchAsYouTypeResults';
import paths from '@constants/paths';
import useFreezeBodyScroll from '@hooks/useFreezeBodyScroll';
import { trackSearch } from '@helpers/analyticsHelpers/trackSearchAsYouType';
import { selectDeliveryPicker } from '@slices/deliveryPickerSlice';
import useStockChange from '@hooks/useStockChange';
import Search from '@icons/search.svg';
import Clear from '@icons/clear.svg';
import ArrowRight from '@icons/arrow-right.svg';

const SearchAsYouType = () => {
  const dispatch = useAppDispatch();
  const { isMobile, fromDesktop } = useResponsive();
  const { t } = useTranslation('search');
  const router = useCustomRouter();

  const isSearchExpanded = useAppSelector(selectToolbarSearchIsExpanded);
  const deliveryPicker = useAppSelector(selectDeliveryPicker);

  const [inputSearchTerm, setInputSearchTerm] = useState('');
  const [results, setResults] = useState<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel>({});
  const [suggestions, setSuggestions] = useState<AutocompleteResultData>({});
  const inputRef = useRef<HTMLInputElement>(null);

  const usedQuery = results.usedQuery || inputSearchTerm || '';

  useFreezeBodyScroll(!isMobile && isSearchExpanded, true);

  const minSearchLength = 2;
  const maxResults = 10;

  const close = () => {
    if (isSearchExpanded && deliveryPicker.isOpen) return;
    if (router.asPath.startsWith(paths.PDP)) return;
    if (!router.asPath.startsWith(paths.SEARCH)) {
      setInputSearchTerm('');
    }
    dispatch(setToolbarSearchIsExpanded(false));
  };

  const clear = () => {
    setInputSearchTerm('');
    inputRef.current?.focus();
  };

  const getResults = (term: string) => {
    if (term.length < minSearchLength) return;

    trackSearch(term);

    Promise.all([getSearchResultsAsYouType(term, 0, maxResults), getSuggestedSearchTerms(term)]).then(
      ([results, suggestions]) => {
        setResults(results.data);
        setSuggestions(suggestions?.data || {});
        dispatch(setToolbarSearchIsExpanded(true));
      }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetResults = useCallback(debounce(getResults, Config.TIMEOUT.searchGetSuggestionsShortMs), []);

  const gotoSearchResultPage = (e?: FormEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    debouncedGetResults.cancel();

    dispatch(setToolbarSearchIsExpanded(false));
    // Push, but do not include internal data userInputQuery in the URL
    router.push(
      { pathname: paths.SEARCH, query: { q: usedQuery.trim(), userInputQuery: inputSearchTerm.trim() } },
      { pathname: paths.SEARCH, query: { q: usedQuery.trim() } }
    );
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    setInputSearchTerm(val);
    // Make sure old results are cleared when the user starts typing again, if the search results are not showing
    if (!isSearchExpanded) setResults({});

    return debouncedGetResults(val);
  };

  useEffect(() => {
    // Close SAYT when delivery picker is opened
    if (isSearchExpanded && deliveryPicker.isOpen && fromDesktop) {
      dispatch(setToolbarSearchIsExpanded(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryPicker.isOpen]);

  useStockChange(async () => {
    // In mobile we keep the search open when selecting store in delivery picker, so we want to update the search for the new store
    if (isMobile) debouncedGetResults(inputSearchTerm);
  });

  return (
    <StyledSearchAsYouType adjustForToolbar={isMobile && isSearchExpanded} clickHandler={close}>
      {isMobile && isSearchExpanded && (
        <StyledSearchButton
          color="black"
          size="large"
          onClick={close}
          isSearchOpen={!isSearchExpanded}
          data-testid="close-search-button"
        >
          <StyledSearchButtonIcon svg={ArrowRight} size={16} color="white" isSearchOpen={true} />
        </StyledSearchButton>
      )}

      <StyledSearchForm onSubmit={gotoSearchResultPage}>
        <StyledSearchContainer resultsVisible={isSearchExpanded}>
          <StyledSearchField
            ref={inputRef}
            name="search"
            type="search"
            aria-haspopup={isSearchExpanded}
            aria-autocomplete="list"
            role="combobox"
            variant="rounded"
            placeholder={isMobile ? t('search->field->mobile->placeholder') : t('search->field->placeholder')}
            onChange={onChange}
            value={inputSearchTerm}
            aria-label={isMobile ? t('search->field->mobile->placeholder') : t('search->field->placeholder')}
            autoComplete="off"
            autoFocus={isMobile}
            data-testid="search-field"
          />

          <StyledSearchClearButton shiftLeft={!isMobile} visible={!!inputSearchTerm} type="button" onClick={clear}>
            <Icon svg={Clear} size={16} />
          </StyledSearchClearButton>

          {!isMobile && inputSearchTerm.length > 0 && (
            <StyledSearchButtonSubmit color="black" size="medium" onClick={gotoSearchResultPage}>
              <Icon svg={Search} size={16} color="white" />
            </StyledSearchButtonSubmit>
          )}
        </StyledSearchContainer>
      </StyledSearchForm>

      {isSearchExpanded && (
        <SearchAsYouTypeResults
          suggestions={suggestions}
          results={results}
          maxResults={maxResults}
          onSuggestionClick={(suggestion) => {
            setInputSearchTerm(suggestion.term || '');
            getResults(suggestion.term || '');
          }}
          onProductClick={() => {}}
          usedQuery={usedQuery}
          userInputQuery={inputSearchTerm}
        />
      )}
    </StyledSearchAsYouType>
  );
};

export default SearchAsYouType;
