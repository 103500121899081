import Config from '@config';
import { Api as CmsApi } from '@api/cmsApi';

const api = new CmsApi({
  baseUrl: '/axfoodcommercewebservices/v2',
});

export const findComponentsByIds = async (componentIds: Array<string>, storeId?: string) => {
  const res = await api.baseSiteId.findComponentsByIds(Config.SITE_NAME, {
    componentIds,
    storeId,
  });
  return res.data;
};
