import useTranslation from 'next-translate/useTranslation';
import getPromotionPrices from '@helpers/getPromotionPrices';
import {
  StyledProductPrice,
  StyledProductPriceDecimal,
  StyledProductPriceIcon,
  StyledProductPriceSymbol,
  StyledProductPriceText,
  StyledProductPriceTextWrapper,
  StyledProductPriceUnit,
  StyledProductPriceUserIcon,
  StyledProductPriceWrapper,
} from '@molecules/PriceLabel/PriceLabel.styles';
import type {
  AxfoodBasicProductViewModel,
  AxfoodOfflineProductViewModel,
  AxfoodProductDetailsViewModel,
} from '@api/storeFrontApi';
import User from '@icons/user.svg';
import WillysPlusLogo from '@icons/non-standard/WILLYS-PLUS-LOGO.svg';

export type PriceLabelVariant = 'list' | 'normal';
interface Props {
  product: AxfoodProductDetailsViewModel | AxfoodOfflineProductViewModel | AxfoodBasicProductViewModel;
  className?: string;
  variant?: PriceLabelVariant;
}

const PriceLabel = ({ product, className, variant = 'normal' }: Props) => {
  const price = product?.priceNoUnit?.split(',')[0];
  const decimal = product?.priceNoUnit?.split(',')[1];
  const unit = product?.priceUnit?.split('/')[1];
  const promotion = product?.potentialPromotions?.[0];
  const hasLoyaltyPromotion = promotion?.campaignType === 'LOYALTY';
  const hasSegmentedPromotion = promotion?.campaignType === 'SEGMENTED';
  const hasPromotion = !!promotion;
  const hasDepositPrice = 'depositPrice' in product && product.depositPrice !== '';
  const { t } = useTranslation('product');
  const { promotionPrice, promotionDecimal, promotionTail, promotionSymbol } = getPromotionPrices(
    promotion?.rewardLabel
  );
  return (
    <StyledProductPrice
      className={className}
      variant={variant}
      data-testid="product-price"
      hasPromotion={hasPromotion}
      hasLoyaltyPromotion={hasLoyaltyPromotion}
      hasSegmentedPromotion={hasSegmentedPromotion}
    >
      <StyledProductPriceTextWrapper>
        {hasSegmentedPromotion && <StyledProductPriceUserIcon svg={User} size={16} variant={variant} />}
        <StyledProductPriceText variant={variant}>{hasPromotion ? promotionPrice : price}</StyledProductPriceText>
        <StyledProductPriceSymbol>{hasPromotion && promotionSymbol}</StyledProductPriceSymbol>

        <StyledProductPriceWrapper variant={variant}>
          <StyledProductPriceDecimal variant={variant}>
            {hasPromotion ? promotionDecimal : decimal}
          </StyledProductPriceDecimal>
          <StyledProductPriceUnit variant={variant}>
            {hasPromotion ? promotionTail : hasDepositPrice ? t('product->price->deposit') : `/${unit}`}
          </StyledProductPriceUnit>
        </StyledProductPriceWrapper>
      </StyledProductPriceTextWrapper>
      {hasLoyaltyPromotion && <StyledProductPriceIcon svg={WillysPlusLogo} variant={variant} size={48} />}
    </StyledProductPrice>
  );
};
export default PriceLabel;
